import React, { FC } from 'react';
import strings from './clock.translations';
import { ClockType } from './ClockHandler';
import { ClockMethodProps } from './ClockMethod';
import { Button } from '../shared/button/Button';

export const ClockMethodNoLocation: FC<ClockMethodProps> = ({ setViewState }) => {
  return (
    <>
      <p className="text-sm font-content leading-4">{strings.noLocationsClockInDescription}</p>
      <Button
        onClick={() => {
          setViewState(ClockType.Manual);
        }}
      >
        {strings.manualClocking}
      </Button>
    </>
  );
};
