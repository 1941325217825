import React, { FC } from 'react';
import strings from './clock.translations';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import { useDeviceLocation } from '../../services/location/useDeviceLocation';
import { Loader } from '../shared/Loader';
import { Position } from '../../services/location/location-helper';
import { Divider } from '../shared/Divider';
import { WorkTimeDataSource } from '../../services/gql/graphql';
import { useErrorHandler } from 'react-error-boundary';
import { useNearestLocation } from '../../services/location/useNearestLocation';
import { WorkLocationMap } from '../shared/maps/WorkLocationMap';
import { Feature } from '../../services/backstage/Feature';
import { Button } from '../shared/button/Button';
import { useLocationService } from '../../services/location/LocationService';

interface ClockWithLocationProps {
  type: ClockInStatus;
  onSuccess: () => void;
  onForgotten: () => void;
}

export const ClockLocation: FC<ClockWithLocationProps> = ({ type, onSuccess, onForgotten }) => {
  const [setClockState, loading] = useClockService(service => [service.setClockState, service.loadingState]);
  const handleError = useErrorHandler();

  const [location] = useDeviceLocation(service => [service.location]);
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);

  const currentPositionCoords: Position | undefined = location.position?.coords;

  const { closestLocation, distanceOutOfRadius } = useNearestLocation(currentPositionCoords as Position);

  const handleLocationClock = async () => {
    try {
      if (locationId === undefined) return;
      await setClockState(type, locationId, WorkTimeDataSource.Location, {coords: location.position?.coords});
      onSuccess();
    } catch (e) {
      // Capture error and send it up to the error boundary
      handleError(e);
    }
  };

  return (
    <>
      {currentPositionCoords && closestLocation ? (
        <>
          <WorkLocationMap workLocation={closestLocation} personPosition={currentPositionCoords} />
          {!distanceOutOfRadius ? (
            <>
              <p className="text-sm font-content text-green-600 leading-4">{strings.clockLocationText.valid}</p>
              <Button loading={loading} onClick={handleLocationClock} className="mb-4">
                {strings.clock(type)}
              </Button>
            </>
          ) : (
            <>
              <p className="text-sm font-content text-orange-600 leading-4">
                {strings.clockLocationText.tooFar(type, distanceOutOfRadius.toPrecision(3))}
              </p>
              <Button loading={loading} onClick={handleLocationClock} className="mb-4">
                {strings.clockAnyway(type)}
              </Button>
            </>
          )}
          <Feature name="retroClockIn">
            <>
              <Divider />
              <Button
                onClick={() => {
                  onForgotten();
                }}
              >
                {strings.forgotTitle(type)}
              </Button>
            </>
          </Feature>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <Loader className={'h-10'} />
          {!location.dateGranted && <p className="font-content">{strings.allowLocationText}</p>}
        </div>
      )}
    </>
  );
};
