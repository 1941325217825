import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { SubmissionErrorBoundary } from '../../../components/errors/SubmissionErrorBoundary';
import { Modal } from '../../../components/shared/modal/Modal';
import { Title } from '../../../components/shared/text/Title';
import strings from '../manageOrg.translations'
import { AddLocationForm } from './AddLocationForm';

interface AddLocationModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddLocationModal: FC<AddLocationModalProps> = ({ open, onClose }) => {
  const emptyOnClose = () => {
    onClose();
  };
  
  return (
    <Modal open={open} onClose={emptyOnClose}>
      <div className="relative m-8">
        <Title>{strings.addLocationModal.title}</Title>
        <SubmissionErrorBoundary>
          <AddLocationForm onComplete={emptyOnClose} />
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={emptyOnClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
