import { FC } from 'react';
import { TSCardChevronRight } from '../../../components/shared/card/Card';
import { InitialsAvatar } from '../../../components/shared/InitialsAvatar';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export interface Location {
  id: number;
  name: string;
}

interface LocationListProps {
  locations: Location[];
}

export const LocationList: FC<LocationListProps> = ({ locations }) => {
  const navigate = useNavigate();

  const navigateToLocation = (id: number) => {
    navigate(`/manage-location/${id}`);
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
      {locations.map(location => (
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
          <TSCardChevronRight
            onClick={() => navigateToLocation(location.id)}
            title={location.name}
            icon={<InitialsAvatar name={location.name} />}
          />
        </motion.div>
      ))}
    </div>
  );
};
