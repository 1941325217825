import React, { FC } from 'react';
import { format, isToday } from 'date-fns';
import strings from '../../../roster.translations';
import { Button } from '../../../../../components/shared/button/Button';
import { FiCalendar } from 'react-icons/fi';
import { StaffCalendarContextObject } from '../../../rosterTypes';
import { ESSMobileShiftChit } from './ESSMobileShiftChit';
import { useNavigate } from 'react-router-dom';
import { dateToRosterDateFormat } from '../../../rosterHelper';

interface ESSMobileCalendarRowProps {
  date: Date;
  rowContext: StaffCalendarContextObject;
}

export const ESSMobileCalendarRow: FC<ESSMobileCalendarRowProps> = ({ date, rowContext }) => {
  const isCurrentToday = isToday(date);
  const navigate = useNavigate();

  return (
    <div className="grid grid-flow-col gap-2 py-2" style={{ gridTemplateColumns: 'min-content 1fr min-content' }}>
      <div
        className={`w-16 h-16 flex flex-col items-center justify-center rounded-md font-bold ${
          isCurrentToday && 'bg-green-600 text-white'
        }`}
      >
        <p className="text-2xl">{date.getDate()}</p>
        <p className="text-xl">{format(date, 'E')}</p>
      </div>
      <ESSMobileShiftChit
        rowContext={rowContext}
      />
      {rowContext.hasRoster && (
        <Button onClick={ () => {
          navigate(`/calendar/${rowContext.id}/${dateToRosterDateFormat(date).substring(0, 10)}/StaffRosterView`);
        }} className="flex items-center space-x-2">
          <FiCalendar className="h-6 w-6" />
          <p className="whitespace-nowrap">{strings.essMobile.viewRoster}</p>
        </Button>
      )}
    </div>
  );
};
