import { FC } from 'react';
import { CreateCalendarIcon } from '../../../../../components/shared/icons/CreateCalendarIcon';
import strings from '../../../roster.translations';

interface CreateButtonProps {}

export const CreateButton: FC<CreateButtonProps> = () => {
  return (
    <div
      className={`h-full w-full flex items-center justify-center flex-row space-x-8 px-4 opacity-0 hover:opacity-100`}
    >
      <div className="justify-center items-center flex-col">
        <CreateCalendarIcon />
        <p className="text-xs font-bold">{strings.create.toUpperCase()}</p>
      </div>
    </div>
  );
};
