import React, { FC } from 'react';
import { StaffOption } from '../rosterTypes';
import { RosterPickerIcon } from './RosterPickerIcon';

interface RosterPickerButtonProps {
  value: StaffOption;
}

export const RosterPickerButton: FC<RosterPickerButtonProps> = ({ value }) => {
  return (
    <div className="flex flex-row gap-4 items-center justify-center">
      {value?.id && <RosterPickerIcon name={value?.name}/>}
      <p className="text-ellipsis truncate">{value?.name}</p>
    </div>
  );
};
