import React, { FC, useEffect, useState } from 'react';
import strings from './clock.translations';
import { parseJSON, setSeconds } from 'date-fns';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import { WorkTimeDataSource } from '../../services/gql/graphql';
import { useErrorHandler } from 'react-error-boundary';
import { dateToGQLFormat } from '../../helpers/dateHelper';
import { useDeviceLocation } from '../../services/location/useDeviceLocation';
import { Button } from '../shared/button/Button';
import { DateTimeInput } from '../shared/date/DateTimeInput';
import { useLocationService } from '../../services/location/LocationService';
import { SelectInput, SelectInputOption } from '../shared/form/SelectForm';

interface clockForgotProps {
  type: ClockInStatus;
  onSuccess: () => void;
}

export const ClockForgot: FC<clockForgotProps> = ({ type, onSuccess }) => {
  const [setClockState, loading, error, clockLocationId] = useClockService(service => [
    service.setClockState,
    service.loadingState,
    service.errorMessage,
    service.clockLocationId
  ]);
  const [location] = useDeviceLocation(service => [service.location]);
  const [selectedLocationId, setSelectedLocation, locationList] = useLocationService(state => [
    state.selectedLocation?.id,
    state.setSelectedLocation,
    state.locationList
  ]);

  const locationId = clockLocationId || selectedLocationId;

  const locationOptions = locationList?.map(loc => ({
    id: loc.id.toString(),
    name: loc.name
  }));

  const getDefaultOption = () => {
    return locationOptions.find(opt => opt.id === locationId?.toString());
  }

  const [selectedOption, setSelectedOption] = useState<SelectInputOption | undefined>(getDefaultOption);

  const onLocationChange = (newLocOption?: SelectInputOption) => {
    setSelectedOption(newLocOption);
    if (newLocOption) {
      const newLoc = locationList.find(loc => loc.id.toString() === newLocOption.id);

      if (newLoc) {
        setSelectedLocation(newLoc);
      }
    }
  };

  const handleError = useErrorHandler();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (!selectedDate) return;
    const formattedDate = dateToGQLFormat(selectedDate);
    const cleanedDate = setSeconds(parseJSON(formattedDate ?? new Date()), 0); //Strip seconds from dateTime

    if (cleanedDate > new Date()) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [selectedDate]);

  const onForgotSubmit = async () => {
    try {
      if (!selectedDate || locationId === undefined) return;
      await setClockState(type, locationId, WorkTimeDataSource.ManualLate, {
        coords: location.position?.coords,
        manualDate: dateToGQLFormat(selectedDate)
      });
      onSuccess();
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <>
      <p className="text-sm font-content leading-5">{strings.forgotDescription(type)}</p>
      {locationOptions.length > 1 && (
        <SelectInput
          name="selected-location"
          placeholder={strings.selectLocation}
          value={selectedOption}
          onChange={onLocationChange}
          options={locationOptions}
          className="w-full"
          disabled={!!clockLocationId}
        />
      )}
      <DateTimeInput title={strings.common.date} name="dateTime" value={selectedDate} onChange={setSelectedDate} />
      {error && <p className="text-sm text-red-500 leading-5">{error}</p>}
      {!isValid && <p className="text-sm text-red-500 leading-5">{strings.errorFutureDate}</p>}
      <Button
        primary
        loading={loading}
        disabled={!isValid}
        onClick={() => {
          onForgotSubmit();
        }}
      >
        {strings.common.submit}
      </Button>
    </>
  );
};
