import React, { FC } from 'react';
import strings from './myData.translations';
import { TSTitleCard } from '../../components/shared/card/Card';
import { RiFileUserFill } from 'react-icons/ri';

interface ContactUsCardProps {}

export const contactUsCardId = 'contact-us-card';

export const ContactUsCard: FC<ContactUsCardProps> = () => {
  const contactAddress = 'dpo@timeshift.work';

  return (
    <TSTitleCard
      id={contactUsCardId}
      title={strings.contactUsCard.title}
      descriptor={strings.contactUsCard.gdpr}
      icon={RiFileUserFill}
    >
      <div>
        <p className="leading-5">
          {strings.contactUsCard.description}{' '}
          <a className="text-secondary-500 " href={`mailto:${contactAddress}`}>
            {contactAddress}
          </a>
        </p>
      </div>
    </TSTitleCard>
  );
};
