import { FC, HTMLAttributes } from 'react';
import { ClockPopover } from '../../components/clock/ClockPopover';
import { Feature } from '../../services/backstage/Feature';
import { FiCalendar, FiSettings, FiUser } from 'react-icons/fi';
import strings from './home.translations';
import { isAdmin, Role, useAuthenticationService } from '../../services/authentication/authentication-service';
import { TenantLogo } from '../../components/shared/TenantLogo';
import { QuickLink } from './QuickLink';
import { useGetMeQuery } from '../../services/gql/graphql';

interface HomeQuickLinksProps extends HTMLAttributes<HTMLDivElement> {}

export const HomeQuickLinks: FC<HomeQuickLinksProps> = () => {
  const [role] = useAuthenticationService(state => [state.role]);
  const [{ data }] = useGetMeQuery();
  const myUserId = data!.myEmployee!.identityId;

  return (
    <div className="flex flex-col max-w-md mx-auto h-auto md:h-[100vh] p-7 bg-primary-std border-primary-std border rounded-lg">
      <TenantLogo />
      <div className="flex flex-col gap-2 py-5 border-b mb-5">
        <Feature name="respect">
          {role !== Role.SuperAdmin && (
            <QuickLink url={`/team-member/${myUserId}`} label={strings.quickLinks.myProfile} icon={<FiUser />} />
          )}
        </Feature>
        <Feature name="roster">
          <QuickLink url={'/calendar'} label={strings.quickLinks.calendar} icon={<FiCalendar />} />
        </Feature>
        <Feature name="devices">
          {isAdmin(role) && (
            <QuickLink url={'/manage-organisation'} label={strings.quickLinks.manageOrg} icon={<FiSettings />} />
          )}
        </Feature>
      </div>

      <Feature name="clockIn">
        <>
          <ClockPopover />
        </>
      </Feature>
    </div>
  );
};
