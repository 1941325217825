import { HTMLAttributes } from "react";
import { Flags } from "./backstage.model";
import { useFlags } from "./BackstageProvider";

interface FeatureProps extends HTMLAttributes<HTMLDivElement> {
    name: Flags | Flags[]
}

export const Feature: React.FC<FeatureProps> = ({ name, children }) => {
    if (!Array.isArray(name)) {
      name = Array(name)
    }

    const flags = useFlags<Flags>(name);

    const keys = Object.keys(flags) as Flags[]
    const canShow = keys.some(key => flags[key]); 
  
    if (!children) return null;
    if (!canShow) return null;
  
    return (
      <>
        {children}
      </>
    );
  };