import React, { FC } from 'react';
import { TimeshiftAvatar } from '../../../components/shared/TimeshiftAvatar';
import { SkeletonLoader } from '../../../components/shared/SkeletonLoader';

interface UnverifiedClockCardSkeletonProps {}

export const UnverifiedClockCardSkeleton: FC<UnverifiedClockCardSkeletonProps> = () => {
  return (
    <div className="flex flex-col py-2 px-5 sm:flex-row border border-primary-std rounded bg-primary-std">
      <div className="flex items-center gap-2 m-2 w-full animate-pulse">
        <TimeshiftAvatar name={'Loading'} size={40} greyscale={true} />
        <div className="flex m-2 gap-2 flex-col w-full h-full">
          <SkeletonLoader className="w-[50%] h-full" />
          <SkeletonLoader className="w-full h-full" />
        </div>
      </div>
      <div className="flex m-2 gap-2 w-full sm:flex-col border-t sm:border-t-0">
        <SkeletonLoader className="w-full h-full" />
        <SkeletonLoader className="w-[50%] h-full" />
      </div>
    </div>
  );
};
