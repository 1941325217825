import LocalizedStrings from 'react-localization';
import { withCommon } from '../../services/translation/commonTranslations';

const sharedStrings = new LocalizedStrings({
  en: {
    next: 'Next',
    previous: 'Previous',
    submit: 'Submit',
    loading: {
      longerThanExpected: 'This is taking a bit longer than expected.',
      ifDelayContinues: 'If this delay continues to occur please use the button below to contact support.',
      restartApplication: 'Restart application',
      contactSupport: 'Contact support'
    },
    date: {
      previousWeek: 'Previous week',
      nextWeek: 'Next week'
    },
    table: {
      rowExpandLabel: `Show additional details`
    },
    dateRangeInput: {
      noSelected: 'No selected',
      clear: 'Clear'
    }
  }
});

export default withCommon<typeof sharedStrings>(sharedStrings);
