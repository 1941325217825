import { BusynessLevel } from './rosterTypes';
import {
  ReadRoster,
  ReadRosterTask,
  useGetRostersByLocationAndDateQuery,
  WorkingPatternDay
} from '../../services/gql/graphql';
import { positionTask } from './ganttView/RosterTaskPopover';
import { convertDateToGQLFormat, isDateEqual } from '../../helpers/dateHelper';
import { addDays, subDays } from 'date-fns';
import { handleError } from '../../services/gql/config/URQLProvider';

export const busynessToInputStyle = (level: BusynessLevel) => {
  const inputStyle: string =
    level === BusynessLevel.BUSY
      ? 'text-red-500 focus:ring-red-500'
      : level === BusynessLevel.NORMAL
      ? 'text-yellow-500 focus:ring-yellow-500'
      : 'text-green-500 focus:ring-green-500';

  return inputStyle;
};

export const busynessToBgStyle = (level: BusynessLevel) => {
  const bgStyle: string =
    level === BusynessLevel.BUSY ? 'bg-red-100' : level === BusynessLevel.NORMAL ? 'bg-yellow-100' : 'bg-green-100';

  return bgStyle;
};

export const busynessToTarget = (level: BusynessLevel) => {
  switch (level) {
    case BusynessLevel.BUSY:
      return 3;
    case BusynessLevel.NORMAL:
      return 2;
    case BusynessLevel.QUIET:
      return 1;
  }
};

export const dateToRosterDateFormat = (date: Date) => {
  return date.toISOString().substring(0, 19);
};

export const useGetStationTasksForPosition = (tasks: ReadRosterTask[], startTime: Date, endTime: Date) => {
  let taskArray: positionTask[] = [];
  const startSeconds = startTime.getTime() / 1000;
  const endSeconds = endTime.getTime() / 1000;

  tasks.forEach((task: ReadRosterTask) => {
    const lowerBound = task.timeSlots![0].start;
    const upperBound = task.timeSlots![0].end;

    if (
      (startSeconds > lowerBound && startSeconds < upperBound) ||
      (endSeconds > lowerBound && endSeconds < upperBound)
    ) {
      const existingTask = taskArray.find(t => t.name === task.taskName);

      if (existingTask) {
        existingTask.count++;
      } else {
        taskArray.push({ name: task.taskName, count: 1 });
      }
    }
  });

  return { taskArray };
};

export const canWorkTargetDay = (workPatterns: WorkingPatternDay[], targetDate: Date) => {
  const canWorkFromPattern = !!workPatterns?.find(
    (dayPattern: WorkingPatternDay) => dayPattern.dayNumber === targetDate.getDay()
  )?.startTime;

  return canWorkFromPattern;
};

export const useGetAdjacentRosters = (
  rosterDate: Date
): { prevRoster: ReadRoster | undefined; nextRoster: ReadRoster | undefined } => {
  const ADJACENT_DATE_RANGE: number = 7;

  const [{ data, error }] = useGetRostersByLocationAndDateQuery({
    variables: {
      locationAndDay: {
        locationId: 1,
        dateRangeStart: convertDateToGQLFormat(subDays(rosterDate, ADJACENT_DATE_RANGE)),
        dateRangeEnd: convertDateToGQLFormat(addDays(rosterDate, ADJACENT_DATE_RANGE))
      }
    },
    requestPolicy: 'cache-first'
  });

  if (error) {
    handleError(error);
  }

  const existingRosterIdx: number =
    data?.rostersInDateRange?.findIndex(roster => isDateEqual(new Date(roster.rosterDate), rosterDate)) ?? 0;

  return {
    prevRoster: (data?.rostersInDateRange![existingRosterIdx - 1] as ReadRoster) ?? undefined,
    nextRoster: (data?.rostersInDateRange![existingRosterIdx + 1] as ReadRoster) ?? undefined
  };
};

export const getRosteredStaffIds = (roster: ReadRoster) => {
  const ids: string[] = [];

  roster.stations?.forEach(station => {
    station.positions?.forEach(position => {
      const id = position.employee?.identityId;
      if (id) {
        ids.push(id);
      }
    });
  });

  return ids;
};
