import { FC } from 'react';
import strings from '../shifts.translations';
import { format } from 'date-fns';
import { ShiftDetailsSummaryType } from './ShiftDetailsView';

interface TimeSummaryProps {
  type: ShiftDetailsSummaryType;
  dateTime: Date;
  rightAlign?: boolean;
}

export const TimeSummary: FC<TimeSummaryProps> = ({ type, dateTime, rightAlign }) => {
  return (
    <div
      className={`flex flex-col ${type === ShiftDetailsSummaryType.OUT ? 'items-end sm:items-start' : 'items-start'}`}
    >
      <div>{type === ShiftDetailsSummaryType.IN ? strings.detailView.clockIn : strings.detailView.clockOut}</div>
      <div className="text-4xl font-bold">{format(dateTime, 'HH:mm')}</div>
      <div className="hidden sm:block">{format(dateTime, 'do MMMM')}</div>
      <div className="sm:hidden">{format(dateTime, 'do MMM')}</div>
    </div>
  );
};
