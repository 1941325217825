import { FC } from 'react';
import { AiOutlineClockCircle, AiOutlineWarning } from 'react-icons/ai';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { RosterCalendarContextObject } from '../RosterCalendar';

interface SummaryStatsProps {
  contextObject: RosterCalendarContextObject
}

export const SummaryStats: FC<SummaryStatsProps> = ({ contextObject }) => {
  const employees = contextObject.numberOfEmployees;
  const hoursWorked = contextObject.numberOfHoursWorked;
  const errors = contextObject.numberOfErrors;
  
  return (
    <div className="flex flex-col hidden">
      <div className="flex items-center space-x-2">
        <HiOutlineUserGroup />
        <p>{employees}</p>
      </div>
      <div className="flex items-center space-x-2">
        <AiOutlineClockCircle />
        <p>{hoursWorked}</p>
      </div>
      <div className="flex items-center space-x-2">
        <AiOutlineWarning />
        <p>{errors}</p>
      </div>
    </div>
  );
};
