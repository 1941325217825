import LocalizedStrings from 'react-localization';
import { withCommon } from '../services/translation/commonTranslations';

const pagesStrings = new LocalizedStrings({
  en: {
    notFound: {
      number: '404',
      title: 'Page not found',
      tryAgain: 'Please check the URL in the address bar and try again.',
      goHome: 'Go home',
      contactSupport: 'Contact support'
    },
    sandbox: {
      clockOutSwitch: 'Switch'
    }
  }
});

export default withCommon<typeof pagesStrings>(pagesStrings);
