import { FC, HTMLAttributes } from 'react';
import { useVariable } from '../../services/backstage/BackstageProvider';

interface TenantLogoProps extends HTMLAttributes<HTMLDivElement> {}

export const TenantLogo: FC<TenantLogoProps> = () => {
  const tenantLogo = useVariable('logo');
  const tenantLogoUrl = useVariable('logoUrl');

  return tenantLogo ? (
    <div aria-hidden className="w-full" dangerouslySetInnerHTML={{ __html: decodeURIComponent(tenantLogo) }} />
  ) : tenantLogoUrl ? (
    <div
      className="h-24 w-full bg-contain bg-no-repeat bg-center mx-auto"
      style={{ backgroundImage: `url(${tenantLogoUrl})` }}
    />
  ) : (
    <div className="h-24 bg-gray-500" />
  );
};
