import React, { FC, ReactNode } from 'react';
import { merge } from '../../../helpers/Utility';
import { Checkbox } from './Checkbox';
import { InputProps, withField } from '../form/Field';

interface checkboxGroupItem {
  key: string;
  checked: boolean;
  descriptionMarkup: ReactNode;
  className?: string;
}

interface CheckboxGroupProps extends InputProps<string> {
  items?: checkboxGroupItem[];
  fieldSetClasses?: string;
  labelClasses?: string;
  noOptionsString?: string;
  color?: string;
  initialValue?: string[];
}

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  name,
  items,
  fieldSetClasses,
  onChange,
  noOptionsString,
  labelClasses,
  color
}) => {
  return (
    <fieldset className={fieldSetClasses || ''} id={name}>
      {items && items.length ? (
        items.map((item: checkboxGroupItem) => {
          const { key, descriptionMarkup, checked } = item;

          return (
            <div className="flex flex-grow min-w-[33%] md:p-1">
              <label key={`${name}-${key}`} className={`${labelClasses ? labelClasses : ''} flex w-full p-2 space-x-2`}>
                <Checkbox
                  className={merge(item.className, 'rounded-md')}
                  onChange={() => onChange(key)}
                  checked={checked}
                  color={color}
                />
                <div className="flex flex-col leading-4">{descriptionMarkup}</div>
              </label>
            </div>
          );
        })
      ) : (
        <p className="text-red-500">{noOptionsString}</p>
      )}
    </fieldset>
  );
};

export const CheckboxGroupField = withField(CheckboxGroup, 'Optional', true);
