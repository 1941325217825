import { FC } from 'react';
import strings from '../../team.translations';
import { PageContainer } from '../../../../components/page/PageContainer';
import { PageHeader } from '../../../../components/page/PageHeader';
import { FiUsers } from 'react-icons/fi';
import { PageSizer, SizerMode } from '../../../../components/page/PageSizer';
import { SubmissionErrorBoundary } from '../../../../components/errors/SubmissionErrorBoundary';
import { AddMultipleTeamMembersForm } from './AddMultipleTeamMembersForm';

export const AddMultipleTeamMembers: FC = () => {
  const breadcrumbs = [
    { name: strings.teamPageTitle, to: '/team' },
    { name: strings.addTeamMenu.addYourTeam, to: '/team/add-multiple' }
  ];

  return (
    <PageContainer>
      <PageHeader pageTitle={strings.addTeamMenu.addYourTeam} breadcrumbs={breadcrumbs} icon={<FiUsers />} />
      <PageSizer mode={SizerMode.PAGE}>
        <SubmissionErrorBoundary>
          <AddMultipleTeamMembersForm />
        </SubmissionErrorBoundary>
      </PageSizer>
    </PageContainer>
  );
};
