import { FC, HTMLAttributes } from 'react';
import { merge } from '../../helpers/Utility';

export enum SizerMode {
  CONTENT,
  HEADER,
  PAGE
}

interface PageSizerProps extends HTMLAttributes<HTMLDivElement> {
  mode?: SizerMode;
}

export const PageSizer: FC<PageSizerProps> = ({ mode, children, className, ...props }) => {
  let classToApply = merge('max-w-7xl w-full mx-auto sm:px-4 lg:px-8', className);

  if (mode === SizerMode.HEADER || mode === SizerMode.PAGE) {
    classToApply = merge(classToApply, 'py-4 px-4');
  }

  if (mode === SizerMode.PAGE) {
    classToApply = merge(classToApply, 'lg:py-8');
  }

  return (
    <div {...props} className={classToApply}>
      {children}
    </div>
  );
};
