import { FC } from 'react';
import { Row } from 'react-table';
import { RiArrowRightSFill, RiArrowDownSFill } from 'react-icons/ri';
import strings from '../shared.translations';

interface TableExpanderProps {
  row: Row<any>;
}

export const TableExpander: FC<TableExpanderProps> = ({ row }) => {
  const iconStyle = 'h-7 w-7';
  const label = strings.table.rowExpandLabel;

  return (
    <span {...row.getToggleRowExpandedProps()}>
      <button aria-label={label}>
        {row.isExpanded ? <RiArrowDownSFill className={iconStyle} /> : <RiArrowRightSFill className={iconStyle} />}
      </button>
    </span>
  );
};
