import { FC, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  Column,
  PluginHook,
  Row,
  TableOptions as WrongTableOptions,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { Loader } from '../Loader';
import { RowView } from './Row';
import { PageControls } from '../pagination/Pagination';
import strings from './table.translations';
import { TableHeader } from './TableHeader';

interface CorrectTableOptions<T extends object = {}> extends Omit<WrongTableOptions<T>, 'columns'> {
  columns: Column<T> | Column<T>[];
}

// There appears to be an issue with the published types that stop a valid
// configuration of the table - This patches that type.
declare module 'react-table' {
  export function useTable<D extends object = {}>(
    options: CorrectTableOptions<D>,
    ...plugins: Array<PluginHook<D>>
  ): TableInstance<D>;
}

export interface SubComponentProps<T> {
  row: T;
}

export interface TableProperties<T extends Record<string, unknown>> extends CorrectTableOptions<T> {
  loading?: boolean;
  onClick?: (row: Row<T>) => void;
  pageSize?: number;
  DetailsView?: FC<SubComponentProps<T>>;
  filterValue?: any;
  includeRecord?: (record: T, filterValue: any) => boolean;
  autoExpandIdx?: number;
  rowIdCallback?: (row: Row<T>) => string;
}

export function TableContents<T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProperties<T>>
): ReactElement {
  const { loading, onClick, DetailsView, includeRecord, filterValue, pageSize, autoExpandIdx, rowIdCallback } = props;

  const globalFilter = useCallback(
    (rows: Row[], columns: Column[], filterVal: any) => {
      let trimmedVal = filterVal.trim();

      return !includeRecord || rows.filter(row => includeRecord(row.values as T, trimmedVal));
    },
    [includeRecord]
  );

  const autoExpand = useMemo(() => {
    const autoExpandObj: { [key: string]: boolean } = {};
    autoExpandObj[autoExpandIdx!] = true;
    return autoExpandObj;
  }, [autoExpandIdx]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
    prepareRow
  } = useTable<T>(
    {
      globalFilter: includeRecord ? globalFilter : undefined,
      initialState: {
        expanded: autoExpandIdx && autoExpandIdx !== -1 ? autoExpand : {},
        pageSize,
        globalFilter: filterValue,
        sortBy: [
          {
            id: 'nameAndId',
            desc: false
          }
        ]
      },
      ...props
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filterValue);
  }, [filterValue, setGlobalFilter]);

  const contents = pageSize ? page : rows;

  let showHeader: boolean = false;
  if (Array.isArray(props.columns)) {
    props.columns.forEach(column => {
      if (column.Header !== undefined) {
        showHeader = true;
      }
    });
  }

  return (
    <>
      <div className={'w-full overflow-hidden'}>
        <table className="min-w-full divide-y divide-primary-std" {...getTableProps}>
          {showHeader && <TableHeader headerGroups={headerGroups} />}
          {contents && contents.length > 0 ? (
            <tbody className="divide-y divide-primary-std" {...getTableBodyProps}>
              {contents.map((row, index) => {
                prepareRow(row);
                return (
                  <RowView
                    key={row.id}
                    id={rowIdCallback ? rowIdCallback(row) : undefined}
                    row={row}
                    DetailsView={DetailsView}
                    onClick={onClick}
                    index={index}
                  />
                );
              })}
            </tbody>
          ) : null}
        </table>
      </div>
      {pageSize && contents && contents.length > 0 && (
        <PageControls
          canGoBackward={canPreviousPage}
          canGoForward={canNextPage}
          currentPage={pageIndex}
          numberOfPages={pageCount}
          forward={nextPage}
          backward={previousPage}
        />
      )}
      {contents && contents.length === 0 && !loading ? (
        <div className="py-5 px-6 w-full flex justify-center border-t border-primary-std">
          <p className="text-xs font-medium text-primary-std uppercase py-1">{strings.nothingToShow}</p>
        </div>
      ) : loading ? (
        <div className="py-5 px-6 w-full flex justify-center bg-secondary-std border-t border-primary-std">
          <Loader />
        </div>
      ) : null}
    </>
  );
}

export default TableContents;
