import { WorkingPatternDay, WorkingPatternDayInput, WorkingPatternPeriods } from '../services/gql/graphql';
import { secondsSinceMidnightToTimeString, timeStringToSecondsSinceMidnight } from './dateHelper';

export interface EmployeeSchedule {
  dayNumber?: number;
  id: string;
  day: string;
  start?: string;
  end?: string;
  available: boolean;
}

export const patternToEmployeeSchedule = (data: WorkingPatternPeriods) => {
  let returnVal: EmployeeSchedule[] = [];
  const periods = data.periods;

  // Sunday goes to the end.
  const dayIndexes = [1, 2, 3, 4, 5, 6, 0];

  if (Array.isArray(periods) && periods.length > 0) {
    let pattern: WorkingPatternDay[];
    pattern = periods[0].patterns!;

    returnVal = dayIndexes.map(index => {
      const day = pattern.find(p => p.dayNumber === index);
      const available = !!day?.endTime;

      return {
        dayNumber: day?.dayNumber,
        id: day?.id,
        day: day?.day || '',
        start: available ? secondsSinceMidnightToTimeString(day?.startTime) : undefined,
        end: available ? secondsSinceMidnightToTimeString(day?.endTime) : undefined,
        available: available
      };
    });
  }

  return returnVal;
};

export const employeeScheduleToPattern = (schedule: EmployeeSchedule[]) => {
  const patterns: WorkingPatternDayInput[] = [];

  schedule.forEach(day => {
    const startTime = day.available ? timeStringToSecondsSinceMidnight(day.start || '') : undefined;
    let endTime = day.available ? timeStringToSecondsSinceMidnight(day.end || '') : undefined;
    if (endTime === 0) {
      // An end time of 0 actually means 'a full day since midnight'.
      endTime = 24 * 60 * 60;
    }

    patterns.push({
      startTime: startTime,
      endTime: endTime,
      available: day.available,
      dayNumber: day.dayNumber
    });
  });

  return patterns;
};
