import { FC, useCallback, useState } from 'react';
import { dateToTimeString } from '../../../helpers/dateHelper';
import { GanttSlider } from './GanttSlider';

interface GanttTimePickerProps {
  startTime: Date;
  endTime: Date;
  onChangeTimes: (newStart: Date, newEnd: Date) => void;
  minTime: Date;
  maxTime: Date;
  color: string;
  minuteIncrement?: number;
  readOnly?: boolean;
}

export const DEFAULT_MINUTE_INCREMENT = 15;
export const TIME_PICKER_CELL_SIZE = 120;
export const END_BUFFER_CELL_SIZE = 100;

export const GanttTimePicker: FC<GanttTimePickerProps> = ({
  minTime,
  maxTime,
  startTime,
  endTime,
  onChangeTimes,
  color,
  minuteIncrement = DEFAULT_MINUTE_INCREMENT,
  readOnly
}) => {
  /*
   * The Gantt slider needs to have two versions of start and end time.
   *
   * startTime/endTime are the 'real' picker values. These are the things the rest of the system knows about.
   * workingStartTime/workingEndTime are what the component is currently showing.
   *
   * We need the two different versions because we want the Gantt picker to show the correct time while you're in
   * the middle of sliding on of the ends, but we don't want to spam the onChange handler.
   */
  const [workingStartTime, setWorkingStartTime] = useState<Date>(startTime);
  const [workingEndTime, setWorkingEndTime] = useState<Date>(endTime);

  // This is called when we're happy with the times shown, and want to tell the rest of the system.
  const confirmWorkingTimes = useCallback(
    (startToConfirm: Date, endToConfirm: Date) => {
      onChangeTimes(startToConfirm, endToConfirm);
    },
    [onChangeTimes]
  );

  //TODO - Renenable these methods if we have implement timepickers
  // const updateStartPicker = (newVal?: string) => {
  //   if (!newVal) {
  //     return;
  //   }
  //
  //   let newStart = timeStringToDate(newVal);
  //
  //   if (newStart < minTime) {
  //     newStart = minTime;
  //   }
  //
  //   setWorkingStartTime(newStart);
  //   confirmWorkingTimes(newStart, workingEndTime);
  // };
  //
  // const updateEndPicker = (newVal?: string) => {
  //   if (!newVal) {
  //     return;
  //   }
  //
  //   let newEnd = timeStringToDate(newVal);
  //   if (newEnd > maxTime) {
  //     newEnd = maxTime;
  //   }
  //
  //   setWorkingEndTime(newEnd);
  //   confirmWorkingTimes(workingStartTime, newEnd);
  // };

  return (
    <div className="flex w-full border-b-2">
      <div style={{ width: TIME_PICKER_CELL_SIZE }} className="flex justify-center gap-2 px-2">
        <div className="flex flex-row gap-2 items-center">
          <div>{dateToTimeString(workingStartTime)}</div>
          <p>{'-'}</p>
          <div>{dateToTimeString(workingEndTime)}</div>
        </div>
      </div>
      <div className="flex-1">
        <GanttSlider
          maxTime={maxTime}
          minTime={minTime}
          startTime={workingStartTime}
          endTime={workingEndTime}
          onChangeStartTime={setWorkingStartTime}
          onChangeEndTime={setWorkingEndTime}
          onConfirmTimes={confirmWorkingTimes}
          color={color}
          minuteIncrement={minuteIncrement}
          readOnly={readOnly}
        />
      </div>
      <div style={{ width: END_BUFFER_CELL_SIZE }}></div>
    </div>
  );
};
