import React, { FC, Suspense } from 'react';
import { FabricModalHost } from './components/fabric/Fabric';
import { Route, Routes } from 'react-router-dom';
import { URQLProvider } from './services/gql/config/URQLProvider';
import { NotFound } from './pages/NotFound';
import { Login } from './services/authentication/routes/Login';
import { Logout } from './services/authentication/routes/Logout';
import { PostLogout } from './services/authentication/routes/PostLogout';
import { Redirect } from './services/authentication/routes/Redirect';
import { CatastrophicErrorBoundary } from './components/errors/CatastrophicErrorBoundary';
import { AppInsightsRouter, reactPlugin } from './helpers/AppInsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Authenticated } from './services/authentication/routes/AuthenticatedRoute';
import { Loading } from './components/shared/Loading';
import { NativeFontManager } from './helpers/native/NativeFontManager';
import { HomePage } from './pages/home/HomePage';
import { TeamMember } from './pages/team/teamMember/TeamMember';
import { GetStartedPage } from './services/authentication/routes/GetStarted';
import { NFCRedirect } from './components/clock/NFCRedirect';
import { NFCClock } from './components/clock/NFCClock';
import { MyDataPage } from './pages/myData/MyData';
import { ToastService } from './services/notification/ToastService';
import { BackstageProvider } from './services/backstage/BackstageProvider';
import { TeamPage } from './pages/team/teamTable/TeamPage';
import { AllShiftsReport } from './pages/shifts/shiftReports/AllShiftsReport';
import { AllShiftsPage } from './pages/shifts/AllShiftsPage';
import { AddMultipleTeamMembers } from './pages/team/teamTable/addTeamMembers/AddMultipleTeamMembers';
import { Scribbles } from './pages/scribble/Scribbles';
import { CalendarScribble } from './pages/scribble/CalendarScribble';
import { RosterPage } from './pages/roster/ganttView/RosterPage';
import { RosterSelectPage } from './pages/roster/calendar/rosterCalendar/RosterSelectPage';
import { TeamMemberScheduleEdit } from './pages/team/teamMember/overview/TeamMemberScheduleEdit';
import { RosterCreate } from './pages/roster/create/RosterCreate';
import { RosterRequirementPage } from './pages/roster/requirementView/RosterRequirementPage';
import { RosterCommonWrapper } from './pages/roster/ganttView/RosterCommonWrapper';
import { CalendarPage } from './pages/roster/calendar/ESS/CalendarPage';
import { StaffRosterPage } from './pages/roster/calendar/ESS/StaffRosterPage';
import { TeamMemberDetailsEdit } from './pages/team/teamMember/overview/TeamMemberDetailsEdit';
import { ManageOrgPage } from './pages/manageOrg/ManageOrgPage';
import { AllLocationsPage } from './pages/manageOrg/locations/AllLocationsPage';
import { ManageLocationPage } from './pages/manageLocations/ManageLocationPage';
import { MapScribble } from './pages/scribble/MapScribble';
import { AddTeamMemberPage } from './pages/team/teamMember/AddTeamMemberPage';
import { ManageDevicePage } from './pages/devices/ManageDevicePage';
import { AllDevicesPage } from './pages/devices/AllDevicesPage';
import { ClockInOutPage } from './pages/clockInOut/ClockInOutPage';
import { ClockInOutPageContainer } from './pages/clockInOut/ClockInOutContainer';
import { ClockInOutCancel } from './pages/clockInOut/ClockInOutCancel';
import { ClockInOutSuccess } from './pages/clockInOut/ClockInOutSuccess';
import { RequireManager } from './services/authentication/routes/RequireManager';
import { RequireAdmin } from './services/authentication/routes/RequireAdmin';
import { RequireDebug } from './services/authentication/routes/RequireDebug';
import { RequireOwnPageOrManager } from './services/authentication/routes/RequireOwnPageOrManager';
import { PrivacyPolicyPage } from './pages/myData/PrivacyPolicyPage';

const App: FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <AppInsightsRouter>
        <CatastrophicErrorBoundary>
          <BackstageProvider>
            <URQLProvider>
              <ToastService>
                <NativeFontManager />
                <FabricModalHost />
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route element={<Authenticated />}>
                      <Route path="/" element={<HomePage />} />
                      <Route element={<ClockInOutPageContainer />}>
                        <Route path="/clock-in-out" element={<ClockInOutPage />} />
                        <Route path="/clock-in-out/:deviceId/:qrCode" element={<ClockInOutPage />} />
                        <Route path="/clock-in-cancel" element={<ClockInOutCancel />} />
                        <Route path="/clock-in-out-success/:state" element={<ClockInOutSuccess />} />
                      </Route>
                      <Route path="/my-data" element={<MyDataPage />} />
                      <Route path="/NC1yMzRsLWg0Y2st8Jkpg" element={<NFCRedirect />} />
                      <Route path="/nfc" element={<NFCClock />} />
                      <Route element={<RequireAdmin />}>
                        <Route path="/manage-organisation" element={<ManageOrgPage />} />
                        <Route path="/manage-organisation/locations" element={<AllLocationsPage />} />
                      </Route>
                      <Route element={<RequireOwnPageOrManager />}>
                        <Route path="/team-member/:userId" element={<TeamMember />} />
                      </Route>
                      <Route element={<RequireManager />}>
                        <Route path="/manage-location/:locationId" element={<ManageLocationPage />} />
                        <Route path="/manage-location/:locationId/devices" element={<AllDevicesPage />} />
                        <Route path="/manage-location/:locationId/devices/:deviceId" element={<ManageDevicePage />} />
                        <Route path="/team" element={<TeamPage />} />
                        <Route path="/team/add-team-member" element={<AddTeamMemberPage />} />
                        <Route path="/team-member/:userId/set-schedule" element={<TeamMemberScheduleEdit />} />
                        <Route path="/team-member/:userId/edit-details" element={<TeamMemberDetailsEdit />} />
                        <Route
                          path="/team-member/:userId/edit-details/:scrollPayIntoView"
                          element={<TeamMemberDetailsEdit />}
                        />
                        <Route path="/team/add-multiple" element={<AddMultipleTeamMembers />} />
                        <Route path="/shifts/report" element={<AllShiftsReport />} />
                        <Route path="/shifts" element={<AllShiftsPage />}>
                          <Route path=":recordId" element={<AllShiftsPage />}>
                            <Route path=":shiftStart" element={<AllShiftsPage />} />
                          </Route>
                        </Route>
                        <Route path="/roster" element={<RosterSelectPage />} />
                        <Route path="/roster/create" element={<RosterCreate />} />
                        <Route path="/roster/:rosterId" element={<RosterCommonWrapper />}>
                          <Route path=":date" element={<RosterPage />} />
                          <Route path=":date/requirements" element={<RosterRequirementPage />} />
                        </Route>
                      </Route>
                      <Route path="/calendar" element={<CalendarPage />} />
                      <Route path="/calendar/:rosterId/:date/StaffRosterView" element={<StaffRosterPage />} />
                      <Route element={<RequireDebug />}>
                        <Route path="/scribbles" element={<Scribbles />} />
                        <Route path="/scribbles/calendar" element={<CalendarScribble />} />
                        <Route path="/scribbles/map" element={<MapScribble />} />
                      </Route>
                    </Route>
                    <Route path="/get-started" element={<GetStartedPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/logout/callback" element={<PostLogout />} />
                    <Route path="/signin-oidc" element={<Redirect />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </ToastService>
            </URQLProvider>
          </BackstageProvider>
        </CatastrophicErrorBoundary>
      </AppInsightsRouter>
    </Suspense>
  );
};

export default withAITracking(reactPlugin, App);
