import { FC, HTMLAttributes } from 'react';

export const DraftCalendarIcon: FC<HTMLAttributes<SVGSVGElement>> = props => (
  <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5625 11.8627H14.2708V14.0196H16.5625V11.8627ZM21.1458 11.8627H18.8542V14.0196H21.1458V11.8627ZM25.7292 11.8627H23.4375V14.0196H25.7292V11.8627ZM28.0208 4.31372H26.875V2.15686H24.5833V4.31372H15.4167V2.15686H13.125V4.31372H11.9792C10.7073 4.31372 9.69896 5.28431 9.69896 6.47059L9.6875 21.5686C9.6875 22.7549 10.7073 23.7255 11.9792 23.7255H28.0208C29.2813 23.7255 30.3125 22.7549 30.3125 21.5686V6.47059C30.3125 5.28431 29.2813 4.31372 28.0208 4.31372ZM28.0208 21.5686H11.9792V9.70588H28.0208V21.5686Z"
      fill="#666666"
    />
  </svg>
);
