import React, { FC, useCallback, useEffect, useState } from 'react';
import strings from '../roster.translations';
import { ReadRosterPositions, ReadRosterTask } from '../../../services/gql/graphql';
import { GanttTimePicker } from '../../../components/shared/gantt/GanttTimePicker';
import { NOT_ASSIGNED_EMPLOYEE_ID, useRosterService } from './rosterService';
import { LEFT_COLUMN_WIDTH } from './Roster';
import { FiX } from 'react-icons/fi';
import { dateToSecondsSinceMidnight, secondsSinceMidnightToDate } from '../../../helpers/dateHelper';
import { StaffOption } from '../rosterTypes';
import { PickerDirection, RosterPersonPicker } from './RosterPersonPicker';
import { RosterPickerButton } from './RosterPickerButton';
import { RosterTaskPopover } from './RosterTaskPopover';
import { useGetStationTasksForPosition } from '../rosterHelper';

interface PositionProps {
  position: ReadRosterPositions;
  stationId: number;
  stationTasks: ReadRosterTask[];
  color: string;
  dropdownDirection?: PickerDirection;
}

export const Position: FC<PositionProps> = ({ position, stationId, stationTasks, color, dropdownDirection }) => {
  /*
   * To keep re-renders to a minimum, each position component is responsible for it's own state.
   */
  const [positionId, setPositionId] = useState<string>(position.id);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getStaff, updateRosterEmployee, unavailableIds] = useRosterService(state => [
    state.getStaff,
    state.updateRosterEmployee,
    state.rosteredStaffIds
  ]);

  const [staffOptions, setStaffOptions] = useState<StaffOption[]>([]);

  const [startTime, setStartTime] = useState<Date>(secondsSinceMidnightToDate(position.start));
  const [endTime, setEndTime] = useState<Date>(secondsSinceMidnightToDate(position.end));

  let { taskArray } = useGetStationTasksForPosition(stationTasks, startTime, endTime);

  useEffect(() => {
    // If we ever note that the position prop has a different position ID, then we need to reset all the state.
    if (position.id !== positionId) {
      setPositionId(position.id);
      setStartTime(secondsSinceMidnightToDate(position.start));
      setEndTime(secondsSinceMidnightToDate(position.end));
    }
  }, [position, positionId, setPositionId, setStartTime, setEndTime]);

  useEffect(() => {
    const staff = getStaff(stationId, dateToSecondsSinceMidnight(startTime), dateToSecondsSinceMidnight(endTime));

    if (staff) {
      const options = staff.map(item => {
        return {
          id: item.employeeId!,
          name: strings.employeeName(item.firstName, item.lastName)
        };
      });

      options.unshift({
        id: NOT_ASSIGNED_EMPLOYEE_ID,
        name: strings.notAssigned
      });

      // Filter out any employee's that are already in a roster (Excludes employee's own roster).
      setStaffOptions(
        options.filter(
          item =>
            !unavailableIds?.includes(item.id) ||
            (position.employee?.identityId && position.employee.identityId === item.id)
        )
      );
    }
  }, [getStaff, stationId, position, startTime, endTime, unavailableIds]);

  const [updatePositionTimes, deletePosition, completed] = useRosterService(state => [
    state.updatePositionTimes,
    state.deletePositionFromStation,
    state.completed
  ]);
  const [minTime, maxTime] = useRosterService(state => [state.minTime, state.maxTime]);

  const updateTimes = useCallback(
    (newStart: Date, newEnd: Date) => {
      setStartTime(newStart);
      setEndTime(newEnd);
      updatePositionTimes(position.id, newStart, newEnd);
    },
    [setStartTime, updatePositionTimes, position]
  );

  const setCurrentlySelectedEmployee = () => {
    let value = staffOptions.find(item => item.id === position.employee?.identityId);
    if (!value) {
      value = staffOptions[0];
    }
    return value;
  };

  const deleteStationPosition = async () => {
    setIsLoading(true);
    try {
      await deletePosition(positionId);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-row bg-primary-std">
      <div className="flex flex-row gap-2 h-[58px] items-center border-r-2 pr-5" style={{ width: LEFT_COLUMN_WIDTH }}>
        <div className="flex items-center justify-center pl-4 pr-1 w-full">
          {staffOptions?.length === 0 || completed ? (
            <div className="flex w-full">
              <RosterPickerButton value={setCurrentlySelectedEmployee()} />
            </div>
          ) : (
            <RosterPersonPicker
              options={staffOptions}
              value={setCurrentlySelectedEmployee()}
              onChange={(newVal: StaffOption) => {
                updateRosterEmployee(newVal?.id, position.id);
              }}
              selectClassName={staffOptions?.length === 0 ? 'border-dashed bg-gray-500' : ''}
              direction={dropdownDirection}
            />
          )}
        </div>
        <RosterTaskPopover positionTasks={taskArray} bulletColor={color} />
        {!completed && (
          <button
            className={`${isLoading && 'bg-gray-200 animate-pulse'}`}
            onClick={() => {
              !isLoading && deleteStationPosition();
            }}
            disabled={isLoading}
          >
            <FiX className="w-6 h-6 text-gray-500" />
          </button>
        )}
      </div>
      <div className="flex-1">
        <GanttTimePicker
          maxTime={maxTime}
          minTime={minTime}
          startTime={startTime}
          endTime={endTime}
          onChangeTimes={updateTimes}
          color={color}
          readOnly={completed}
        />
      </div>
    </div>
  );
};
