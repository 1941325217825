import { FC, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { RosterStation } from '../../../services/gql/graphql';
import { useRosterService } from './rosterService';
import strings from '../roster.translations';

interface StationHeaderProps {
  station: RosterStation;
  color: string;
  readOnly?: boolean;
}

export const StationHeader: FC<StationHeaderProps> = ({ station, color, readOnly }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addPositionToStation] = useRosterService(state => [state.addPositionToStation]);

  const onAddClick = async () => {
    setIsLoading(true);
    await addPositionToStation(station.stationId);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-1 border-b-2 mx-4 gap-2" style={{ color: color, borderColor: color }}>
      <p className="font-bold flex-1 self-end">{station.stationName}</p>
      {!readOnly && (
        <button
          aria-label={strings.positions.add}
          name={strings.positions.add}
          onClick={() => {
            !isLoading && onAddClick();
          }}
          className={`p-2 h-[fit-content] w-max rounded-full hover:bg-gray-200 ${
            isLoading && 'bg-gray-200 animate-pulse'
          }`}
          disabled={isLoading}
        >
          <div style={{ color: isLoading ? 'grey' : color }}>
            <FiPlus />
          </div>
        </button>
      )}
    </div>
  );
};
