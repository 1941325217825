export const PaginationLocEN = (page: string, outOf: string) => (currentPage: number, numberOfPages?: number) => {
  return (
    <>
      <span className="mr-1">{page}</span>
      <span className="font-bold">{currentPage + 1}</span>
      {numberOfPages ? (
        <>
          <span className="mx-1">{outOf}</span>
          <span className="font-bold text-primary-std">{numberOfPages}</span>
        </>
      ) : null}
    </>
  );
};
