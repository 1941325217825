import * as React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { ReactNode } from 'react';
import { Button } from './button/Button';
import { Modal, ModalProps } from './modal/Modal';

export enum AlertStyle {
  Warning = 'Warning'
}

export interface ConfirmDialogProps extends ModalProps {
  title: string;
  confirmContent: ReactNode;
  style?: AlertStyle;
  onSuccess?: () => void;
  confirmLabel: string;
  confirmButton?: ReactNode;
  cancelLabel: string;
  loading?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onSuccess,
  title,
  confirmContent,
  style,
  confirmLabel,
  cancelLabel,
  confirmButton,
  loading
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          {(() => {
            switch (style) {
              case AlertStyle.Warning:
                return (
                  <div className="mx-auto flex-shrink-0 sm:mr-4 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FiAlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                );
              default:
                return;
            }
          })()}
          <div className="mt-1 text-center sm:mt-0 sm:text-left">
            <h2 className="text-2xl tracking-tight font-bold text-primary">{title}</h2>
            <div className="mt-2">{confirmContent}</div>
          </div>
        </div>
      </div>
      <div className="space-y-2 sm:space-y-0 px-6 py-2 sm:px-6 sm:pb-6 justify-end sm:flex sm:flex-row">
        <Button className="sm:mr-3 w-full sm:w-auto" onClick={() => onClose()}>
          {cancelLabel}
        </Button>
        {confirmButton ? (
          confirmButton
        ) : (
          <Button primary className="w-full sm:w-auto" onClick={onSuccess} loading={loading}>
            {confirmLabel}
          </Button>
        )}
      </div>
    </Modal>
  );
};
