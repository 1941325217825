import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import { TextInput } from '../../components/shared/form/TextInput';
import { Button } from '../../components/shared/button/Button';
import { Watch } from '../../components/shared/icons/Watch';
import strings from '../../components/clock/clock.translations';
import { useLocationService } from '../../services/location/LocationService';
import { useGetMeQuery, WorkTimeDataSource } from '../../services/gql/graphql';
import { LoggerLevel, useLogger } from '../../services/logging/Logger';
import { SelectInput, SelectInputOption } from '../../components/shared/form/SelectForm';
import { useErrorHandler } from 'react-error-boundary';

const CLOCK_CODE_LENGTH = 8;

interface ClockInOutFormProps {
  newStatus: ClockInStatus;
}

interface DeviceWithLocation {
  name: string;
  deviceId: string;
  locationId: number;
}

export const ClockInOutForm: FC<ClockInOutFormProps> = ({ newStatus }) => {
  const { qrCode: urlQrCode } = useParams<'qrCode'>();
  const { deviceId: urlDeviceId } = useParams<'deviceId'>();

  const { log } = useLogger();

  const isCodeValid = (code?: string) => !!code && code.length === CLOCK_CODE_LENGTH;

  const [secureCode, setSecureCode] = useState<string>(urlQrCode || '');
  const [deviceId, setDeviceId] = useState<string>(urlDeviceId || '');

  const navigate = useNavigate();

  const [clockState, setClockState] = useClockService(service => [service.clockState, service.setClockState]);

  const [locationList] = useLocationService(service => [service.locationList]);

  const buildDevicesList = () => {
    const devicesList: DeviceWithLocation[] = [];
    locationList.forEach(loc => {
      loc.devices?.forEach(device => {
        if (device.activated) {
          let name = device.name;

          if (locationList.length > 1) {
            name = device.name + ` (${loc.name})`;
          }

          devicesList.push({
            name,
            deviceId: device.id,
            locationId: loc.id
          });
        }
      });
    });

    return devicesList;
  };

  const [devicesList] = useState<DeviceWithLocation[]>(buildDevicesList());

  const findLocationIdFromDevice = useCallback(
    (deviceId: string) => {
      let locationId: number | undefined;

      devicesList.forEach(device => {
        if (device.deviceId === deviceId) {
          locationId = device.locationId;
        }
      });

      return locationId;
    },
    [devicesList]
  );

  const [locationId, setLocationId] = useState<number | undefined>(findLocationIdFromDevice(deviceId));

  useEffect(() => {
    if (deviceId) {
      setLocationId(findLocationIdFromDevice(deviceId));
    }
  }, [deviceId, findLocationIdFromDevice]);

  const deviceOptions = devicesList?.map(device => ({
    id: device.deviceId,
    name: device.name
  }));
  const [selectedDevice, setSelectedDevice] = useState<SelectInputOption>();

  const onDeviceChange = (newDeviceOption?: SelectInputOption) => {
    setSelectedDevice(newDeviceOption);
    if (newDeviceOption) {
      setDeviceId(newDeviceOption.id);
    }
  };

  const onCodeChange = (newCode: string) => {
    if (newCode.length > CLOCK_CODE_LENGTH) {
      return;
    }

    const onlyDigits = newCode.replace(/\D+/g, '');
    setSecureCode(onlyDigits);
  };

  const [loading, setLoading] = useState<boolean>(false);

  const [{ data: userData }] = useGetMeQuery();
  const handleError = useErrorHandler();

  const onSubmit = async () => {
    setLoading(true);

    try {
      await setClockState(newStatus, locationId!, WorkTimeDataSource.SecureCode, { secureCode, deviceId });
      navigate(`/clock-in-out-success/${newStatus}`);
    } catch (e) {
      handleError(e);
      log(`Employee ${userData?.myEmployee?.identityId} failed to clock in with QR`, LoggerLevel.Error, true);
    } finally {
      setLoading(false);
    }
  };

  if (!urlDeviceId && deviceOptions?.length === 0) {
    return <div>{strings.noDevices}</div>;
  }

  const locationNotAssociated = !locationId && urlDeviceId;

  return (
    <>
      {locationNotAssociated ? (
        <div>{strings.locationNotAssociatedErrorMessage}</div>
      ) : (
        <>
          <div className="flex flex-col gap-5 items-center w-full">
            <div className="flex flex-col gap-5 w-full">
              <TextInput
                name="clock-code"
                placeholder={strings.clockCode}
                value={secureCode}
                onChange={onCodeChange}
                className={`w-full ${!!urlDeviceId ? 'hidden' : ''}`}
              />
              <SelectInput
                name="selected-device"
                placeholder={strings.device}
                value={selectedDevice}
                onChange={onDeviceChange}
                options={deviceOptions}
                className={`w-full ${isCodeValid(urlQrCode) ? 'hidden' : ''}`}
              />
            </div>
            <div className="flex flex-row gap-5 w-full">
              <Button onClick={() => navigate('/clock-in-cancel')} className="flex items-center justify-center w-full">
                {strings.common.cancel}
              </Button>
              <Button
                disabled={!isCodeValid(secureCode)}
                onClick={onSubmit}
                loading={loading}
                primary
                className="flex items-center justify-center w-full gap-2"
              >
                <Watch className="w-4 h-6" />
                {clockState === ClockInStatus.In ? strings.common.clockOut : strings.common.clockIn}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
