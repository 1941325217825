import { FC, useEffect, useMemo, useRef } from 'react';
import {
  AuthenticationType,
  CameraOptions,
  data,
  layer,
  Map as OriginalAzureMap,
  ServiceOptions,
  source,
  StyleOptions
} from 'azure-maps-control';
import './atlas.css';
import { MapProps } from './Map';
import { merge } from '../../../helpers/Utility';

const defaultServiceOptions: ServiceOptions = {
  authOptions: {
    authType: AuthenticationType.subscriptionKey,
    subscriptionKey: 'yEVOsaMGDuas2LMDmJPRMcFS6VcRC17TmGZRnay-ft8'
  }
};

const defaultCameraOptions: CameraOptions = {
  zoom: 14
};

const isDark = window.matchMedia('(prefers-color-scheme: dark)');

const defaultStyleOptions: StyleOptions = {
  view: 'Auto',
  autoResize: true,
  showLogo: false,
  showFeedbackLink: false,
  renderWorldCopies: false,
  style: isDark.matches ? 'night' : 'road'
};

export const AzureMap: FC<MapProps> = ({ className, points, onPointClick, center, origin }) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<OriginalAzureMap | null>();
  const primaryPointSource = useRef(new source.DataSource());
  const secondaryPointSource = useRef(new source.DataSource());
  const dynamicPointSource = useRef(new source.DataSource());

  const config = JSON.stringify({ center, origin, onPointClick });

  const options = useMemo(() => {
    return {
      center,
      origin,
      onPointClick
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if (!ref.current) return;
    const map = new OriginalAzureMap(ref.current, {
      ...defaultServiceOptions,
      ...defaultCameraOptions,
      ...defaultStyleOptions,
      ...options
    });
    map.events.add('ready', () => {
      map.sources.add(primaryPointSource.current);
      map.sources.add(secondaryPointSource.current);
      map.sources.add(dynamicPointSource.current);

      map.layers.add(
        new layer.BubbleLayer(primaryPointSource.current, undefined, {
          radius: 5,
          strokeColor: '#4288f7',
          strokeWidth: 6,
          color: 'white'
        })
      );

      map.layers.add(
        new layer.BubbleLayer(secondaryPointSource.current, undefined, {
          radius: 5,
          strokeColor: '#00ffff',
          strokeWidth: 6,
          color: 'white'
        })
      );

      map.layers.add(
        new layer.BubbleLayer(dynamicPointSource.current, undefined, {
          radius: 5,
          strokeColor: 'red',
          strokeWidth: 6,
          color: 'white'
        })
      );

      map.events.add('click', e => {
        if (options.onPointClick) {
          const long = e.position?.[0];
          const lat = e.position?.[1];
          if (!long || !lat) return;
          options.onPointClick({ longitude: long, latitude: lat });
        }
      });
      mapRef.current = map;
    });
    return () => {
      map.dispose();
    };
  }, [options]);

  useEffect(() => {
    const primaryPoints = points?.filter(point => point.primary && !point.dynamic);
    const secondaryPoints = points?.filter(point => !point.primary && !point.dynamic);
    const dynamicPoints = points?.filter(point => point.dynamic);

    primaryPoints &&
      primaryPointSource.current.add(primaryPoints.map(point => new data.Point([point.longitude, point.latitude])));
    secondaryPoints &&
      secondaryPointSource.current.add(secondaryPoints.map(point => new data.Point([point.longitude, point.latitude])));

    dynamicPointSource.current.clear();
    dynamicPoints &&
      dynamicPointSource.current.add(dynamicPoints.map(point => new data.Point([point.longitude, point.latitude])));
  }, [points]);

  return (
    <>
      <div ref={ref} style={{ background: 'bg-secondary-std' }} className={merge('overflow-hidden', className)} />
    </>
  );
};
