import React, { FC, useEffect } from 'react';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { AiFillHome } from 'react-icons/ai';
import { LargeTitle } from '../../components/shared/text/Title';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import strings from './home.translations';
import { DateTime } from '../../components/shared/date/DateTime';
import { merge } from '../../helpers/Utility';
import { HomeQuickLinks } from './HomeQuickLinks';

const styleForStatus = (status: ClockInStatus) => {
  switch (status) {
    case ClockInStatus.In:
      return 'text-white bg-primary-500';
    case ClockInStatus.Out:
      return 'text-primary-std bg-gray-800 dark:bg-grey bg-opacity-5';
    case ClockInStatus.Unknown:
      return 'text-primary-std bg-gray-800 dark:bg-grey bg-opacity-5';
    default:
      break;
  }
};

const contentForStatus = (status: ClockInStatus) => {
  switch (status) {
    case ClockInStatus.In:
      return <div>{strings.status.statusIn}</div>;
    case ClockInStatus.Out:
      return <div>{strings.status.statusOut}</div>;
    case ClockInStatus.Unknown:
      return <div>{strings.status.statusUnknown}</div>;
    default:
      break;
  }
};

interface HomeHeaderProps {}

export const HomeHeader: FC<HomeHeaderProps> = () => {
  const [clockState, refreshClockState] = useClockService(service => [service.clockState, service.refreshClockState]);

  useEffect(() => {
    refreshClockState();
  }, [refreshClockState])

  return (
    <div className="w-full md:py-3 border-primary-std bg-secondary-std border-b overflow-visible">
      <PageSizer mode={SizerMode.HEADER} className="relative">
        <LargeTitle className="sm:text-3xl flex items-center gap-2">
          <AiFillHome />
          <DateTime format="EEEE do MMMM yyyy" date={new Date()} />
        </LargeTitle>
        <div
          className={merge(
            'relative flex-col lg:w-max md:w-max sm:w-full sm:inline-block py-1.5 px-4 mt-2 text-center rounded-md text-sm font-medium transition-colors',
            styleForStatus(clockState)
          )}
        >
          {contentForStatus(clockState)}
        </div>
        <div className="w-80 hidden md:block absolute top-5 right-5 z-10">
          <HomeQuickLinks />
        </div>
      </PageSizer>
    </div>
  );
};
