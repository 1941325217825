export const constructNameEN = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};

export const getInitialsEN = (name: string) => {
  return name
    ?.match(/\b([A-Z])/g)
    ?.join('')
    .toUpperCase();
};
