import { FC, HTMLAttributes } from 'react';
import { merge } from '../../../helpers/Utility';
import { AutofillIcon } from './AutofillingIcon';

interface AutoFillLoaderProps extends HTMLAttributes<HTMLDivElement> {}

export const AutoFillLoader: FC<AutoFillLoaderProps> = ({ className }) => (
  <div className={merge(className, 'animate-spin-slow')}>
    <AutofillIcon />
  </div>
);
