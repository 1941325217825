import { FC, Fragment, Suspense } from 'react';
import { AzureMap } from './AzureMap';

export interface MapPoint {
  primary?: boolean;
  dynamic?: boolean;
  longitude: number;
  latitude: number;
}

export interface MapProps {
  onPointClick?: (point: { longitude: number; latitude: number }) => void;
  className?: string;
  points?: MapPoint[];
  origin?: { position: { longitude: number; latitude: number }; radius: number };
  center?: [number, number];
}

export enum MapVariants {
  Relative = 'relative',
  StreetLevel = 'street-level'
}

export const Map: FC<MapProps> = props => {
  return (
    <Suspense fallback={Fragment}>
      <AzureMap {...props} />
    </Suspense>
  );
};
