export interface TimeSlot {
  start: Date;
  end: Date;
}

export const useTimeSlots = (startTime: Date, endTime: Date, minutesPerSlot: number, addBuffer?: boolean) => {
  const slots: TimeSlot[] = [];
  let currentStartTime = startTime;

  const addTimeSlot = (time: Date) => {
    const slotStartTime = new Date(time);
    const slotEndTime = new Date(time);
    slotEndTime.setMinutes(time.getMinutes() + minutesPerSlot);

    slots.push({ start: slotStartTime, end: slotEndTime });

    return slotEndTime;
  };

  while (currentStartTime < endTime) {
    currentStartTime = addTimeSlot(currentStartTime);
  }

  if (addBuffer) {
    addTimeSlot(currentStartTime);
  }

  return slots;
};

export const findSlotIndex = (time: Date, timeSlots: TimeSlot[], start: boolean) => {
  return timeSlots.findIndex(slot => {
    if (start && slot.start.toTimeString() === time.toTimeString()) {
      // If we're looking for a start time, and the current slot starts there, it's a match.
      return true;
    } else if (!start && slot.end.toTimeString() === time.toTimeString()) {
      // If we're looking for an end time, and the current slot ends there, it's a match.
      return true;
    } else if (slot.start.toTimeString() < time.toTimeString() && slot.end.toTimeString() > time.toTimeString()) {
      // If the time we're looking for lies in the current slot, it's right either way.
      return true;
    }

    return false;
  });
};

export const getTimeSlotIndexInSlider = (slider: HTMLDivElement, event: MouseEvent, timeSlots: TimeSlot[]) => {
  /*
   * This function works out where your mouse is along the slider, and sets the
   * appropriate slot index.
   */
  const sliderRect = slider.getBoundingClientRect();

  if (!sliderRect) {
    return;
  }
  // X position of your mouse, relative to the screen.
  const mouseScreenX = event.clientX;

  // X position of the start of the slider, relative to the screen.
  const sliderStartX = sliderRect.left;

  // X position of your mouse, relative to the start of the slider.
  const mouseSliderX = mouseScreenX - sliderStartX;

  // Total width of the slider.
  const sliderWidth = sliderRect.width;

  let newSlotIndex = -1;

  if (mouseSliderX <= 0) {
    //Can't go below 0.
    newSlotIndex = 0;
  } else if (mouseSliderX >= sliderWidth) {
    //Can't go past the end of the slider.
    newSlotIndex = timeSlots.length - 1;
  } else {
    // This is how wide a single slot is.
    const slotLength = sliderWidth / timeSlots.length;

    // How many slots along the slider your mouse is.
    const slotPosition = mouseSliderX / slotLength;
    newSlotIndex = Math.floor(slotPosition);
  }

  return newSlotIndex;
};
