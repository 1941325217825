import React, { FC } from 'react';
import { EmployeeAvatar } from '../../home/present/EmployeeAvatar';
import { EmployeeStatus } from '../../home/present/EmployeePresenceCard';
import { SkeletonLoader } from '../../../components/shared/SkeletonLoader';

export const TeamMemberHeaderContentSkeleton: FC = () => {
  return (
    <div className={'flex flex-col md:flex-row justify-between md:items-center'}>
      <div className="flex flex-row gap-8 items-center text-4xl font-bold">
        <EmployeeAvatar
          employee={{
            firstName: '',
            lastName: '',
            status: EmployeeStatus.NOT_ON_SHIFT,
            id: ''
          }}
          fetching={true}
        />
        <div>
          <SkeletonLoader className="w-[250px] h-10" />
        </div>
      </div>
      <SkeletonLoader className="w-full md:w-[150px] h-10 mt-2 md:mt-0" />
    </div>
  );
};
