import * as React from 'react';
import { InputProps, withField } from './Field';
import { FiClock } from 'react-icons/fi';
import { merge } from '../../../helpers/Utility';
import { Icon } from '../icons/Icon';

interface TimeInputProps extends InputProps<string | undefined> { 
  disabled?: boolean
}

export const TimeInput: React.FC<TimeInputProps> = ({ className, name, onChange, value, placeholder, disabled, ...props }) => {
  const outerClass = merge('flex items-center relative', className);

  let innerClass = 'w-full shadow-sm pl-10 focus:ring-primary-500 bg-primary-std focus:border-primary-500 font-medium border-primary-std rounded-md relative';
  if (disabled) {
    innerClass = merge(innerClass, 'bg-gray-200');
  } 
  
  return <div className={outerClass}>
    <Icon className="absolute left-3 z-10" small Icon={FiClock} />
    <input
      {...props}
      type="time"
      id={name}
      disabled={disabled}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={innerClass}
    />
  </div>
};

export const TimeField = withField(TimeInput, 'Optional');