import { FC } from 'react';
import { createClient, Provider, cacheExchange, dedupExchange, fetchExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { useAuthenticationService } from '../../authentication/authentication-service';
import { DisabledPropertyName } from '@microsoft/applicationinsights-common';
import { dependencyReportingExchange } from '../../../helpers/AppInsights';
import { useVariable } from '../../backstage/BackstageProvider';
import { LoggerLevel, useLogger } from '../../logging/Logger';

export const URQLProvider: FC = ({ children }) => {
  const { log } = useLogger();
  const apiUrl = useVariable('apiUrl');

  const client = createClient({
    url: apiUrl!,
    suspense: true,
    exchanges: [devtoolsExchange, dedupExchange, cacheExchange, dependencyReportingExchange, fetchExchange],
    fetchOptions: () => {
      const authService = useAuthenticationService.getState();
      const user = authService.user;

      const controller = new AbortController();

      const requestOptions: RequestInit = {
        headers: {
          authorization: user?.access_token ? `Bearer ${user.access_token}` : '',
          'tenant-id': user?.profile.organisation ? user.profile.organisation : '',
          [DisabledPropertyName]: 'true'
        },
        signal: controller.signal
      };

      // If for whatever reason the access token is still expired at this point then abort the current request and renew the access token via signInRedirection
      if (user?.expired) {
        log('Attempt to make a request with an expired token', LoggerLevel.Warn, true);
        setTimeout(() => {
          controller.abort();
          authService.userManager.then(userManager => {
            userManager.signinRedirect();
          })
        }, 0)
      }

      return requestOptions;
    }
  });

  return <Provider value={client}>{children}</Provider>;
};

export const handleError = (error: Error) => {
  error.stack = new Error().stack;
  throw error;
};
