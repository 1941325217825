import React, { FC } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { TsForm } from '../../components/shared/form/Form';
import { SubmitCancelButtons } from '../../components/shared/form/SubmitCancelButtons';
import { TextFieldWithOptionalLabel } from '../../components/shared/form/TextInput';
import { useUpdateDeviceMutation } from '../../services/gql/graphql';
import strings from './devices.translations';

interface EditDeviceFormProps {
  id: string;
  name: string;
  locationId: number;
  onClose: () => void;
}

interface EditDeviceFields {
  name: string;
}

export const EditDeviceForm: FC<EditDeviceFormProps> = ({ id, name, locationId, onClose }) => {
  const methods = useForm<EditDeviceFields>({ defaultValues: { name } });

  const [{ fetching }, updateDevice] = useUpdateDeviceMutation();

  const handleError = useErrorHandler();

  const onSubmit = async (values: EditDeviceFields) => {
    try {
      const result = await updateDevice({
        args: {
          name: values.name,
          id: id,
          locationId
        }
      });

      if (result.error) {
        handleError(result.error);
      }

      onClose();
    } catch (e) {
      handleError(e as Error);
    }
  };

  return (
    <TsForm methods={methods} onSubmit={onSubmit}>
      <div className="flex flex-col space-y-5 xl:w-96 mt-3">
        <TextFieldWithOptionalLabel
          name="name"
          title={strings.editDeviceModal.nameField}
          validationMessage={strings.editDeviceModal.nameRequired}
          required
          placeholder={strings.editDeviceModal.namePlaceholder}
        />
        <div className="flex items-center justify-end gap-3">
          <SubmitCancelButtons
            onComplete={() => {}}
            loading={fetching}
            submitText={strings.common.save}
          ></SubmitCancelButtons>
        </div>
      </div>
    </TsForm>
  );
};
