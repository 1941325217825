import { ReactNode } from 'react';
import create from 'zustand';
import produce from 'immer';
import Fuse from 'fuse.js';
import { FabricModal } from '../../components/fabric/Fabric';

export interface FabricLocation {
  id: string;
  name: string;
  path: string;
  icon?: ReactNode;
  description?: string;
  args?: FabricArgs[];
  actions?: FabricAction[];
  resolver?: (term?: string) => Promise<FabricItem[]>;
  parents?: FabricLocation[];
}

export interface FabricAction {
  type: 'action';
  id: string;
  name: string;
  icon: ReactNode;
  description: string;
  path?: string;
  modal?: FabricModal;
  score?: number;
}

export enum FabricArgs {
  Id,
  Date
}

export interface FabricItem {
  type: 'item';
  id: string;
  name: string;
  searchable?: string[];
  score?: number;
}

export enum FabricResolverState {
  Idle,
  Loading
}

export interface FabricSection {
  id: string;
  name: string;
  path: string;
  state: FabricResolverState;
  items?: FabricItem[];
  actions?: FabricAction[];
}

export interface FabricCache {
  lastUpdated: Date;
  items: FabricItem[];
  term: string;
}

export interface FabricService {
  locations: FabricLocation[];
  registerLocation: (location: FabricLocation) => void;

  cache: { [key: string]: FabricCache };

  setModal: (action: FabricAction) => void;
  clearModal: () => void;
  Modal?: FabricModal;
  showModal: boolean;

  search: (term?: string) => Promise<void>;
  term?: string;
  results?: FabricSection[];
}

const fuse = new Fuse<(FabricItem | FabricAction) & { sectionId: string; searchable?: string[] }>([], {
  includeScore: true,
  keys: ['name', 'searchable']
});

export const useFabric = create<FabricService>((set, get) => ({
  locations: [],
  registerLocation: location =>
    set(
      produce(state => {
        state.locations.push(location);
        location.actions?.forEach(action =>
          fuse.add({
            ...action,
            sectionId: location.id,
            searchable: [action.description]
          })
        );
      })
    ),

  showModal: false,
  setModal: (action: FabricAction) => {
    set(state => ({ ...state, Modal: action.modal, showModal: true }));
  },
  clearModal: () => {
    set(state => ({ ...state, showModal: false }));
    setTimeout(() => set(state => ({ ...state, Modal: undefined })), 300);
  },

  cache: {},

  search: async term => {
    // Set term immediately
    set(state => ({ ...state, term }));

    // If the term is less than 4 characters, just have no results.
    if (!term || term.length <= 3) {
      set(state => ({ ...state, results: [] }));
      return;
    }

    // Search through existing actions & preloaded items
    const items = fuse.search(term).map(result => ({ ...result.item, score: result.score }));
    const { locations } = await get();
    const sections: FabricSection[] = locations.map(location => ({
      ...location,
      state: FabricResolverState.Idle,
      actions: items.filter(item => item.type === 'action' && item.sectionId === location.id) as FabricAction[],
      items: items.filter(item => item.type === 'item' && item.sectionId === location.id) as FabricItem[]
    }));
    set(state => ({ ...state, results: sections }));

    // Go over all the registered locations, see if cache is invalid, resolve new data
    const { cache } = get();
    locations.map(async location => {
      // If cache is fine, just return
      // TODO: Cache term is a substring of new term
      if (cache[location.id]) return;

      // Set section state as loading

      // Resolve the location with the term
      const results = await location.resolver?.(term);
      // Update the cache
      set(
        produce(state => {
          state.cache[location.id] = {
            lastUpdated: new Date(),
            term: term,
            items: results
          };
        })
      );

      if (!results) return;

      // Add the new results to the results
      results.forEach(result =>
        fuse.add({
          type: 'item',
          id: result.id,
          name: result.name,
          searchable: result.searchable,
          sectionId: location.id
        })
      );

      // After the resolver action, get the latest term to avoid race conditions
      const mostRecentState = get();
      const mostRecentTerm = mostRecentState.term;
      if (!mostRecentTerm || mostRecentTerm.length <= 3) return;

      // Re-search the fuse with the new items
      const items = fuse.search(mostRecentTerm).map(result => ({ ...result.item, score: result.score }));
      set(state => {
        // I'm unsure if this is the best way of doing is as 'otherSections' ~could~ be old but unlikely.
        const otherSections = state.results?.filter(section => section.id !== location.id) ?? [];
        const newCurrentSection = {
          ...location,
          state: FabricResolverState.Idle,
          actions: items.filter(item => item.type === 'action' && item.sectionId === location.id) as FabricAction[],
          items: items.filter(item => item.type === 'item' && item.sectionId === location.id) as FabricItem[]
        };

        return { ...state, results: [...otherSections, newCurrentSection] };
      });
    });
  }
}));

export const registerLocation = useFabric.getState().registerLocation;
