import React, { FC, HTMLAttributes } from 'react';
import { merge } from '../../helpers/Utility';

interface ClockTitleProps extends HTMLAttributes<HTMLHeadingElement> {}

export const ClockTitle: FC<ClockTitleProps> = ({ children, className, ...props }) => {
  return (
    <h2 {...props} className={merge(`text-center text-xl font-extrabold`, className)}>
      {children}
    </h2>
  );
};
