import { FC } from 'react';
import { Title } from '../../../../components/shared/text/Title';
import { SubmissionErrorBoundary } from '../../../../components/errors/SubmissionErrorBoundary';
import { FiX } from 'react-icons/fi';
import { Modal } from '../../../../components/shared/modal/Modal';
import strings from '../../roster.translations';
import { RosterCreateModalContent } from './RosterCreateModalContent';

interface RosterCreateModalProps {
  open: boolean;
  onClose: () => void;
  date: Date;
}

export const RosterCreateModal: FC<RosterCreateModalProps> = ({ open, onClose, date }) => {
  return (
    <Modal open={open} onClose={onClose} widthOverride='w-full sm:max-w-[500px]'>
      <div className="relative m-8">
        <Title>{strings.createRosterModal.title}</Title>
        <SubmissionErrorBoundary>
          <RosterCreateModalContent onClose={onClose} date={date}/>
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
