import { FC } from 'react';
import { PageContainer } from '../../../../components/page/PageContainer';
import { RosterHeader } from '../../ganttView/RosterHeader';
import { ESSDesktopRosterView } from './desktop/ESSDesktopRosterView';
import { ESSMobileRosterView } from './mobile/ESSMobileRosterView';

export const StaffRosterView: FC = () => {
  return (
    <PageContainer customHeader={<RosterHeader calendar={true} readOnly={true} />}>
      <div className="hidden lg:block relative w-full">
        <ESSDesktopRosterView />
      </div>
      <div className="lg:hidden m-5 py-5 bg-primary-std border-primary-std rounded-lg">
        <ESSMobileRosterView />
      </div>
    </PageContainer>
  );
};
