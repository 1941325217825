import React, { FC, useMemo, useState } from 'react';
import { PageHeader } from '../../../components/page/PageHeader';
import { FiChevronDown, FiMapPin, FiUser, FiUsers } from 'react-icons/fi';
import { PageSizer, SizerMode } from '../../../components/page/PageSizer';
import { PageContainer } from '../../../components/page/PageContainer';
import strings from '../team.translations';
import { TableContainer } from '../../../components/shared/table/TableContainer';
import { StaffMember, TeamTable } from './TeamTable';
import { handleError } from '../../../services/gql/config/URQLProvider';
import { Dropdown } from '../../../components/shared/dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import { Permissions, useAuthenticationService } from '../../../services/authentication/authentication-service';
import { useFlag } from '../../../services/backstage/BackstageProvider';
import { Button } from '../../../components/shared/button/Button';
import { DropdownAction } from '../../../components/shared/action/Action';
import { RiDatabase2Line } from 'react-icons/ri';
import { useGetEmployeesForUserQuery } from '../../../services/gql/graphql';
import { useLocationService } from '../../../services/location/LocationService';
import { UpdateSelectedLocationsModal } from '../../shifts/UpdateSelectedLocationsModal';

export const TeamPage: FC = () => {
  const [permissions] = useAuthenticationService(state => [state.user?.profile.permission]);
  const canCreateEmployee = permissions?.includes(Permissions.EmployeeCreate) ?? false;
  const breadcrumbs = [{ name: strings.teamPageTitle, to: '/team' }];

  const addMultipleUsers = useFlag('addMultipleUsers');
  const importFlag = useFlag('import');

  const addMultipleTeamMembers = () => {
    navigate('add-multiple');
  };

  const userFeatures: {
    key: string;
    featureEnabled?: boolean;
    label?: string;
    action?: () => void;
    icon?: JSX.Element;
  }[] = [
    {
      key: 'addSingleUser',
      featureEnabled: canCreateEmployee,
      label: strings.addTeamMenu.addATeamMember,
      action: () => navigate('/team/add-team-member'),
      icon: <FiUser className="w-4 h-4 mt-0.5 mr-2" />
    },
    {
      key: 'addMultipleUsers',
      featureEnabled: addMultipleUsers && canCreateEmployee,
      label: strings.addTeamMenu.addYourTeam,
      action: addMultipleTeamMembers,
      icon: <FiUsers className="w-4 h-4 mt-0.5 mr-2" />
    },
    {
      key: 'import',
      featureEnabled: importFlag,
      label: strings.addTeamMenu.importFromSpreadsheet,
      icon: <RiDatabase2Line className="w-4 h-4 mt-0.5 mr-2" />
    }
  ];
  const allowedUserFeatures = userFeatures.filter(feat => feat.featureEnabled);

  const [{ data, error, fetching }] = useGetEmployeesForUserQuery({
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    ),
    requestPolicy: 'network-only'
  });
  if (error) handleError(error);

  const staff: StaffMember[] = data?.employeesForUser?.employees as StaffMember[];

  const navigate = useNavigate();

  const [showUpdateLocationModal, setShowUpdateLocationModal] = useState<boolean>(false);

  const [locations, selectedShiftLocations] = useLocationService(state => [
    state.locationList,
    state.selectedShiftLocations
  ]);

  return (
    <PageContainer
      customHeader={
        <PageHeader pageTitle={strings.teamPageTitle} breadcrumbs={breadcrumbs} icon={<FiUser />}>
          <div className="flex flex-col md:flex-row gap-2 md:gap-5">
            {locations?.length > 1 && (
              <Button
                onClick={() => setShowUpdateLocationModal(true)}
                primary
                className="flex items-center justify-center space-x-2"
              >
                <FiMapPin />
                <span>{strings.selectLoc}</span>
              </Button>
            )}
            {allowedUserFeatures.length > 1 ? (
              <Dropdown
                className="!m-0"
                button={
                  <Button primary className="w-full md:w-max mt-2 md:mt-0 flex justify-center items-center space-x-2">
                    <FiUsers />
                    <p>{strings.addTeamMenu.button}</p>
                    <FiChevronDown />
                  </Button>
                }
              >
                {allowedUserFeatures.map(feat => {
                  return (
                    <DropdownAction>
                      {feat.icon}
                      <span onClick={feat.action}>{feat.label}</span>
                    </DropdownAction>
                  );
                })}
              </Dropdown>
            ) : (
              allowedUserFeatures.length > 0 &&
              allowedUserFeatures.map(feat => (
                <Button onClick={feat.action} primary className="flex justify-center">
                  {feat.icon}
                  <span>{feat.label}</span>
                </Button>
              ))
            )}
          </div>
        </PageHeader>
      }
    >
      <PageSizer className="flex flex-col gap-5" mode={SizerMode.PAGE}>
        <TableContainer>
          <TeamTable
            staff={staff || []}
            fetching={fetching}
            locations={selectedShiftLocations
              .filter(location => location.checked && location.key!)
              .map(location => location.key)}
          />
        </TableContainer>
      </PageSizer>
      <UpdateSelectedLocationsModal
        onCompleteCallback={() => {
          setShowUpdateLocationModal(false);
        }}
        open={showUpdateLocationModal}
        onClose={() => setShowUpdateLocationModal(false)}
      />
    </PageContainer>
  );
};
