import React, { FC } from 'react';
import { RosterHeaderCommon } from '../common/RosterHeaderCommon';
import strings from '../roster.translations';
import { FiCalendar } from 'react-icons/fi';
import { format } from 'date-fns';
import { useRosterService } from '../ganttView/rosterService';

interface RosterRequirementHeaderProps {}

export const RosterRequirementHeader: FC<RosterRequirementHeaderProps> = () => {
  const [date] = useRosterService(state => [state.roster.rosterDate]);
  const dateToDisplay = format(new Date(date), 'EEEE do MMMM y');

  const breadcrumbs = [
    { name: strings.rosterBreadcrumbTitle, to: '/roster' },
    {
      name: (
        <div className="flex gap-2 items-center">
          <FiCalendar />
          {dateToDisplay}
        </div>
      ),
      to: '/'
    },
    {
      name: strings.rosterRequirements,
      to: '/'
    }
  ];

  return (
    <RosterHeaderCommon
      headerTitle={strings.rosterRequirements}
      breadcrumbs={breadcrumbs}
      headerButtons={<>{'buttons here'}</>}
    />
  );
};
