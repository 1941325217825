import React, { FC, Suspense, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Button } from '../../../components/shared/button/Button';
import strings from '../team.translations';
import { useNavigate } from 'react-router-dom';
import { ReadEmployee, useCreateUserAndEmployeeMutation } from '../../../services/gql/graphql';
import { formatPhoneNumber } from '../../../helpers/Utility';
import { v4 as uuidv4 } from 'uuid';
import { handleBEFormError } from './overview/TeamFormCommon';
import { ContactInfo, ContactInfoType } from '../../../components/shared/form/OptionalContactInfo';
import { useErrorHandler } from 'react-error-boundary';
import { useVariable, useFlag } from '../../../services/backstage/BackstageProvider';
import { useLocationService } from '../../../services/location/LocationService';
import { TeamMemberAddOrEditFormContent } from '../TeamMemberAddOrEditFormContent';
import { TSTitleCard } from '../../../components/shared/card/Card';
import { SubmissionErrorBoundary } from '../../../components/errors/SubmissionErrorBoundary';
import { TableLoader } from '../../../components/shared/table/TableLoader';
import { TsForm } from '../../../components/shared/form/Form';

interface AddSingleTeamMemberProps {
  refetchCallback: () => void;
  employee: ReadEmployee;
}

export interface TeamMemberAddFields {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  role: string;
  sendRegistration: boolean;
  ignoreHourContribution: boolean;
  userName: string;
  locationIds?: number[];
  contractTypeId?: string;
}

interface FieldsWithLoopCount extends TeamMemberAddFields {
  loopCount?: number;
}

export const TSTitleCardStyle = 'space-y-5 max-w-3xl mx-auto p-5 mb-4';

export const AddSingleTeamMemberForm: FC<AddSingleTeamMemberProps> = ({ refetchCallback, employee }) => {
  const handleError = useErrorHandler();
  const methods: UseFormReturn<TeamMemberAddFields> = useForm<TeamMemberAddFields>({
    defaultValues: {
      ignoreHourContribution: false,
      sendRegistration: true,
      locationIds: []
    },
    shouldFocusError: true
  });

  const navigate = useNavigate();
  
  const goBack = () => {
    navigate('/team');
  };

  const goToEmployeePage = (id: string) => {
    navigate(`/team-member/${id}`);
  }

  const [mappedLocations, selectedLocationId] = useLocationService(state => [state.mappedLocations, state.selectedLocation!.id]);
  const [{ fetching }, createUserAndEmployee] = useCreateUserAndEmployeeMutation();
  const contractTypeId = useVariable('contractTypeId');
  const locationsEditable = useFlag('devices');

  const [contactInfoType, setContactInfoType] = useState<ContactInfoType>(ContactInfo.none);

  const onSubmit = async (data: FieldsWithLoopCount) => {
    try {
      if (fetching) {
        return;
      }

      if (!(data.phoneNumber || data.email)) {
        return;
      }

      let locationIds = [selectedLocationId];
      
      const mappedCheckedLocations = mappedLocations.filter(location => location.checked);
      if (locationsEditable && mappedCheckedLocations.length > 0) {
        locationIds = mappedCheckedLocations.map(location => Number(location.key));
      }

      const numberToSend = formatPhoneNumber(data.phoneNumber);
      const payload: TeamMemberAddFields = {
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        ...(data.email && { email: data.email }),
        ...(data.phoneNumber && { phoneNumber: numberToSend }),
        userName: uuidv4(),
        sendRegistration: data.sendRegistration,
        ignoreHourContribution: data.ignoreHourContribution,
        ...(contractTypeId && { contractTypeId: contractTypeId }),
        locationIds
      };
      const result = await createUserAndEmployee({
        employee: payload
      });
      if (result.error) {
        throw result.error;
      } else {
        refetchCallback();
        goToEmployeePage(result.data?.createUserAndEmployee?.identityId);
      }
    } catch (ex: any) {
      if (ex.graphQLErrors[0].extensions.code === 'USER_IDENTITY' && (data.loopCount || 0) < 2) {
        await onSubmit({ ...data, loopCount: (data.loopCount ?? 0) + 1 });
      } else {
        const { emailError, phoneError } = handleBEFormError(ex, methods, handleError);
        setContactInfoType(emailError ? ContactInfo.email : phoneError ? ContactInfo.phone : contactInfoType);
      }
    }
  };

  return (
    <TsForm methods={methods} onSubmit={onSubmit}>
      <TSTitleCard title={strings.addTeamMenu.addATeamMember} className={TSTitleCardStyle}>
        <SubmissionErrorBoundary>
          <Suspense fallback={<TableLoader />}>
            <TeamMemberAddOrEditFormContent addMode={true} employee={employee} methods={methods}/>
          </Suspense>
        </SubmissionErrorBoundary>
      </TSTitleCard>
      <div className="space-y-5 max-w-3xl mx-auto mb-4pt-5 pl-5">
        <div className="flex w-full items-center justify-end gap-5">
          <Button className="w-full sm:w-auto" onClick={goBack}>
            {strings.common.cancel}
          </Button>
          <Button primary className="w-full sm:w-auto" type="submit" loading={fetching}>
            {strings.addTeamMenu.addTeamMember}
          </Button>
        </div>
      </div>
    </TsForm>
  );
};
