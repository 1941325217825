import { FC, useCallback, useEffect, useState } from 'react';
import { GiPokecog } from 'react-icons/gi';
import { NavPage } from '../../../components/page/Breadcrumbs';
import { PageContainer } from '../../../components/page/PageContainer';
import { PageHeader } from '../../../components/page/PageHeader';
import { PageSizer, SizerMode } from '../../../components/page/PageSizer';
import { handleError } from '../../../services/gql/config/URQLProvider';
import { useLocationsQuery } from '../../../services/gql/graphql';
import strings from '../manageOrg.translations';
import { LocationList, Location } from './LocationList';
import ReactPaginate from 'react-paginate';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { TSCard } from '../../../components/shared/card/Card';
import { Search } from '../../../components/shared/Search';
import { AnimatePresence } from 'framer-motion';
import { Button } from '../../../components/shared/button/Button';
import { AddLocationModal } from './AddLocationModal';
import { useNavigate } from 'react-router-dom';

export const AllLocationsPage: FC = () => {
  const breadcrumbs: NavPage[] = [
    {
      name: strings.manageOrgSection.title,
      to: '/manage-organisation'
    },
    {
      name: strings.locationsSection.title,
      to: '/manage-organisation/locations'
    }
  ];

  const navigate = useNavigate();

  const [allLocations, setAllLocations] = useState<Location[]>([]);
  const [currentLocations, setCurrentLocations] = useState<Location[]>([]);

  const [forcePage, setForcePage] = useState<number | undefined>(undefined);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const itemsPerPage = 10;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [{ data, error }] = useLocationsQuery();
  if (error) handleError(error);

  const onSearch = (newValue: string) => {
    setForcePage(0);
    updatePageCount(
      allLocations.filter(location => location.name.toLowerCase().includes(newValue.toLowerCase())),
      true
    );
  };

  const updatePageCount = useCallback(
    (items: Location[], fromSearch = false) => {
      let offset = itemOffset;

      // Search has been reset so reset the pagination as well.
      if (fromSearch) {
        offset = 0;
        setItemOffset(offset);
      }

      const endOffset = offset + itemsPerPage;

      setCurrentLocations(items!.slice(offset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    },
    [itemOffset]
  );

  useEffect(() => {
    if (!data?.locations) {
      return;
    }

    let locations = data?.locations?.map(location => {
      return {
        id: location.id,
        name: location.name
      };
    });

    setAllLocations(locations);

    updatePageCount(locations);
  }, [data, updatePageCount]);

  const handlePageClick = (event: any) => {
    setForcePage(undefined);

    const newOffset = (event.selected * itemsPerPage) % allLocations.length;
    setItemOffset(newOffset);
  };

  return (
    <PageContainer
      customHeader={
        <PageHeader pageTitle={strings.locationsSection.title} breadcrumbs={breadcrumbs} icon={<GiPokecog />} />
      }
    >
      <PageSizer className="flex gap-5 flex-col" mode={SizerMode.PAGE}>
        <div className="flex flex row">
          <Search onChange={onSearch} />
          <Button primary className="ml-auto" onClick={() => setModalOpen(true)}>
            {strings.addLocationModal.title}
          </Button>
        </div>
        {currentLocations.length > 0 ? (
          <>
            <AnimatePresence>
              <LocationList locations={currentLocations} />
            </AnimatePresence>
            <ReactPaginate
              onPageChange={handlePageClick}
              previousLabel={<HiOutlineChevronLeft />}
              nextLabel={<HiOutlineChevronRight />}
              pageCount={pageCount}
              pageRangeDisplayed={1}
              //@ts-ignore
              renderOnZeroPageCount={null}
              pageLabelBuilder={page => strings.common.pagination.pageXOfY(page, pageCount)}
              containerClassName="flex items-center space-x-2 font-semibold justify-center"
              pageClassName={'hidden'}
              activeClassName={'!block'}
              breakLabel={''}
              forcePage={forcePage}
            />
          </>
        ) : (
          <>
            <TSCard className="font-semibold flex justify-center pl-6 pr-3 py-6">
              {strings.locationsSection.noData}
            </TSCard>
          </>
        )}
        <AddLocationModal
          open={modalOpen}
          onClose={(id?: number) => {
            if (id) {
              navigate(`/manage-location/${id}`)
            }
            setModalOpen(false);
          }}
        />
      </PageSizer>
    </PageContainer>
  );
};
