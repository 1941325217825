import { FC, useCallback, useEffect, useState } from 'react';
import { PageSizer, SizerMode } from '../../../../components/page/PageSizer';
import { Calendar } from '../common/Calendar';
import { MonthPicker } from '../common/MonthPicker';
import { SingleRosterJobStatus } from '../../../../services/gql/graphql';
import { useRosterSelectService } from '../common/rosterSelectService';
import { RosterCalendarContent } from './content/RosterCalendarContent';
import { RosterCalendarHeader } from './RosterCalendarHeader';
import { RosterCreateModal } from './RosterCreateModal';
import { isDateInPast } from '../../../../helpers/dateHelper';
import { ConfirmDialog } from '../../../../components/shared/ConfirmDialog';
import strings from '../../roster.translations';
import { format } from 'date-fns';
import { Loader } from '../../../../components/shared/Loader';
import { useBuildRosterCalendarEvents } from '../hooks/useBuildRosterCalendarEvents';

interface RosterCalendarProps {}

export interface RosterCalendarContextObject {
  id?: string;
  rosterStatus?: RosterStatus;
  optimiserStatus?: SingleRosterJobStatus;
  numberOfEmployees?: number;
  numberOfHoursWorked?: number;
  numberOfErrors?: number;
  onClick?: () => void;
  deleteOnClick?: () => void;
}

interface DeleteRosterModalData {
  date: Date;
  rosterId: string;
}

export enum RosterStatus {
  published = 'Complete',
  draft = 'Draft'
}

export const isPublished = (item: RosterCalendarContextObject) => item.rosterStatus === RosterStatus.published;

export const RosterCalendar: FC<RosterCalendarProps> = () => {
  const [deleteRoster] = useRosterSelectService(state => [state.deleteRoster]);

  const [monthToShow, setMonthToShow] = useState<Date>(new Date());

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [dateToCreateRoster, setDateToCreateRoster] = useState<Date>();
  const [dataToDeleteRoster, setDateToDeleteRoster] = useState<DeleteRosterModalData>();

  const onCreateEvent = useCallback(
    (date: Date) => {
      setDateToCreateRoster(date);
      setCreateModalOpen(true);
    },
    [setCreateModalOpen, setDateToCreateRoster]
  );

  const onDeleteEvent = useCallback(
    (date: Date, id: string) => {
      setDateToDeleteRoster({
        date: date,
        rosterId: id
      });
      setDeleteModalOpen(true);
    },
    [setDateToDeleteRoster, setDeleteModalOpen]
  );

  const { calendarLoading, buildEvents, refreshOptimiserStatuses, events } = useBuildRosterCalendarEvents(
    onDeleteEvent,
    onCreateEvent
  );

  const onMonthChange = (newDate: Date) => {
    setMonthToShow(newDate);
  };

  useEffect(() => {
    buildEvents(monthToShow);
  }, [buildEvents, monthToShow]);

  useEffect(() => {
    const interval = setInterval(refreshOptimiserStatuses, 2000);
    return () => clearInterval(interval);
  }, [refreshOptimiserStatuses]);

  const onConfirmDelete = () => {
    deleteRoster(dataToDeleteRoster?.rosterId!);
    // Need to update the deleted roster in the events array.
    const eventToUpdateIndex = events.findIndex(event => event?.contextObject?.id === dataToDeleteRoster?.rosterId);

    if (!isDateInPast(dataToDeleteRoster!.date, new Date())) {
      events[eventToUpdateIndex].contextObject = { onClick: () => onCreateEvent(dataToDeleteRoster!.date) };
    }

    setDeleteModalOpen(false);
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="w-full border border-b-2 bg-primary-std">
        <PageSizer mode={SizerMode.CONTENT}>
          <MonthPicker value={monthToShow} onChange={onMonthChange} />
        </PageSizer>
      </div>
      <PageSizer className="flex flex-col flex-1 p-5" mode={SizerMode.CONTENT}>
        {calendarLoading ? (
          <Loader className="mt-10 h-10" />
        ) : (
          <Calendar
            monthToShow={monthToShow}
            HeaderComponent={RosterCalendarHeader}
            ContentComponent={RosterCalendarContent}
            events={events}
            className="rounded-lg flex-1"
            options={{ minimumCellHeight: 100 }}
          />
        )}
      </PageSizer>
      <RosterCreateModal
        open={createModalOpen}
        date={dateToCreateRoster!}
        onClose={() => {
          setCreateModalOpen && setCreateModalOpen(false);
        }}
      />
      {dataToDeleteRoster && (
        <ConfirmDialog
          title={strings.deleteRosterConfirmModal.title}
          confirmContent={strings.deleteRosterConfirmModal.message(format(dataToDeleteRoster.date!, 'MMMM do'))}
          confirmLabel={strings.deleteRosterConfirmModal.confirm}
          cancelLabel={strings.common.cancel}
          open={deleteModalOpen}
          onSuccess={onConfirmDelete}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};
