import { FC, useRef } from 'react';
import { Grid } from './Grid';
import { findSlotIndex, getTimeSlotIndexInSlider, useTimeSlots } from './ganttHelper';
import { Bar } from './Bar';

interface GanttSliderProps { 
  startTime: Date;
  endTime: Date;
  onChangeStartTime: (newTime: Date) => void;
  onChangeEndTime: (newTime: Date) => void;
  onConfirmTimes: (startToConfirm: Date, endToConfirm: Date) => void;
  minTime: Date;
  maxTime: Date;
  color: string;
  minuteIncrement: number;
  readOnly?: boolean;
}

export const GanttSlider: FC<GanttSliderProps> = ({
  startTime,
  endTime,
  onChangeStartTime,
  onChangeEndTime,
  onConfirmTimes,
  minTime,
  maxTime,
  color,
  minuteIncrement,
  readOnly
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const timeSlots = useTimeSlots(minTime, maxTime, minuteIncrement);

  const startSlot = findSlotIndex(startTime, timeSlots, true);
  const endSlot = findSlotIndex(endTime, timeSlots, false);

  const eventToTime = (start: boolean, event: MouseEvent) => {
    if (!sliderRef.current) {
      return;
    }

    const newSlotIndex = getTimeSlotIndexInSlider(sliderRef.current, event, timeSlots);

    if (newSlotIndex === undefined || newSlotIndex < 0) {
      return;
    }

    let time: Date;
    
    if (start) {
      // Can't let the start tab go past the end tab.
      const slotToSet = Math.min(newSlotIndex, endSlot - 1);
      time = timeSlots[slotToSet].start;
    } else {
      // Can't let the end tab go past the start tab.
      const slotToSet = Math.max(newSlotIndex, startSlot + 1);
      time = timeSlots[slotToSet].end;
    }

    return time;
  }

  const onDrag = (start: boolean, event: MouseEvent) => {
    const newTime = eventToTime(start, event);

    if (!newTime) {
      return;
    }

    if (start) {
      onChangeStartTime(newTime);
    } else {
      onChangeEndTime(newTime);
    }
  };

  const onDragEnd = (start: boolean, event: MouseEvent) => {
    const newTime = eventToTime(start, event);

    if (!newTime) {
      return;
    }

    if (start) {
      onConfirmTimes(newTime, endTime);
    } else {
      onConfirmTimes(startTime, newTime);
    }
  }

  return (
    <div className="relative w-full h-16" ref={sliderRef}>
      <Grid minTime={minTime} maxTime={maxTime} />
      <Bar
        color={color}
        startSlot={startSlot}
        endSlot={endSlot}
        slotCount={timeSlots.length}
        onStartHandleDrag={(event: MouseEvent) => onDrag(true, event)}
        onEndHandleDrag={(event: MouseEvent) => onDrag(false, event)}
        onStartHandleDragEnd={(event: MouseEvent) => onDragEnd(true, event)}
        onEndHandleDragEnd={(event: MouseEvent) => onDragEnd(false, event)}
        readOnly={readOnly}
      />
    </div>
  );
};
