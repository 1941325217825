import React, { FC, ReactNode } from 'react';
import { merge } from '../../helpers/Utility';
import { InputProps, withField } from './form/Field';

interface radioGroupItem {
  key: string;
  value: string;
  descriptionMarkup: ReactNode;
  className?: string;
}

interface RadioGroupProps extends InputProps<string> {
  items?: radioGroupItem[];
  fieldSetClasses?: string;
  labelClasses?: string;
  noOptionsString?: string;
}

export const RadioGroup: FC<RadioGroupProps> = ({
  name,
  items,
  value,
  fieldSetClasses,
  onChange,
  noOptionsString,
  labelClasses
}) => {
  return (
    <fieldset className={fieldSetClasses || ''} id={name}>
      {items && items.length ? (
        items.map((item: radioGroupItem) => {
          const { key, descriptionMarkup, value: itemValue } = item;

          return (
            <div className="flex flex-grow min-w-[33%] md:px-2 md:py-2">
              <label key={`${name}-${key}`} className={`${labelClasses ? labelClasses : ''} flex w-full p-4 space-x-2`}>
                <input
                  className={merge(item.className, 'rounded-md')}
                  type="radio"
                  onChange={() => onChange(itemValue)}
                  checked={value === itemValue}
                  value={itemValue}
                  name={itemValue}
                />
                <div className="flex flex-col leading-4">{descriptionMarkup}</div>
              </label>
            </div>
          );
        })
      ) : (
        <p className="text-red-500">{noOptionsString}</p>
      )}
    </fieldset>
  );
};

export const RadioGroupField = withField(RadioGroup, 'Optional');
