import { FC, HTMLAttributes } from 'react';
import { BackgroundStyle, PageBackground } from './PageBackground';
import { MainHeader } from './MainHeader';
import { useHslBuilder } from './useHsl';

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  customHeader?: JSX.Element;
  lockHeader?: boolean;
  backgroundStyle?: BackgroundStyle;
}

export const PageContainer: FC<PageContainerProps> = ({
  customHeader,
  lockHeader,
  backgroundStyle,
  children
}) => {
  const hslBuilder = useHslBuilder();

  return (
    <div className="w-full overflow-hidden">
      <div
        className={`${
          lockHeader ? 'h-screen' : 'min-h-screen'
        } w-screen flex flex-col overflow-hidden relative`}
      >
        <MainHeader>{customHeader}</MainHeader>
        <PageBackground backgroundStyle={backgroundStyle} />
        <div className="w-full h-full absolute -z-20" style={{background: hslBuilder(1)}}></div>
        {lockHeader ? <div className="flex-1 overflow-auto relative">{children}</div> : children}
      </div>
    </div>
  );
};
