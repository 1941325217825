import { IExceptionTelemetry } from '@microsoft/applicationinsights-common';
import { FC, useEffect, useState } from 'react';
import { useAppInsights } from '../../helpers/AppInsights';
import { v4 } from 'uuid';
import strings from './errors.translations';
import { Modal } from '../shared/modal/Modal';
import { SubmissionErrorContents } from './SubmissionErrorContents';

interface SubmissionErrorModalProps {
  error: Error;
  open: boolean;
  onClose: () => void;
}

export const SubmissionErrorModal: FC<SubmissionErrorModalProps> = ({ error, open, onClose }) => {
  const [exception, setException] = useState<IExceptionTelemetry>();
  const appInsights = useAppInsights();

  useEffect(() => {
    if (!error) return;
    const exception = {
      id: v4(),
      exception: error
    };
    setException(exception);
    appInsights.trackException(exception);
  }, [error, appInsights]);

  return (
    <Modal open={open} onClose={onClose}>
      <SubmissionErrorContents
        error={error}
        trackingId={exception?.id}
        buttonActionLabel={strings.common.close}
        onButtonAction={onClose}
        className="p-5"
      />
    </Modal>
  );
};
