import { FC, useMemo } from 'react';
import { EmployeeWorkTimeRecord, useGetShiftsListQuery, WorkTimeDataRisk } from '../../../services/gql/graphql';
import strings from '../home.translations';
import { SubmissionErrorBoundary } from '../../../components/errors/SubmissionErrorBoundary';
import { UnverifiedClocksCard } from './UnverifiedClocksCard';
import { PageControls, usePagination } from '../../../components/shared/pagination/Pagination';
import { dateToGQLFormat } from '../../../helpers/dateHelper';
import { handleError } from '../../../services/gql/config/URQLProvider';
import { UnverifiedClockCardSkeleton } from './UnverifiedClockCardSkeleton';

interface UnverifiedClocksListProps {}

export const UnverifiedClocksList: FC<UnverifiedClocksListProps> = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  const [{ data, error, fetching }] = useGetShiftsListQuery({
    variables: {
      queryWorkItemArgs: {
        from: dateToGQLFormat(twoWeeksAgo),
        to: dateToGQLFormat(today)
      }
    },
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    )
  });

  if (error) {
    handleError(error);
  }

  const riskyClocks = data?.getWorkTimeRecord?.filter(
    item => item.risk !== WorkTimeDataRisk.Low && item.employee?.isDeleted !== true
  );

  const [offset, limit, controls] = usePagination(8, riskyClocks?.length);

  const clocksToDisplay = riskyClocks?.slice(offset, offset + limit);

  return (
    <SubmissionErrorBoundary>
      {riskyClocks?.length === 0 ? (
        <p>{strings.unverifiedClocks.none}</p>
      ) : (
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
          {fetching ? (
            <>
              <UnverifiedClockCardSkeleton />
              <UnverifiedClockCardSkeleton />
              <UnverifiedClockCardSkeleton />
              <UnverifiedClockCardSkeleton />
            </>
          ) : (
            <>
              {clocksToDisplay?.map(clock => (
                <UnverifiedClocksCard shift={clock as EmployeeWorkTimeRecord} key={clock.id} />
              ))}
            </>
          )}
        </div>
      )}
      {controls.numberOfPages && controls.numberOfPages > 1 && <PageControls className="mt-2" {...controls} />}
    </SubmissionErrorBoundary>
  );
};
