import { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { merge } from '../../helpers/Utility';

interface LinkButtonProps extends HTMLAttributes<HTMLLinkElement> {
  compact?: boolean;
  big?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tint?: boolean;
  to: string;
}

export const LinkButton: FC<LinkButtonProps> = ({
  to,
  children,
  compact,
  tint,
  big,
  primary,
  secondary,
  className,
  ...props
}) => {
  let style = (() => {
    if (primary && !tint)
      return 'bg-primary-500 border hover:bg-primary-600 border-primary-600 text-white font-medium focus:ring-primary-500';
    if (secondary && !tint)
      return 'bg-secondary-500 border hover:bg-secondary-600 border-secondary-600 text-white font-medium focus:ring-secondary-500';
    if (primary && tint)
      return 'bg-primary-50 border hover:bg-primary-100 border-primary-200 dark:text-primary-400 text-primary-700 font-normal focus:ring-primary-500';
    if (secondary && tint)
      return 'bg-secondary-50 border hover:bg-secondary-100 border-secondary-200 dark:text-secondary-400 text-secondary-700 font-normal focus:ring-secondary-500';

    return 'bg-primary-std border border-primary-std hover:bg-secondary-std text-primary-std font-normal focus:ring-gray-600 dark:focus:ring-gray-400';
  })();

  style = merge(
    className,
    !compact ? 'px-3 py-2' : 'px-2 py-1.5',
    big ? `px-4 py-4` : 'px-3 py-2',
    'rounded-md',
    'shadow-sm text-sm text-center relative overflow-hidden',
    'ring-offset-white dark:ring-offset-black focus:outline-none focus:ring-2 focus:ring-offset-2',
    style
  );

  return (
    <Link to={to} className={style}>
      {children}
    </Link>
  );
};
