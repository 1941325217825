import { FC } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import create, { State } from 'zustand';
import createContext from 'zustand/context';
import { dateToSecondsSinceMidnight } from '../../../helpers/dateHelper';
import {
  AssignEmployeeToRosterPositionMutation,
  EmployeePattern,
  ReadRoster,
  ReadRosterPositions,
  RosteredEmployee,
  RosterStaff,
  useAddPositionToStationMutation,
  useAssignEmployeeToRosterPositionMutation,
  useCompleteRosterMutation,
  useDeletePositionMutation,
  useFakeUncompleteRosterMutation,
  useRemoveEmployeeFromRosterPositionMutation,
  useUpdatePositionTimesMutation
} from '../../../services/gql/graphql';
import { RosterStatus } from '../calendar/rosterCalendar/RosterCalendar';
import { getRosteredStaffIds } from '../rosterHelper';

export const NOT_ASSIGNED_EMPLOYEE_ID = '';

export interface RosterService extends State {
  roster: ReadRoster;
  minTime: Date;
  maxTime: Date;
  completed: boolean;
  updatePositionTimes: (id: string, start: Date, end: Date) => void;
  addPositionToStation: (stationId: number) => void;
  deletePositionFromStation: (positionId: string) => void;
  publishRoster: (publish: boolean) => void;
  staff?: RosterStaff[];
  rosteredStaffIds?: string[];
  patterns?: EmployeePattern[];
  updateRosterEmployee: (employeeId: string, positionId: string) => void;
  getStaff: (stationId: number, startTime: number, endTime: number) => RosterStaff[]
}

interface RosterServiceProps {
  roster: ReadRoster;
  staff?: RosterStaff[];
  patterns?: EmployeePattern[];
  minTime: Date;
  maxTime: Date;
}

const { Provider, useStore } = createContext<RosterService>();
export const useRosterService = useStore;

export const RosterServiceProvider: FC<RosterServiceProps> = ({ children, roster, staff, minTime, maxTime, patterns }) => {
  const handleError = useErrorHandler();

  const [, updatePositionTimes] = useUpdatePositionTimesMutation();
  const [, addPosition] = useAddPositionToStationMutation();
  const [, deletePosition] = useDeletePositionMutation();
  const [, completeRoster] = useCompleteRosterMutation();
  const [, uncompleteRoster] = useFakeUncompleteRosterMutation();

  const searchRosterForPosition = (roster: ReadRoster, positionId: string) => {
    let position: ReadRosterPositions | undefined;

    roster.stations?.forEach(station => {
      position = station.positions?.find(pos => pos.id === positionId);
    });

    return position;
  };

  const [, assignRosterEmployee] = useAssignEmployeeToRosterPositionMutation();

  const [, removeRosterEmployee] = useRemoveEmployeeFromRosterPositionMutation();

  const createStore = () =>
    create<RosterService>((set, get) => {
      return {
        updatePositionTimes: async (id, start, end) => {
          const startSecs = dateToSecondsSinceMidnight(start);
          const endSecs = dateToSecondsSinceMidnight(end);

          const result = await updatePositionTimes({
            args: {
              rosterId: get().roster?.id,
              rosterPositionId: id,
              start: startSecs,
              end: endSecs
            }
          });

          if (result.error) {
            handleError(result.error);
          } else {
            // Apply the changes to the saved roster object.
            const roster = { ...get().roster };
            const position = searchRosterForPosition(roster, id);

            if (position) {
              position.start = startSecs;
              position.end = endSecs;
            }

            set({ roster });
          }
        },
        addPositionToStation: async (stationId: number) => {
          const result = await addPosition({
            args: {
              stationId: stationId,
              start: 10 * 60 * 60,
              end: 18 * 60 * 60,
              rosterId: get().roster.id
            }
          });

          let newPosition: ReadRosterPositions | undefined = undefined;

          if (result.error) {
            handleError(result.error);
          } else if (result.data?.createRosterPosition) {
            // Apply the changes to the saved roster object.
            const fullRoster = { ...get().roster };

            const resultStations = result.data.createRosterPosition.stations;
            const resultStation = resultStations?.find(station => station.stationId === stationId);

            if (resultStation?.positions) {
              newPosition = resultStation.positions[resultStation.positions.length - 1] as ReadRosterPositions;
            }

            if (newPosition) {
              const fullRosterStation = fullRoster.stations?.find(station => station.stationId === stationId);

              if (fullRosterStation?.positions) {
                fullRosterStation.positions.push(newPosition);
                set({ roster: fullRoster });
              }
            }
          }

          return newPosition;
        },
        deletePositionFromStation: async (positionId: string) => {
          const result = await deletePosition({
            args: {
              positionId: positionId,
              rosterId: get().roster.id
            }
          });

          if (result.error) {
            handleError(result.error);
          } else {
            // Apply the changes to the saved roster object.
            const fullRoster = { ...get().roster };

            fullRoster.stations?.forEach(station => {
              const deletedPositionIndex = station.positions?.findIndex(pos => pos.id === positionId);

              if (deletedPositionIndex !== undefined && deletedPositionIndex >= 0) {
                station.positions!.splice(deletedPositionIndex, 1);
              }
            });

            set({ roster: fullRoster });
          }
        },
        publishRoster: async (publish: boolean) => {
          if (publish) {
            const result = await completeRoster({
              rosterId: get().roster.id
            });

            if (result.error) {
              handleError(result.error);
            } else {
              const roster = { ...get().roster };
              roster.status = RosterStatus.published;
              set({ roster, completed: true });
            }
          } else {
            const result = await uncompleteRoster({
              args: {
                rosterId: get().roster.id,
                //TEMPORARY HACK- set the platesTarget to be a random number.
                //Only Kura uses this value, we're just setting it to force the state into
                //draft, since we don't have an endpoint for that.
                platesTarget: Date.now() % 1000000
              }
            })

            if (result.error) {
              handleError(result.error);
            } else {
              const roster = { ...get().roster };
              roster.status = RosterStatus.draft;
              set({ roster, completed: false });
            }
          }
        },
        roster,
        minTime,
        maxTime,
        staff,
        patterns,
        updateRosterEmployee: async (employeeId, positionId) => {
          let result;
          const removing = employeeId === NOT_ASSIGNED_EMPLOYEE_ID;

          if (removing) {
            result = await removeRosterEmployee({
              args: {
                rosterId: get().roster.id,
                positonId: positionId
              }
            });
          } else {
            result = await assignRosterEmployee({
              args: {
                rosterId: get().roster.id,
                rosterPositionId: positionId,
                employeeId
              }
            });
          }

          if (result.error) {
            handleError(result.error);
          } else {
            // Update the stored copy of the roster.

            const roster = { ...get().roster };
            let positionToEdit: ReadRosterPositions | undefined;
            roster.stations!.forEach(station => {
              const foundPosition = station.positions!.find(pos => pos.id === positionId);

              if (foundPosition) {
                positionToEdit = foundPosition;
              }
            });

            if (positionToEdit) {
              if (removing) {
                positionToEdit.employee = undefined;
              } else {
                const resultData = result.data as AssignEmployeeToRosterPositionMutation;
                const resultStations = resultData.assignEmployeeToRosterPosition!.stations!;

                resultStations.forEach(station => {
                  station.positions?.forEach(position => {
                    if (position.id === positionToEdit!.id) {
                      positionToEdit!.employee = position.employee as RosteredEmployee;
                    }
                  });
                });
              }
            }

            set({ rosteredStaffIds: getRosteredStaffIds(roster)});
          }
        },
        completed: roster.status === RosterStatus.published,
        rosteredStaffIds: getRosteredStaffIds(roster),
        getStaff: (stationId: number, startTime: number, endTime: number) => {
          if (!get().staff) {
            return [];
          }

          const fullStaffList: RosterStaff[] = get().staff!;

          const filterBySkills = (staffMember: RosterStaff) => {
            const skillScore = staffMember.stationSkillScore?.find(score => score.stationId === stationId);

            if (skillScore && skillScore.score > 0) {
              return true;
            }

            return false;
          }

          const staffWithSkills: RosterStaff[] = fullStaffList.filter(filterBySkills);

          const filterByAvailability = (staffMember: RosterStaff) => {
            if (!get().patterns) {
              return false;
            }

            const employeePattern = get().patterns!.find(p => p.identityId === staffMember.employeeId);

            if (!employeePattern || !employeePattern.periods) {
              return false;
            }

            const rosterDate = new Date(get().roster.rosterDate);
            const dayPattern = employeePattern.periods[0].patterns?.find(p => p.dayNumber === rosterDate.getDay());

            if (!dayPattern) {
              return false;
            }

            if (dayPattern.startTime > startTime || dayPattern.endTime < endTime) {
              return false;
            }

            return true;
          }

          const availableStaff: RosterStaff[] = staffWithSkills.filter(filterByAvailability);

          const sortBySkill = (a: RosterStaff, b: RosterStaff) => {
            const aSkillScore = a.stationSkillScore?.find(score => score.stationId === stationId);
            const bSkillScore = b.stationSkillScore?.find(score => score.stationId === stationId);

            if (!aSkillScore || !bSkillScore) {
              return 0;
            } else {
              return aSkillScore.score - bSkillScore.score;
            }
          }

          availableStaff.sort(sortBySkill);

          return availableStaff;
        }
      };
    });

  return <Provider createStore={createStore}>{children}</Provider>;
};
