import React, { FC } from 'react';
import { useVariable } from '../../services/backstage/BackstageProvider';
import { Img } from 'react-suspense-img';
import { useHslBuilder } from './useHsl';

export enum BackgroundStyle {
  VIVID,
  SUBTLE
}

interface PageBackgroundProps {
  backgroundStyle?: BackgroundStyle;
  fixPosition?: boolean;
}

export const PageBackground: FC<PageBackgroundProps> = ({ backgroundStyle = BackgroundStyle.SUBTLE, fixPosition }) => {
  const bgImageUrl = useVariable('backgroundImageUrl');
  const hslBuilder = useHslBuilder();

  let baseOpacity: number;
  switch (backgroundStyle) {
    case BackgroundStyle.SUBTLE:
      baseOpacity = 0.75;
      break;
    case BackgroundStyle.VIVID:
      baseOpacity = 0.2;
      break;
  }

  return (
    <div className="relative w-full h-[0] -z-10 print:hidden">
      <div className={`${fixPosition ? 'fixed' : 'absolute'} h-[100vh] w-full`} style={{ background: hslBuilder(1) }}>
        <div className="relative w-full">
          <div className="fixed w-full h-[100vh] top-0 hidden dark:block bg-black opacity-70 z-10"></div>
          {bgImageUrl && <Img className="w-full" src={bgImageUrl}></Img>}
          <div
            className="hidden md:block absolute w-full h-[101%] top-0"
            style={{ backgroundImage: `linear-gradient(${hslBuilder(baseOpacity)}, ${hslBuilder(1)} 60%)` }}
          />
          <div
            className="md:hidden absolute w-full h-[101%] top-0"
            style={{ backgroundImage: `linear-gradient(${hslBuilder(baseOpacity)}, ${hslBuilder(1)})` }}
          />
        </div>
      </div>
    </div>
  );
};
