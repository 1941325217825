import { FC } from 'react';
import { CalendarCellProps } from '../../common/Calendar';
import { RosterCalendarContextObject } from '../RosterCalendar';
import { CreateButton } from './CreateButton';
import { DeleteButton } from './DeleteButton';
import { IconToDisplay } from './IconToDisplay';
import { SummaryStats } from './SummaryStats';
import { isAfter, isEqual } from 'date-fns';

export const RosterCalendarContent: FC<CalendarCellProps<RosterCalendarContextObject>> = ({ date, contextObject }) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return contextObject ? (
    <div className="relative h-full w-full">
      {contextObject?.deleteOnClick && <DeleteButton onClick={contextObject.deleteOnClick} />}
      {contextObject?.id ? (
        <div className={`h-full flex items-center justify-center flex-row space-x-8 px-4`}>
          <IconToDisplay contextObject={contextObject} date={date} />
          <SummaryStats contextObject={contextObject} />
        </div>
      ) : (
        (isAfter(date, currentDate) || isEqual(currentDate, date)) && <CreateButton />
      )}
    </div>
  ) : (
    <div></div>
  );
};
