import { FC } from 'react';
import Avatar from '@strawberrylemonade/boring-avatars';

interface AvatarProps {
  name?: string;
  variant?: 'marble' | 'beam' | 'pixel' | 'sunset' | 'ring' | 'bauhaus';
  size?: number;
  greyscale?: boolean;
}

export const TimeshiftAvatar: FC<AvatarProps> = ({ name, greyscale, variant = 'beam', size = 70}) => {
  const initials = name
    ?.match(/\b([A-Z])/g)
    ?.join('')
    .toUpperCase();

  return (
    <span className={greyscale ? 'filter grayscale' : ''}>
      <Avatar
        name={initials}
        colors={[
          'var(--primary-color-700)',
          'var(--primary-color-500)',
          'var(--primary-color-800)',
          'var(--secondary-color-600)',
          'var(--secondary-color-600)'
        ]}
        size={size}
        variant={variant}
      />
    </span>
  );
};
