import * as React from 'react';
import { InputProps, withField } from './Field';
import { merge } from '../../../helpers/Utility';

interface TextInputProps extends InputProps<string> {
  placeholder?: string;
  autoComplete?: string;
  type?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
  className,
  name,
  onChange,
  value,
  placeholder,
  Icon,
  type = 'text',
  ...props
}) => {
  return (
    <div className="mt-1 flex rounded-md shadow-sm text-primary-std">
      {Icon ? (
        <span className="inline-flex items-center px-2 rounded-l-md border border-r-0 border-primary-std bg-gray-50 dark:bg-secondary-std">
          <Icon className="w-5 h-5" />
        </span>
      ) : null}
      <input
        {...props}
        type={type}
        name={name}
        id={name}
        className={merge(
          Icon ? 'rounded-r-md' : 'rounded-md',
          'focus:ring-primary-500 bg-primary-std focus:border-primary-500 flex-1 min-w-0 block w-full sm:text-sm border-primary-std',
          className
        )}
        placeholder={placeholder}
        value={value}
        onChange={event => onChange(event.target.value)}
      />
    </div>
  );
};

export const TextFieldWithOptionalLabel = withField(TextInput, 'Optional');
export const TextField = withField(TextInput, '');
