import * as React from 'react';
import { merge } from '../../../helpers/Utility';
import { InputProps, withField } from './Field';

interface SelectInputProps extends InputProps<{ [key: string]: any } | undefined> {
  placeholder?: string;
  options?: { id: string; name: string }[];
  selectClassName?: string,
  disabled?: boolean
}

export const SelectInputCustom: React.FC<SelectInputProps> = ({
  className,
  selectClassName,
  name,
  onChange,
  value,
  placeholder,
  options,
  disabled,
  ...props
}) => {
  return (
    <select
      {...props}
      disabled={disabled}
      name={name}
      id={name}
      className={merge(
        selectClassName,
        'border-primary-std bg-primary-std focus:ring-primary-500 focus:border-primary-500 block w-full rounded-md py-2 pl-3 pr-10 focus:outline-none sm:text-sm'
      )}
      value={value?.id}
      onChange={event => onChange(options?.find(option => option.id === event.target.value))}
    >
      {!value ? <option>{placeholder ? placeholder : 'Please select'}</option> : null}
      {options?.map(option => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export const SelectFieldCustom = withField(SelectInputCustom, 'Optional');
