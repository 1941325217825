import * as React from 'react';
import { Loader } from '../Loader';
import { merge } from '../../../helpers/Utility';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  compact?: boolean;
  big?: boolean;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  tint?: boolean;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  compact,
  big,
  primary,
  secondary,
  loading,
  danger,
  disabled,
  tint,
  className,
  type = 'button',
  ...props
}) => {
  const style = (() => {
    if (disabled) return 'bg-gray-400 border border-gray-400 text-primary-std font-medium focus:ring-primary-500';
    if (primary && !tint)
      return 'bg-primary-500 hover:bg-primary-600 border border-primary-600 text-white font-medium focus:ring-primary-500';
    if (secondary && !tint)
      return 'bg-secondary-500 hover:bg-secondary-600 border border-secondary-600 text-white font-medium focus:ring-secondary-500';
    if (danger && !tint)
      return 'bg-red-500 hover:bg-red-600 border border-red-600 text-white font-medium focus:ring-red-500';
    if (primary && tint)
      return 'bg-primary-50 hover:bg-primary-100 border border-primary-200 dark:text-primary-400 text-primary-700 font-normal focus:ring-primary-500';
    if (secondary && tint)
      return 'bg-secondary-50 hover:bg-secondary-100 border border-secondary-200 dark:text-secondary-400 text-secondary-700 font-normal focus:ring-secondary-500';
    if (danger && tint)
      return 'bg-red-50 hover:bg-red-100 dark:hover:bg-red-600 dark:hover:bg-opacity-30 dark:bg-red-600 dark:bg-opacity-20 border border-red-200 dark:border-red-300 text-red-600 dark:text-red-300 font-medium focus:ring-red-500';

    return 'bg-primary-std border border-primary-std hover:bg-secondary-std text-primary-std font-normal focus:ring-gray-600 dark:focus:ring-gray-400';
  })();

  return (
    <button
      type={type}
      className={merge(
        className,
        disabled ? 'pointer-events-none' : '',
        !compact ? 'px-3 py-2 rounded-md' : 'px-2 py-1.5 rounded-md',
        big ? `px-4 py-4` : 'px-3 py-2 rounded-md',
        'shadow-sm text-sm text-center relative overflow-hidden',
        'ring-offset-white dark:ring-offset-black focus:outline-none focus:ring-2 focus:ring-offset-2',
        style
      )}
      {...props}
    >
      {loading ? (
        <span
          className={merge(
            'absolute inset-0 flex justify-center items-center',
            primary && !tint ? 'bg-primary-500' : null,
            primary && tint ? 'bg-primary-50' : null,
            secondary && !tint ? 'bg-secondary-500' : null,
            secondary && tint ? 'bg-secondary-50' : null,
            danger && !tint ? 'bg-red-500' : null,
            danger && tint ? 'bg-red-50' : null,
            !primary && !secondary && !danger ? 'bg-primary-std' : null
          )}
        >
          <Loader className="w-6 h-6" />
        </span>
      ) : null}
      {children}
    </button>
  );
};
