import React, { FC, useEffect, useState } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import strings from '../../team.translations';
import { ReadEmployee } from '../../../../services/gql/graphql';
import { useNavigate } from 'react-router-dom';
import { useTeamMemberService } from '../TeamMemberService';
import { TSTitleCard } from '../../../../components/shared/card/Card';
import { EmployeeSchedule } from '../../../../helpers/BuildWorkingPattern';
import { Loader } from '../../../../components/shared/Loader';

interface TeamMemberAvailabilityScheduleCardProps {
  enableEdit?: boolean;
  employee: ReadEmployee;
}

export const TeamMemberAvailabilityScheduleCard: FC<TeamMemberAvailabilityScheduleCardProps> = ({
  employee,
  enableEdit
}) => {
  const [dataToRender, setDataToRender] = useState<any>();
  const navigate = useNavigate();

  // The backend is a bit weird and won't allow employees with this role to be changed.
  const isManager = employee.role === "Manager";

  const [getAvailabilitySchedule] = useTeamMemberService(state => [state.getAvailabilitySchedule]);

  useEffect(() => {
    const get = async () => {
      const response = await getAvailabilitySchedule(employee.identityId);
      setDataToRender(response);
    };
    get();
  }, [employee.identityId, getAvailabilitySchedule]);

  const onEditClick = () => {
    navigate('set-schedule');
  };

  return (
    <TSTitleCard
      editDetails={enableEdit && !isManager ? {
        label: strings.scheduleCard.editLabel,
        onClick: onEditClick
      } : undefined}
      title={strings.scheduleCard.cardTitle}
      icon={AiOutlineCalendar}
    >
      <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 sm:grid-flow-col sm:grid-rows-4 gap-y-5 gap-x-8 lg:px-24">
        {dataToRender ? dataToRender.map((item: EmployeeSchedule) => {
          return (
            <>
              <div className="flex space-x-8">
                <p className="flex-1 text-right font-semibold w-24 sm:w-auto sm:px-0">{item.day}</p>
                <p className="flex-1">
                  {item.available
                    ? `${item.start} - ${item.end}`
                    : strings.scheduleCard.notAvailable}
                </p>
              </div>
            </>
          );
        }) : <Loader className="h-10 mx-auto col-span-full" />}
      </div>
    </TSTitleCard>
  );
};
