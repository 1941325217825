import { FC, HTMLAttributes } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { merge } from '../../../helpers/Utility';

export enum Status {
  OK,
  BAD
}

interface StatusIconProps extends HTMLAttributes<HTMLDivElement> {
  status: Status;
}

export const StatusIcon: FC<StatusIconProps> = ({ status, className }) => {
  let style = merge(className, 'rounded-full text-white p-1 font-bold');
  let icon: JSX.Element;

  switch (status) {
    case Status.BAD:
      style = merge(style, 'bg-red-500');
      icon = <FiX className="h-full" />;
      break;
    case Status.OK:
      style = merge(style, 'bg-green-500');
      icon = <FiCheck className="h-full" />;
      break;
  }
  return <div className={style}>{icon}</div>;
};
