import * as React from 'react';
import { useState } from 'react';
import { merge } from '../../../helpers/Utility';
import { InputProps, withField } from './Field';

export enum CurrencyType {
  GBP
}

interface CurrencyInputProps extends InputProps<number | undefined> {
  currencyType?: CurrencyType;
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  className,
  name,
  currencyType = CurrencyType.GBP,
  onChange,
  value,
  Icon,
  ...props
}) => {
  const floatToString = (float: number) => {
    switch (currencyType) {
      case CurrencyType.GBP:
        return `£${float.toFixed(2)}`;
    }
  };

  const stringToFloat = (string: string) => {
    switch (currencyType) {
      case CurrencyType.GBP:
        return parseFloat(string.substr(1, string.length - 1));
    }
  };

  const [displayedValue, setDisplayedValue] = useState<string>(value ? floatToString(value) : floatToString(0));

  let completeRegex: string;
  let inProgressRegex: string;
  switch (currencyType) {
    case CurrencyType.GBP:
      completeRegex = '^£[0-9]*\\.?[0-9][0-9]$';
      inProgressRegex = '^£[0-9]*\\.?[0-9]?[0-9]?$';
      break;
  }
  return (
    <div className="mt-1 flex rounded-md shadow-sm text-primary-std">
      {Icon ? (
        <span className="inline-flex items-center px-2 rounded-l-md border border-r-0 border-primary-std bg-gray-50 dark:bg-secondary-std">
          <Icon className="w-5 h-5" />
        </span>
      ) : null}
      <input
        {...props}
        type="text"
        pattern={completeRegex}
        name={name}
        id={name}
        className={merge(
          Icon ? 'rounded-r-md' : 'rounded-md',
          'focus:ring-primary-500 bg-primary-std focus:border-primary-500 flex-1 min-w-0 block w-full sm:text-sm border-primary-std',
          className
        )}
        value={displayedValue}
        onChange={event => {
          const newStringValue = event.target.value;
          const regex = new RegExp(inProgressRegex);

          if (!regex.test(newStringValue)) {
            return;
          }

          setDisplayedValue(newStringValue);
          const number = stringToFloat(newStringValue);

          if (!isNaN(number)) {
            onChange(number);
          }
        }}
      />
    </div>
  );
};

export const CurrencyField = withField(CurrencyInput, 'Optional');
