import React, { FC } from 'react';
import strings from './text.translations';

interface LiveLabelProps {}

export const LiveLabel: FC<LiveLabelProps> = () => {
  return (
    <div className="uppercase inline rounded-md font-bold py-1 px-2 border-2 border-blue-700 dark:border-blue-500 text-blue-700 dark:text-blue-500">
      <span className="animate-pulse mr-2">{strings.fullStop}</span>
      {strings.live}
    </div>
  );
};
