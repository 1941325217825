import { FC } from 'react';
import { DangerCard } from './DangerCard';
import { Permissions, useAuthenticationService } from '../../../../services/authentication/authentication-service';
import { ReadEmployee } from '../../../../services/gql/graphql';

interface DangerCardSectionProps {
  userData: ReadEmployee;
}

export const dangerCardSectionId = 'danger-section';

export const DangerCardSection: FC<DangerCardSectionProps> = ({userData}) => {

  const [permission] = useAuthenticationService(state => [state.user?.profile.permission]);
  const canDelete = permission?.includes(Permissions.EmployeeDelete) ?? false;
  const canDeactivate = permission?.includes(Permissions.EmployeeDeactivate) ?? false;

  const dangerCard = (
    <DangerCard
      employeeId={userData!.identityId}
      isDeactivated={Boolean(userData!.isDeactivated)}
      firstName={userData!.firstName}
      lastName={userData!.lastName}
      disableDeactivate={!canDeactivate}
      disableDelete={!canDelete}
    />
  );

  const showDangerCard =
    (canDelete || canDeactivate) && !userData?.isDeleted;

  return <section id={dangerCardSectionId}>
      <div>{showDangerCard && dangerCard}</div>
  </section>;
};