import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isManagement, isStaff, useAuthenticationService } from '../authentication-service';
import { useParams } from 'react-router';
import { useGetMeQuery } from '../../gql/graphql';

export const RequireOwnPageOrManager: FC = () => {
  const location = useLocation();
  const [role] = useAuthenticationService(state => [state.role]);
  const isManager = isManagement(role);
  const isStaffRole = isStaff(role);

  const { userId } = useParams<'userId'>();
  const [{ data }] = useGetMeQuery();
  const myUserId = data!.myEmployee!.identityId;
  const isOwnPage = userId === myUserId;

  if (!isManager && isStaffRole && !isOwnPage) {
    return <Navigate to={'*'} replace={true} state={{ restore: location.pathname }} />;
  }

  return <Outlet />;
};
