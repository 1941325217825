import React, { FC } from 'react';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { NavPage } from '../../components/page/Breadcrumbs';
import { FiSettings } from 'react-icons/fi';
import { PageHeader } from '../../components/page/PageHeader';
import { LocationDetailsCard } from './LocationDetailsCard';
import { DeleteLocationCard } from './DeleteLocationCard';
import { ClockInDevicesCard } from './ClockInDevicesCard';
import strings from './manageLocation.translations';
import { useLocationQuery } from '../../services/gql/graphql';
import { handleError } from '../../services/gql/config/URQLProvider';
import { useParams } from 'react-router-dom';
import { Location } from '../manageOrg/locations/LocationList';
import { useLocationService } from '../../services/location/LocationService';
import { isAdmin, useAuthenticationService } from '../../services/authentication/authentication-service';
import { Feature } from '../../services/backstage/Feature';
import { RequireAssignedLocation } from '../../services/authentication/routes/RequireAssignedLocation';

export const ManageLocationPage: FC = () => {
  const { locationId: urlLocationId } = useParams<'locationId'>();
  const [selectedLocationId] = useLocationService(state => [state.selectedLocation?.id]);
  const [role] = useAuthenticationService(state => [state.role]);

  const [{ data, error }] = useLocationQuery({
    variables: {
      id: Number(urlLocationId)!
    }
  });
  if (error) handleError(error);

  const breadcrumbs: NavPage[] = [
    {
      name: strings.title,
      to: `/manage-location/${urlLocationId}`
    },
    {
      name: data!.location!.name,
      to: `/manage-location/${urlLocationId}`
    }
  ];

  const canDeleteLocation = isAdmin(role) && Number(urlLocationId)! !== selectedLocationId;

  return (
    <PageContainer
      customHeader={<PageHeader pageTitle={data!.location!.name} breadcrumbs={breadcrumbs} icon={<FiSettings />} />}
    >
      <PageSizer className="flex flex-col gap-5" mode={SizerMode.PAGE}>
        <RequireAssignedLocation locationIds={[urlLocationId!]} errorMessage={strings.notAssigned}>
          <LocationDetailsCard location={data?.location as Location} />
          <ClockInDevicesCard />
          <Feature name="debug">
            {canDeleteLocation && <DeleteLocationCard locationId={Number(urlLocationId)!} />}
          </Feature>
        </RequireAssignedLocation>
      </PageSizer>
    </PageContainer>
  );
};
