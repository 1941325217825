import strings from '../../team.translations';
import { UseFormReturn } from 'react-hook-form';
import { ReadRole } from '../../../../services/gql/graphql';
import React from 'react';

export enum BEFieldValidation {
  EmailInUse = 'Email already in use',
  PhoneInUse = 'Phone Number already in use'
}

export interface BEValidationReason {
  reason: string;
}

export interface BEEmailValidationErr extends BEValidationReason {
  email: string;
}

export interface BEPhoneValidationErr extends BEValidationReason {
  phoneNumber: string;
}

export const extractBEError = (errorParent: string[], methods: UseFormReturn<any>) => {
  let errorObj: BEEmailValidationErr | BEPhoneValidationErr = JSON.parse(errorParent[0].replaceAll("'", '"'));

  if (errorObj.reason.includes(BEFieldValidation.EmailInUse)) {
    methods.setError('email', {
      message: strings.addTeamMemberForm.repeatEmail,
      type: 'BEValidation'
    });
  } else if (errorObj.reason.includes(BEFieldValidation.PhoneInUse)) {
    methods.setError('phoneNumber', {
      message: strings.addTeamMemberForm.repeatPhone,
      type: 'BEValidation'
    });
  }
};

export interface BEFormErrorSummary {
  emailError: boolean;
  phoneError: boolean;
}

export const handleBEFormError = (
  error: any,
  methods: UseFormReturn<any>,
  handleError: (error: unknown) => void
): BEFormErrorSummary => {
  let emailErr: string[] | undefined = undefined;
  let phoneErr: string[] | undefined = undefined;

  try {
    let { InvalidEmail } = JSON.parse(error.graphQLErrors[0].message);
    emailErr = InvalidEmail;
  } catch (e) {
    //Catch parse error but continue
  }

  try {
    let { InvalidPhoneNumber } = JSON.parse(error.graphQLErrors[0].message);
    phoneErr = InvalidPhoneNumber;
  } catch (e) {
    //Catch parse error but continue
  }

  if (!emailErr && !phoneErr) {
    //Unexpected BE error - handle with catch-all solution
    handleError(error);
  }

  emailErr && extractBEError(emailErr, methods);
  phoneErr && extractBEError(phoneErr, methods);

  return { emailError: !!emailErr, phoneError: !!phoneErr };
};

enum legacyRoleMap {  
  Admin = 'admin',
  Manager = 'manager',
  Staff = 'staff'
}

export const roleSelectOptions = (roles: ReadRole[]) => {
  return roles?.map(role => {
    let roleName = role.name.replaceAll(' ', '').toLowerCase();
    
    // Need to map new roles (e.g. TimeshiftAdmin) into legacy roles (e.g. Admin)")
    roleName = Object.values(legacyRoleMap).find(value => roleName.includes(value)) as string;

    const descriptionIndex = Object.keys(strings.addTeamMemberForm).findIndex(
      key => key.toLowerCase() === `${roleName}description`
    );
    const descriptionValue = Object.values(strings.addTeamMemberForm)[descriptionIndex];
    const nameIndex = Object.keys(strings.addTeamMemberForm).findIndex(key => key.toLowerCase() === `${roleName}`);
    const nameValue = Object.values(strings.addTeamMemberForm)[nameIndex];
    return {
      key: role.id,
      value: role.name,
      descriptionMarkup: (
        <>
          <span className="mb-2">{nameValue}</span>
          <span>{descriptionValue}</span>
        </>
      )
    };
  });
};
