import React, { FC } from 'react';
import { TextInput } from '../components/shared/form/TextInput';
import { FiCopy } from 'react-icons/fi';
import { createErrorToast, createSuccessToast } from '../services/notification/ToastService';
import { useAuthenticationService } from '../services/authentication/authentication-service';
import { IconButton } from '../components/shared/button/IconButton';

interface TextCopyProps {
  title?: string;
  text: string;
}

export const GqlHeaderObj = (tenantId: string, token: string) => `{
  "tenant-id": "${tenantId}",
  "authorization": "Bearer ${token}"
}`;

export const TextCopy: FC<TextCopyProps> = ({ title, text }) => {
  const copyToClipboard = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(text).then(() => {
        createSuccessToast('Copied');
      });
    return Promise.reject('The Clipboard API is not available.').then(() => {
      createErrorToast('Failed to copy');
    });
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <p className="font-bold text-lg">{title}</p>
      <div className="flex flex-row gap-4 w-full items-center bg-secondary-std p-4">
        <div className="w-full">
          <TextInput onChange={() => {}} name={'tokenCopy'} value={text} />
        </div>
        <IconButton className="" label={''} Icon={FiCopy} onClick={copyToClipboard} />
      </div>
    </div>
  );
};

export const UserInfoCopyBoxes = () => {
  const [user] = useAuthenticationService(state => [state.user]);

  return (
    <div className="flex flex-col gap-4 w-full">
      <TextCopy
        text={GqlHeaderObj(user?.profile.organisation ?? 'No OrgID', user?.access_token ?? 'No Token')}
        title={'GraphQL Header'}
      />
      <TextCopy text={user?.access_token ?? 'No Token'} title={'User Token'} />
    </div>
  );
};
