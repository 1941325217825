import React, { FC } from 'react';
import { TSCardChevronRight } from '../../components/shared/card/Card';
import { FiClock } from 'react-icons/fi';
import strings from './manageLocation.translations';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../components/shared/icons/Icon';

interface ClockInDevicesCardProps {}

export const ClockInDevicesCard: FC<ClockInDevicesCardProps> = () => {
  const navigate = useNavigate();

  const onClockInDevicesClick = () => {
    navigate('devices');
  };

  return (
    <TSCardChevronRight
      onClick={onClockInDevicesClick}
      icon={<Icon Icon={FiClock} theme='primary'/>}
      title={strings.clockInDevicesCard.title}
      description={strings.clockInDevicesCard.description}
      largeHeader
    />
  );
};
