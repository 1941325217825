import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isAdmin, useAuthenticationService } from '../authentication-service';

export const RequireAdmin: FC = () => {
  const location = useLocation();
  const [role] = useAuthenticationService(state => [state.role]);
  const isManager = isAdmin(role);

  if (!isManager) {
    return <Navigate to={'*'} replace={true} state={{ restore: location.pathname }} />;
  }

  return <Outlet />;
};
