import { FC } from 'react';
import {
  EmployeeWorkTimeRecord,
  GetMyShiftsListQuery,
  GetShiftsListQuery,
  useGetMyShiftsListQuery,
  useGetShiftsListQuery
} from '../../services/gql/graphql';
import { dateToGQLFormat } from '../../helpers/dateHelper';
import { ShiftsTable, ShiftsTableProps } from './ShiftsTable';

interface EmployeeShiftsTableProps extends ShiftsTableProps {
  isMe?: boolean;
  locations?: string[]
}

export const EmployeeShiftsTable: FC<EmployeeShiftsTableProps> = ({
  startDate,
  endDate,
  userId,
  recordId,
  needsRefetch,
  refetchComplete,
  onShiftEdit,
  isMe,
  locations = []
}) => {
  const startDateForQuery = new Date(startDate);
  startDateForQuery.setHours(0, 0, 0, 0);

  const endDateForQuery = new Date(endDate);
  endDateForQuery.setHours(0, 0, 0, 0);
  //We want to include the end date, so set it to midnight on the day after.
  endDateForQuery.setDate(endDateForQuery.getDate() + 1);

  const queryEndpoint = isMe ? useGetMyShiftsListQuery : useGetShiftsListQuery;
  const [{ data }, refetch] = queryEndpoint({
    variables: {
      queryWorkItemArgs: {
        employeeId: isMe ? undefined : userId,
        from: dateToGQLFormat(startDateForQuery),
        to: dateToGQLFormat(endDateForQuery)
      }
    },
    requestPolicy: 'network-only'
  });

  let shiftData: EmployeeWorkTimeRecord[] | undefined;

  if (isMe) {
    const dataToRead = data as GetMyShiftsListQuery;
    shiftData = dataToRead?.getMyWorkTimeRecord as EmployeeWorkTimeRecord[];
  } else {
    const dataToRead = data as GetShiftsListQuery;
    shiftData = dataToRead?.getWorkTimeRecord as EmployeeWorkTimeRecord[];
  }

  if (locations) {
    shiftData = shiftData.filter(data => locations.includes(String(data.locationId)));
  }

  return (
    <ShiftsTable
      startDate={startDate}
      endDate={endDate}
      userId={userId}
      recordId={recordId}
      needsRefetch={needsRefetch}
      refetchComplete={refetchComplete}
      onShiftEdit={onShiftEdit}
      data={shiftData}
      refetch={refetch}
      isMe={isMe}
    />
  );
};
