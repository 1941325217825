import React, { FC } from 'react';
import strings from './manageLocation.translations';
import { TsForm } from '../../components/shared/form/Form';
import { TextFieldWithOptionalLabel } from '../../components/shared/form/TextInput';
import { useForm } from 'react-hook-form';
import { Location } from '../manageOrg/locations/LocationList';
import { useUpdateLocationMutation } from '../../services/gql/graphql';
import { handleError } from '../../services/gql/config/URQLProvider';
import { SubmitCancelButtons } from '../../components/shared/form/SubmitCancelButtons';

interface EditLocationDetailsFormProps {
  onComplete: () => void;
  location: Location
}
interface EditLocationFields {
  name: string;
}

export const EditLocationDetailsForm: FC<EditLocationDetailsFormProps> = ({ onComplete, location }) => {
  const methods = useForm<EditLocationFields>({
    defaultValues: {
      name: location.name
    }
  });
  
  const [{ fetching }, UpdateLocation] = useUpdateLocationMutation();
  
  const onSubmit = async (values: EditLocationFields) => {
    try {
      await UpdateLocation({
        location: {
          id: location.id,
          name: values.name
        }
      });
    } catch (e) {
      handleError(e as Error);
    }
    onComplete();
  };

  return (
    <TsForm methods={methods} onSubmit={onSubmit}>
      <div className="flex flex-col space-y-5 xl:w-96 mt-3">
        <TextFieldWithOptionalLabel
          name="name"
          title={strings.locationDetailsCard.name}
          validationMessage={strings.editLocationDetails.nameRequired}
          required
          placeholder={location.name}
        />
        <div className="flex items-center justify-end gap-3">
          <SubmitCancelButtons onComplete={onComplete} loading={fetching} submitText={strings.common.save}/>
        </div>
      </div>
    </TsForm>
  );
};
