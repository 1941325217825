import React, { FC, Suspense } from 'react';
import { ESSDesktopCalendar } from './desktop/ESSDesktopCalendar';
import { PageHeader } from '../../../../components/page/PageHeader';
import strings from '../calendar.translations';
import { FiCalendar } from 'react-icons/fi';
import { PageContainer } from '../../../../components/page/PageContainer';
import { ESSMobileCalendar } from './mobile/ESSMobileCalendar';
import { TableLoader } from '../../../../components/shared/table/TableLoader';
import { useGetMyWorkingPatternsQuery, WorkingPatternDay } from '../../../../services/gql/graphql';
import { format } from 'date-fns';
import { RosterSelectServiceProvider } from '../common/rosterSelectService';

export const CalendarPage: FC = () => {
  const breadcrumbs = [{ name: strings.calendarBreadcrumbTitle, to: '/calendar' }];

  const [{ data: patternData }] = useGetMyWorkingPatternsQuery({
    variables: {
      dateAndPeriods: {
        date: format(new Date(), 'yyyy-MM-dd'),
        periods: 1
      }
    }
  });

  return (
    <PageContainer
      customHeader={<PageHeader pageTitle={strings.cardTitle} breadcrumbs={breadcrumbs} icon={<FiCalendar />} />}
    >
      <RosterSelectServiceProvider>
        <div className="hidden lg:block">
          <ESSDesktopCalendar
            workPatterns={patternData?.employeeWorkingPatterns?.periods![0].patterns as WorkingPatternDay[]}
          />
        </div>
        <div className="lg:hidden">
          <Suspense fallback={<TableLoader />}>
            <ESSMobileCalendar
              workPatterns={patternData?.employeeWorkingPatterns?.periods![0].patterns as WorkingPatternDay[]} readOnly
            />
          </Suspense>
        </div>
      </RosterSelectServiceProvider>
    </PageContainer>
  );
};
