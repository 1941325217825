import { FC, useCallback, useEffect, useState } from 'react';
import { GiPokecog } from 'react-icons/gi';
import { NavPage } from '../../components/page/Breadcrumbs';
import { PageContainer } from '../../components/page/PageContainer';
import { PageHeader } from '../../components/page/PageHeader';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import strings from '../devices/devices.translations';
import { useParams } from 'react-router-dom';
import { handleError } from '../../services/gql/config/URQLProvider';
import { Device, useGetDevicesForLocationQuery, useLocationQuery } from '../../services/gql/graphql';
import { Search } from '../../components/shared/Search';
import { Button } from '../../components/shared/button/Button';
import { AnimatePresence } from 'framer-motion';
import ReactPaginate from 'react-paginate';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { TSCard } from '../../components/shared/card/Card';
import { DeviceList } from './DeviceList';
import { AddDeviceModal } from './AddDeviceModal';
import { RequireAssignedLocation } from '../../services/authentication/routes/RequireAssignedLocation';

export const AllDevicesPage: FC = () => {
  const { locationId } = useParams<'locationId'>();

  const [{ data: locationData, error: locationError }] = useLocationQuery({
    variables: {
      id: Number(locationId!)
    }
  });

  if (locationError) handleError(locationError);

  const breadcrumbs: NavPage[] = [
    {
      name: strings.manageLocationBreadcrumb,
      to: `/manage-location/${locationId}`
    },
    {
      name: `${locationData?.location?.name}`,
      to: `/manage-location/${locationId}/devices`
    },
    {
      name: strings.title,
      to: `/manage-location/${locationId}/devices`
    }
  ];

  const [{ data }] = useGetDevicesForLocationQuery({ variables: { id: Number(locationId) } });

  // if (error) handleError(error);

  const [allDevices, setAllDevices] = useState<Device[]>([]);
  const [currentDevices, setCurrentDevices] = useState<Device[]>([]);

  const [forcePage, setForcePage] = useState<number | undefined>(undefined);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const itemsPerPage = 10;

  const onSearch = (newValue: string) => {
    setForcePage(0);
    updatePageCount(
      allDevices.filter(device => device.name.toLowerCase().includes(newValue.toLowerCase())),
      true
    );
  };

  const updatePageCount = useCallback(
    (items: Device[], fromSearch = false) => {
      let offset = itemOffset;

      // Search has been reset so reset the pagination as well.
      if (fromSearch) {
        offset = 0;
        setItemOffset(offset);
      }

      const endOffset = offset + itemsPerPage;

      setCurrentDevices(items!.slice(offset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    },
    [itemOffset]
  );

  const handlePageClick = (event: any) => {
    setForcePage(undefined);

    const newOffset = (event.selected * itemsPerPage) % allDevices.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (!data?.devicesForLocation) {
      return;
    }

    const devices = data?.devicesForLocation as Device[];

    setAllDevices(devices);
    updatePageCount(devices);
  }, [data, updatePageCount]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <PageContainer
      customHeader={<PageHeader pageTitle={strings.title} breadcrumbs={breadcrumbs} icon={<GiPokecog />} />}
    >
      <PageSizer className="flex gap-5 flex-col" mode={SizerMode.PAGE}>
        <RequireAssignedLocation locationIds={[locationId!]} errorMessage={strings.notAssigned}>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col">
              <Search onChange={onSearch} />
            </div>
            <div className="flex flex-1">
              <Button primary className="text-sm ml-auto sm:px-1 py-0.5 text-xs" onClick={() => setModalOpen(true)}>
                {strings.addDeviceModal.title}
              </Button>
            </div>
          </div>
          {currentDevices.length > 0 ? (
            <>
              <AnimatePresence>
                <DeviceList devices={currentDevices} />
              </AnimatePresence>
              <ReactPaginate
                onPageChange={handlePageClick}
                previousLabel={<HiOutlineChevronLeft />}
                nextLabel={<HiOutlineChevronRight />}
                pageCount={pageCount}
                pageRangeDisplayed={1}
                //@ts-ignore
                renderOnZeroPageCount={null}
                pageLabelBuilder={page => strings.common.pagination.pageXOfY(page, pageCount)}
                containerClassName="flex items-center space-x-2 font-semibold justify-center"
                pageClassName={'hidden'}
                activeClassName={'!block'}
                breakLabel={''}
                forcePage={forcePage}
              />
            </>
          ) : (
            <>
              <TSCard className="font-semibold flex justify-center pl-6 pr-3 py-6">{strings.noData}</TSCard>
            </>
          )}
          <AddDeviceModal
            locationId={parseInt(locationId!)}
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
            }}
          />
        </RequireAssignedLocation>
      </PageSizer>
    </PageContainer>
  );
};
