import React, { FC } from 'react';
import { PageContainer } from '../../../components/page/PageContainer';
import { RosterRequirementHeader } from './RosterRequirementHeader';
import { RosterRequirement } from './RosterRequirement';
import { useRosterService } from '../ganttView/rosterService';

interface RosterRequirementPageProps {}

export const RosterRequirementPage: FC<RosterRequirementPageProps> = () => {
  const [roster] = useRosterService(state => [state.roster]);

  return (
    <div>
      <PageContainer customHeader={<RosterRequirementHeader />}>
        <RosterRequirement roster={roster} />
      </PageContainer>
    </div>
  );
};
