import { FC } from 'react';
import { CategorisedTraining, TrainingCategory } from '../../../../services/gql/graphql';
import { SkillsTrainingItem } from './SkillsTrainingItem';

interface SkillsStationProps {
  trainingCategory: TrainingCategory;
  checkedTrainingItems: CategorisedTraining[];
  onCheck: (itemId: number) => void;
  color: string;
}

export const SkillsStation: FC<SkillsStationProps> = ({ trainingCategory, checkedTrainingItems, onCheck, color }) => {
  return (
    <div>
      <div className="text-xl font-bold mb-2">{trainingCategory.name}</div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-5">
        {trainingCategory.trainingItems!.map(item => {
          const checkedInList = checkedTrainingItems.find(i => i.id === item.id);
          const checked = !!checkedInList;

          return (
            <SkillsTrainingItem
              key={item.id}
              checked={checked}
              trainingItem={item}
              onCheck={() => onCheck(item.id)}
              color={color}
            />
          );
        })}
      </div>
    </div>
  );
};
