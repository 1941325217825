import React, { FC, HTMLAttributes } from 'react';
import { PageSizer, SizerMode } from './PageSizer';
import { Breadcrumbs, NavPage } from './Breadcrumbs';
import { merge } from '../../helpers/Utility';

interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  breadcrumbs?: NavPage[];
  pageTitle?: string;
  icon?: JSX.Element;
  bigHeader?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({ pageTitle, children, breadcrumbs, icon, bigHeader }) => {
  let className = 'w-screen bg-secondary-std border-primary-std border-b';

  if (bigHeader) {
    className = merge(className, 'sm:-mb-16');
  }

  return (
    <div className={className}>
      <PageSizer mode={SizerMode.HEADER}>
        {breadcrumbs && <Breadcrumbs pages={breadcrumbs} />}
        <div className={'w-full flex flex-col md:flex-row justify-between min-h-[37px]'}>
          <div className="flex gap-5 items-center text-2xl font-bold">
            {icon}
            <div>{pageTitle}</div>
          </div>
          {children}
        </div>
        {bigHeader && <div className="hidden sm:block h-16" />}
      </PageSizer>
    </div>
  );
};
