import React, { FC, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useLocationService } from '../../../services/location/LocationService';
import {
  EmployeePattern,
  ReadRoster,
  RosterStaff,
  useAvailabilityPatternsForDayQuery,
  useGetRosterByIdQuery,
  useGetRosterStaffQuery
} from '../../../services/gql/graphql';
import { RosterServiceProvider } from './rosterService';

interface RosterCommonWrapperProps {}

export const RosterCommonWrapper: FC<RosterCommonWrapperProps> = () => {
  const { rosterId } = useParams<'rosterId'>();
  const { date } = useParams<'date'>();
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);
  const navigate = useNavigate();

  const [{ data: rosterData }] = useGetRosterByIdQuery({
    variables: {
      id: rosterId
    },
    requestPolicy: 'network-only'
  });

  const [{ data: staffData }] = useGetRosterStaffQuery({
    variables: {
      args: {
        rosterId: rosterId
      }
    },
    requestPolicy: 'network-only'
  });

  const [{ data: patternData }] = useAvailabilityPatternsForDayQuery({
    variables: {
      args: {
        locationId: locationId || 1,
        periods: 1,
        date: date
      }
    },
    requestPolicy: 'network-only'
  });

  useEffect(() => {
    if (rosterData?.roster?.location?.id !== locationId) {
      navigate("/roster");
    }
  }, [locationId, navigate, rosterData]);

  const minTime = new Date(0);
  minTime.setHours(9);

  const maxTime = new Date(0);
  maxTime.setHours(22);

  // TODO temp until we get isDeleted && isDeactivated flags/
  const filterOutDeletedStaff = (staff: RosterStaff[]) => {
    return staff.filter(item => item.firstName && item.lastName && !item.firstName.toLowerCase().includes('deleted'));
  };

  return (
    <RosterServiceProvider
      patterns={patternData!.locationWorkingPatterns!.employeePatterns! as EmployeePattern[]}
      roster={rosterData!.roster as ReadRoster}
      staff={filterOutDeletedStaff(staffData?.rosterStaff?.staffList as RosterStaff[])}
      minTime={minTime}
      maxTime={maxTime}
      key={rosterId}
    >
      <Outlet />
    </RosterServiceProvider>
  );
};
