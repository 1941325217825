import { FC } from 'react';
import { Loader } from '../Loader';

export const TableLoader: FC = () => {
  return (
    <div className="flex justify-center items-center w-full p-10">
      <Loader className="w-10 h-10" />
    </div>
  );
};
