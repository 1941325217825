import { FC, Fragment, PropsWithChildren, ReactElement } from 'react';
import { Row } from 'react-table';
import { merge } from '../../../helpers/Utility';

interface RowProps<T extends Record<string, unknown>> {
  row: Row<T>;
  onClick?: (row: Row<T>) => void;
  DetailsView?: FC<{ row: T }>;
  index: number;
  id?: string;
}

export function RowView<T extends Record<string, unknown>>(props: PropsWithChildren<RowProps<T>>): ReactElement {
  const { row, onClick, DetailsView, index, id } = props;

  let style = merge(onClick ? 'cursor-pointer' : null);
  if (index % 2 === 0) {
    style = merge(style, 'bg-secondary-std');
  } else {
    style = merge(style, 'bg-primary-std');
  }
  return (
    <Fragment>
      <tr onClick={() => onClick?.(row)} className={style} id={id} {...row.getRowProps()}>
        {row.cells.map((cell, i) => {
          return (
            <td
              {...cell.getCellProps()}
              className={`px-4 sm:px-6 py-4 whitespace-pre-wrap text-sm text-primary-std ${
                i === 0 ? 'font-medium' : ''
              } ${i > 2 && cell.column.id !== 'expander' ? 'hidden sm:table-cell' : ''}`}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
      {row.isExpanded && DetailsView ? (
        <tr>
          <td colSpan={100}>
            <DetailsView row={row.original} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
}
