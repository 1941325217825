import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { IconType } from 'react-icons';
import { merge } from '../../../helpers/Utility';

export interface InputProps<T> extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange'> {
  name: string;
  onChange: (value: T) => void;
  value?: T;
  Icon?: IconType;
}

export interface FieldProps extends React.HTMLAttributes<HTMLElement> {
  name: string;
  title?: string;
  Icon?: IconType;
  required?: boolean;
  validator?: (value: any) => boolean;
  customError?: CustomFieldErrorParser;
  validationMessage?: string;
  customValidatorMessage?: string;
  onChange?: (newValue?: any) => void;

  [x: string]: any;
}

export interface CustomFieldErrorParser {
  primaryId: string;
  secondaryId: string;
  index: number;
}

export const withField = (
  Input: React.FC<InputProps<any>>,
  optionalLabel: string,
  isGroup = false
): React.FC<FieldProps> => {
  const Field: React.FC<FieldProps> = ({
    name,
    title,
    Icon,
    validationMessage,
    customValidatorMessage,
    validator,
    customError,
    required,
    className,
    onChange,
    ...props
  }) => {
    const { register, watch, formState, setValue } = useFormContext();

    React.useEffect(() => {
      register(name, { required, validate: validator });
    }, [name, register, required, validator]);

    const value = watch(name);
    let error: any;
    if (formState.errors) {
      if (!customError) {
        error = formState.errors[name];
      } else {
        const primary = formState.errors[customError.primaryId];
        if (primary) {
          const primaryObj = primary[customError.index];
          if (primaryObj) {
            error = primaryObj[customError.secondaryId];
          }
        }
      }
    }

    return (
      <div className={merge(className, 'flex-grow mb-2')}>
        <div className="flex justify-between items-center">
          {title ? <label className="text-sm mb-1">{title}</label> : null}
          {!required ? <label className="text-xs font-medium mb-1 text-secondary-std">{optionalLabel}</label> : null}
        </div>
        <Input
          {...props}
          Icon={Icon}
          className={className}
          name={name}
          value={value}
          onChange={newValue => {
            let returnValue = newValue;

            // If the field is a group field (i.e. supports multiple values such as the "CheckboxGroupField" component) then need to return the state of the whole group.
            if (isGroup) {
              const group = value;
              if (group.includes(newValue)) {
                group.splice(group.indexOf(newValue), 1);
              } else {
                group.push(newValue);
              }

              returnValue = group;
            }
            setValue(name, returnValue, { shouldDirty: true });
            onChange && onChange(returnValue);
          }}
        ></Input>
        {error?.type === 'validate' && (
          <p className="mt-2 text-sm text-red-600" id={`${name}-validator-error`}>
            {customValidatorMessage}
          </p>
        )}
        {error?.type === 'required' && (
          <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
            {validationMessage}
          </p>
        )}
        {/*This error occurs when the BE rejects a form submission (Eg/ duplicate email)*/}
        {error?.type === 'BEValidation' && (
          <p className="mt-2 text-sm text-red-600" id={`${name}-be-validation-error`}>
            {error.message ?? ''}
          </p>
        )}
        {error?.type === 'custom' && (
          <p className="mt-2 text-sm text-red-600" id={`${name}-custom-error`}>
            {error.message}
          </p>
        )}
      </div>
    );
  };

  return Field;
};
