import { differenceInDays, differenceInMinutes, format } from 'date-fns';
import strings from '../pages/shifts/shifts.translations';

export const timeStringToDate = (timeString: string) => {
  const splitString = timeString.split(':');
  const hours = parseInt(splitString[0]);
  const minutes = parseInt(splitString[1]);

  const date = new Date(0);
  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};

export const dateToTimeString = (date: Date) => {
  return format(date, 'HH:mm');
};

export const dateToSecondsSinceMidnight = (date: Date) => {
  return date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds();
};

export const secondsSinceMidnightToTimeString = (seconds: number) => {
  return dateToTimeString(secondsSinceMidnightToDate(seconds));
};

export const secondsSinceMidnightToDate = (secondsSinceMidnight: number) => {
  const date = new Date(0);

  const hours = Math.floor(secondsSinceMidnight / 3600);
  const secondsSinceHour = secondsSinceMidnight - hours * 3600;
  const minutes = Math.floor(secondsSinceHour / 60);
  const secondsSinceMinute = secondsSinceHour - minutes * 60;

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(secondsSinceMinute);

  return date;
};

export const timeStringToSecondsSinceMidnight = (timeString: string) => {
  return dateToSecondsSinceMidnight(timeStringToDate(timeString));
};

export const calculateMinutesDuration = (start: Date, end: Date) => {
  return differenceInMinutes(Math.floor(stripSeconds(end)), Math.floor(stripSeconds(start)));
};

export const displayMinutesAsHoursMinutes = (minutes: number) => {
  const numberOfHours = Math.floor(minutes / 60);
  const numberOfMinutes = minutes - numberOfHours * 60;

  let stringToReturn = '';

  if (numberOfHours > 0) {
    stringToReturn += `${numberOfHours}${strings.common.hourShortHand} `;
  }

  stringToReturn += `${numberOfMinutes}${strings.common.minuteShortHand}`;

  return stringToReturn;
};

export const secondsToDate = (x: number) => {
  const date = new Date(0);
  date.setHours(Math.floor(x / 3600), (x % 3600) / 60);
  return date;
};

export const secondsToTimeString = (seconds: number) => {
  const date = secondsToDate(seconds);
  let hours = date.getHours().toString();
  if (hours.length === 1) {
    hours = '0' + hours;
  }

  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) {
    minutes = '0' + minutes;
  }

  return hours + ':' + minutes;
};

export const isDateInPast = (dateOne: Date, dateTwo: Date) =>
  new Date(dateOne).setHours(0, 0, 0, 0) < new Date(dateTwo).setHours(0, 0, 0, 0);

export const convertDateToGQLFormat = (date: Date) => format(date, 'yyyy-MM-dd');

export const createUTCDate = (year: number, month: number, day: number) => {
  return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
};

export const formatUTCDate = (date: Date) => {
  return createUTCDate(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getDaysInMonth = (month: number, year: number): Date[] => {
  const lastOfMonth = new Date(year, month + 1, 0);
  const monthLength = lastOfMonth.getDate();

  return Array.from({ length: monthLength }, (_, i) => createUTCDate(year, month, i + 1));
};

export const getDatePeriod = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];

  const dateToAdd = new Date(startDate);
  while (dateToAdd <= endDate) {
    dates.push(formatUTCDate(dateToAdd));
    dateToAdd.setDate(dateToAdd.getDate() + 1);
  }

  return dates;
};

export const isDateEqual = (dateOne: Date, dateTwo: Date) => {
  return (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() === dateTwo.getDate()
  );
};

export const getStartAndEndDateOfMonth = (date: Date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return {
    startDate: firstDay,
    endDate: lastDay
  };
};

export const dateToApiFormat = (date: Date) => {
  const utcDate = formatUTCDate(date);

  return utcDate.toISOString().substring(0, 10);
};

export const dateToGQLFormat = (date: Date) => {
  return date.toISOString();
};

export const stripSeconds = (time: Date) => {
  return new Date(time).setSeconds(0, 0);
};

export const compareDateToRosterDate = (date: Date, rosterDate: string) => {
  return rosterDate.substring(0, 19) === date.toISOString().substring(0, 19);
};

export const dayDiff = (startDate: number | Date, endDate: number | Date) => {
  return Math.abs(differenceInDays(startDate, endDate));
};
