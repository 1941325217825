import { FC, useState } from 'react';
import { BigCheckbox } from '../../../../components/shared/checkboxes/BigCheckbox';
import strings from '../../team.translations';
import { Role } from '../../../../services/authentication/authentication-service';
import { FiPlus, FiTrash } from 'react-icons/fi';
import { SelectFieldCustom } from '../../../../components/shared/form/SelectFieldCustom';
import {
  ContactInfo,
  ContactInfoType,
  OptionalContactInfo
} from '../../../../components/shared/form/OptionalContactInfo';
import { RoleOption } from './AddMultipleTeamMembersForm';
import { TextFieldWithOptionalLabel } from '../../../../components/shared/form/TextInput';
import { useFormContext } from 'react-hook-form';
import { formatPhoneNumber, validateEmail, validatePhone } from '../../../../helpers/Utility';
import { IconButton } from '../../../../components/shared/button/IconButton';

export const AddTeamMemberRow: FC<{ idx: number; methods: any; handleDelete: () => void; handleAdd: () => void }> = ({
  idx,
  methods,
  handleDelete,
  handleAdd
}) => {
  const [contactInfoType, setContactInfoType] = useState<ContactInfoType>(ContactInfo.email);

  const roleOptions: RoleOption[] = [
    {
      id: Role.Staff,
      name: strings.common.roles.staff
    },
    {
      id: Role.Manager,
      name: strings.common.roles.manager
    },
    {
      id: Role.Admin,
      name: strings.common.roles.admin
    }
  ];

  const { getValues } = useFormContext();

  const searchDuplicate = (field: string, eleId: number) => {
    let isValid = true;
    const allValues = getValues();
    const targetVal: string = allValues.members[eleId][field];

    if (allValues) {
      allValues.members.forEach((ele: any, idx: number) => {
        if (ele[field] !== undefined && targetVal !== undefined) {
          if (ele[field] === targetVal && idx !== eleId) {
            isValid = false;
          }
        }
      });
    }
    return isValid;
  };

  return (
    <>
      <TextFieldWithOptionalLabel
        name={`members[${idx}].firstName`}
        placeholder={strings.addTeamMemberForm.firstNamePlaceholder}
        customError={{
          primaryId: 'members',
          secondaryId: 'firstName',
          index: idx
        }}
        validationMessage={strings.common.mandatory(strings.addTeamMemberForm.firstName)}
        required
        className="min-w-[80px] sm:min-w-0"
      />
      <TextFieldWithOptionalLabel
        name={`members[${idx}].lastName`}
        placeholder={strings.addTeamMemberForm.lastNamePlaceholder}
        customError={{
          primaryId: 'members',
          secondaryId: 'lastName',
          index: idx
        }}
        validationMessage={strings.common.mandatory(strings.addTeamMemberForm.lastName)}
        required
        className="min-w-[80px] sm:min-w-0"
      />

      <div className="flex space-x-4 mb-2">
        <OptionalContactInfo
          setContactTypeCallback={setContactInfoType}
          selectedContactType={contactInfoType}
          index={idx}
        />
        <TextFieldWithOptionalLabel
          className={`mb-0 ${contactInfoType === ContactInfo.none ? '' : 'hidden'} min-w-[100px] sm:min-w-0`}
          name={`members[${idx}].contactInfoPlaceholder`}
          placeholder={strings.addTeamMemberForm.contactInformationPlaceholder}
          readOnly
          required={contactInfoType === ContactInfo.none}
          validationMessage={strings.teamMembersModal.contactValidation}
        />
        <TextFieldWithOptionalLabel
          className={`mb-0 ${contactInfoType === ContactInfo.email ? '' : 'hidden'} min-w-[100px] sm:min-w-0`}
          name={`members[${idx}].email`}
          placeholder={strings.addTeamMemberForm.emailPlaceholder}
          customError={{
            primaryId: 'members',
            secondaryId: 'email',
            index: idx
          }}
          validator={(email: string) => {
            return searchDuplicate('email', idx) && validateEmail(email);
          }}
          customValidatorMessage={strings.addTeamMemberForm.uniqueValidValsRequired(strings.addTeamMemberForm.email)}
          required={contactInfoType === ContactInfo.email}
          validationMessage={strings.teamMembersModal.emailValidation}
        />
        <TextFieldWithOptionalLabel
          className={`mb-0 ${contactInfoType === ContactInfo.phone ? '' : 'hidden'} min-w-[100px] sm:min-w-0`}
          name={`members[${idx}].phoneNumber`}
          placeholder={strings.addTeamMemberForm.phoneNumberPlaceholder}
          customError={{
            primaryId: 'members',
            secondaryId: 'phoneNumber',
            index: idx
          }}
          validator={(phone: string) => {
            return searchDuplicate('phoneNumber', idx) && validatePhone(phone);
          }}
          customValidatorMessage={strings.addTeamMemberForm.uniqueValidValsRequired(
            strings.addTeamMemberForm.phoneNumber
          )}
          required={contactInfoType === ContactInfo.phone}
          validationMessage={strings.teamMembersModal.telephoneValidation}
          onChange={e => {
            methods.setValue(`members[${idx}].phoneNumber`, formatPhoneNumber(e));
          }}
        />
      </div>
      <SelectFieldCustom
        id="role"
        name={`members[${idx}].role`}
        required
        options={roleOptions}
        className="mt-1 min-w-[100px]"
      />
      <BigCheckbox onChange={() => {}} register={methods.register(`members[${idx}].sendRegistration`)} />
      <div className="flex gap-2 items-center justify-between mb-2">
        {methods.watch('members').length > 1 ? (
          <IconButton onClick={handleDelete} Icon={FiTrash} label={strings.common.delete} />
        ) : (
          <div />
        )}
        {methods.watch('members').length === idx + 1 ? (
          <IconButton onClick={handleAdd} Icon={FiPlus} label={strings.teamMembersModal.add} />
        ) : (
          <div />
        )}
      </div>
    </>
  );
};
