import React, { FC, useState } from 'react';
import { BsCurrencyPound } from 'react-icons/bs';
import strings from '../home.translations';
import { TimeCostSummaryCard, TimeCostType } from './TimeCostSummaryCard';
import { CustomIntervalTimePicker } from '../../../components/shared/date/CustomIntervalTimePicker';
import { useSummaryData } from '../summary/useSummaryData';
import { handleError } from '../../../services/gql/config/URQLProvider';
import { TSTitleCard } from '../../../components/shared/card/Card';
import { dayDiff } from '../../../helpers/dateHelper';

interface TimeCostSummarySectionProps {}

export const TimeCostSummarySection: FC<TimeCostSummarySectionProps> = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const start = new Date(today);
  start.setDate(start.getDate() - 6);
  const [[startDate, endDate], setDatePair] = useState<[Date, Date]>(() => [start, today]);

  const { summaryData, error, fetching } = useSummaryData(startDate, endDate);
  if (error) handleError(error);

  return (
    <TSTitleCard
      title={strings.timeCostSummary.title}
      icon={BsCurrencyPound}
      largeContent={
        <CustomIntervalTimePicker
          startDate={startDate}
          endDate={endDate}
          onChange={setDatePair}
          dayDiff={dayDiff(startDate, endDate)}
          labels={{
            previous: { hide: true },
            next: { hide: true }
          }}
        />
      }
    >
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <TimeCostSummaryCard
          type={TimeCostType.TIME}
          title={strings.timeCostSummary.cardTitles.time}
          summaryData={summaryData}
          fetching={fetching}
          dayDiff={dayDiff(startDate, endDate)}
        />
        <TimeCostSummaryCard
          type={TimeCostType.COST}
          title={strings.timeCostSummary.cardTitles.cost}
          summaryData={summaryData}
          fetching={fetching}
          dayDiff={dayDiff(startDate, endDate)}
        />
        <TimeCostSummaryCard
          type={TimeCostType.STAFF}
          title={strings.timeCostSummary.cardTitles.staff}
          summaryData={summaryData}
          fetching={fetching}
          dayDiff={dayDiff(startDate, endDate)}
        />
      </div>
    </TSTitleCard>
  );
};
