export const requestLocationFromBrowser = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve(position);
      },
      (error: GeolocationPositionError) => {
        reject(error);
      }
    );
  });
};
