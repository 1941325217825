import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Loading } from '../../../components/shared/Loading';
import { useAuthenticationService } from '../authentication-service';

interface LoginProps {}

export const Login: FC<LoginProps> = () => {
  const userManager = useAuthenticationService(state => state.userManager);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const state = location.state as { restore?: string };
      if (state?.restore) window.localStorage.setItem('ts-restore', state?.restore);
      await (await userManager).signinRedirect();
    })();
  }, [userManager, location]);

  return <Loading />;
};
