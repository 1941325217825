import React, { FC } from 'react';
import { ClockInStatus, ClockProvider } from '../../services/clockIn/ClockService';
import { TSCard } from '../../components/shared/card/Card';
import { ClockRequest } from '../../components/clock/ClockRequest';
import { useParams } from 'react-router-dom';

export const ClockInOutSuccess: FC = () => {
  const { state } = useParams<'state'>();

  const clockStatus: ClockInStatus = state ? parseInt(state) : ClockInStatus.Unknown;
  return (
    <ClockProvider>
      <TSCard>
        <div className="flex flex-col items-center space-y-4 w-full text-center">
          <ClockRequest isModal={true} clockState={clockStatus} />
        </div>
      </TSCard>
    </ClockProvider>
  );
};
