import { getClosestLocationToPosition, getDistanceInKm, Position, WorkLocation } from './location-helper';
import { useApprovedLocations } from './useApprovedLocations';

export const useNearestLocation = (position?: Position) => {
  const { workLocations } = useApprovedLocations();

  let closestLocation: WorkLocation | undefined;
  let distanceFromLocation: number | undefined;
  let distanceOutOfRadius: number | undefined;

  if (position) {
    closestLocation = getClosestLocationToPosition(workLocations, position);
    if (closestLocation) {
      distanceFromLocation = getDistanceInKm(position, closestLocation.position);
      distanceOutOfRadius =
        closestLocation.radius <= distanceFromLocation ? distanceFromLocation - closestLocation.radius : undefined;
    }
  }

  return { closestLocation, distanceFromLocation, distanceOutOfRadius };
};
