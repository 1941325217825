import React, { FC, HTMLAttributes } from 'react';
import { TimeshiftLogo } from '../shared/TimeshiftLogo';
import { PageSizer, SizerMode } from './PageSizer';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import strings from './page.translations';
import { Disclosure } from '@headlessui/react';
import { FiLogOut, FiMenu, FiUser, FiX } from 'react-icons/fi';
import { isAdmin, Role, useAuthenticationService } from '../../services/authentication/authentication-service';
import { TimeshiftAvatar } from '../shared/TimeshiftAvatar';
import { useGetMeQuery } from '../../services/gql/graphql';
import { handleError } from '../../services/gql/config/URQLProvider';
import { pageType } from '../../services/authentication/routes/AuthenticatedRoute';
import { Feature } from '../../services/backstage/Feature';
import { GiPokecog } from 'react-icons/gi';
import { merge } from '../../helpers/Utility';
import { DropdownAction } from '../shared/action/Action';
import { Divider } from '../shared/Divider';
import { Dropdown, DropdownOrigin } from '../shared/menu/Dropdown';
import { Flags } from '../../services/backstage/backstage.model';

interface MainHeaderProps extends HTMLAttributes<HTMLDivElement> {}

const pages: pageType[] = [
  { name: strings.common.home, to: '/', rolesRequired: [Role.Admin, Role.Manager, Role.Staff] },
  { name: strings.nav.pageNames.team, to: '/team', rolesRequired: [Role.Admin, Role.Manager] },
  { name: strings.nav.pageNames.shifts, to: '/shifts', rolesRequired: [Role.Admin, Role.Manager] },
  { name: strings.nav.pageNames.roster, to: '/roster', rolesRequired: [Role.Admin, Role.Manager], feature: 'roster' },
  { name: strings.nav.pageNames.calendar, to: '/calendar', rolesRequired: [Role.Staff, Role.Admin], feature: 'roster' }
];

interface NavigationLinkProps {
  page: pageType;
  isMobile?: boolean;
}

export const NavigationLink: FC<NavigationLinkProps> = ({ page, isMobile }) => {
  return (
    <div className="flex flex-col">
      <NavLink
        key={`${page.name}-${page.to}`}
        to={page.to}
        end
        className={({ isActive }) =>
          `border-transparent h-full hover:border-primary-std dark:hover:border-white inline-flex items-center font-medium ${
            isActive ? 'border-primary-500 text-primary-std' : 'text-secondary-std'
          } ${isMobile ? 'pl-3 pr-4 py-4 border-l-4 text-base' : 'px-1 pt-1 border-b-2 text-sm'}`
        }
      >
        {page.name}
      </NavLink>
      {/*TODO - Re-enable framer once header api logic is reworked*/}
      {/*{active ? (*/}
      {/*  <motion.div*/}
      {/*    layoutId={`main-header-nav-bar`}*/}
      {/*    transition={{ type: 'spring', stiffness: 500, damping: 25 }}*/}
      {/*    className="bg-primary-500 w-full rounded-b-lg"*/}
      {/*    style={{ height: 5 }}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <div className="h-[5px]" />*/}
      {/*)}*/}
    </div>
  );
};

export const MainHeader: FC<MainHeaderProps> = ({ children, className, ...props }) => {
  const [role] = useAuthenticationService(state => [state.role]);
  const [{ data, error }] = useGetMeQuery();
  if (error) handleError(error);
  const name = strings.employeeName(data?.myEmployee?.firstName ?? '', data?.myEmployee?.lastName ?? '');

  const navigate = useNavigate();

  const lockedPage = (lockedStyle: string, page: pageType) => {
    return (
      <div data-tip={strings.nav.lockedFeature} className={lockedStyle}>
        {page.name}
      </div>
    );
  };

  const myUserId = data!.myEmployee!.identityId;

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <div>
          <div
            {...props}
            className={merge('w-screen bg-primary-std border-primary-std border-b print:hidden', className)}
          >
            {open && <Disclosure.Button className="absolute w-full h-20 z-20"></Disclosure.Button>}
            <PageSizer className="flex items-stretch" mode={SizerMode.HEADER}>
              <div className="flex items-center mr-4 lg:hidden z-10">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-primary-std hover:text-primary-std hover:bg-secondary-std focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                  <span className="sr-only">{strings.nav.mobileOpenMenu}</span>
                  {open ? (
                    <FiX className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FiMenu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className={'w-full flex items-center justify-center lg:flex-1'}>
                <Link to="/">
                  <TimeshiftLogo className="h-7" />
                </Link>
              </div>

              <div className="hidden lg:ml-6 lg:flex lg:space-x-8 whitespace-nowrap -my-4">
                {pages
                  .filter(page => {
                    return !page.rolesRequired || page.rolesRequired.includes(role!);
                  })
                  .map(page => {
                    if (page.locked) {
                      return lockedPage(
                        'border-transparent cursor-default text-secondary-std inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                        page
                      );
                    }

                    return page.feature ? (
                      <Feature name={page.feature! as Flags} key={page.to}>
                        {<NavigationLink page={page} />}
                      </Feature>
                    ) : (
                      <NavigationLink page={page} />
                    );
                  })}
              </div>

              <div className={'z-20 w-min flex gap-5 items-center justify-end lg:w-full'}>
                <Dropdown origin={DropdownOrigin.TOP_RIGHT} button={<TimeshiftAvatar name={name} size={40} />}>
                  <div className="px-4 pt-2">
                    <h2 className="text-xl font-medium">{name}</h2>
                    <p className="text-secondary-std">{data?.myEmployee?.email}</p>
                  </div>
                  <Divider />
                  <Feature name="respect">
                    {role !== Role.SuperAdmin && (
                      <DropdownAction onClick={() => navigate(`/team-member/${myUserId}`)}>
                        <FiUser className="w-4 h-4 mt-0.5" />
                        <span>{strings.nav.myProfile}</span>
                      </DropdownAction>
                    )}
                  </Feature>
                  <Feature name="gdpr">
                    {role !== Role.SuperAdmin && (
                      <DropdownAction onClick={() => navigate('/my-data')}>
                        <GiPokecog className="w-4 h-4 mt-0.5" />
                        <span>{strings.nav.myData}</span>
                      </DropdownAction>
                    )}
                  </Feature>
                  <Feature name="debug">
                    <DropdownAction onClick={() => navigate('/scribbles')}>
                      <GiPokecog className="w-4 h-4 mt-0.5" />
                      <span>{strings.nav.uiTest}</span>
                    </DropdownAction>
                  </Feature>
                  <Feature name="devices">
                    {isAdmin(role) && (
                      <DropdownAction onClick={() => navigate('/manage-organisation')}>
                        <GiPokecog className="w-4 h-4 mt-0.5" />
                        <span>{strings.nav.manageOrg}</span>
                      </DropdownAction>
                    )}
                  </Feature>
                  <DropdownAction onClick={() => navigate('/logout')}>
                    <FiLogOut className="w-4 h-4 mt-0.5" />
                    <span>{strings.nav.logout}</span>
                  </DropdownAction>
                </Dropdown>
              </div>
            </PageSizer>
          </div>

          <Disclosure.Panel className="lg:hidden absolute h-[0] w-full z-20">
            <div className="bg-primary-std space-y-px z-20">
              {pages
                .filter(page => {
                  return !page.rolesRequired || page.rolesRequired.includes(role!);
                })
                .map(page => {
                  return (
                    <>
                      <div key={`${page.name}-${page.to}`}>
                        {page.locked ? (
                          lockedPage(
                            'border-transparent text-secondary-std hover:bg-secondary-std block pl-3 pr-4 py-4 border-l-4 text-base font-medium',
                            page
                          )
                        ) : page.feature ? (
                          <Feature name={page.feature! as Flags} key={page.to}>
                            <NavigationLink page={page} isMobile={true} />
                          </Feature>
                        ) : (
                          <NavigationLink page={page} isMobile={true} />
                        )}
                      </div>
                      <Divider />
                    </>
                  );
                })}
            </div>
            <Disclosure.Button className="absolute bg-black opacity-50 w-full h-screen z-10"></Disclosure.Button>
          </Disclosure.Panel>
          {children}
        </div>
      )}
    </Disclosure>
  );
};
