import { FC } from 'react';
import { motion } from 'framer-motion';
import { TSCardChevronRight } from '../../components/shared/card/Card';
import { FiCheck } from 'react-icons/fi';
import { AiOutlineWarning } from 'react-icons/ai';
import { Icon } from '../../components/shared/icons/Icon';
import { useNavigate } from 'react-router-dom';
import { Device } from '../../services/gql/graphql';

interface DeviceListProps {
  devices: Device[];
}

export const DeviceList: FC<DeviceListProps> = ({ devices }) => {
  const navigate = useNavigate();

  const navigateToDeviceDetails = (id: string) => {
    navigate(`${id}`);
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
      {devices.map(device => (
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
          <TSCardChevronRight
            onClick={() => navigateToDeviceDetails(device.id)}
            title={device.name}
            icon={device.activated ? <Icon Icon={FiCheck} theme='primary' /> : <Icon Icon={AiOutlineWarning} theme='amber' />}
          />
        </motion.div>
      ))}
    </div>
  );
};
