import * as React from 'react';
import { InputProps, withField } from './Field';
import { merge } from '../../../helpers/Utility';

export interface SelectInputOption { id: string; name: string }

interface SelectInputProps extends InputProps<SelectInputOption | undefined> {
  placeholder?: string;
  options?: SelectInputOption[];
  disabled?: boolean;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  className,
  name,
  onChange,
  value,
  placeholder,
  options,
  disabled,
  ...props
}) => {
  return (
    <select
      {...props}
      name={name}
      id={name}
      className={merge(
        className,
        'block w-full pl-3 pr-10 py-2 sm:text-sm border-primary-std bg-primary-std focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md'
      )}
      value={value?.id}
      onChange={event => onChange(options?.find(option => option.id === event.target.value))}
      disabled={disabled}
    >
      {!value ? <option>{placeholder ? placeholder : 'Please select'}</option> : null}
      {options?.map(option => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export const SelectField = withField(SelectInput, 'Optional');
