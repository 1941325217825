import * as React from 'react';
import { format, getDay } from 'date-fns';
import { FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi';
import { Day } from './Day';

export interface DayLabels {
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
}

interface DatePickerProps {
  selected: Date[];
  viewing: Date;
  calendar: Date[][];
  isSelected: (date: Date) => boolean;
  inRange: (date: Date, min: Date, max: Date) => boolean;
  viewPreviousMonth: () => void;
  viewNextMonth: () => void;
  onDayClick?: (day: Date) => void;
  dismiss?: () => void;
  hideDismiss?: boolean;
  labels: DayLabels;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  calendar,
  viewing,
  selected,
  viewPreviousMonth,
  hideDismiss,
  viewNextMonth,
  isSelected,
  inRange,
  labels,
  onDayClick = () => {},
  dismiss = () => {}
}) => {
  return (
    <>
      <div className="flex justify-between items-center px-3 py-3">
        <button
          type="button"
          aria-label="Previous Month"
          className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800 p-1.5 rounded-lg"
          onClick={viewPreviousMonth}
        >
          <FiChevronLeft className="w-6 h-6" />
        </button>

        <p>{format(viewing, 'MMMM yyyy')}</p>

        <div>
          <button
            type="button"
            aria-label="Next Month"
            className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800 p-1.5 rounded-lg"
            onClick={viewNextMonth}
          >
            <FiChevronRight className="w-6 h-6" />
          </button>
          {!hideDismiss && (
            <button
              type="button"
              aria-label="Close"
              className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800 mx-1 p-1.5 rounded-lg"
              onClick={dismiss}
            >
              <FiX className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>

      <div className="px-2 pt-2">
        <div className="grid grid-cols-7 text-sm text-center">
          {calendar.length > 0 &&
            calendar[0].map(day => (
              <div key={`${day}`} className="p-1">
                {
                  [
                    labels.Sunday,
                    labels.Monday,
                    labels.Tuesday,
                    labels.Wednesday,
                    labels.Thursday,
                    labels.Friday,
                    labels.Saturday
                  ][getDay(day)]
                }
              </div>
            ))}
        </div>

        {calendar.map(week => (
          <div key={`week-${week[0]}`} className="grid grid-cols-7 text-primary-std">
            {week.map(day => (
              <Day
                key={day.toString()}
                day={day}
                selected={selected}
                viewing={viewing}
                inRange={inRange}
                isSelected={isSelected}
                onClick={() => onDayClick(day)}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
