import LocalizedStrings from 'react-localization';
import { withCommon } from '../../services/translation/commonTranslations';

const manageLocationStrings = new LocalizedStrings({
  en: {
    title: 'Manage Location',
    locationDetailsCard: {
      title: 'Location Details',
      name: 'Location Name'
    },
    editLocationDetails: {
      editLabel: 'Edit Location Details',
      nameRequired: 'Location name required'
    },
    clockInDevicesCard: {
      title: 'Clock in devices',
      description: 'View and manage devices used for clocking in'
    },
    deleteLocationCard: {
      title: 'Delete Location',
      description: 'Permanently remove the location from the system',
      confirmation: 'Are you sure you want to delete this location?'
    },
    notAssigned: 'You do not have permission to view this location.'
  }
});

export default withCommon<typeof manageLocationStrings>(manageLocationStrings);
