import LocalizedStrings from 'react-localization';
import { withCommon } from '../../services/translation/commonTranslations';

const homeStrings = new LocalizedStrings({
  en: {
    showQuickLinks: 'Quick Links',
    quickLinks: {
      getTheApp: 'Get the app',
      contactSupport: 'Contact Support',
      privacyPolicy: 'Privacy Policy',
      clockIn: 'Clock-In',
      calendar: 'Calendar',
      myProfile: 'My Profile',
      manageOrg: 'Manage Organisation',
      manageLocation: 'Manage Location'
    },
    status: {
      statusIn: 'You are currently clocked in',
      statusOut: 'You are currently clocked out',
      statusUnknown: 'Loading your work status...'
    },
    unverifiedClocks: {
      title: 'Unverified clock-in-outs',
      lastTwoWeeks: 'Last 2 weeks',
      approve: 'Approve',
      edit: 'Edit',
      none: 'There are no clock ins or outs to verify.',
      now: 'Now',
      today: 'Today',
      approveSuccess: 'Shift approved',
      approveFail: 'Shift failed to approve'
    },
    timeCostSummary: {
      title: 'Time & cost summary',
      people: 'people',
      person: 'person',
      previousDays: (days: number) => `Compared to the previous ${days} days`,
      previousDay: 'Compared to the previous day',
      same: 'The same',
      cardTitles: {
        time: 'Time worked',
        cost: 'Total cost',
        staff: 'Total staff working'
      }
    }
  }
});

export default withCommon<typeof homeStrings>(homeStrings);
