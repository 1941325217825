import { WorkTimeDataSource } from '../../../services/gql/graphql';
import strings from './table.translations';

export const ClockMethodToString = (clockMethod: WorkTimeDataSource | undefined) => {
  switch (clockMethod) {
    case WorkTimeDataSource.Location:
      return strings.verboseClockMethods.Location;
    case WorkTimeDataSource.Manual:
      return strings.verboseClockMethods.Manual;
    case WorkTimeDataSource.ManualLate:
      return strings.verboseClockMethods.ManualLate;
    case WorkTimeDataSource.Nfc:
      return strings.verboseClockMethods.Nfc;
    case WorkTimeDataSource.Override:
      return strings.verboseClockMethods.Override;
    case WorkTimeDataSource.SecureCode:
      return strings.verboseClockMethods.Device;
    default:
      return undefined;
  }
};
