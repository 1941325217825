import { FC } from 'react';
import { GiPokecog } from 'react-icons/gi';
import {
  HiOutlineLibrary,
  HiOutlineLocationMarker
} from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { NavPage } from '../../components/page/Breadcrumbs';
import { PageContainer } from '../../components/page/PageContainer';
import { PageHeader } from '../../components/page/PageHeader';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { TSCardChevronRight, TSTitleCard } from '../../components/shared/card/Card';
import { Icon } from '../../components/shared/icons/Icon';
import { TenantLogo } from '../../components/shared/TenantLogo';
import { useVariable } from '../../services/backstage/BackstageProvider';
import strings from './manageOrg.translations';

export const ManageOrgPage: FC = () => {
  const name = useVariable('name');
  const navigate = useNavigate();

  const breadcrumbs: NavPage[] = [
    {
      name: strings.manageOrgSection.title,
      to: '/manage-organisation'
    }
  ];

  const onLocationClick = () => {
    navigate('locations')
  }

  return (
    <PageContainer customHeader={<PageHeader pageTitle={strings.manageOrgSection.title} breadcrumbs={breadcrumbs} icon={<GiPokecog />}/>}>
      <PageSizer className="flex gap-5 flex-col" mode={SizerMode.PAGE}>
        <TSTitleCard title={strings.manageOrgSection.orgDetailsCard.title} icon={HiOutlineLibrary} className="w-full">
          <div className="flex flex-col md:flex-row space-y-12 md:space-y-0">
            <div className="flex flex-col space-y-1 md:mr-auto">
              <span className='font-semibold'>{strings.manageOrgSection.orgDetailsCard.name}</span>
              <span>{name}</span>
            </div>
            <div className="flex flex-col space-y-1 md:mr-[33%]">
              <span className='font-semibold'>{strings.manageOrgSection.orgDetailsCard.logo}</span>
              <TenantLogo />
            </div>
          </div>
        </TSTitleCard>
        <TSCardChevronRight
          onClick={onLocationClick}
          icon={<Icon Icon={HiOutlineLocationMarker} theme='primary' />}
          title={strings.manageOrgSection.locationCard.title}
          description={strings.manageOrgSection.locationCard.desc}
          largeHeader
        />
      </PageSizer>
    </PageContainer>
  );
};
