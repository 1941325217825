import { FC, HTMLAttributes } from 'react';
import { TSCard } from '../card/Card';

export interface TableContainerProperties extends HTMLAttributes<HTMLDivElement> {
  name?: string;
}

export const TableContainer: FC<TableContainerProperties> = ({ name, children }) => {
  return (
    <TSCard>
      {name && (
        <div className="py-3 px-4 w-full flex border-b border-gray-200 justify-between items-center">
          <div className="items-center hidden sm:flex">
            <h1 className="text-xl leading-4 mt-1 font-semibold text-secondary-std">{name}</h1>
          </div>
        </div>
      )}
      {children}
    </TSCard>
  );
};
