import React, { FC } from 'react';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { PageContainer } from '../../components/page/PageContainer';
import { UserInfoCopyBoxes } from '../../helpers/TextCopy';

interface SuperAdminHomePageProps {}

export const SuperAdminHomePage: FC<SuperAdminHomePageProps> = () => {
  return (
    <PageContainer>
      <PageSizer className="relative flex-col md:flex-row flex gap-5" mode={SizerMode.PAGE}>
        <UserInfoCopyBoxes />
      </PageSizer>
    </PageContainer>
  );
};
