export const getPayFromDuration = (durationInMinutes: number, hourlyRate: number) => {
  return (hourlyRate * durationInMinutes) / 60;
};

export const displayPay = (pay: number) => {
  return pay > 0 ? `£${pay.toFixed(2)}` : '-';
};

export const getPayToDisplay = (durationInMinutes: number, hourlyRate: number) => {
  return displayPay(getPayFromDuration(durationInMinutes, hourlyRate));
};
