import { ComponentType, FC, HTMLAttributes, useEffect } from 'react';
import { useLilius } from 'use-lilius';
import calendarTranslations from '../calendar.translations';
import { merge } from '../../../../helpers/Utility';
import { isDateInPast } from '../../../../helpers/dateHelper';

export interface CalendarCellProps<T> {
  date: Date;
  today?: boolean;
  contextObject?: T;
}

export interface CalendarEvent<T> {
  date: Date;
  contextObject?: T;
}

interface CalendarOptions {
  minimumCellHeight?: number;
}

interface CalendarProps extends HTMLAttributes<HTMLDivElement> {
  monthToShow: Date;
  HeaderComponent: ComponentType<CalendarCellProps<any>>;
  ContentComponent: ComponentType<CalendarCellProps<any>>;
  events?: CalendarEvent<any>[];
  options?: CalendarOptions;
  weeksStartsOn?: number;
  customDaysOfWeek?: string[];
}

export const Calendar: FC<CalendarProps> = ({
  monthToShow,
  HeaderComponent,
  ContentComponent,
  events,
  options,
  weeksStartsOn = 0,
  customDaysOfWeek,
  className
}) => {
  const lilius = useLilius({ viewing: monthToShow, weekStartsOn: weeksStartsOn });
  const weeks = lilius.calendar[0];
  const daysOfWeek = customDaysOfWeek || Object.values(calendarTranslations.shorthandDays);

  useEffect(() => {
    lilius.setViewing(monthToShow);
  }, [monthToShow, lilius]);

  return (
    <div className={merge(className, 'flex flex-col overflow-auto h-full w-full border bg-primary-std')}>
      <div className="flex w-full border-b border-l border-r">
        {weeks[0].map(day => (
          <div className="flex flex-col w-full items-center py-2 font-bold text-gray-500" key={day.getDate()}>
            {daysOfWeek[day.getDay()]}
          </div>
        ))}
      </div>
      {weeks.map((currentWeek: Date[], idx: number) => {
        return (
          <div className="flex flex-1 relative" key={idx}>
            {currentWeek.map(currentDay => {
              const event = events?.find(e => {
                return e.date.toDateString() === currentDay.toDateString();
              });
              const isToday = currentDay.toDateString() === new Date().toDateString();
              const greyMonth = currentDay.getMonth() !== lilius.viewing.getMonth();

              return (
                <div
                  key={currentDay.getDate()}
                  className={`flex-1 border-primary-std border flex flex-col relative ${
                    event?.contextObject.onClick ? 'cursor-pointer' : ''
                  }`}
                  style={{ minHeight: options?.minimumCellHeight || 150 }}
                  onClick={event?.contextObject.onClick}
                >
                  {greyMonth && <div className="bg-gray-300 h-full w-full bg-opacity-30 absolute" />}
                  <div>
                    <HeaderComponent date={currentDay} contextObject={event?.contextObject} today={isToday} />
                  </div>
                  <div className={`${isDateInPast(currentDay, new Date()) ? 'opacity-50' : ''} flex-1`}>
                    <ContentComponent date={currentDay} contextObject={event?.contextObject} today={isToday} />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
