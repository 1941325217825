import LocalizedStrings from 'react-localization';
import { withCommon } from '../../../services/translation/commonTranslations';

const upcomingShiftStrings = new LocalizedStrings({
  en: {
    nextShiftTitle: 'Your Next Shift',
    upcomingShiftTitle: 'Upcoming Shifts',
    viewButton: 'View Roster',
    startEndTime: (start: string, end: string) => `${start} - ${end}`,
    noData: 'No upcoming shifts scheduled'
  }
});

export default withCommon<typeof upcomingShiftStrings>(upcomingShiftStrings);
