import { FC } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useClient } from 'urql';
import create, { State } from 'zustand';
import createContext from 'zustand/context';
import { convertDateToGQLFormat } from '../../../../helpers/dateHelper';
import {
  GetOptimiserStatusesDocument,
  GetOptimiserStatusesQuery,
  GetOptimiserStatusesQueryVariables,
  GetRostersByLocationAndDateDocument,
  GetRostersByLocationAndDateQuery,
  GetRostersByLocationAndDateQueryVariables,
  GetTemplatesDocument,
  GetTemplatesQuery,
  GetTemplatesQueryVariables,
  useCreateRosterFromTemplateMutation,
  useDeleteRosterMutation
} from '../../../../services/gql/graphql';

export interface RosterSelectService extends State {
  getRosterByLocationAndDate: (startDate: Date, endDate: Date, locationId: number) => any;
  getOptimiserStatuses: (startDate: Date, endDate: Date, areaId: number) => any;
  createRoster: (templateName: string, date: Date, locationId: number) => Promise<string>;
  deleteRoster: (rosterId: string) => void;
}

const { Provider, useStore } = createContext<RosterSelectService>();
export const useRosterSelectService = useStore;

export const RosterSelectServiceProvider: FC = ({ children }) => {
  const client = useClient();
  const handleError = useErrorHandler();

  const [, createRosterFromTemplate] = useCreateRosterFromTemplateMutation();

  const [, deleteRoster] = useDeleteRosterMutation();

  const createStore = () =>
    create<RosterSelectService>((set, get) => {
      return {
        getRosterByLocationAndDate: async (startDate: Date, endDate: Date, locationId: number) => {
          const response = await client
            .query<GetRostersByLocationAndDateQuery, GetRostersByLocationAndDateQueryVariables>(
              GetRostersByLocationAndDateDocument,
              {
                locationAndDay: {
                  locationId: locationId,
                  dateRangeStart: convertDateToGQLFormat(startDate),
                  dateRangeEnd: convertDateToGQLFormat(endDate)
                }
              },
              {
                requestPolicy: 'network-only'
              }
            )
            .toPromise();

          if (response.error) {
            handleError(response.error);
          }

          return response.data?.rostersInDateRange;
        },
        getOptimiserStatuses: async (startDate: Date, endDate: Date, areaId: number) => {
          const response = await client
            .query<GetOptimiserStatusesQuery, GetOptimiserStatusesQueryVariables>(
              GetOptimiserStatusesDocument,
              {
                args: {
                  areaId: areaId,
                  dateRangeStart: convertDateToGQLFormat(startDate),
                  dateRangeEnd: convertDateToGQLFormat(endDate)
                }
              },
              {
                requestPolicy: 'network-only'
              }
            )
            .toPromise();

          if (response.error) {
            handleError(response.error);
          }

          const locationStatuses = response.data?.batchJobStatus?.locationStatuses;

          if (locationStatuses) {
            return locationStatuses[0].statuses;
          }
        },
        createRoster: async (templateName, date, locationId) => {
          const templates = await client
            .query<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument)
            .toPromise();

          if (templates.error) {
            handleError(templates.error);
          }

          const result = await createRosterFromTemplate({
            args: {
              templateId: templates.data?.templates?.find(template => template.name === templateName)?.id,
              rosterDate: convertDateToGQLFormat(date),
              locationId: locationId
            }
          });

          let returnVal = '';

          if (result.error) {
            handleError(result.error);
          } else {
            returnVal = result.data?.createRosterFromTemplate?.id as string;
          }

          return returnVal;
        },
        deleteRoster: async rosterId => {
          const result = await deleteRoster({
            id: rosterId
          });

          if (result.error) {
            handleError(result.error);
          }
        }
      };
    });

  return <Provider createStore={createStore}>{children}</Provider>;
};
