import { getDate } from 'date-fns';
import getMonth from 'date-fns/getMonth';
import LocalizedStrings from 'react-localization';
import { constructNameEN } from '../../../employee/employee-name-helper';

const shiftStrings = new LocalizedStrings({
  en: {
    title: 'Time & cost report',
    description: (startDate: Date, endDate: Date) =>
      `A time & cost report for the period starting ${getDate(startDate)}/${
        getMonth(startDate) + 1
      } and ending ${getDate(endDate)}/${getMonth(endDate) + 1}.`,
    shiftsOverview: {
      title: 'Shifts overview',
      description: (startDate: Date, endDate: Date) =>
        `A list of shifts for the period starting ${getDate(startDate)}/${getMonth(startDate) + 1} and ending ${getDate(
          endDate
        )}/${getMonth(endDate) + 1}.`
    },
    timeOverview: {
      title: 'Time overview',
      headers: {
        firstName: 'First name',
        lastName: 'Last name',
        total: 'Total',
        employeeName: 'Employee Name'
      },
      total: 'Total'
    },
    employeeName: constructNameEN
  }
});

export default shiftStrings;
