import React, { FC } from 'react';
import { ClockType } from './ClockHandler';
import strings from './clock.translations';
import { LocationPermissionType, useDeviceLocation } from '../../services/location/useDeviceLocation';
import { Button } from '../shared/button/Button';

export interface ClockMethodProps {
  setViewState: (state: ClockType) => void;
}

export const ClockMethod: FC<ClockMethodProps> = ({ setViewState }) => {
  const [validateAndSetLocationPermission, getUserLocation] = useDeviceLocation(service => [
    service.validateAndSetLocationPermission,
    service.getUserLocation
  ]);

  return (
    <>
      <p className="text-sm font-content leading-5">{strings.locationClockInDescription}</p>
      <Button
        primary
        onClick={() => {
          validateAndSetLocationPermission(LocationPermissionType.Granted);
          setViewState(ClockType.Location);
          getUserLocation();
        }}
      >
        {strings.giveLocationAccess}
      </Button>
      <p className="text-sm font-content leading-4 border-t border-gray-200 pt-3">{strings.manualClockInWarning}</p>
      <Button
        onClick={() => {
          validateAndSetLocationPermission(LocationPermissionType.Denied);
          setViewState(ClockType.Manual);
        }}
      >
        {strings.confirmManualClocking}
      </Button>
    </>
  );
};
