import React, { FC, HTMLAttributes } from 'react';
import { merge } from '../../../helpers/Utility';
import { RosterBusyPickerHeight } from './RosterBusyPicker';
import { BusynessLevel } from '../rosterTypes';
import { busynessToBgStyle, busynessToInputStyle } from '../rosterHelper';

interface RosterBusyInputProps extends HTMLAttributes<HTMLDivElement> {
  level: BusynessLevel;
  isActive: boolean;
  setLevelCallback: (level: BusynessLevel | null) => void;
}

export const RosterBusyInput: FC<RosterBusyInputProps> = ({ level, isActive, setLevelCallback, className}) => {
  return (
    <div
      className={merge(
        `flex items-center justify-center ${isActive && busynessToBgStyle(level)}`,
        className,
        RosterBusyPickerHeight
      )}
    >
      <input
        className={`rounded-md ${isActive && busynessToInputStyle(level)} w-5 h-5`}
        type="radio"
        onChange={() => {
          setLevelCallback(isActive ? null : level);
        }}
        checked={isActive}
      />
    </div>
  );
};
