import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { SubmissionErrorBoundary } from '../../components/errors/SubmissionErrorBoundary';
import { Modal } from '../../components/shared/modal/Modal';
import { Title } from '../../components/shared/text/Title';
import strings from './devices.translations';
import { EditDeviceForm } from './EditDeviceForm';

interface EditDeviceModalProps {
  open: boolean;
  name: string;
  locationId: number;
  onClose: () => void;
  id: string;
}

export const EditDeviceModal: FC<EditDeviceModalProps> = ({ open, onClose, locationId, id, name }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="relative m-8">
        <Title>{strings.editDeviceModal.title}</Title>
        <SubmissionErrorBoundary>
          <EditDeviceForm id={id} name={name} locationId={locationId} onClose={onClose}/>
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
