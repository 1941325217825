import React, { createContext, FC, HTMLAttributes, useContext } from 'react';
import { FiDatabase, FiPrinter } from 'react-icons/fi';
import { Loader } from '../shared/Loader';
import translations from './report.translations';
import { merge } from '../../helpers/Utility';
import { Button } from '../shared/button/Button';

export interface Report {
  name: string;
  description: string;
  loading?: boolean;
  data?: Record<string, string>[];
}

const ReportContext = createContext<Report>({
  name: '',
  description: '',
  data: []
});

export const useReport = () => {
  const context = useContext(ReportContext);
  if (context === undefined) {
    throw new Error('useReport must be used within a ReportProvider');
  }
  return context;
};

export const ReportProvider = ReportContext.Provider;

export interface ReportContainerProps {
  report: Report;
}

export const ReportContainer: FC<ReportContainerProps> = ({ report, children }) => {
  return (
    <ReportProvider value={report}>
      <div className="max-w-7xl w-full mx-auto sm:px-4 lg:px-8 py-4 px-4 lg:py-8">
        <div className="bg-primary-std border-primary-std rounded-lg border sm:shadow-sm print:border-0">
          {children}
        </div>
      </div>
    </ReportProvider>
  );
};

const convertToUrl = (text: string) => window.URL.createObjectURL(new Blob([text], { type: 'text/csv' }));

const convertToCSV = (report: Report) => {
  const data = report.data;
  if (!data || data.length <= 0) return '';
  const keys = Object.keys(data[0]);
  const headers = keys.map(key => `"${key}"`).join(',') + '\n';
  const content = data.flatMap(row => keys.map(key => `"${row[key]}"`).join(',')).join('\n') + '\n';
  return headers + content;
};

export const Controls = () => {
  const report = useReport();
  return (
    <div className="flex flex-col md:flex-row justify-between print:hidden">
      <div className={'flex flex-col md:flex-row gap-2 md:gap-5'}>
        <Button
          onClick={() => window.print()}
          primary
          className="flex justify-center w-full md:w-max self-center mt-2 md:mt-0"
        >
          <FiPrinter className="w-4 h-6 mr-2" />
          {translations.printReport}
        </Button>
        <Button
          className="flex justify-center w-full md:w-max self-center mt-2 md:mt-0"
          primary
          onClick={() => {
            const csv = convertToCSV(report);
            const url = convertToUrl(csv);
            window.location.assign(url);
          }}
        >
          <FiDatabase className="w-4 h-6 mr-2" />
          {translations.downloadData}
        </Button>
      </div>
    </div>
  );
};

export const Description = () => {
  const report = useReport();
  return <p className="text-secondary-std">{report.description}</p>;
};

const Th: FC<HTMLAttributes<HTMLTableCellElement>> = ({ children, className, ...props }) => {
  return (
    <th scope="col" {...props} className={merge('p-2 print:p-1 font-semibold', className)}>
      {children}
    </th>
  );
};

export const Td: FC<HTMLAttributes<HTMLTableCellElement>> = ({ children, className, ...props }) => {
  return (
    <td {...props} className={merge('p-2 print:p-1', className)}>
      {children}
    </td>
  );
};

interface TableProps {
  headers: { title: string; accessor: string }[];
  Cell: FC<{ value: string | number }>;
  onRowClick?: (str: Record<string, string>, i: number) => void;
}

export const Table: FC<TableProps> = ({ headers, children, Cell, onRowClick }) => {
  const { data, loading } = useReport();

  const handleRowClick = (row: Record<string, string>, i: number) => () => {
    if (onRowClick) {
      onRowClick(row, i);
    }
  };

  const clickRowLabel = onRowClick && translations.viewEmployeesDetails;

  return loading ? (
    <div className="bg-primary-std border p-6 flex justify-center items-center">
      <Loader className="w-8" />
    </div>
  ) : (
    <table className="table-auto bg-primary-std min-w-full divide-y divide-primary-std border border-primary-std mb-6 print:text-sm">
      <tbody className="bg-primary-std divide-y divide-primary-std border-x border-primary-std">
        <tr className="divide-x divide-primary-std">
          {headers.map((header, i) => (
            <Th key={i}>{header.title}</Th>
          ))}
        </tr>
        {data?.map((row, i) => (
          <tr
            aria-label={clickRowLabel}
            title={clickRowLabel}
            onClick={handleRowClick(row, i)}
            key={i}
            className={merge('divide-x divide-primary-std', i % 2 === 0 ? 'bg-secondary-std' : '')}
          >
            {headers.map((header, j) => (
              <Td key={j}>
                <Cell value={row[header.accessor]} />
              </Td>
            ))}
          </tr>
        ))}
        {children ? (
          <tr>
            <td className="p-2 print:p-1" colSpan={headers.length}>
              {children}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};
