import React, { FC } from 'react';
import { RosterStation } from '../../../../../services/gql/graphql';
import { RosterTimerBar } from '../../../common/RosterTimerBar';
import { Station } from '../../../ganttView/Station';
import { generateColors } from '../../../ganttView/rosterHelper';
import { useRosterService } from '../../../ganttView/rosterService';

export const ESSDesktopRosterView: FC = () => {
  const [roster] = useRosterService(state => [state.roster]);
  const colors = generateColors(roster.stations?.length || 0);

  return (
    <div className="mx-[5%] border-l border-r border-primary-std">
      <RosterTimerBar readOnly />
      {roster.stations?.map((station, idx) => (
        <Station station={station as RosterStation} color={colors[idx]} />
      ))}
    </div>
  );
};
