import React, { FC, useState } from 'react';
import { RiAlarmWarningFill } from 'react-icons/ri';
import { Title } from '../../../../components/shared/text/Title';
import strings from '../../team.translations';
import { useGdprDeleteUserMutation, useUpdateDeactivatedStatusMutation } from '../../../../services/gql/graphql';
import { ConfirmDialog } from '../../../../components/shared/ConfirmDialog';
import { TSCard } from '../../../../components/shared/card/Card';
import { TeamMemberUpdateType, useTeamMemberService } from '../TeamMemberService';
import { Button } from '../../../../components/shared/button/Button';
import { Icon } from '../../../../components/shared/icons/Icon';

interface DangerCardProps {
  employeeId: string;
  isDeactivated: boolean;
  firstName: string;
  lastName: string;
  disableDeactivate?: boolean;
  disableDelete?: boolean;
}

export const DangerCard: FC<DangerCardProps> = ({
  employeeId,
  isDeactivated,
  firstName,
  lastName,
  disableDeactivate,
  disableDelete
}) => {
  const [forceUpdate] = useTeamMemberService(state => [state.forceUpdate]);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false);

  const [deactivateAlertOpen, setDeactivateAlertOpen] = useState<boolean>(false);

  const [deactivatedRequest, updateDeactivated] = useUpdateDeactivatedStatusMutation();

  const deactivatedStatus = isDeactivated;

  const [deleteRequest, gdprDeleteUser] = useGdprDeleteUserMutation();

  const deleteUser = async () => {
    if (!deleteRequest.fetching) {
      const result = await gdprDeleteUser({
        id: employeeId
      });

      if (!result.error) {
        forceUpdate(TeamMemberUpdateType.ALL);
      }
    }
  };

  const toggleDeactivate = async () => {
    if (!deactivatedRequest.fetching) {
      const result = await updateDeactivated({
        id: employeeId,
        deactivated: !deactivatedStatus
      });

      if (!result.error) {
        forceUpdate(TeamMemberUpdateType.ALL);
      }
    }
  };

  return (
    <TSCard className="flex flex-col flex-1 p-6 gap-4 !border-red-600">
      <Title className="flex items-center space-x-2 border-solid border-b pb-4">
        <Icon theme="red" Icon={RiAlarmWarningFill} />
        <div className="space-x-2 flex items-center justify-center">
          <span className="whitespace-nowrap text-red-600">{strings.dangerCard.cardTitle}</span>
        </div>
      </Title>
      <div className="flex flex-row w-full items-center flex-wrap md:flex-nowrap -mt-4">
        {!disableDeactivate && (
          <div className="flex mr-0 md:mr-8 items-center mt-[10px]">
            <div className="w-full">
              <p className="font-semibold">
                {deactivatedStatus ? strings.dangerCard.reactivateAccount : strings.dangerCard.deactivateAccount}
              </p>
              <p className="pr-3">
                {deactivatedStatus
                  ? strings.dangerCard.reactivateDescription
                  : strings.dangerCard.deactivateDescription}
              </p>
            </div>
            <Button
              onClick={() => {
                if (!disableDeactivate) setDeactivateAlertOpen(true);
              }}
              disabled={disableDeactivate}
              loading={deactivatedRequest.fetching}
              className="h-full flex-shrink-0"
              danger={!deactivatedStatus}
              tint={!deactivatedStatus}
            >
              {deactivatedStatus ? strings.dangerCard.reactivate : strings.dangerCard.deactivate}
            </Button>
          </div>
        )}
        {!disableDeactivate && (
          <div className="flex items-center mt-[10px]">
            <div>
              <p className="font-semibold">{strings.dangerCard.deleteAccount}</p>
              <p className="pr-3">{strings.dangerCard.deleteDescription}</p>
            </div>
            <Button
              onClick={() => {
                if (!disableDelete) setDeleteAlertOpen(true);
              }}
              disabled={disableDelete}
              loading={deleteRequest.fetching}
              className="h-full flex-shrink-0"
              danger
            >
              {strings.common.delete}
            </Button>
          </div>
        )}
      </div>

      <div className="w-full flex justify-center items-center">
        <ConfirmDialog
          title={deactivatedStatus ? strings.dangerCard.reactivateAccount : strings.dangerCard.deactivateAccount}
          open={deactivateAlertOpen}
          onClose={() => setDeactivateAlertOpen(false)}
          onSuccess={() => {
            setDeactivateAlertOpen(false);
            toggleDeactivate();
          }}
          confirmLabel={deactivatedStatus ? strings.dangerCard.reactivate : strings.dangerCard.deactivate}
          cancelLabel={strings.common.cancel}
          confirmContent={
            <>
              <div className="text-secondary">
                <p className="mb-4">
                  {deactivatedStatus
                    ? strings.dangerCard.reactivateModalConfirmation
                    : strings.dangerCard.deactivateModalConfirmation}
                </p>

                {!deactivatedStatus && (
                  <>
                    <p>{strings.dangerCard.thisWill}</p>
                    <p className="font-semibold">
                      {strings.dangerCard.willDescriptionPartOne.replace('{name}', `${firstName} ${lastName}`)}
                    </p>
                    <p className="mb-4 font-semibold">
                      {strings.dangerCard.willDescriptionPartTwo.replace('{name}', `${firstName} ${lastName}`)}
                    </p>

                    <p>{strings.dangerCard.thisWont}</p>
                    <p className="font-semibold">
                      {strings.dangerCard.wontDescriptionPartOne.replace('{name}', `${firstName} ${lastName}`)}
                    </p>
                    <p className="mb-4 font-semibold">
                      {strings.dangerCard.wontDescriptionPartTwo.replace('{name}', `${firstName} ${lastName}`)}
                    </p>

                    <p>{strings.dangerCard.stillCharged}</p>
                    <p>{strings.dangerCard.stillReactivate}</p>
                  </>
                )}
              </div>
            </>
          }
          confirmButton={
            <Button
              danger
              tint
              className="w-full sm:w-auto"
              disabled={disableDeactivate}
              onClick={() => {
                if (!disableDeactivate) {
                  setDeactivateAlertOpen(false);
                  toggleDeactivate();
                }
              }}
            >
              {deactivatedStatus ? strings.dangerCard.reactivate : strings.dangerCard.deactivate}
            </Button>
          }
        />

        <ConfirmDialog
          title={strings.dangerCard.deleteAccount}
          open={deleteAlertOpen}
          onClose={() => setDeleteAlertOpen(false)}
          onSuccess={() => {
            setDeleteAlertOpen(false);
            deleteUser();
          }}
          confirmLabel={strings.common.delete}
          cancelLabel={strings.common.cancel}
          confirmContent={
            <>
              <div className="text-secondary mb-4">
                <p className="mb-4">{strings.dangerCard.deactivateModalConfirmation}</p>

                <p>{strings.dangerCard.thisWill}</p>
                <p className="font-semibold">
                  {strings.dangerCard.willDescriptionPartOne.replace('{name}', `${firstName} ${lastName}`)}
                </p>
                <p className="font-semibold">
                  {strings.dangerCard.willDescriptionPartTwo.replace('{name}', `${firstName} ${lastName}`)}
                </p>
                <p className="mb-4 font-semibold">
                  {strings.dangerCard.willDescriptionPartThree.replace('{name}', `${firstName} ${lastName}`)}
                </p>

                <p>{strings.dangerCard.thisWont}</p>
                <p className="mb-4 font-semibold">
                  {strings.dangerCard.wontDescriptionPartTwo.replace('{name}', `${firstName} ${lastName}`)}
                </p>

                <p>{strings.dangerCard.stopCharged}</p>
              </div>

              <p className="bg-red-200 text-red-600 mx-[-1.75rem] p-2">{strings.dangerCard.cantRecover}</p>
            </>
          }
          confirmButton={
            <Button
              danger
              className="w-full sm:w-auto"
              disabled={disableDelete}
              onClick={() => {
                if (!disableDelete) {
                  setDeleteAlertOpen(false);
                  deleteUser();
                }
              }}
            >
              {strings.common.delete}
            </Button>
          }
        />
      </div>
    </TSCard>
  );
};
