import { FC, HTMLAttributes } from 'react';
import { merge } from '../../../helpers/Utility';

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {}

export const LargeTitle: FC<TitleProps> = ({ children, className, ...props }) => {
  return (
    <h1 {...props} className={merge('text-2xl font-extrabold tracking-tight text-4xl', className)}>
      {children}
    </h1>
  );
};

export const Title: FC<TitleProps> = ({ children, className, ...props }) => {
  return (
    <h2 {...props} className={merge(`text-xl font-extrabold tracking-tight sm:text-2xl`, className)}>
      {children}
    </h2>
  );
};
