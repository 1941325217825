import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarEvent } from '../common/Calendar';
import { JobDateStatusGt, ReadRoster } from '../../../../services/gql/graphql';
import { useRosterSelectService } from '../common/rosterSelectService';
import { getDaysInMonth, getStartAndEndDateOfMonth, isDateInPast } from '../../../../helpers/dateHelper';
import { dateToRosterDateFormat } from '../../rosterHelper';
import { RosterCalendarContextObject, RosterStatus } from '../rosterCalendar/RosterCalendar';
import { useLocationService } from '../../../../services/location/LocationService';

export const useBuildRosterCalendarEvents = (
  onDelete: (day: Date, id: string) => void,
  onCreate: (day: Date) => void
) => {
  const navigate = useNavigate();

  const [getRosterByLocationAndDate, getOptimiserStatuses] = useRosterSelectService(state => [
    state.getRosterByLocationAndDate,
    state.getOptimiserStatuses
  ]);

  const [events, setEvents] = useState<CalendarEvent<RosterCalendarContextObject>[]>([]);
  const [locationId, areaId] = useLocationService(state => [
    state.selectedLocation?.id,
    state.selectedLocation?.area?.id
  ]);

  const [calendarLoading, setCalendarLoading] = useState<boolean>(false);

  const applyOptimiserStatuses = useCallback(
    async (eventsToUpdate: CalendarEvent<RosterCalendarContextObject>[]) => {
      if (!eventsToUpdate || eventsToUpdate.length === 0) {
        return;
      }

      const { startDate, endDate } = getStartAndEndDateOfMonth(eventsToUpdate[0].date);
      const optimiserData = (await getOptimiserStatuses(startDate, endDate, areaId!)) as JobDateStatusGt[];

      eventsToUpdate.forEach(event => {
        const optimiser = optimiserData.find(o => o.date.includes(dateToRosterDateFormat(event.date)));

        if (optimiser) {
          event.contextObject!.optimiserStatus = optimiser.status;
        }
      });
    },
    [getOptimiserStatuses, areaId]
  );

  const refreshOptimiserStatuses = useCallback(async () => {
    const newEvents = [...events];
    await applyOptimiserStatuses(newEvents);
    setEvents(newEvents);
  }, [setEvents, events, applyOptimiserStatuses]);

  const buildEvents = useCallback(
    async (date: Date) => {
      setCalendarLoading(true);

      const { startDate, endDate } = getStartAndEndDateOfMonth(date);

      const rosterData = (await getRosterByLocationAndDate(startDate, endDate, locationId!)) as ReadRoster[];

      const daysInMonth = getDaysInMonth(date.getMonth(), date.getFullYear());

      const newEvents = daysInMonth.map(day => {
        const event: CalendarEvent<RosterCalendarContextObject> = { date: day, contextObject: {} };

        const dateToCompare = dateToRosterDateFormat(day);
        const roster = rosterData.find(r => r.rosterDate.substring(0, 19) === dateToCompare);

        const inPast = isDateInPast(day, new Date());

        if (roster) {
          event.contextObject = {
            rosterStatus: roster.status as RosterStatus,
            id: roster.id
          };
        }

        if (!event.contextObject?.rosterStatus && !inPast) {
          // Future days without rosters need to be able to create them manually.
          event.contextObject = { onClick: () => onCreate(day) };
        }

        if (roster && event.contextObject) {
          event.contextObject.onClick = () => {
            navigate(`${roster.id}/${roster.rosterDate.substring(0, 10)}`);
          };

          if (!inPast) {
            // If the roster isn't in the past, you can delete it.
            event.contextObject.deleteOnClick = () => {
              onDelete(day, roster.id);
            };
          }
        }

        return event;
      });

      await applyOptimiserStatuses(newEvents);

      setCalendarLoading(false);

      setEvents(newEvents);
    },
    [getRosterByLocationAndDate, applyOptimiserStatuses, navigate, onDelete, onCreate, setEvents, locationId]
  );

  return { buildEvents, events, refreshOptimiserStatuses, calendarLoading };
};
