import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthenticationState, Permissions, useAuthenticationService } from '../authentication-service';
import { LocationProvider } from '../../location/LocationService';

interface AuthenticatedProps {
  permissions?: Permissions[];
}

export interface pageType {
  name: string;
  to: string;
  locked?: boolean;
  rolesRequired?: string[];
  debug?: boolean;
  feature?: string;
}

export const Authenticated: FC<AuthenticatedProps> = ({ permissions }) => {
  const [authState, user] = useAuthenticationService(state => [state.state, state.user]);

  const location = useLocation();

  // If the user is not authenticated, redirect them to a logon page
  if (authState === AuthenticationState.Unauthenticated) {
    return <Navigate to="/login" replace={true} state={{ restore: location.pathname }} />;
  }

  // If the user is not allowed here
  if (user && permissions && !permissions.every(i => user.profile.permission.includes(i))) {
    // This will need to be replaced with a real permission screen
    return null;
  }

  // If they're a user, go ahead and continue to what they wanted to see
  return (
    <LocationProvider>
      <Outlet />
    </LocationProvider>
  );
};
