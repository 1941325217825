import React, { FC } from 'react';
import strings from './upcomingShift.translations';
import { TSCard } from '../../../components/shared/card/Card';
import { FiClock } from 'react-icons/fi';
import { ShiftData } from './UpcomingShiftSection';
import { Icon } from '../../../components/shared/icons/Icon';
import { Button } from '../../../components/shared/button/Button';
import { Loading } from '../../../components/shared/Loading';

interface NextShiftCardProps {
  shift: ShiftData;
  loading?: boolean;
  callback: (startDate: Date, endDate: Date) => void;
}

export const NextShiftCard: FC<NextShiftCardProps> = ({ shift, loading, callback }) => {
  return loading ? (
    <Loading />
  ) : (
    <TSCard className="pl-6 pr-3 py-3">
      <div className="flex flex-row items-center">
        <Icon theme={'primary'} Icon={FiClock} />
        <div className="px-4 flex items-center w-full">
          <div>
            <h2 className="text-md font-extrabold">{strings.nextShiftTitle}</h2>
            <p className="text-2xl font-extrabold">{shift.date}</p>
            <p className="text-lg">{strings.startEndTime(shift.startTime, shift.endTime)}</p>
          </div>
          <Button className="ml-auto" onClick={() => callback(shift.startTimeRaw, shift.endTimeRaw)}>{strings.viewButton}</Button>
        </div>
      </div>
    </TSCard>
  );
};
