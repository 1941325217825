import { Outlet } from 'react-router';
import { FC, HTMLAttributes } from 'react';
import { TimeshiftLogo } from '../../components/shared/TimeshiftLogo';
import { ClockInOutBackgroundImage } from './ClockInOutBackground';

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {}

export const ClockInOutPageContainer: FC<PageContainerProps> = () => {
  return (
    <div className="relative w-screen h-screen flex flex-col overflow-auto pb-10">
      <TimeshiftLogo className="w-60 sm:w-72 m-auto mt-10 sm:mt-20" />
      <ClockInOutBackgroundImage className="absolute top-[20%] md:top-[60%]" />
      <div className="flex justify-center items-center flex-1 z-10">
        <div className="max-w-xl w-5/6 bg-primary-std max-h-[70vh] overflow-auto">
          <Outlet />
        </div>
      </div>
      <div className="fixed top-0 w-screen h-screen bg-primary-std -z-10"></div>
      <div className="fixed bottom-0 w-screen h-[60vh] bg-gradient-to-b dark:bg-primary-std from-white via-white via-primary-100 to-blue-100 -z-10"></div>
    </div>
  );
};
