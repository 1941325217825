import LocalizedStrings from 'react-localization';
import { withCommon } from '../../translation/commonTranslations';

const routesStrings = new LocalizedStrings({
  en: {
    getStarted: {
      title: 'Welcome!',
      spiel: [
        'This is your go to place to clocking in and out, making it easier to start and end your shifts.',
        "It is super simple.",
        "To log in using your phone number, insert the one time code that will be texted to you.",
        'To log in with an email account, insert your password.',
        "Once you are logged in:",
        "If you are a staff member, you can now clock in, choose to verify it securely using your location.",
        "For managers, you are set to manage your team and explore shifts."
      ]
    }
  }
});

export default withCommon<typeof routesStrings>(routesStrings);
