import { FC } from 'react';
import strings from '../../../roster.translations';
import { secondsToTimeString } from '../../../../../helpers/dateHelper';
import { Shift } from '../../../../../services/gql/graphql';

interface ESSDesktopShiftChitProps {
  shift: Shift;
}

export const ESSDesktopShiftChit: FC<ESSDesktopShiftChitProps> = ({ shift }) => {
  return (
    <div className="flex flex-col p-1 rounded-lg border border-primary-500 bg-primary-100 items-center justify-center text-xs">
      <p className="text-primary-500 font-bold uppercase">{strings.essMobile.shift}</p>
      <p className="text-secondary-std">{secondsToTimeString(shift.start)}</p>
      <p className="text-secondary-std">{secondsToTimeString(shift.end)}</p>
    </div>
  );
};
