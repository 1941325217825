import { useGetPolicyAcceptanceDatesQuery } from '../../services/gql/graphql';
import { handleError } from '../../services/gql/config/URQLProvider';
import strings from './myData.translations';

export const useGetAcceptanceDates = () => {
  const [{ data, error }] = useGetPolicyAcceptanceDatesQuery();
  if (error) handleError(error);

  const privacyPolicyAcceptanceDate = data?.myEmployee?.acceptedPrivacyPolicy;
  const termsAndConditionsAcceptanceDate = data?.myEmployee?.acceptedTermsAndConditions;

  return {
    privacyPolicyAcceptanceDate: privacyPolicyAcceptanceDate
      ? new Date(privacyPolicyAcceptanceDate as string).toLocaleDateString()
      : strings.privacyInformationCard.never,
    termsAndConditionsAcceptanceDate: termsAndConditionsAcceptanceDate
      ? new Date(termsAndConditionsAcceptanceDate as string).toLocaleDateString()
      : strings.privacyInformationCard.never
  };
};
