import { IExceptionTelemetry } from '@microsoft/applicationinsights-common';
import { FC, useEffect, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useAppInsights } from '../../helpers/AppInsights';
import { v4 } from 'uuid';
import strings from './errors.translations';
import { SubmissionErrorContents } from './SubmissionErrorContents';

export const SubmissionError: FC<FallbackProps> = ({ children, error, resetErrorBoundary }) => {
  const [exception, setException] = useState<IExceptionTelemetry>();
  const appInsights = useAppInsights();

  useEffect(() => {
    if (!error) return;
    const exception = {
      id: v4(),
      exception: error
    };
    setException(exception);
    appInsights.trackException(exception);
  }, [error, appInsights]);

  const resetAndGoHome = () => {
    resetErrorBoundary();
    setException(undefined);
  };

  return exception ? (
    <SubmissionErrorContents
      error={error}
      trackingId={exception?.id}
      buttonActionLabel={strings.tryAgain}
      onButtonAction={resetAndGoHome}
    />
  ) : null;
};

export const SubmissionErrorBoundary: FC = ({ children }) => {
  return <ErrorBoundary FallbackComponent={SubmissionError}>{children}</ErrorBoundary>;
};
