import React, { FC, useMemo } from 'react';
import { PageHeader } from '../../../components/page/PageHeader';
import { FiUserPlus } from 'react-icons/fi';
import { PageSizer, SizerMode } from '../../../components/page/PageSizer';
import { PageContainer } from '../../../components/page/PageContainer';
import strings from '../team.translations';
import { AddSingleTeamMemberForm } from './AddSingleTeamMemberForm';
import { Permissions, useAuthenticationService } from '../../../services/authentication/authentication-service';
import { ReadEmployee, useGetEmployeesForUserQuery, useGetStaffByIdQuery } from '../../../services/gql/graphql';
import { handleError } from '../../../services/gql/config/URQLProvider';
import { useParams } from 'react-router-dom';

export const AddTeamMemberPage: FC = () => {
  const [permissions] = useAuthenticationService(state => [state.user?.profile.permission]);
  const canCreateEmployee = permissions?.includes(Permissions.EmployeeCreate) ?? false;

  const [{ error }, getStaffQuery] = useGetEmployeesForUserQuery({
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    ),
    requestPolicy: 'network-only'
  });
  if (error) handleError(error);

  const breadcrumbs = [
    {
      name: strings.teamPageTitle,
      to: '/team'
    },
    {
      name: strings.addTeamMenu.addATeamMember,
      to: '/team/add-team-member'
    }
  ];

  const { userId } = useParams<'userId'>();

  const [{ data: userData }] = useGetStaffByIdQuery({
    variables: {
      id: userId
    },
    requestPolicy: 'network-only'
  });

  return (
    <PageContainer>
      <PageHeader icon={<FiUserPlus />} pageTitle={strings.addTeamMenu.addATeamMember} breadcrumbs={breadcrumbs} />
      <PageSizer mode={SizerMode.PAGE}>
        <div className="py-5">
          {canCreateEmployee && (
            <AddSingleTeamMemberForm
              refetchCallback={getStaffQuery}
              employee={userData?.employee as ReadEmployee}
            />
          )}
        </div>
      </PageSizer>
    </PageContainer>
  );
};
