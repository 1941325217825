import { FC, createContext, useContext } from 'react';
import { BackstageService } from '@softwareimaging/backstage';
import LocalProvider from '@softwareimaging/backstage-local';
import HTTPProvider from '@softwareimaging/backstage-http';
import { suspend, clear } from 'suspend-react';
import config from './backstage.config';

declare global {
  interface Window {
    BACKSTAGE_URL: string;
    BACKSTAGE_ENVIRONMENT: string;
  }
}

const regex = new RegExp(/^(https:|http:)\S*/gm);
export const BackstageContext = createContext<BackstageService | null>(null);

const providers = [];
if (process.env.NODE_ENV === 'development') {
  providers.push(LocalProvider(3, { config }));
}

if (window.BACKSTAGE_URL && regex.test(window.BACKSTAGE_URL)) {
  providers.push(HTTPProvider(1, { url: `${window.BACKSTAGE_URL}${window.BACKSTAGE_ENVIRONMENT}/index.json` }));
}

export const service = new BackstageService(providers);

interface BackstageProps {}
export const Backstage: React.FC<BackstageProps> = ({ children }) => {
  const s = suspend(async () => {
    await service.getConfiguration();
    return service;
  }, []);

  return <BackstageContext.Provider value={s}>{children}</BackstageContext.Provider>;
};

export const useBackstage = () => {
  const backstage = useContext(BackstageContext);
  if (!backstage) {
    throw new Error('Backstage is not available');
  }
  return backstage;
};

export function useFlag<T extends string>(flag: T) {
  const backstage = useBackstage();
  return suspend(async () => await backstage.getFlag(flag, () => clear([flag])), [flag]);
}

function useGetAllFlags() {
  const backstage = useBackstage();
  return suspend(async () => {
    const configFlags = await (await backstage.getConfiguration()).flags;
    return configFlags;
  }, ['getAllFlags']);
}

export function useFlags<T extends string>(flags: T[]): { [key in T]: boolean } {
  const allFlags = useGetAllFlags();
  if (!allFlags) return {} as { [key in T]: boolean };

  const availableKeys = Object.keys(allFlags) as T[];
  const selectedKeys = availableKeys.filter(key => flags.includes(key));
  return selectedKeys.reduce((obj, key) => {
    obj[key] = allFlags[key];
    return obj;
  }, {} as { [key in T]: boolean });
}

export function useVariable<T extends string>(variable: T) {
  const backstage = useBackstage();
  return suspend(async () => await backstage.getVariable(variable, () => clear([variable])), [variable]);
}

export const getVariable = async (key: string) => {
  const variable = await service.getVariable(key);
  if (!variable) {
    throw new Error(`Variable ${key} not found`);
  }
  return variable;
};

export const BackstageProvider: FC = ({ children }) => {
  return <Backstage>{children}</Backstage>;
};
