import * as React from 'react';
import { format } from 'date-fns';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
interface MonthPickerProps {
  value: Date;
  onChange: (newValue: Date) => void;
}

export const MonthPicker: React.FC<MonthPickerProps> = ({
  value,
  onChange
}) => {
  const prevMonth = () => {
    const newVal = new Date(value);

    //Go back a month.
    newVal.setDate(-1);

    //Set to first of previous month.
    newVal.setDate(1);

    onChange(newVal);
  }

  const nextMonth = () => {
    const newVal = new Date(value);

    //Go forwards a month.
    newVal.setDate(32);

    //Set to first of next month.
    newVal.setDate(1);

    onChange(newVal);
  }

  return (
    <>
      <div className="flex justify-between items-center px-3 py-3">
        <button
          type="button"
          aria-label="Previous Month"
          className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800 p-1.5 rounded-lg"
          onClick={prevMonth}
        >
          <FiChevronLeft className="w-6 h-6" />
        </button>

        <p>{format(value, 'MMMM yyyy')}</p>

        <div>
          <button
            type="button"
            aria-label="Next Month"
            className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800 p-1.5 rounded-lg"
            onClick={nextMonth}
          >
            <FiChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </>
  );
};
