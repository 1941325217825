import React, { FC, useState } from 'react';
import strings from './myData.translations';
import { Divider } from '../../components/shared/Divider';
import { Title } from '../../components/shared/text/Title';
import { useGetAcceptanceDates } from './useGetAcceptanceDates';
import { Modal } from '../../components/shared/modal/Modal';
import { TSTitleCard } from '../../components/shared/card/Card';
import { RiFileUserFill } from 'react-icons/ri';
import { Button } from '../../components/shared/button/Button';
import { useGetPolicies } from './useGetPolicies';

interface PrivacyInformationCardProps {}

export const privacyInformationCardId = 'privacy-information-card';

export const PrivacyInformationCard: FC<PrivacyInformationCardProps> = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState<boolean>(false);

  const { privacyPolicy, termsAndConditions } = useGetPolicies();

  const { privacyPolicyAcceptanceDate, termsAndConditionsAcceptanceDate } = useGetAcceptanceDates();

  return (
    <TSTitleCard
      title={strings.privacyInformationCard.title}
      descriptor={strings.privacyInformationCard.gdpr}
      icon={RiFileUserFill}
      id={privacyInformationCardId}
    >
      <div className="grid grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="flex items-center gap-3">
          <Button onClick={() => setShowTermsAndConditions(true)} className="w-40">
            {strings.privacyInformationCard.viewTermsAndConditions}
          </Button>
          <div className="flex flex-col font-medium">
            <p>
              {strings.privacyInformationCard.lastUpdated} {termsAndConditions.lastModified}
            </p>
            <p>
              {strings.privacyInformationCard.agreed} {termsAndConditionsAcceptanceDate}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <Button onClick={() => setShowPrivacyPolicy(true)} className="w-40">
            {strings.privacyInformationCard.viewPolicy}
          </Button>
          <div className="flex flex-col font-medium">
            <p>
              {strings.privacyInformationCard.lastUpdated} {privacyPolicy.lastModified}
            </p>
            <p>
              {strings.privacyInformationCard.agreed} {privacyPolicyAcceptanceDate}
            </p>
          </div>
        </div>

        <Modal open={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)}>
          <div className="p-8 max-w-md max-h-screen">
            <div className="flex items-center justify-between mb-4">
              <Title className="flex items-center">
                <div className="h-full w-full flex items-center">
                  <span className="whitespace-nowrap">{strings.termsAndConditions}</span>
                </div>
              </Title>
            </div>
            <Divider />
            <div className="w-full overflow-y-auto break-words">{termsAndConditions.data}</div>
          </div>
        </Modal>

        <Modal open={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)}>
          <div className="p-8 max-w-md max-h-screen">
            <div className="flex items-center justify-between mb-4">
              <Title className="flex items-center">
                <div className="h-full w-full flex items-center">
                  <span className="whitespace-nowrap">{strings.privacyPolicy}</span>
                </div>
              </Title>
            </div>
            <Divider />
            <div className="w-full overflow-y-auto break-words">{privacyPolicy.data}</div>
          </div>
        </Modal>
      </div>
    </TSTitleCard>
  );
};
