import LocalizedStrings from 'react-localization';
import { constructNameEN } from '../../employee/employee-name-helper';
import { withCommon } from '../../services/translation/commonTranslations';
import { format } from 'date-fns';

const rosterStrings = new LocalizedStrings({
  en: {
    calendarBreadcrumbTitle: 'Calendar',
    rosterBreadcrumbTitle: 'Rosters',
    cardTitle: 'Rosters',
    published: 'Published',
    completed: 'Completed',
    error: 'Error',
    autofill: 'Auto-Fill',
    queued: 'Queued',
    inProgress: 'In Progress',
    ready: 'Ready',
    draft: 'Draft',
    roster: 'Roster',
    createRosters: 'Create Rosters',
    editRoster: 'Edit Roster',
    publishedRoster: 'Published Roster',
    publishButton: {
      draft: 'Publish Roster',
      complete: 'Roster Published',
      edit: 'Edit Roster'
    },
    rosterRequirements: 'Roster Requirements',
    viewRequirements: 'View Requirements',
    positions: {
      add: 'Add position'
    },
    confirmModal: {
      confirmPublish: 'Are you sure you would like to publish this roster? Staff members will be able to see it.',
      publish: 'Publish',
      confirm: 'Confirm',
      confirmEdit:
        'Are you sure you would like to edit this roster? Staff members will not be able to see it until you publish it again.'
    },
    rosterInformation: 'Roster Information',
    employeeName: constructNameEN,
    notAssigned: 'Not assigned',
    noStaff: 'No Staff Available',
    noResults: 'No results',
    create: 'Create',
    createRosterModal: {
      title: 'Create new roster',
      howBusy: 'How busy do you expect the day to be?',
      radioOptions: {
        busy: 'Busy',
        normal: 'Normal',
        quiet: 'Quiet'
      }
    },
    deleteRosterConfirmModal: {
      title: 'Delete Roster',
      message: (date: string) => `Are you sure you want to delete the roster on ${date}?`,
      confirm: 'Yes'
    },
    rosterCreate: {
      startDate: 'Start date',
      endDate: 'End date',
      submitText: 'Create Rosters and Autofill',
      selectRosterDates: 'Which dates would you like to create rosters for?',
      selectBusy: 'How busy do you expect to be?',
      selectStartEndDate: 'Please select a start and end date',
      selectStartEndDateInvalid: 'Start date must be before end date',
      createTabHeader: 'Create Rosters and Autofill',
      submit: {
        createPeriod: (startDate: Date, endDate: Date) =>
          `Rosters will be created and autofilled for the period between ${format(
            startDate,
            'dd/MM/yyyy'
          )} and ${format(endDate, 'dd/MM/yyyy')}.`,
        autofillTime:
          'Autofilling the rosters can take a few minutes. You can see the progress of the optimiser on the rostering screen.',
        start: 'Click create rosters and autofill to get started!'
      },
      levels: {
        busy: 'Busy',
        normal: 'Normal',
        quiet: 'Quiet'
      },
      error: {
        unableToSend: 'Unable to send request. Please try again, or contact technical support.',
        issueWithRequest: 'There was an issue with the request. Please try again, or contact technical support.'
      }
    },
    essMobile: {
      notAvail: 'Not available',
      shift: 'Shift',
      viewRoster: 'View roster',
      noStaff: 'There are no staff on this station.'
    },
    popover: {
      shiftTasks: 'Shift Tasks'
    },
    rosterDateSwitch: {
      prevLabel: 'Previous roster',
      nextLabel: 'Next roster'
    }
  }
});

export default withCommon<typeof rosterStrings>(rosterStrings);
