import { FC } from 'react';
import { useFabric } from '../../services/fabric/fabric-service';
import { Modal } from '../shared/modal/Modal';

export interface FabricModalProps {
  onClose: () => void;
}

export type FabricModal = FC<FabricModalProps>;

export const FabricModalHost: FC = () => {
  const [SelectedModal, showModal, clearModal] = useFabric(state => [state.Modal, state.showModal, state.clearModal]);

  return (
    <Modal open={showModal} onClose={() => clearModal()}>
      {SelectedModal ? <SelectedModal onClose={() => clearModal()} /> : null}
    </Modal>
  );
};
