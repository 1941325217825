import { FC, HTMLAttributes, useState } from 'react';
import { CombinedError } from 'urql';
import { merge } from '../../helpers/Utility';
import { Button } from '../shared/button/Button';
import { errorCodeToMessage } from './ErrorCodeToMessage';
import strings from './errors.translations';

interface SubmissionErrorContentsProps extends HTMLAttributes<HTMLDivElement> {
  error: Error;
  trackingId?: string;
  onButtonAction: () => void;
  buttonActionLabel: string;
}

export const SubmissionErrorContents: FC<SubmissionErrorContentsProps> = ({
  error,
  trackingId,
  onButtonAction,
  buttonActionLabel,
  className
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  let messageToShow = strings.apology;

  const combinedError: CombinedError = error as CombinedError;
  if (combinedError.graphQLErrors.length > 0) {
    const gqlError = combinedError.graphQLErrors[0];
    if (gqlError.extensions?.code) {
      messageToShow = errorCodeToMessage(gqlError.extensions?.code);
    }
  }

  return (
    <div className={merge("w-full flex flex-col items-center gap-5", className)}>
      <p className="font-content">{messageToShow}</p>
      <p>
        {strings.trackingId}
        <code>{trackingId}</code>
      </p>
      <Button className="w-full" onClick={onButtonAction}>
        {buttonActionLabel}
      </Button>
      <Button onClick={() => setShowDetails(!showDetails)} className="w-full">
        {showDetails ? 'Hide details' : 'Show details'}
      </Button>
      {showDetails && (
        <div className="p-3 border rounded border-red-700 bg-red-600 bg-opacity-5 text-primary-std text-center w-full text-sm break-words">
          {strings.message}
          <code>{error.message}</code>
        </div>
      )}
    </div>
  );
};
