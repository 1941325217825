import React, { FC, useCallback, useEffect, useState } from 'react';
import { ESSMobileCalendarRow } from './ESSMobileCalendarRow';
import { CustomIntervalTimePicker } from '../../../../../components/shared/date/CustomIntervalTimePicker';
import { useRosterSelectService } from '../../common/rosterSelectService';
import { ReadRoster, useGetMyShiftsForDateRangeQuery } from '../../../../../services/gql/graphql';
import { CalendarEvent } from '../../common/Calendar';
import { ESSCalendarProps, StaffCalendarContextObject } from '../../../rosterTypes';
import { canWorkTargetDay } from '../../../rosterHelper';
import { RosterStatus } from '../../rosterCalendar/RosterCalendar';
import { useNavigate } from 'react-router';
import { useLocationService } from '../../../../../services/location/LocationService';
import { compareDateToRosterDate, dayDiff, getDatePeriod } from '../../../../../helpers/dateHelper';

export const ESSMobileCalendar: FC<ESSCalendarProps> = ({ workPatterns, readOnly }) => {
  const navigate = useNavigate();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const twoWeeks = new Date();
  twoWeeks.setHours(0, 0, 0, 0);
  twoWeeks.setDate(twoWeeks.getDate() + 14);

  const [[startDate, endDate], setDatePair] = useState<[Date, Date]>(() => [today, twoWeeks]);
  const [events, setEvents] = useState<CalendarEvent<StaffCalendarContextObject>[]>([]);

  const [getRosterByLocationAndDate] = useRosterSelectService(state => [state.getRosterByLocationAndDate]);
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);

  const [{ data: shiftData }] = useGetMyShiftsForDateRangeQuery({
    variables: {
      range: {
        start: startDate,
        end: endDate
      }
    },
    requestPolicy: 'network-only'
  });

  const processMobileEvents = useCallback(async () => {
    const displayDays = getDatePeriod(startDate, endDate);
    const rosterData = await getRosterByLocationAndDate(startDate, endDate, locationId!);
    const shiftsForLocation = shiftData?.myShiftsForDateRange?.shifts?.filter(
      shift => shift.location?.id === locationId
    );

    const mobileEvents: CalendarEvent<StaffCalendarContextObject>[] = displayDays.map((ele: Date) => {
      const roster = rosterData.find(
        (roster: ReadRoster) =>
          compareDateToRosterDate(ele, roster.rosterDate) && roster.status === RosterStatus.published
      );
      const canWorkFromPattern = canWorkTargetDay(workPatterns!, ele);

      return {
        date: ele,
        contextObject: {
          id: roster?.id,
          status: roster?.status,
          hasRoster: !!roster,
          canWork: canWorkFromPattern,
          onClick: () => {
            navigate(`/roster/${roster.id}/${roster.rosterDate.substring(0, 10)}/StaffRosterView`);
          },
          shift: shiftsForLocation?.find(shift => compareDateToRosterDate(ele, shift.date))
        }
      };
    });

    setEvents(mobileEvents);
  }, [getRosterByLocationAndDate, endDate, startDate, shiftData, workPatterns, navigate, locationId]);

  const onDateChange = (dates: [Date, Date]) => {
    processMobileEvents();
    setDatePair(dates);
  };

  useEffect(() => {
    processMobileEvents();
  }, [processMobileEvents]);

  return (
    <div className="flex flex-col flex-1 p-2">
      <CustomIntervalTimePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
        dayDiff={dayDiff(startDate, endDate)}
        inputStyle="w-[100%]"
      />
      <div className="flex flex-col p-2">
        {events.map((ele: CalendarEvent<StaffCalendarContextObject>) => {
          return <ESSMobileCalendarRow date={ele.date} rowContext={ele.contextObject!} />;
        })}
      </div>
    </div>
  );
};
