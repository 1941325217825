import { FC } from 'react';
import { AzureMap } from '../maps/AzureMap';
import { InputProps, withField } from './Field';

export interface MapPoint {
  primary?: boolean;
  longitude: number;
  latitude: number;
}

export interface MapInputProps extends InputProps<{ longitude: number; latitude: number }> {
  className?: string;
  points?: MapPoint[];
  origin?: { position: { longitude: number; latitude: number }; radius: number };
  center?: [number, number];
}

export const MapInput: FC<MapInputProps> = ({ onChange, value, points, ...props }) => {
  const staticPoints = points || [];
  const interactivePoints = value
    ? [
        ...staticPoints,
        {
          dynamic: true,
          longitude: value.longitude,
          latitude: value.latitude
        }
      ]
    : staticPoints;

  return (
    <div className="cursor-pointer">
      <AzureMap onPointClick={point => onChange(point)} points={interactivePoints} {...props} />
    </div>
  );
};

export const MapField = withField(MapInput, 'optional');
