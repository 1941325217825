import { FC, useState } from 'react';
import { AiOutlineClockCircle, AiOutlineWarning } from 'react-icons/ai';
import { FiCheck } from 'react-icons/fi';
import { HiOutlineLibrary } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { NavPage } from '../../components/page/Breadcrumbs';
import { PageContainer } from '../../components/page/PageContainer';
import { PageHeader } from '../../components/page/PageHeader';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { TSCardChevronRight, TSTitleCard } from '../../components/shared/card/Card';
import { ConfirmDialog } from '../../components/shared/ConfirmDialog';
import { Icon } from '../../components/shared/icons/Icon';
import { useVariable } from '../../services/backstage/BackstageProvider';
import {
  useDeleteDeviceMutation,
  useGetDeviceByIdQuery,
  useLocationQuery,
  useResetDeviceMutation
} from '../../services/gql/graphql';
import strings from '../devices/devices.translations';
import { EditDeviceModal } from './EditDeviceModal';
import { useErrorHandler } from 'react-error-boundary';
import { SubmissionErrorModal } from '../../components/errors/SubmissionErrorModal';
import { Feature } from '../../services/backstage/Feature';
import { RequireAssignedLocation } from '../../services/authentication/routes/RequireAssignedLocation';

interface ManageDevicePageProps {}

export const ManageDevicePage: FC<ManageDevicePageProps> = () => {
  const { locationId } = useParams<'locationId'>();
  const { deviceId } = useParams<'deviceId'>();
  const deviceUrl = useVariable('deviceUrl');
  const navigate = useNavigate();

  const [{ fetching: fetchingDelete, error: deleteError }, deleteDevice] = useDeleteDeviceMutation();

  const [{ fetching: fetchingReset, error: resetError }, resetDevice] = useResetDeviceMutation();

  const handleError = useErrorHandler();

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteErrorModalOpen, setDeleteErrorModalOpen] = useState<boolean>(false);
  const [resetErrorModalOpen, setResetErrorModalOpen] = useState<boolean>(false);

  const [resetModalOpen, setResetModalOpen] = useState<boolean>(false);
  const [{ data: locationData, error: locationError }] = useLocationQuery({
    variables: {
      id: Number(locationId!)
    }
  });
  if (locationError) handleError(locationError);

  const [{ data }] = useGetDeviceByIdQuery({ variables: { id: deviceId! } });

  const breadcrumbs: NavPage[] = [
    {
      name: strings.manageLocationBreadcrumb,
      to: `/manage-location/${locationId}`
    },
    {
      name: `${locationData?.location?.name}`,
      to: `/manage-location/${locationId}/devices`
    },
    {
      name: strings.title,
      to: `/manage-location/${locationId}/devices`
    },
    {
      name: strings.manageDevice.title,
      to: `/manage-location/${locationId}/devices/${deviceId}`
    }
  ];

  const onReset = async () => {
    const result = await resetDevice({ id: deviceId });
    if (result.error) {
      setResetErrorModalOpen(true);
    } else {
      setResetModalOpen(false);
      navigate(`/manage-location/${locationId}/devices/${deviceId}`);
    }
  };

  const onDelete = async () => {
    const result = await deleteDevice({ id: deviceId });
    if (result.error) {
      setDeleteErrorModalOpen(true);
      setDeleteModalOpen(false);
    } else {
      navigate(`/manage-location/${locationId}/devices`);
    }
  };

  return (
    <PageContainer
      customHeader={
        <PageHeader pageTitle={strings.manageDevice.title} breadcrumbs={breadcrumbs} icon={<AiOutlineClockCircle />} />
      }
    >
      <PageSizer className="flex gap-5 flex-col" mode={SizerMode.PAGE}>
        <RequireAssignedLocation locationIds={[locationId!]} errorMessage={strings.notAssigned}>
          <TSTitleCard
            title={strings.manageDevice.detailsSection.title}
            icon={HiOutlineLibrary}
            className="w-full"
            editDetails={{
              label: strings.manageDevice.detailsSection.editDevice,
              onClick: () => {
                setEditModalOpen(true);
              }
            }}
          >
            <div className="flex flex-col md:flex-row space-y-12 md:space-y-0">
              <div className="flex flex-col space-y-1 md:mr-auto">
                <span className="font-semibold">{strings.manageDevice.detailsSection.name}</span>
                <span>{data?.device?.name}</span>
              </div>
            </div>
          </TSTitleCard>
          <TSTitleCard
            title={strings.manageDevice.statusSection.title}
            icon={data?.device?.activated ? FiCheck : AiOutlineWarning}
            theme={data?.device?.activated ? 'primary' : 'amber'}
            className="w-full"
          >
            <div className="flex flex-col items-start	md:items-center space-y-2">
              {data?.device?.activated ? (
                <>
                  <span>{strings.manageDevice.statusSection.approvedDesc}</span>
                  <span>{strings.manageDevice.statusSection.approvedUrlDesc}</span>
                  <span className="!mt-5">{deviceUrl}</span>
                </>
              ) : (
                <>
                  <span>{strings.manageDevice.statusSection.pendingDesc}</span>
                  <span>{strings.manageDevice.statusSection.pendingUrlDesc}</span>
                  <div className="font-semibold flex flex-col md:flex-row">
                    <span className="mr-5">{`${deviceUrl}/${data?.device?.activationId}`}</span>{' '}
                    <span>
                      {strings.manageDevice.statusSection.setupCodeDesc} {data?.device?.activationToken}
                    </span>
                  </div>
                </>
              )}
            </div>
          </TSTitleCard>
          {data?.device?.activated && (
            <TSCardChevronRight
              onClick={() => {
                setResetModalOpen(true);
              }}
              title={strings.manageDevice.resetSection.title}
              icon={<Icon Icon={AiOutlineWarning} theme="amber" />}
              description={strings.manageDevice.resetSection.desc}
              largeHeader
            />
          )}
          <Feature name="debug">
            <TSCardChevronRight
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              title={strings.manageDevice.deleteSection.title}
              icon={<Icon Icon={AiOutlineWarning} theme="red" />}
              description={strings.manageDevice.deleteSection.desc}
              largeHeader
            />
          </Feature>
          <EditDeviceModal
            open={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
            }}
            id={deviceId!}
            locationId={parseInt(locationId!)}
            name={data?.device?.name || ''}
          />
          <ConfirmDialog
            title={strings.manageDevice.deleteSection.title}
            confirmContent={<p>{strings.manageDevice.deleteSection.confirmation}</p>}
            confirmLabel={strings.common.delete}
            cancelLabel={strings.common.cancel}
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onSuccess={onDelete}
            loading={fetchingDelete}
          ></ConfirmDialog>
          <SubmissionErrorModal
            error={deleteError!}
            onClose={() => setDeleteErrorModalOpen(false)}
            open={deleteErrorModalOpen}
          />
          <ConfirmDialog
            title={strings.manageDevice.resetSection.title}
            confirmContent={<p>{strings.manageDevice.resetSection.confirmation}</p>}
            confirmLabel={strings.common.reset}
            cancelLabel={strings.common.cancel}
            open={resetModalOpen}
            onClose={() => setResetModalOpen(false)}
            onSuccess={onReset}
            loading={fetchingReset}
          ></ConfirmDialog>
          <SubmissionErrorModal
            error={resetError!}
            onClose={() => setResetErrorModalOpen(false)}
            open={resetErrorModalOpen}
          />
        </RequireAssignedLocation>
      </PageSizer>
    </PageContainer>
  );
};
