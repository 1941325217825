import React, { FC, useState } from 'react';
import { FiClipboard } from 'react-icons/fi';
import { TSCard } from '../../../components/shared/card/Card';
import { motion } from 'framer-motion';
import strings from '../roster.translations';

export interface positionTask {
  name: string;
  count: number;
}


interface RosterTaskPopoverProps {
  positionTasks: positionTask[];
  bulletColor: string;
}

export const RosterTaskPopover: FC<RosterTaskPopoverProps> = ({ positionTasks, bulletColor }) => {
  const [openState, setOpenState] = useState<boolean>(false);

  return (
    <div className="flex relative">
      <div className="px-1">
        <FiClipboard
          className="w-6 h-6 text-gray-500 hover:opacity-70 cursor-help"
          onMouseEnter={() => {
            setOpenState(true);
          }}
          onMouseLeave={() => {
            setOpenState(false);
          }}
        />
      </div>
      {openState && (
        <motion.div
          layout
          initial={{ opacity: 0, bottom: -50 }}
          animate={{ opacity: 1, bottom: 0 }}
          className="absolute left-8 bottom-0 z-30"
        >
          <TSCard className="w-full flex flex-col items-center justify-center p-4 mx-8 gap-2">
            <p className="font-semibold text-xl">{strings.popover.shiftTasks}</p>
            {positionTasks.map((ele: positionTask) => {
              return <PopoverTask task={ele} bulletColor={bulletColor} />;
            })}
          </TSCard>
        </motion.div>
      )}
    </div>
  );
};

interface PopoverTaskProps {
  task: positionTask;
  bulletColor: string;
}

export const PopoverTask: FC<PopoverTaskProps> = ({ task, bulletColor }) => {
  return (
    <div className="flex flex-row items-center justify-start w-full gap-4 px-2">
      <div className="w-2 h-2 rounded-full" style={{ backgroundColor: bulletColor }} />
      <p className="truncate text-lg">{task.name}</p>
    </div>
  );
};
