import React, { FC } from 'react';
import strings from '../../../roster.translations';
import { secondsToTimeString } from '../../../../../helpers/dateHelper';
import { StaffCalendarContextObject } from '../../../rosterTypes';

interface ESSMobileShiftChitProps {
  rowContext: StaffCalendarContextObject;
}

export const ESSMobileShiftChit: FC<ESSMobileShiftChitProps> = ({ rowContext }) => {
  if (!rowContext.shift) {
    if (rowContext.canWork) {
      return <div />;
    } else {
      return (
        <div className="flex flex-1 rounded-lg border border-gray-400 bg-gray-300 items-center justify-center">
          <p className="text-gray-500 font-bold">{strings.essMobile.notAvail}</p>
        </div>
      );
    }
  } else {
    return (
      <div className="flex flex-1 rounded-lg border border-primary-500 bg-primary-100 items-center justify-center">
        <div className="flex flex-row gap-2 font-bold uppercase">
          <p className="text-primary-500">{`${strings.essMobile.shift}:`}</p>
          <p className="text-secondary-std">{`${secondsToTimeString(rowContext.shift.start)} - ${secondsToTimeString(
            rowContext.shift.end
          )}`}</p>
        </div>
      </div>
    );
  }
};
