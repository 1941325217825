import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Aborter, SubscriptionKeyCredential, MapsURL, SearchURL, Models } from 'azure-maps-rest';
import { Combobox } from '@headlessui/react';
import { useVariable } from '../../../services/backstage/BackstageProvider';
import { debounce } from 'lodash';

interface LocationSearchBoxProps {
  initialSearchValue?: string;
  onSelect: (address: string, longitude: number, latitude: number) => void;
}

export const LocationSearchBox: FC<LocationSearchBoxProps> = ({ onSelect, initialSearchValue = '' }) => {
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchValue);
  const [resultList, setResultList] = useState<Models.SearchAddressResult[] | undefined>();
  const [selectedLocation, setSelectedLocation] = useState<Models.SearchAddressResult>();
  const subscriptionKey = useVariable('mapsKey') || '';

  const search = useCallback(
    (term: string) => {
      // Use SubscriptionKeyCredential with a subscription key.
      var subscriptionKeyCredential = new SubscriptionKeyCredential(subscriptionKey);

      // Use subscriptionKeyCredential to create a pipeline.
      var pipeline = MapsURL.newPipeline(subscriptionKeyCredential, {
        retryOptions: { maxTries: 4 } // Retry options
      });

      // Create an instance of the SearchURL client.
      var searchURL = new SearchURL(pipeline);
      searchURL.searchAddress(Aborter.timeout(10000), `${term} uk`).then(response => {
        setResultList(response.results?.filter(res => res.address?.freeformAddress));
      });
    },
    [subscriptionKey, setResultList]
  );

  const debouncedSearch = useMemo(() => debounce(search, 500), [search]);

  useEffect(() => {
    if (searchTerm.length < 5) {
      setResultList(undefined);
      return;
    }

    debouncedSearch(searchTerm);
  }, [setResultList, searchTerm, debouncedSearch]);

  const displayValue = (val?: Models.SearchAddressResult) => {
    if (!val || !val.address || !val.address.freeformAddress) {
      return searchTerm;
    } else {
      return val.address.freeformAddress;
    }
  };

  const onChange = (newVal: Models.SearchAddressResult) => {
    onSelect(newVal.address!.freeformAddress || '', newVal.position!.lon!, newVal.position!.lat!);
    setSelectedLocation(newVal);
  };

  return (
    <div className="relative">
      <Combobox value={selectedLocation} onChange={onChange}>
        <Combobox.Input
          className="w-full focus:ring-primary-500 bg-primary-std focus:border-primary-500 flex-1 min-w-0 block w-full sm:text-sm border-primary-std rounded-md"
          placeholder="Search"
          displayValue={displayValue}
          onChange={event => setSearchTerm(event.target.value)}
        />
        <Combobox.Options className="absolute z-20 w-full">
          {resultList?.map(res => (
            <Combobox.Option key={res.id} value={res} className="bg-primary-std p-2 border border-primary-std">
              {res.address?.freeformAddress}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};
