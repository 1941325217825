import * as React from 'react';
import { format, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import { InputProps, withField } from '../form/Field';
import { TimeInput } from '../form/TimeInput';
import { DateInput } from './DateInput';

interface DateTimeInputProps extends InputProps<Date | undefined> {}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  className,
  name,
  onChange,
  value,
  placeholder,
  ...props
}) => {
  const updateValue = (date?: Date, time?: string) => {
    const combinedDate = (() => {
      if (!value) {
        // If there is no value and the date is being set, just default to noon
        if (date) return setHours(date, 12);

        // If there is no value and the time is being set, just default to today
        if (time) {
          const [hourString, minString] = time.split(':');
          return setMinutes(setHours(new Date(), parseInt(hourString, 10)), parseInt(minString, 10));
        }
        return;
      }

      if (date) {
        const hours = getHours(value);
        const mins = getMinutes(value);
        return setMinutes(setHours(date, hours), mins);
      }

      if (time) {
        const [hourString, minString] = time.split(':');
        return setMinutes(setHours(value, parseInt(hourString, 10)), parseInt(minString, 10));
      }
    })();

    onChange(combinedDate);
  };

  const time = value ? format(value, 'HH:mm') : undefined;

  return (
    <div className="flex space-x-3">
      <DateInput
        labels={{
          Sunday: 'Sun',
          Monday: 'Mon',
          Tuesday: 'Tues',
          Wednesday: 'Wed',
          Thursday: 'Thurs',
          Friday: 'Fri',
          Saturday: 'Sat'
        }}
        name={`${name}-date`}
        value={value}
        onChange={date => updateValue(date)}
      />
      <TimeInput name={`${name}-time`} value={time} className="w-max" onChange={time => updateValue(undefined, time)} />
    </div>
  );
};

export const DateTimeField = withField(DateTimeInput, 'Optional');
