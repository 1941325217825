import { FC } from 'react';
import { DeleteRosterCalendarIcon } from '../../../../../components/shared/icons/DeleteRosterCalendarIcon';

interface DeleteButtonProps {
  onClick: () => void;
}

export const DeleteButton: FC<DeleteButtonProps> = ({ onClick }) => {
  return (
    <div className="absolute w-full h-full opacity-0 hover:opacity-100">
      <DeleteRosterCalendarIcon
        onClick={event => {
          event.stopPropagation();
          onClick();
        }}
        className="absolute right-0 m-1"
      />
    </div>
  );
};
