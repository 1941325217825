import { TeamTableRow } from './TeamTable';

export const globalFilter = (user: TeamTableRow, filterValue: string) => {
  const filterEmail = user.contactDetails?.email?.toLowerCase().includes(filterValue.toLowerCase());
  const filterName = user.nameAndId?.name?.toLowerCase().includes(filterValue.toLowerCase());
  const filterRole = user.role?.toLowerCase().includes(filterValue.toLowerCase());
  const filterPhone = user.contactDetails?.phonenumber?.includes(filterValue);
  const filterLocation = !!user.nameAndId?.locationNames?.find(locationName => {
    return locationName.toLowerCase().includes(filterValue.toLowerCase());
  });
  return filterEmail || filterName || filterRole || filterPhone || filterLocation;
};
