import React, { FC, useEffect, useRef, useState } from 'react';
import strings from './myData.translations';
import { Checkbox } from '../../components/shared/checkboxes/Checkbox';
import { FiDownload } from 'react-icons/fi';
import {
  GetAllMyShiftsDocument,
  GetAllMyShiftsQuery,
  GetAllMyShiftsQueryVariables,
  GetMyPersonalInfoDocument,
  GetMyPersonalInfoQuery,
  GetMyPersonalInfoQueryVariables
} from '../../services/gql/graphql';
import { useClient } from 'urql';
import { TSTitleCard } from '../../components/shared/card/Card';
import { RiFileUserFill } from 'react-icons/ri';
import { Button } from '../../components/shared/button/Button';

interface DownloadYourDataCardProps {}

export const downloadYourDataCardId = 'download-your-data-card';

export const DownloadYourDataCard: FC<DownloadYourDataCardProps> = () => {
  const client = useClient();

  const downloadLink = useRef<HTMLAnchorElement>(null);

  const [loading, setLoading] = useState(false);

  const [dataToDownload, setDataToDownload] = useState<any>(null);

  const [personalData, setPersonalData] = useState<any>(null);
  const [shiftsAndRecordData, setShiftsAndRecordData] = useState<any>(null);

  const [personalInfoChecked, setPersonalState] = useState<boolean>(false);
  const [shiftsAndRecordChecked, setShiftsAndRecordState] = useState<boolean>(false);

  const onPersonalChange = () => {
    setPersonalState(!personalInfoChecked);
  };

  const onShiftAndRecordChange = () => {
    setShiftsAndRecordState(!shiftsAndRecordChecked);
  };

  const downloadData = async () => {
    const downloadPersonal = async () => {
      const response = await client
        .query<GetMyPersonalInfoQuery, GetMyPersonalInfoQueryVariables>(GetMyPersonalInfoDocument)
        .toPromise();

      const data = response?.data?.gdpr_myPersonalInfo;
      // Cache personal data in order to avoid re-requesting.
      setPersonalData(data);

      return data;
    };

    const downloadShiftsAndRecords = async () => {
      const response = await client
        .query<GetAllMyShiftsQuery, GetAllMyShiftsQueryVariables>(GetAllMyShiftsDocument)
        .toPromise();

      const data = response?.data?.getAllMyWorkTimeRecord;
      // Cache personal data in order to avoid re-requesting.
      setShiftsAndRecordData(data);

      return data;
    };

    setLoading(true);

    let dataToDownload = null;

    if (personalInfoChecked && shiftsAndRecordChecked) {
      const personalDataToConCat = personalData || (await downloadPersonal());
      const shiftDataToConCat = shiftsAndRecordData || (await downloadShiftsAndRecords());
      dataToDownload = { shiftInfo: shiftDataToConCat, personalInfo: personalDataToConCat };
    } else if (personalInfoChecked) {
      dataToDownload = personalData || (await downloadPersonal());
    } else if (shiftsAndRecordChecked) {
      dataToDownload = shiftsAndRecordData || (await downloadShiftsAndRecords());
    }

    if (dataToDownload) {
      setDataToDownload(dataToDownload);
    }

    setLoading(false);
  };
  useEffect(() => {
    if (dataToDownload) {
      if (!downloadLink.current) return;
      downloadLink.current.click();
    }
  }, [dataToDownload, downloadLink]);

  return (
    <TSTitleCard
      id={downloadYourDataCardId}
      title={strings.downloadDataCard.title}
      descriptor={strings.downloadDataCard.gdpr}
      icon={RiFileUserFill}
    >
      <div>
        <p className="leading-5">{strings.downloadDataCard.description}</p>
        <div className="flex flex-col sm:flex-row flex-wrap justify-between w-full mt-4">
          <div className="space-x-10 flex items-center">
            <Checkbox label={strings.personalInfo} onChange={onPersonalChange} checked={personalInfoChecked} />
            <Checkbox
              label={strings.downloadDataCard.shiftAndBreakRecords}
              onChange={onShiftAndRecordChange}
              checked={shiftsAndRecordChecked}
            />
          </div>
          <Button
            disabled={!personalInfoChecked && !shiftsAndRecordChecked}
            loading={loading}
            className="flex mt-3 sm:mt-0 items-center justify-center space-x-2"
            onClick={downloadData}
          >
            <FiDownload />
            <span>{strings.common.download}</span>
          </Button>
        </div>
      </div>

      <a
        ref={downloadLink}
        className="hidden"
        href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(dataToDownload))}`}
        download={`personal-data-${new Date().toLocaleDateString('en-GB').split('/').reverse().join('')}.json`}
      >
        {'Not shown'}
      </a>
    </TSTitleCard>
  );
};
