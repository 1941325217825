import { TSCard } from '../../components/shared/card/Card';
import { TimeshiftLogo } from '../../components/shared/TimeshiftLogo';
import strings from './myData.translations';
import { useGetPolicies } from './useGetPolicies';

export const PrivacyPolicyPage = () => {
  const { privacyPolicy } = useGetPolicies();

  return (
    <div className="w-screen min-h-screen sm:p-5 flex items-center justify-center">
      <TSCard className="py-10 flex flex-col items-center gap-10 w-full h-screen sm:h-auto sm:max-w-xl px-10 sm:px-20">
        <TimeshiftLogo className="h-12 mt-10 sm:mt-0" />
        <p className="font-bold text-4xl">{strings.privacyPolicy}</p>
        <div className="w-full overflow-y-auto break-words whitespace-pre-wrap">{privacyPolicy.data}</div>
      </TSCard>
    </div>
  );
};
