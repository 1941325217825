import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { merge } from '../../../helpers/Utility';

export interface CheckboxProps {
  label?: string;
  onChange: () => void;
  checked?: boolean;
  register?: UseFormRegisterReturn;
  className?: string;
  inputClassName?: string;
  rightAlignCheckbox?: boolean;
  color?: string;
  name?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  onChange,
  checked,
  register,
  className,
  inputClassName,
  rightAlignCheckbox,
  color,
  name
}) => {
  return (
    <div className={merge(className, 'space-x-2 flex items-center')} id={name}>
      {rightAlignCheckbox && <span>{label}</span>}
      <input
        type="checkbox"
        {...register}
        checked={checked}
        onChange={onChange}
        className={merge(inputClassName, 'outline-0 focus:ring-gray-400 focus:border-gray-400')}
        style={{ color: color }}
      />
      {!rightAlignCheckbox && <span>{label}</span>}
    </div>
  );
};
