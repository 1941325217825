import React, { FC } from 'react';
import { format, isWeekend } from 'date-fns';
import { merge } from '../../../helpers/Utility';
import { RosterBusyness } from './RosterCreateBusyness';
import { RosterBusyInput } from './RosterBusyInput';
import { BusynessLevel } from '../rosterTypes';

interface RosterBusyPickerProps {
  busyData: RosterBusyness;
  changeCallback: (newData: RosterBusyness) => void;
  first?: boolean;
}

export const RosterBusyPickerHeight = 'h-[100px]';
export const RosterBusyHeaderHeight = 'h-[50px]';

export const RosterBusyPicker: FC<RosterBusyPickerProps> = ({ busyData, changeCallback, first }) => {
  const formatMonthDay = () => {
    return (
      <div
        className={merge(
          'flex flex-col items-center justify-center font-bold text-gray-500 border-b',
          RosterBusyHeaderHeight,
          isWeekend(busyData.date) ? 'text-green-500' : ''
        )}
      >
        <p>{format(busyData.date, 'E').charAt(0)}</p>
        <p>{`${busyData.date.getDate()}/${busyData.date.getMonth() + 1}`}</p>
      </div>
    );
  };

  const commonPickerCallback = (newLevel: BusynessLevel | null) => {
    changeCallback({ date: busyData.date, level: newLevel });
  };

  return (
    <div>
      {formatMonthDay()}
      <RosterBusyInput
        level={BusynessLevel.BUSY}
        isActive={busyData.level === BusynessLevel.BUSY}
        setLevelCallback={newLevel => {
          commonPickerCallback(newLevel);
        }}
        className={first ? 'border-b' : 'border-l border-b'}
      />
      <RosterBusyInput
        level={BusynessLevel.NORMAL}
        isActive={busyData.level === BusynessLevel.NORMAL}
        setLevelCallback={newLevel => {
          commonPickerCallback(newLevel);
        }}
        className={first ? 'border-b' : 'border-l border-b'}
      />
      <RosterBusyInput
        level={BusynessLevel.QUIET}
        isActive={busyData.level === BusynessLevel.QUIET}
        setLevelCallback={newLevel => {
          commonPickerCallback(newLevel);
        }}
        className={first ? 'border-b' : 'border-l border-b'}
      />
    </div>
  );
};
