import { FC, useState } from 'react';
import strings from '../../team.translations';
import { EmployeeSchedule, employeeScheduleToPattern } from '../../../../helpers/BuildWorkingPattern';
import { TeamMemberScheduleEditFormRow } from './TeamMemberScheduleEditFormRow';
import { useUpdateWorkingPatternMutation } from '../../../../services/gql/graphql';
import { useErrorHandler } from 'react-error-boundary';
import { ConfirmDialog } from '../../../../components/shared/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/shared/button/Button';
import { TSCard } from '../../../../components/shared/card/Card';

interface TeamMemberScheduleEditFormProps {
  userId: string;
  userFirstName: string;
  periodId: number;
  initSchedule: EmployeeSchedule[];
}

const DEFAULT_START = '09:00';
const DEFAULT_END = '22:00';

export const TeamMemberScheduleEditForm: FC<TeamMemberScheduleEditFormProps> = ({
  userId,
  userFirstName,
  periodId,
  initSchedule
}) => {
  const [schedule, setSchedule] = useState<EmployeeSchedule[]>(initSchedule);
  const [updatePatternRes, updatePattern] = useUpdateWorkingPatternMutation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  const onCheck = (scheduleDay: EmployeeSchedule) => () => {
    const newSched = [...schedule];
    const dayToEdit = newSched.find(d => scheduleDay.day === d.day);

    if (dayToEdit) {
      if (dayToEdit.available) {
        dayToEdit.available = false;
      } else {
        dayToEdit.available = true;
        if (!dayToEdit.start) {
          dayToEdit.start = DEFAULT_START;
        }

        if (!dayToEdit.end) {
          dayToEdit.end = DEFAULT_END;
        }
      }
    }

    setSchedule(newSched);
  };

  const onStartChange = (scheduleDay: EmployeeSchedule) => (newVal?: string) => {
    const newSched = [...schedule];
    const dayToEdit = newSched.find(d => scheduleDay.day === d.day);

    if (dayToEdit) {
      dayToEdit.start = newVal;
    }

    setSchedule(newSched);
  };

  const onEndChange = (scheduleDay: EmployeeSchedule) => (newVal?: string) => {
    const newSched = [...schedule];
    const dayToEdit = newSched.find(d => scheduleDay.day === d.day);

    if (dayToEdit) {
      dayToEdit.end = newVal;
    }

    setSchedule(newSched);
  };

  const onSubmit = () => {
    setModalOpen(true);
  };

  const goBack = () => {
    navigate(`/team-member/${userId}`);
  };

  const onSave = async () => {
    const result = await updatePattern({
      args: {
        employeeId: userId,
        periodId: periodId,
        patterns: employeeScheduleToPattern(schedule)
      }
    });

    if (result.error) {
      handleError(result.error);
    } else {
      goBack();
    }
  };

  return (
    <>
      <TSCard className="space-y-5 max-w-3xl mx-auto p-5">
        <div className="grid grid-cols-3 gap-y-4 gap-x-2 sm:gap-x-10" style={{ gridTemplateColumns: 'auto 2fr 2fr' }}>
          <div></div>
          <p className="text-center">{strings.editSchedule.availableFrom}</p>
          <p className="text-center">{strings.editSchedule.availableUntil}</p>
          {schedule.map(item => (
            <TeamMemberScheduleEditFormRow
              key={item.day}
              schedule={item}
              onCheck={onCheck(item)}
              onStartChange={onStartChange(item)}
              onEndChange={onEndChange(item)}
            />
          ))}
        </div>
        <ConfirmDialog
          title={strings.editSchedule.editTitle}
          confirmContent={strings.editSchedule.modalText(userFirstName)}
          confirmLabel={strings.editSchedule.confirm}
          cancelLabel={strings.common.cancel}
          open={modalOpen}
          onSuccess={onSave}
          onClose={() => setModalOpen(false)}
          loading={updatePatternRes.fetching}
        ></ConfirmDialog>
      </TSCard>
      <div className="space-y-5 max-w-3xl mx-auto pt-5 pl-5">
        <div className="flex w-full items-center justify-end gap-5">
          <Button onClick={goBack}>{strings.common.cancel}</Button>
          <Button primary onClick={onSubmit}>
            {strings.common.save}
          </Button>
        </div>
      </div>
    </>
  );
};
