import React, { FC } from 'react';
import { PageHeader } from '../../components/page/PageHeader';
import { GiPokecog } from 'react-icons/gi';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { PageContainer } from '../../components/page/PageContainer';
import strings from './myData.translations';
import { PrivacyInformationCard, privacyInformationCardId } from './PrivacyInformationCard';
import { InformationUpToDateCard, informationUpToDateCardId } from './InformationUpToDateCard';
import { DownloadYourDataCard, downloadYourDataCardId } from './DownloadYourDataCard';
import { DeleteDataCard, deleteDataCardId } from './DeleteDataCard';
import { ContactUsCard, contactUsCardId } from './ContactUsCard';
import { VerticalMenu } from '../../components/shared/menu/VerticalMenu';
import { FiUser } from 'react-icons/fi';

interface myDataPageProps {}

export const MyDataPage: FC<myDataPageProps> = () => {
  const breadcrumbs = [{ name: strings.myDataPageTitle, to: '/my-data' }];

  const colouredUserIcon = <FiUser fill="currentColor" className="w-4 h-4 mt-0.5" />;

  const sideMenuOptions = [
    {
      icon: colouredUserIcon,
      label: strings.sideNav.privacyInfo,
      anchorId: privacyInformationCardId
    },
    {
      icon: colouredUserIcon,
      label: strings.sideNav.downloadData,
      anchorId: downloadYourDataCardId
    },
    {
      icon: colouredUserIcon,
      label: strings.sideNav.infoUpToDate,
      anchorId: informationUpToDateCardId
    },
    {
      icon: colouredUserIcon,
      label: strings.sideNav.deleteYourData,
      anchorId: deleteDataCardId
    },
    {
      icon: colouredUserIcon,
      label: strings.sideNav.contactUs,
      anchorId: contactUsCardId
    }
  ];

  const allElements = (
    <>
      <PrivacyInformationCard />
      <DownloadYourDataCard />
      <InformationUpToDateCard />
      <DeleteDataCard />
      <ContactUsCard />
    </>
  );

  return (
    <PageContainer
      customHeader={<PageHeader pageTitle={strings.myDataPageTitle} breadcrumbs={breadcrumbs} icon={<GiPokecog />} />}
    >
      <PageSizer className="flex gap-5 flex-col lg:flex-row" mode={SizerMode.PAGE}>
        <VerticalMenu sideMenuOptions={sideMenuOptions} allElements={allElements} isScroll={true} />
      </PageSizer>
    </PageContainer>
  );
};
