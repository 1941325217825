import React, { FC, HTMLAttributes } from 'react';
import { toast, Toast, useToaster } from 'react-hot-toast';
import { AnimatePresence, motion } from 'framer-motion';
import { FiAlertCircle, FiAlertTriangle, FiCheck, FiX } from 'react-icons/fi';
import { TSCard } from '../../components/shared/card/Card';
import { Icon } from '../../components/shared/icons/Icon';
import { IconButton } from '../../components/shared/button/IconButton';

interface ToastServiceProps extends HTMLAttributes<HTMLDivElement> {}

export const createSuccessToast = (message: string) => {
  toast.success(message, {
    position: 'top-right'
  });
};

export const createErrorToast = (message: string) => {
  toast.error(message, {
    position: 'top-right'
  });
};

export const ToastService: FC<ToastServiceProps> = ({ children }) => {
  const { toasts, handlers } = useToaster();

  interface ToastContentsProps {
    toast: Toast;
  }

  const ErrorToast: FC<ToastContentsProps> = ({ toast }) => {
    return (
      <>
        <p className="text-red-400 font-bold text-lg leading-tight capitalize">{toast.type}</p>
        <p className="text-md leading-tight">{toast.message}</p>
      </>
    );
  };

  const NotificationToast: FC<ToastContentsProps> = ({ toast }) => {
    return <p className="text-md leading-tight">{toast.message}</p>;
  };

  const SuccessToast: FC<ToastContentsProps> = ({ toast }) => {
    return (
      <>
        <p className="text-primary-500 font-bold text-lg leading-tight capitalize">{toast.type}</p>
        <p className="text-md leading-tight">{toast.message}</p>
      </>
    );
  };

  const getContentForToast = (toast: Toast) => {
    switch (toast.type) {
      case 'error':
        return <ErrorToast toast={toast} />;
      case 'success':
        return <SuccessToast toast={toast} />;
      default:
        return <NotificationToast toast={toast} />;
    }
  };

  const calcToastPosition = (toast: Toast): string => {
    let toastPosition = 'sm:self-center sm:w-min self-center w-full';

    if (toast.position?.includes('left')) {
      toastPosition = 'sm:self-start sm:w-min self-center w-full';
    } else if (toast.position?.includes('right')) {
      toastPosition = 'sm:self-end sm:w-min self-center w-full';
    }

    return toastPosition;
  };

  const calcToastIcon = (toast: Toast) => {
    if (toast.type === 'success') return <Icon Icon={FiCheck} theme="emerald" />;
    if (toast.type === 'error') return <Icon Icon={FiAlertTriangle} theme="red" />;
    return <Icon Icon={FiAlertCircle} theme="amber" />;
  };

  return (
    <>
      <div className="fixed inset-0 w-full flex mt-[75px] px-4 py-6 pointer-events-none sm:p-6 z-20 items-end sm:items-start">
        <div className="w-full flex flex-col space-y-4">
          <AnimatePresence>
            {toasts.map((localToast: Toast) => {
              return (
                <motion.div
                  layout
                  onHoverStart={handlers.startPause}
                  onHoverEnd={handlers.endPause}
                  key={localToast.id}
                  initial={{ opacity: 0, top: -150 }}
                  animate={{ opacity: 1, top: 0 }}
                  exit={{ opacity: 0, top: -150 }}
                  className={`min-w-[300px] ${calcToastPosition(localToast)}`}
                >
                  <TSCard className="p-4 z-20 flex flex-row gap-4 items-center pointer-events-auto">
                    {calcToastIcon(localToast)}
                    <div className="flex-1 flex flex-col leading-3 w-full whitespace-nowrap">
                      {getContentForToast(localToast)}
                    </div>
                    <IconButton label="Clear notifications" Icon={FiX} onClick={() => toast.remove(localToast.id)} />
                  </TSCard>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      </div>
      {children}
    </>
  );
};
