import { FC } from 'react';
import { LinkButton } from '../../../components/shared/LinkButton';
import { TimeshiftLogo } from '../../../components/shared/TimeshiftLogo';
import strings from './routes.translations';
import { TSCard } from '../../../components/shared/card/Card';

export const GetStartedPage: FC = () => {
  return (
    <div className="w-screen min-h-screen sm:p-5 flex items-center justify-center">
      <TSCard className="py-10 flex flex-col items-center gap-10 w-full h-screen sm:h-auto sm:max-w-xl px-10 sm:px-20">
        <TimeshiftLogo className="h-12 mt-10 sm:mt-0" />
        <div className="flex flex-col items-center gap-5 flex-1 sm:flex-initial">
          <p className="font-bold text-4xl">{strings.getStarted.title}</p>
          {strings.getStarted.spiel.map((para, index) => (
            <p className="text-center" key={index}>
              {para}
            </p>
          ))}
          <LinkButton primary to={'/login'}>
            {'Get started'}
          </LinkButton>
        </div>
      </TSCard>
    </div>
  );
};
