import { FC } from 'react';
import { merge } from '../../../helpers/Utility';
import { MapPoint, Map } from './Map';

interface SinglePointMapProps {
  point: MapPoint;
  className?: string;
}

export const SinglePointMap: FC<SinglePointMapProps> = ({ point, className }) => {
  return (
    <Map
      points={[point]}
      center={[point.longitude, point.latitude]}
      origin={{ position: { longitude: point.longitude, latitude: point.latitude }, radius: 30 }}
      className={merge(className, 'rounded-md h-48')}
    />
  );
};
