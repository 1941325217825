import React, { FC, useState } from 'react';
import { FiMapPin } from 'react-icons/fi';
import strings from './manageLocation.translations';
import { TSTitleCard } from '../../components/shared/card/Card';
import { EditLocationDetailsModal } from './EditLocationDetailsModal';
import { Location } from '../manageOrg/locations/LocationList';

interface LocationDetailsCardProps {
  location: Location;
}

export const LocationDetailsCard: FC<LocationDetailsCardProps> = ({location}) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  return (
    <>
      <TSTitleCard
        editDetails={{
          label: strings.editLocationDetails.editLabel,
          onClick: () => setEditModalOpen(true)
        }}
        title={strings.locationDetailsCard.title}
        icon={FiMapPin}
        className="w-full"
      >
        <div className="flex flex-col md:flex-row space-y-12 md:space-y-0">
          <div className="flex flex-col">
            <span className="font-semibold">{strings.locationDetailsCard.name}</span>
            <span>{location.name}</span>
          </div>
        </div>
      </TSTitleCard>
      <EditLocationDetailsModal open={editModalOpen} onClose={() => setEditModalOpen(false)} location={location}/>
    </>
  );
};
