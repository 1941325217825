import { FC, Suspense } from 'react';
import strings from '../../team.translations';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { SubmissionErrorBoundary } from '../../../../components/errors/SubmissionErrorBoundary';
import { PageContainer } from '../../../../components/page/PageContainer';
import { PageHeader } from '../../../../components/page/PageHeader';
import { PageSizer, SizerMode } from '../../../../components/page/PageSizer';
import { TableLoader } from '../../../../components/shared/table/TableLoader';
import {
  ReadEmployee,
  useEmployeeIdWorkingPatternsQuery,
  useGetStaffByIdQuery,
  WorkingPatternPeriods
} from '../../../../services/gql/graphql';
import { breadcrumbName } from '../../../../helpers/Utility';
import { TeamMemberScheduleEditForm } from './TeamMemberScheduleEditForm';
import { format } from 'date-fns';
import { patternToEmployeeSchedule } from '../../../../helpers/BuildWorkingPattern';
import { FiEdit } from 'react-icons/fi';

interface TeamMemberScheduleEditProps {}

export const TeamMemberScheduleEdit: FC<TeamMemberScheduleEditProps> = () => {
  const { userId } = useParams<'userId'>();
  const handleError = useErrorHandler();

  const [{ data: userData, error: userError }] = useGetStaffByIdQuery({
    variables: {
      id: userId
    },
    requestPolicy: 'network-only'
  });

  const [{ data: scheduleData, error: scheduleError }] = useEmployeeIdWorkingPatternsQuery({
    variables: {
      dateAndPeriods: {
        date: format(new Date(), 'yyyy-MM-dd'),
        periods: 7
      },
      id: userId
    },
    requestPolicy: 'network-only'
  });

  if (userError || scheduleError) {
    handleError(userError || scheduleError);
  }

  const breadcrumbs = [
    {
      name: strings.teamPageTitle,
      to: '/team'
    },
    {
      name: breadcrumbName(userData!.employee! as ReadEmployee, strings.employeeName),
      to: `/team-member/${userId}`
    },
    {
      name: strings.editSchedule.breadcrumb,
      to: `/team-member/${userId}/set-schedule`
    }
  ];

  let periodId = 0;
  if (scheduleData?.employeeIdWorkingPatterns?.periods) {
    periodId = scheduleData.employeeIdWorkingPatterns.periods[0].id;
  }

  return (
    <PageContainer>
      <PageHeader
        icon={<FiEdit />}
        pageTitle={
          strings.editSchedule.editTitle +
          ': ' +
          `${userData!.employee!.firstName + ' ' + userData!.employee!.lastName}`
        }
        breadcrumbs={breadcrumbs}
      />
      <PageSizer mode={SizerMode.PAGE}>
        <div className="py-5">
          <SubmissionErrorBoundary>
            <Suspense fallback={<TableLoader />}>
              <TeamMemberScheduleEditForm
                userId={userId!}
                userFirstName={userData!.employee!.firstName}
                periodId={periodId}
                initSchedule={patternToEmployeeSchedule(
                  scheduleData?.employeeIdWorkingPatterns as WorkingPatternPeriods
                )}
              />
            </Suspense>
          </SubmissionErrorBoundary>
        </div>
      </PageSizer>
    </PageContainer>
  );
};
