import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useFlag } from '../../backstage/BackstageProvider';

export const RequireDebug: FC = () => {
  const location = useLocation();
  const isDebug = useFlag('debug');

  if (!isDebug) {
    return <Navigate to={'*'} replace={true} state={{ restore: location.pathname }} />;
  }

  return <Outlet />;
};