import { FC } from 'react';
import { useNavigate } from 'react-router';
import { LargeTitle } from '../components/shared/text/Title';
import strings from './pages.translations';
import { Button } from '../components/shared/button/Button';

interface NotFoundProps {}

export const NotFound: FC<NotFoundProps> = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-primary-std min-h-screen px-6 py-16 sm:px-6 sm:py-24 grid place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-primary-400 sm:text-5xl">{strings.notFound.number}</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-primary-std: sm:pl-6">
              <LargeTitle>{strings.notFound.title}</LargeTitle>
              <p className="mt-1 text-base text-secondary-std font-content">{strings.notFound.tryAgain}</p>
            </div>
            <div className="mt-6 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button primary onClick={() => navigate('/')}>
                {strings.notFound.goHome}
              </Button>
              <Button primary tint>
                {strings.notFound.contactSupport}
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
