import { differenceInSeconds } from 'date-fns';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import { Loader } from './Loader';
import strings from './shared.translations';
import { Button } from './button/Button';
import { merge } from '../../helpers/Utility';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {}

export const Loading: FC<LoadingProps> = ({ className, ...props }) => {
  const [timeSinceInitialised, setDuration] = useState<number>();
  const [initialisedDate, setTimestamp] = useState<Date>();

  useEffect(() => {
    if (!initialisedDate) {
      setTimestamp(new Date());
      return;
    }

    const id = setInterval(() => {
      const seconds = differenceInSeconds(new Date(), initialisedDate);
      setDuration(seconds);
    }, 1000);
    return () => clearInterval(id);
  }, [initialisedDate]);

  const restartApplication = () => {
    window.location.reload();
  };

  return (
    <div
      className={merge('w-screen h-screen flex flex-col justify-center text-center p-5 items-center', className)}
      {...props}
    >
      <Loader className="w-10 h-10 mb-2" />
      {timeSinceInitialised && timeSinceInitialised > 5 ? <p>{strings.loading.longerThanExpected}</p> : null}
      {timeSinceInitialised && timeSinceInitialised > 10 ? (
        <>
          <p>{strings.loading.ifDelayContinues}</p>
          <div className="flex space-x-3 mt-4">
            <Button onClick={restartApplication} className="flex items-center">
              {strings.loading.restartApplication}
            </Button>
            <Button className="flex items-center">
              <FiMessageCircle className="mr-2" />
              {strings.loading.contactSupport}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
