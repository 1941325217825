import { FC, HTMLAttributes } from 'react';
import { Button } from '../../components/shared/button/Button';
import { useNavigate } from 'react-router-dom';

interface QuickLinkProps extends HTMLAttributes<HTMLDivElement> {
  url: string;
  label: string;
  icon: JSX.Element;
}

export const QuickLink: FC<QuickLinkProps> = ({ url, label, icon }) => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(url)} className="flex items-center gap-5 px-5">
      {icon}
      <p>{label}</p>
    </Button>
  );
};
