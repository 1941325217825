import { BaseLocation, useLocationService } from '../../../services/location/LocationService';
import { Checkbox } from './Checkbox';
import React, { FC, useEffect, useState } from 'react';

interface LocationsCheckboxesProps {
  onUpdate: (locations: BaseLocation[]) => void;
}

export const LocationsCheckboxes: FC<LocationsCheckboxesProps> = ({ onUpdate }) => {
  const [selectedShiftLocations] = useLocationService(state => [state.selectedShiftLocations]);

  const [locations, setLocations] = useState<BaseLocation[]>(
    JSON.parse(JSON.stringify(selectedShiftLocations)) as BaseLocation[]
  );

  useEffect(() => {
    // Need to initially call onUpdate incase the user does not update the checked state of any checkboxes.
    onUpdate(locations);
  }, [onUpdate, locations]);

  return (
    <>
      {locations.length > 1 && (
        <div className={'grid grid-cols-1 md:grid-cols-[repeat(4,_auto)] gap-x-10 gap-y-4'}>
          {locations.map(location => {
            return (
              <Checkbox
                onChange={() => {
                  location.checked = !location.checked;
                  setLocations([...locations]);
                  onUpdate(locations);
                }}
                checked={location.checked}
                label={location.name}
                rightAlignCheckbox={false}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
