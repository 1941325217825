import LocalizedStrings from 'react-localization';
import { withCommon } from '../../services/translation/commonTranslations';

const devicesStrings = new LocalizedStrings({
  en: {
    title: 'Clock in devices',
    manageLocationBreadcrumb: 'Manage Location',
    noData: 'No devices to display',
    addDeviceModal: {
      title: 'Add New Device',
      nameField: 'Device name',
      namePlaceholder: 'Name',
      nameRequired: 'Device name required',
      uniqueName: 'Device name must be unique'
    },
    editDeviceModal: {
      title: 'Edit New Device',
      nameField: 'Device name',
      namePlaceholder: 'Name',
      nameRequired: 'Device name required'
    },
    manageDevice: {
      title: 'Manage Device',
      detailsSection: {
        title: 'Device Details',
        name: 'Device Name',
        station: 'Linked Station',
        editDevice: 'Edit Device'
      },
      statusSection: {
        title: 'Device Status',
        pendingDesc: 'Your device is pending approval.',
        approvedDesc: 'Your device has been approved for use.',
        pendingUrlDesc: 'Go to the following URL on the device and enter the setup code to approve it:',
        approvedUrlDesc: 'Go to the following URL on the device to start using it:',
        setupCodeDesc: 'Setup Code:'
      },
      resetSection: {
        title: 'Reset Device',
        desc: 'Unregister the linked device so a new device can be re-registered.',
        confirmation: 'Are you sure you want to reset this device?'
      },
      deleteSection: {
        title: 'Delete Device',
        desc: 'Permanently disable clock ins from this device',
        confirmation: 'Are you sure you want to delete this device?'
      }
    },
    notAssigned: 'You do not have permission to view devices at this location.'
  }
});

export default withCommon<typeof devicesStrings>(devicesStrings);
