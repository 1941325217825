import LocalizedStrings from 'react-localization';
import { withCommon } from '../../services/translation/commonTranslations';

const errorsStrings = new LocalizedStrings({
  en: {
    title: 'Fatal error',
    apology:
      "We're really sorry, something has gone really wrong. If problems continue to occur please contact support and quote your error tracking code from below.",
    goHome: 'Go home',
    contactSupport: 'Contact support',
    message: 'Message: ',
    trackingId: 'Tracking ID: ',
    tryAgain: 'Try again',
    messages: { 
      wrongOrExpiredCode: "You didn't enter the expected verification code. Please try again.",
      unactivatedDevice: "This device is has been deactivated. Please contact a manager to have this device reset."
    }
  }
});

export default withCommon<typeof errorsStrings>(errorsStrings);
