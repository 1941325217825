import { useCallback } from 'react';
import { useVariable } from '../../services/backstage/BackstageProvider';

export const useHslBuilder = () => {
  const hueString = useVariable('hue');

  let hue: number;
  let saturation: number;
  let lightness: number;

  if (hueString) {
    hue = parseInt(hueString);
    saturation = 40;
    lightness = 95;
  } else {
    hue = 0;
    saturation = 0;
    lightness = 100;
  }

  const buildColorString = useCallback(
    (opacity: number) => {
      return `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
    },
    [hue, saturation, lightness]
  );

  return buildColorString;
};
