import React, { FC } from 'react';
import { TeamMemberCard } from './TeamMemberCard';
import { Feature } from '../../../../services/backstage/Feature';
import { TeamMemberPayCard } from './TeamMemberPayCard';
import { TeamMemberAvailabilityScheduleCard } from './TeamMemberAvailabilityScheduleCard';
import { ReadEmployee } from '../../../../services/gql/graphql';

interface OverviewSectionProps {
  editMemberCard?: boolean;
  editMemberPayCard?: boolean;
  userData: ReadEmployee;
}

export const overviewSectionId = 'overview-section';

export const OverviewSection: FC<OverviewSectionProps> = ({ userData, editMemberCard, editMemberPayCard }) => {
  return (
    <section id={overviewSectionId}>
      {!userData.isDeleted && (
        <div className="grid grid-cols-1 md:grid-cols-2 md:auto-rows-min gap-4">
          <div>
            <TeamMemberCard enableEdit={editMemberCard} employee={userData} />
          </div>
          <div>
            <Feature name="pay">
              <TeamMemberPayCard enableEdit={editMemberPayCard} employee={userData} />
            </Feature>
          </div>
          <div className="col-span-1 md:col-span-2">
            <Feature name="availabilitySchedule">
              <TeamMemberAvailabilityScheduleCard employee={userData} enableEdit={editMemberCard} />
            </Feature>
          </div>
        </div>
      )}
    </section>
  );
};
