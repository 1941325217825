import { IExceptionTelemetry } from '@microsoft/applicationinsights-common';
import { FC, useEffect, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { FiAlertTriangle } from 'react-icons/fi';
import { LargeTitle } from '../shared/text/Title';
import { useAppInsights } from '../../helpers/AppInsights';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router';
import { Loading } from '../shared/Loading';
import strings from './errors.translations';
import pageStrings from '../page/page.translations';
import { Button } from '../shared/button/Button';

export const CatastrophicError: FC<FallbackProps> = ({ children, error, resetErrorBoundary }) => {
  const [exception, setException] = useState<IExceptionTelemetry>();
  const appInsights = useAppInsights();
  const navigate = useNavigate();

  useEffect(() => {
    if (!error) return;
    const exception = {
      id: v4(),
      exception: error
    };
    setException(exception);
    appInsights.trackException(exception);
  }, [error, appInsights]);

  const reset = () => {
    resetErrorBoundary();
    setException(undefined);
  };

  const resetAndGoHome = () => {
    reset();
    navigate('/');
  };

  const resetAndSignout = () => {
    reset();
    navigate('/logout');
  };

  return (
    <div className="bg-primary-std min-h-screen px-6 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      {exception ? (
        <div className="max-w-3xl mx-auto">
          <main className="sm:flex flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left">
            <div className="text-red-400 w-20 h-20">
              <FiAlertTriangle className="w-20 h-20" />
            </div>
            <div className="sm:ml-6 flex flex-col items-center sm:items-start">
              <div className="sm:border-l sm:border-primary-std sm:pl-6">
                <LargeTitle className="text-red-500 mb-3">{strings.title}</LargeTitle>
                <p className="font-content">{strings.apology}</p>
                <span className="mt-1 text-primary-std text-sm">
                  <p className="mt-1 text-secondary-std">
                    {strings.message}
                    <code>{exception.exception?.message}</code>
                  </p>
                  <p className="mt-1 text-secondary-std">
                    {strings.trackingId}
                    <code>{exception.id}</code>
                  </p>
                </span>
              </div>
              <div className="mt-6 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Button danger onClick={resetAndGoHome}>
                  {strings.goHome}
                </Button>
                <Button danger tint>
                  {strings.contactSupport}
                </Button>
                <Button danger tint onClick={resetAndSignout}>
                  {pageStrings.nav.logout}
                </Button>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export const CatastrophicErrorBoundary: FC = ({ children }) => {
  return <ErrorBoundary FallbackComponent={CatastrophicError}>{children}</ErrorBoundary>;
};
