import * as React from 'react';
import { FiCalendar } from 'react-icons/fi';
import { Day, useLilius } from 'use-lilius';
import { format } from 'date-fns';
import { InputProps, withField } from '../form/Field';
import { DatePicker, DayLabels } from './DatePicker';
import { merge } from '../../../helpers/Utility';
import { Button } from '../button/Button';
import { Icon } from '../icons/Icon';
import { Modal } from '../modal/Modal';

const defaultLabels = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tues',
  Wednesday: 'Wed',
  Thursday: 'Thurs',
  Friday: 'Fri',
  Saturday: 'Sat'
};

interface DateInputProps extends InputProps<Date | undefined> {
  labels?: DayLabels;
}

export const DateInput: React.FC<DateInputProps> = ({ value, onChange, className, labels = defaultLabels }) => {
  const { calendar, inRange, isSelected, select, selected, setViewing, viewing, viewNextMonth, viewPreviousMonth } =
    useLilius({ weekStartsOn: Day.MONDAY });

  React.useEffect(() => {
    if (!value) {
      setViewing(new Date());
      return;
    }
    setViewing(value);
    select(value, true);
  }, [value, select, setViewing]);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <div className={merge(className, 'w-full flex relative')}>
      <Button onClick={() => setIsOpen(true)} className={'w-full flex items-center space-x-3'}>
        <Icon small Icon={FiCalendar} />
        {selected[0] ? (
          <span className="font-medium">
            <span className="sm:block hidden">{format(selected[0], 'EEEE do MMMM yyyy')}</span>
            <span className="sm:hidden block">{format(selected[0], 'E do MMM')}</span>
          </span>
        ) : (
          <span className="text-secondary-std">{"No selected"}</span>
        )}
      </Button>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="w-full pb-4 sm:w-sm">
          <DatePicker
            dismiss={() => setIsOpen(false)}
            calendar={calendar[0]}
            selected={selected}
            viewing={viewing}
            inRange={inRange}
            isSelected={isSelected}
            viewPreviousMonth={viewPreviousMonth}
            viewNextMonth={viewNextMonth}
            onDayClick={day => {
              onChange?.(day);
              setIsOpen(false);
            }}
            labels={labels}
          />
        </div>
      </Modal>
    </div>
  );
};

export const DateField = withField(DateInput, 'Optional');
