import React, { FC } from 'react';
import { CalendarCellProps } from '../../common/Calendar';
import { ViewRosterCalendarIcon } from '../../../../../components/shared/icons/ViewRosterCalendarIcon';
import strings from '../../../roster.translations';
import { ESSDesktopShiftChit } from './ESSDesktopShiftChit';
import { StaffCalendarContextObject } from '../../../rosterTypes';

export const StaffCalendarContent: FC<CalendarCellProps<StaffCalendarContextObject>> = ({ contextObject }) => {
  return (
    <div className="relative h-full w-full">
      <div className={`h-full flex items-center justify-center flex-row space-x-8 px-4`}>
        {contextObject?.id && (
          <div className="flex flex-col items-center text-gray-500">
            <div className="flex flex-row gap-3 items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <ViewRosterCalendarIcon />
                <p className="text-xs font-bold">{strings.roster.toUpperCase()}</p>
              </div>
              {contextObject.shift && <ESSDesktopShiftChit shift={contextObject.shift} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
