import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { EditLocationDetailsForm } from './EditLocationDetailsForm';
import { Modal } from '../../components/shared/modal/Modal';
import { Title } from '../../components/shared/text/Title';
import strings from './manageLocation.translations';
import { SubmissionErrorBoundary } from '../../components/errors/SubmissionErrorBoundary';
import { Location } from '../manageOrg/locations/LocationList';

interface LocationDetailsModalProps {
  open: boolean;
  onClose: () => void;
  location: Location
}

export const EditLocationDetailsModal: FC<LocationDetailsModalProps> = ({ open, onClose, location }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="relative m-8">
        <Title>{strings.editLocationDetails.editLabel}</Title>
        <SubmissionErrorBoundary>
          <EditLocationDetailsForm location={location} onComplete={onClose} />
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
