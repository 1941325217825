
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { useCallback } from 'react';
import { useAppInsights } from "../../helpers/AppInsights";
import { useFlag } from '../backstage/BackstageProvider';

export enum LoggerLevel {
  Log,
  Dir,
  Warn,
  Error
}

export const useLogger = () => {
  const appInsights = useAppInsights();
  const debug = useFlag('debug');

  const log = useCallback((loggable: any, logLevel: LoggerLevel = LoggerLevel.Log, logOnAppInsights: boolean = false) => {
    const getLogSeverityLevel = (level: LoggerLevel) => {
      switch (level) {
        case LoggerLevel.Log || LoggerLevel.Dir:
          return SeverityLevel.Information;
        case LoggerLevel.Warn:
          return SeverityLevel.Warning;
        case LoggerLevel.Error:
          return SeverityLevel.Error;
      }
    };
    
    if (debug) {
      switch (logLevel) {
        case LoggerLevel.Log:
          console.log(loggable);
          // TODO remove after mobile fix
          console.trace();
          break;
        case LoggerLevel.Dir:
          console.dir(loggable);
          break;
        case LoggerLevel.Warn:
          console.warn(loggable);
          break;
        case LoggerLevel.Error:
          console.error(loggable);
          break;
      }
    } else {
      if (logOnAppInsights) {
        appInsights.trackTrace({ message: loggable.toString(), severityLevel: getLogSeverityLevel(logLevel) });
      }
    }
  }, []);

  return { log };
}