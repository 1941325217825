import React, { FC, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { TeamMemberUpdateType, useTeamMemberService } from './TeamMemberService';
import { InfoChit, InfoChitImportance } from '../../../components/shared/InfoChit';
import { constructNameEN } from '../../../employee/employee-name-helper';
import { useLocationService } from '../../../services/location/LocationService';
import { dateToGQLFormat } from '../../../helpers/dateHelper';
import { isManagement, useAuthenticationService } from '../../../services/authentication/authentication-service';
import {
  GetMyOpenWorkRecordsQuery,
  GetOpenWorkRecordsQuery,
  ReadEmployee,
  useEndMyWorkTimeRecordMutation,
  useEndWorkTimeRecordMutation,
  useGetMyOpenWorkRecordsQuery,
  useGetOpenWorkRecordsQuery,
  useStartMyWorkTimeRecordMutation,
  useStartWorkTimeRecordMutation,
  WorkTimeDataSource
} from '../../../services/gql/graphql';
import { createSuccessToast } from '../../../services/notification/ToastService';
import { EmployeeAvatar } from '../../home/present/EmployeeAvatar';
import { EmployeeStatus } from '../../home/present/EmployeePresenceCard';
import strings from '../team.translations';
import { Button } from '../../../components/shared/button/Button';
import { Watch } from '../../../components/shared/icons/Watch';
import { RiSendPlaneFill } from 'react-icons/ri';
import { SubmissionErrorModal } from '../../../components/errors/SubmissionErrorModal';
import { TeamMemberClockModal } from './TeamMemberClockModal';
import { ClockInStatus } from '../../../services/clockIn/ClockService';
import { UpdateSelectedLocationsModal } from '../../shifts/UpdateSelectedLocationsModal';
import { FiMapPin } from 'react-icons/fi';

interface TeamMemberHeaderContentProps extends HTMLAttributes<HTMLDivElement> {
  employee: ReadEmployee;
  sendInfoCallback?: () => void;
  isMe?: boolean;
}

export const TeamMemberHeaderContent: FC<TeamMemberHeaderContentProps> = ({ employee, sendInfoCallback, isMe }) => {
  const [clockError, setClockError] = useState<Error>();
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [clockModalOpen, setClockModalOpen] = useState<boolean>(false);

  const [locationList] = useLocationService(state => [state.locationList]);
  const allowedLocations = locationList.filter(loc => {
    return !!employee.restaurants?.find(tmLoc => tmLoc.id === loc.id);
  });

  const [statusUpdateNeeded, updateComplete, forceUpdate] = useTeamMemberService(state => [
    state.updateNeeded === TeamMemberUpdateType.HEADER,
    state.updateComplete,
    state.forceUpdate
  ]);

  const handleError = useErrorHandler();
  const queryEndpoint = isMe ? useGetMyOpenWorkRecordsQuery : useGetOpenWorkRecordsQuery;
  const [{ data, error, fetching }, refetchOpenRecords] = queryEndpoint({
    variables: {
      args: {
        employeeId: isMe ? undefined : employee.identityId
      }
    },
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    ),
    requestPolicy: 'network-only'
  });

  if (error) {
    handleError(error);
  }

  useEffect(() => {
    if (statusUpdateNeeded) {
      refetchOpenRecords();
      updateComplete(TeamMemberUpdateType.HEADER);
    }
  }, [updateComplete, statusUpdateNeeded, refetchOpenRecords]);

  let onShift: boolean | undefined;
  let clockLocationId: number | undefined;

  if (data) {
    if (isMe) {
      const dataToRead = data as GetMyOpenWorkRecordsQuery;
      onShift = dataToRead.getMyOpenWorkTimeRecord && dataToRead.getMyOpenWorkTimeRecord.length > 0;
      if (onShift) {
        clockLocationId = dataToRead.getMyOpenWorkTimeRecord![0].locationId;
      }
    } else {
      const dataToRead = data as GetOpenWorkRecordsQuery;
      onShift = dataToRead.getOpenWorkTimeRecord && dataToRead.getOpenWorkTimeRecord.length > 0;
      if (onShift) {
        clockLocationId = dataToRead.getOpenWorkTimeRecord![0].locationId;
      }
    }
  }

  const startMutation = isMe ? useStartMyWorkTimeRecordMutation : useStartWorkTimeRecordMutation;
  const [startStatus, startRecord] = startMutation();
  const endMutation = isMe ? useEndMyWorkTimeRecordMutation : useEndWorkTimeRecordMutation;
  const [endStatus, endRecord] = endMutation();
  const clockLoading = startStatus.fetching || endStatus.fetching;

  const handleClock = async () => {
    if (allowedLocations.length > 1) {
      setClockModalOpen(true);
    } else {
      const locationId = allowedLocations[0].id;
      const error = await submitClock(locationId);

      if (!error) {
        createSuccessToast(
          onShift
            ? strings.clock.clockedOut.message(employee.firstName)
            : strings.clock.clockedIn.message(employee.firstName)
        );
      } else {
        setClockError(new Error(error));
        setErrorModalOpen(true);
      }
    }
  };

  const submitClock = async (locationId: number) => {
    let error: string | undefined;
    if (onShift) {
      const result = await endRecord({
        workTimeRecordEnd: {
          employeeId: !isMe ? employee.identityId : undefined,
          locationId: locationId,
          end: dateToGQLFormat(new Date()),
          endSource: WorkTimeDataSource.Override
        }
      });

      error = result.error?.message;
    } else {
      const result = await startRecord({
        workTimeRecordStart: {
          employeeId: !isMe ? employee.identityId : undefined,
          locationId: locationId,
          start: dateToGQLFormat(new Date()),
          startSource: WorkTimeDataSource.Override
        }
      });

      error = result.error?.message;
    }

    if (error === undefined) {
      onClockComplete();
    }

    return error;
  };

  const onClockComplete = () => {
    refetchOpenRecords({ requestPolicy: 'network-only' });
    forceUpdate(TeamMemberUpdateType.SHIFTS);
  };

  const [role] = useAuthenticationService(state => [state.role]);
  const isManager = isManagement(role);
  const deletedOrDeactivatedAccountString = employee.isDeleted
    ? strings.deletedAccount
    : employee.isDeactivated
    ? strings.deactivatedAccount
    : '';

  const memberName = constructNameEN(employee.firstName ?? '', employee.lastName ?? '');

  const [locations] = useLocationService(state => [state.locationList]);

  const [showUpdateLocationModal, setShowUpdateLocationModal] = useState<boolean>(false);
  return (
    <>
      <div className={'flex flex-col md:flex-row justify-between lg:relative'}>
        <div className="flex flex-row gap-8 items-center text-4xl font-bold">
          {!employee.isDeleted && (
            <EmployeeAvatar
              employee={{
                firstName: '',
                lastName: '',
                status: onShift ? EmployeeStatus.ON_SHIFT : EmployeeStatus.NOT_ON_SHIFT,
                id: ''
              }}
              fetching={fetching}
            />
          )}
          <div>
            <p>{isMe && !isManager ? strings.myProfileTitle : memberName}</p>
            {deletedOrDeactivatedAccountString && (
              <InfoChit importance={InfoChitImportance.HIGH}>
                <p className="p-2 leading-4 mt-0.5 text-sm">{deletedOrDeactivatedAccountString}</p>
              </InfoChit>
            )}
          </div>
        </div>
        <div className="lg:absolute bottom-0 right-0">
          <div className="flex flex-col md:flex-row gap-2 md:gap-5 ">
            {locations?.length > 1 && (
              <Button
                onClick={() => setShowUpdateLocationModal(true)}
                primary
                className="flex items-center justify-center w-full gap-2 md:w-max self-center mt-2 md:mt-0"
              >
                <FiMapPin className="w-4 h-6" />
                <span>{strings.selectLoc}</span>
              </Button>
            )}
            {isManager && !deletedOrDeactivatedAccountString && (
              <Button
                loading={fetching || clockLoading}
                onClick={handleClock}
                primary
                className="flex items-center justify-center w-full gap-2 md:w-max self-center mt-2 md:mt-0"
              >
                <Watch className="w-4 h-6" />
                {onShift
                  ? strings.clock.clockPersonOut(employee.firstName)
                  : strings.clock.clockPersonIn(employee.firstName)}
              </Button>
            )}
            {sendInfoCallback && !deletedOrDeactivatedAccountString && (
              <Button
                className="flex justify-center w-full md:w-max self-center mt-2 md:mt-0"
                secondary
                onClick={sendInfoCallback}
              >
                <RiSendPlaneFill className="w-4 h-6 mr-2" />
                {strings.onboarding.button}
              </Button>
            )}
          </div>
        </div>

        <TeamMemberClockModal
          open={clockModalOpen}
          onClose={() => setClockModalOpen(false)}
          onSubmit={submitClock}
          onSuccess={onClockComplete}
          loading={clockLoading}
          clockState={onShift ? ClockInStatus.In : ClockInStatus.Out}
          alreadyClockedLocation={clockLocationId}
          locationList={allowedLocations}
        />
        <UpdateSelectedLocationsModal
          onCompleteCallback={() => {
            setShowUpdateLocationModal(false);
          }}
          open={showUpdateLocationModal}
          onClose={() => setShowUpdateLocationModal(false)}
        />
        <SubmissionErrorModal error={clockError!} onClose={() => setErrorModalOpen(false)} open={errorModalOpen} />
      </div>
    </>
  );
};
