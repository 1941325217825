import { FC, HTMLAttributes } from 'react';
import { FiCheck, FiX, FiAlertTriangle } from 'react-icons/fi';
import { WorkTimeDataRisk } from '../../../services/gql/graphql';
import { Icon } from './Icon';

interface RiskIconProps extends HTMLAttributes<HTMLDivElement> {
  risk?: WorkTimeDataRisk;
}

export const RiskIcon: FC<RiskIconProps> = ({ risk, className }) => {
  let icon: JSX.Element;

  switch (risk) {
    case WorkTimeDataRisk.High:
      icon = <Icon Icon={FiAlertTriangle} theme='red'/>;
      break;
    case WorkTimeDataRisk.Medium:
      icon = <Icon Icon={FiAlertTriangle} theme='amber'/>;
      break;
    case WorkTimeDataRisk.Low:
      icon = <Icon Icon={FiCheck} theme='primary'/>;
      break;
    default:
      icon = <Icon Icon={FiX}/>;
      break;
  }

  return icon;
};
