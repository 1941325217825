import React, { FC } from 'react';
import strings from './myData.translations';
import { TSTitleCard } from '../../components/shared/card/Card';
import { RiFileUserFill } from 'react-icons/ri';

interface DeleteDataCardProps {}

export const deleteDataCardId = 'delete-date-card';

export const DeleteDataCard: FC<DeleteDataCardProps> = () => {
  return (
    <TSTitleCard
      id={deleteDataCardId}
      title={strings.deleteDataCard.title}
      descriptor={strings.deleteDataCard.gdpr}
      icon={RiFileUserFill}
    >
      <div>
        <p className="leading-5">{strings.deleteDataCard.description}</p>
      </div>
    </TSTitleCard>
  );
};
