import LocalizedStrings from 'react-localization';
import { withCommon } from '../../../services/translation/commonTranslations';

const tableStrings = new LocalizedStrings({
  en: {
    nothingToShow: 'Nothing to show',
    filter: 'Filter',
    clearFilter: 'Clear filter',
    verboseClockMethods: {
      Location: 'Location',
      Manual: 'Manual',
      ManualLate: 'Forgotten',
      Nfc: 'NFC',
      None: 'None',
      Override: 'Manager',
      Device: 'Device'
    }
  }
});

export default withCommon<typeof tableStrings>(tableStrings);
