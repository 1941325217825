import React, { FC } from 'react';
import { EmployeeWorkTimeRecord } from '../../../services/gql/graphql';
import strings from '../home.translations';
import { differenceInDays, format, parseJSON } from 'date-fns';

interface UnverifiedClocksTimesProps {
  shift: EmployeeWorkTimeRecord;
}

export const UnverifiedClocksTimes: FC<UnverifiedClocksTimesProps> = ({ shift }) => {
  const startDate = new Date(shift.start?.dateTime);
  const endDate = shift.end ? new Date(shift.end?.dateTime) : undefined;
  const startTime = format(startDate, 'HH:mm');
  const endTime = endDate ? format(endDate, 'HH:mm') : strings.unverifiedClocks.now;

  let start = parseJSON(shift.start?.dateTime).setHours(0);
  let end = parseJSON(shift.end?.dateTime).setHours(0);
  let current = parseJSON(new Date());

  const startDif = differenceInDays(current, start);
  const endDif = differenceInDays(current, end);

  const getDateFormat = (difference: number, time: number) => {
    if (difference === 0) {
      return strings.unverifiedClocks.today;
    } else {
      return format(time, 'MMM do');
    }
  };

  if (!end || startDif === endDif) {
    return (
      <div>
        <div>{getDateFormat(startDif, start)}</div>
        <div>{`${startTime} - ${endTime}`}</div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-flow-col whitespace-nowrap gap-x-2" style={{ gridTemplateColumns: 'auto auto 1fr' }}>
        <div>{getDateFormat(startDif, start)}</div>
        <div>{`${startTime}`}</div>
        <div className={'items-center row-span-2 flex'}>{'-'}</div>
        <div>{getDateFormat(endDif, end)}</div>
        <div>{`${endTime}`}</div>
      </div>
    );
  }
};
