import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Loading } from '../../../components/shared/Loading';
import { useAuthenticationService } from '../authentication-service';

interface LogoutProps {}

export const Logout: FC<LogoutProps> = () => {
  const [userManager, user] = useAuthenticationService(state => [state.userManager, state.user]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!user) {
        navigate('/');
        return;
      }

      (await userManager).signoutRedirect({ id_token_hint: user.id_token });
    })();
  }, [userManager, navigate, user]);

  return <Loading />;
};
