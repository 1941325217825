import { FC, HTMLAttributes } from 'react';

interface TimeshiftLogoProps extends HTMLAttributes<HTMLDivElement> {}

export const TimeshiftLogo: FC<TimeshiftLogoProps> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 141 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.67449 0.952637V3.78988H8.51173V3.88067C3.67707 4.56161 0 8.73804 0 13.7316C0 19.2018 4.4488 23.6733 9.9417 23.6733C15.4346 23.6733 19.8834 19.2245 19.8834 13.7316C19.8834 12.4605 19.611 11.2575 19.1798 10.1907L16.5922 11.2802C16.91 12.0973 17.0462 12.8918 17.0462 13.777C17.0462 17.7264 13.8911 20.8587 9.9644 20.8587C6.03765 20.8587 2.85994 17.7037 2.85994 13.777C2.85994 9.85023 6.01496 6.67252 9.9644 6.67252C10.8042 6.67252 11.644 6.80871 12.4612 7.12648L13.4145 4.44812C12.7563 4.22114 12.0753 4.10765 11.3717 3.99416V3.81258H14.2089V0.975334L5.67449 0.952637Z"
        fill="#369343"
      />
      <path
        d="M20.8594 3.10889C20.8594 3.10889 10.5092 11.0759 9.9644 11.6206C9.41965 12.1881 9.41965 13.0052 9.9644 13.5726C10.5092 14.1401 11.349 14.1401 11.9164 13.5726C12.4839 13.0052 20.8821 3.10889 20.8594 3.10889Z"
        fill="#3182BD"
      />
      <g clipPath="url(#clip0)">
        <path d="M29.1496 7.6216H24.2509V4.74536H37.5912V7.6216H32.6925V19.9981H29.1496V7.6216Z" fill="#3182BD" />
        <path
          d="M39.1878 6.09627C38.7941 5.73311 38.5973 5.28279 38.5973 4.74531C38.5973 4.20783 38.7941 3.75751 39.1878 3.39435C39.5814 3.03119 40.0917 2.84961 40.7186 2.84961C41.3455 2.84961 41.8558 3.02393 42.2495 3.37256C42.6431 3.7212 42.8399 4.15699 42.8399 4.67994C42.8399 5.24647 42.6431 5.71132 42.2495 6.08901C41.8558 6.45943 41.3455 6.64101 40.7186 6.64101C40.0917 6.64101 39.5814 6.45943 39.1878 6.09627ZM39.0128 8.27524H42.4244V19.9981H39.0128V8.27524Z"
          fill="#3182BD"
        />
        <path
          d="M64.1698 9.39387C65.0373 10.2582 65.4746 11.5583 65.4746 13.2797V19.9909H62.063V13.8026C62.063 12.873 61.8662 12.1829 61.4871 11.7254C61.1008 11.2678 60.5467 11.0354 59.8323 11.0354C59.0305 11.0354 58.3962 11.2968 57.9297 11.8053C57.4631 12.3209 57.2299 13.0909 57.2299 14.1077V19.9909H53.8182V13.8026C53.8182 11.9578 53.0747 11.0354 51.5876 11.0354C50.8003 11.0354 50.1733 11.2968 49.7068 11.8053C49.2403 12.3209 49.007 13.0909 49.007 14.1077V19.9909H45.5954V8.27533H48.8539V9.62629C49.2913 9.13239 49.8234 8.7547 50.4649 8.49323C51.0992 8.23175 51.7917 8.10101 52.5571 8.10101C53.3882 8.10101 54.139 8.26807 54.8097 8.58765C55.4803 8.91449 56.0198 9.39387 56.428 10.0112C56.9091 9.40113 57.5215 8.92902 58.2577 8.59491C58.994 8.2608 59.7959 8.09375 60.6707 8.09375C62.1359 8.10101 63.3023 8.53681 64.1698 9.39387Z"
          fill="#3182BD"
        />
        <path
          d="M80.1054 15.0953H71.2046C71.365 15.8216 71.744 16.3954 72.3418 16.8167C72.9395 17.238 73.6831 17.4486 74.5725 17.4486C75.1848 17.4486 75.7242 17.3614 76.2054 17.1726C76.6792 16.991 77.1166 16.7005 77.5248 16.3083L79.34 18.2693C78.2319 19.5331 76.6136 20.1651 74.485 20.1651C73.1582 20.1651 71.9846 19.9108 70.964 19.3879C69.9434 18.8722 69.1561 18.1604 68.6021 17.2452C68.0481 16.3301 67.7711 15.2914 67.7711 14.1293C67.7711 12.9817 68.0481 11.9503 68.5875 11.0206C69.1343 10.1127 69.8851 9.38642 70.8401 8.87073C71.795 8.35504 72.8666 8.10083 74.0476 8.10083C75.1994 8.10083 76.2418 8.34778 77.1749 8.84168C78.108 9.33558 78.837 10.0401 79.3692 10.9625C79.9013 11.885 80.1637 12.9599 80.1637 14.1802C80.171 14.2237 80.1492 14.5288 80.1054 15.0953ZM72.1231 11.3257C71.5982 11.7615 71.2775 12.3571 71.1608 13.1125H76.9562C76.8396 12.3716 76.5188 11.7833 75.994 11.3402C75.4691 10.8972 74.8276 10.672 74.0695 10.672C73.2967 10.672 72.648 10.8899 72.1231 11.3257Z"
          fill="#3182BD"
        />
        <path
          d="M84.2094 19.7657C83.0795 19.4389 82.1756 19.0103 81.4831 18.4947L82.6859 15.8363C83.342 16.3157 84.122 16.7006 85.0259 16.9912C85.9298 17.2817 86.8338 17.427 87.7377 17.427C88.7437 17.427 89.4873 17.2817 89.9684 16.9839C90.4495 16.6861 90.6901 16.2866 90.6901 15.7927C90.6901 15.4296 90.5516 15.1245 90.2673 14.8848C89.983 14.6451 89.6185 14.4563 89.1738 14.311C88.7291 14.1658 88.1241 14.006 87.3732 13.8317C86.2068 13.5557 85.2519 13.2797 84.5083 13.0037C83.7648 12.7277 83.1233 12.2846 82.5984 11.6745C82.0662 11.0644 81.7965 10.2509 81.7965 9.23404C81.7965 8.34792 82.0371 7.54171 82.5182 6.82991C82.9993 6.11085 83.721 5.54432 84.6979 5.11579C85.6674 4.69452 86.8556 4.48389 88.2553 4.48389C89.2321 4.48389 90.1871 4.6001 91.1202 4.83252C92.0533 5.06495 92.8697 5.39905 93.5695 5.83485L92.4761 8.51498C91.0619 7.71602 89.6476 7.31655 88.2334 7.31655C87.242 7.31655 86.5057 7.47634 86.0392 7.79592C85.5653 8.1155 85.3248 8.53677 85.3248 9.05972C85.3248 9.58267 85.6018 9.97489 86.1412 10.2218C86.688 10.4761 87.519 10.7303 88.6489 10.9772C89.8153 11.2532 90.7703 11.5292 91.5138 11.8052C92.2574 12.0812 92.8916 12.517 93.4238 13.1126C93.9559 13.7082 94.2183 14.5144 94.2183 15.5313C94.2183 16.4028 93.9778 17.1945 93.4894 17.9136C93.0009 18.6327 92.272 19.2065 91.2951 19.6277C90.3183 20.049 89.1301 20.2596 87.7304 20.2596C86.5057 20.2596 85.3321 20.0926 84.2094 19.7657Z"
          fill="#369343"
        />
        <path
          d="M107.078 9.40824C107.974 10.2798 108.419 11.5727 108.419 13.2868V19.998H105.007V13.8098C105.007 12.8801 104.803 12.1901 104.395 11.7325C103.987 11.2749 103.396 11.0425 102.624 11.0425C101.763 11.0425 101.078 11.3112 100.568 11.8414C100.058 12.3716 99.8024 13.1561 99.8024 14.202V19.998H96.3908V3.83008H99.8024V9.49539C100.254 9.04507 100.801 8.69644 101.443 8.46402C102.084 8.22433 102.784 8.10086 103.542 8.10086C105.007 8.10086 106.181 8.53665 107.078 9.40824Z"
          fill="#369343"
        />
        <path
          d="M111.685 6.09627C111.291 5.73311 111.094 5.28279 111.094 4.74531C111.094 4.20783 111.291 3.75751 111.685 3.39435C112.078 3.03119 112.589 2.84961 113.216 2.84961C113.843 2.84961 114.353 3.02393 114.746 3.37256C115.14 3.7212 115.337 4.15699 115.337 4.67994C115.337 5.24647 115.14 5.71132 114.746 6.08901C114.353 6.45943 113.843 6.64101 113.216 6.64101C112.589 6.64101 112.078 6.45943 111.685 6.09627ZM111.51 8.27524H114.921V19.9981H111.51V8.27524Z"
          fill="#369343"
        />
        <path
          d="M121.832 8.53665H124.85V11.1514H121.92V19.998H118.508V11.1514H116.693V8.53665H118.508V8.0137C118.508 6.67726 118.902 5.61683 119.696 4.8324C120.491 4.04798 121.606 3.65576 123.05 3.65576C123.56 3.65576 124.048 3.71387 124.508 3.82282C124.967 3.93176 125.353 4.09156 125.659 4.29493L124.763 6.75716C124.369 6.48116 123.91 6.34316 123.385 6.34316C122.35 6.34316 121.832 6.90969 121.832 8.04275V8.53665Z"
          fill="#369343"
        />
        <path
          d="M133.831 19.4317C133.496 19.6786 133.08 19.8675 132.592 19.9837C132.104 20.1071 131.586 20.1725 131.046 20.1725C129.647 20.1725 128.568 19.8166 127.795 19.1048C127.03 18.393 126.651 17.3471 126.651 15.9671V11.1516H124.836V8.53682H126.651V5.68237H130.062V8.53682H132.993V11.1516H130.062V15.9235C130.062 16.4174 130.186 16.8024 130.441 17.0639C130.697 17.3326 131.054 17.4706 131.528 17.4706C132.067 17.4706 132.526 17.3253 132.905 17.0348L133.831 19.4317Z"
          fill="#369343"
        />
      </g>
      <g clipPath="url(#clip1)">
        <path
          d="M136.259 1.63354V2.4008H135.281V4.93823H134.261V2.4008H133.289V1.63354H136.259ZM138.674 3.17354C138.704 3.23931 138.734 3.30507 138.764 3.36535C138.788 3.43112 138.811 3.49688 138.829 3.56265C138.853 3.49688 138.877 3.43112 138.907 3.36535C138.937 3.29959 138.966 3.23382 139.002 3.17354L139.795 1.77604C139.837 1.70479 139.879 1.66643 139.927 1.65547C139.974 1.64451 140.04 1.63354 140.123 1.63354H141V4.93823H140.105V3.28315L140.171 2.74059L139.259 4.39567C139.181 4.53268 139.068 4.59845 138.913 4.59845H138.77C138.609 4.59845 138.489 4.53268 138.418 4.39567L137.517 2.76799L137.583 3.28315V4.93823H136.688V1.63354H137.565C137.654 1.63354 137.72 1.63903 137.762 1.64999C137.809 1.66095 137.851 1.70479 137.887 1.77604L138.674 3.17354Z"
          fill="#369343"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="109.58" height="17.41" fill="white" transform="translate(24.2509 2.84961)" />
        </clipPath>
        <clipPath id="clip1">
          <rect width="7.71094" height="2.91509" fill="white" transform="translate(133.289 1.63354)" />
        </clipPath>
      </defs>
    </svg>
  );
};
