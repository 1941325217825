import React, { FC } from 'react';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { HomeHeader } from './HomeHeader';
import { HomeContentsManagers } from './HomeContentsManagers';
import { HomeQuickLinks } from './HomeQuickLinks';
import { PageContainer } from '../../components/page/PageContainer';
import { ClockProvider } from '../../services/clockIn/ClockService';
import { isManagement, isStaff, Role, useAuthenticationService } from '../../services/authentication/authentication-service';
import { SuperAdminHomePage } from './SuperAdminHomePage';
import { BackgroundStyle } from '../../components/page/PageBackground';
import { HomeContentsStaff } from './HomeContentsStaff';

interface HomePageProps {}

export const HomePage: FC<HomePageProps> = () => {
  const [role] = useAuthenticationService(state => [state.role]);

  if (role === Role.SuperAdmin) {
    return <SuperAdminHomePage />;
  }

  return (
    <ClockProvider>
      <PageContainer customHeader={<HomeHeader />} lockHeader backgroundStyle={BackgroundStyle.VIVID}>
        <PageSizer className="relative flex-col md:flex-row flex gap-5" mode={SizerMode.PAGE}>
            <div className="md:hidden w-full">
              <HomeQuickLinks />
            </div>
            {isStaff(role) ? <div className="flex-1 overflow-x-hidden"><HomeContentsStaff /></div> : undefined}
            {isManagement(role) ? <div className="flex-1 overflow-x-hidden"><HomeContentsManagers /></div> : undefined}
            <div className="w-80 hidden md:block"></div>
        </PageSizer>
      </PageContainer>
    </ClockProvider>
  );
};
