import React, { FC, HTMLAttributes } from 'react';
import { CardEditDetails, CardHeader } from './CardHeader';
import { IconType } from 'react-icons';
import { merge } from '../../../helpers/Utility';
import { Icon, Theme } from '../icons/Icon';
import { HiOutlineChevronRight } from 'react-icons/hi';
import EllipsisTooltip from 'ellipsis-tooltip-react-chan';

interface TSTitleCardProps extends HTMLAttributes<HTMLDivElement> {
  descriptor?: string;
  title: string;
  icon?: IconType;
  smallContent?: JSX.Element;
  largeContent?: JSX.Element;
  editDetails?: CardEditDetails;
  theme?: Theme;
}

interface TSCardProps extends HTMLAttributes<HTMLDivElement> {}

const commonCardStyling = 'bg-primary-std border-primary-std rounded-lg border sm:shadow-sm';

export const TSTitleCard: FC<TSTitleCardProps> = ({
  descriptor,
  title,
  icon,
  smallContent,
  largeContent,
  editDetails,
  theme,
  children,
  className,
  ...props
}) => {
  return (
    <div className={merge(commonCardStyling, 'p-2', className)} {...props}>
      <div className={merge('flex flex-col', largeContent && 'gap-4')}>
        <CardHeader
          title={title}
          descriptor={descriptor}
          icon={icon}
          smallContent={smallContent}
          largeContent={largeContent}
          editDetails={editDetails}
          theme={theme}
        />
        <div className="lg:hidden mx-4">{largeContent}</div>
      </div>
      <div className="border-t m-4 pt-4">{children}</div>
    </div>
  );
};

export const TSCard: FC<TSCardProps> = ({ children, className, ...props }) => {
  return (
    <div className={merge(commonCardStyling, className)} {...props}>
      {children}
    </div>
  );
};

interface TSCardChevronRightProps {
  onClick: () => void;
  icon?: JSX.Element;
  title: string;
  description?: string;
  largeHeader?: boolean;
}
export const TSCardChevronRight: FC<TSCardChevronRightProps> = ({ onClick, icon, title, description, largeHeader }) => {
  return (
    <TSCard className="pl-6 pr-3 py-3 hover:cursor-pointer" onClick={onClick}>
      <div className="flex flex-row items-center">
        {icon}
        <div style={{ maxWidth: 'calc(100% - 35px - 40px' }} className="px-4 flex w-full flex-col">
          <EllipsisTooltip
            options={{
              place: 'bottom',
              delayShow: 500
            }}
          >
            <span className={largeHeader ? 'text-xl font-extrabold tracking-tight sm:text-2xl' : 'font-semibold'}>
              {title}
            </span>
          </EllipsisTooltip>
          <p>{description}</p>
        </div>
        <Icon className="h-[35px] w-[35px]" Icon={HiOutlineChevronRight} />
      </div>
    </TSCard>
  );
};
