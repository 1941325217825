import LocalizedStrings from 'react-localization';
import { withCommon } from '../../../services/translation/commonTranslations';

const presentStrings = new LocalizedStrings({
  en: {
    WhosOn: 'Who’s on',
    staffNotClockedIn: (staffName: string) => `${staffName} has not clocked in, are they here?`,
    nobody: 'Nobody has clocked in recently.',
    status: {
      present: 'On shift',
      notOnShift: 'Not on shift',
      justNow: 'Just now',
      clockedOutMinutes: (minutes: number) => `${minutes} min${minutes === 1 ? '' : 's'} ago`,
      clockedOutHours: (hours: number) => `${hours} hr${hours === 1 ? '' : 's'} ago`
    }
  }
});

export default withCommon<typeof presentStrings>(presentStrings);
