import LocalizedStrings from 'react-localization';
import { withCommon } from '../../../services/translation/commonTranslations';

const textStrings = new LocalizedStrings({
  en: {
    live: 'Live',
    fullStop: '●'
  }
});

export default withCommon<typeof textStrings>(textStrings);
