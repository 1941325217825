import LocalizedStrings from 'react-localization';
import { constructNameEN } from '../../employee/employee-name-helper';
import { withCommon } from '../../services/translation/commonTranslations';

const teamStrings = new LocalizedStrings({
  en: {
    teamPageTitle: 'Team Members',
    employeeName: constructNameEN,
    teamMemberTitle: 'Team Member',
    myProfileTitle: 'My Profile',
    cardTitle: 'Personal Information',
    deactivatedAccount: 'This account is deactivated',
    deletedAccount: 'This account is deleted',
    deactivatedUser: (firstName: string, lastName: string) => `${constructNameEN(firstName, lastName)} - Deactivated`,
    showDeletedUsers: 'Show deleted users',
    showDeactivatedUsers: 'Show deactivated users',
    role: 'Role',
    locations: 'Locations',
    location: 'Location',
    locationText: (names: string[]) => `${names.length} locations`,
    addTeamMenu: {
      button: 'Add your team',
      addATeamMember: 'Add a team member',
      addYourTeam: 'Add your team',
      importFromSpreadsheet: 'Import from spreadsheet',
      addTeamMember: 'Add team member'
    },
    addTeamMemberForm: {
      firstName: 'First name',
      firstNamePlaceholder: 'Jane',
      uniqueValsRequired: (field: string) => `${field} must be unique`,
      uniqueValidValsRequired: (field: string) => `${field} must be unique and valid`,
      lastName: 'Last name',
      lastNamePlaceholder: 'Doe',
      contactInformation: 'Contact information',
      contactInformationPlaceholder: 'Please choose a type of contact information',
      email: 'Email',
      emailPlaceholder: 'Email: ',
      invalidEmail: 'Please enter a valid email address',
      invalidPhone: 'Please enter a valid phone number',
      repeatEmail: 'This email address is already being used by another account',
      repeatPhone: 'This phone number is already being used by another account',
      phoneNumber: 'Phone',
      phoneNumberPlaceholder: 'Phone: ',
      role: 'Role',
      admin: 'Admin',
      adminDescription: 'Access to everything including billing.',
      manager: 'Manager',
      managerDescription: 'Manage day-to-day activities & staff.',
      staff: 'Staff',
      staffDescription: 'A basic staff account to clock-in and out.',
      sendAccountInformation: 'You can always do this later if you want to set them up first and invite them later.',
      toggleSendAccountInformation: 'Send user account information upon creation?',
      switchToAddMultiple: 'Switch to add multiple people?',
      errorWarning: 'An Error has occurred: ',
      hqManagerDescription: 'HQ access to activities & staff.', //TODO: CRREATE BETTER TEXT
      regionManagerDescription: 'Manage staff in multiple centers.', //TODO: CRREATE BETTER TEXT
      areaManagerDescription: 'Manage staff in multiple centers.', //TODO: CRREATE BETTER TEXT
      supervisorDescription: 'Supervises', //TODO: CRREATE BETTER TEXT
      hqManager: 'HQ Manager',
      regionManager: 'Region Manager',
      areaManager: 'Area Manager',
      supervisor: 'Supervisor',
      noOptionsString: 'There are no options to select',
      superAdmin: 'Super Admin',
      superAdminDescription: 'This can do everything'
    },
    onboarding: {
      button: 'Send login information',
      allSpiel: (count: number) =>
        `We can send your staff all the information they need to get logged in and started with TimeShift. This will message ${count} ${
          count === 1 ? 'person' : 'people'
        }. Do you want to do this now?`,
      individualSpiel: (firstName: string) =>
        `We can send ${firstName} all the information they need to get logged in and start with TimeShift. Do you want to do this now?`,
      message: (firstName: string, link: string) =>
        `Hey ${firstName}, your TimeShift account is active and ready to go. Just tap on the link and you can get started. ${link}`,
      send: 'Send'
    },
    clock: {
      clockPersonIn: (firstName: string) => `Clock ${firstName} in`,
      clockPersonOut: (firstName: string) => `Clock ${firstName} out`,
      clockedIn: {
        title: 'Clocked in',
        message: (firstName: string) => `${firstName} was successfully clocked in!`
      },
      clockedOut: {
        title: 'Clocked out',
        message: (firstName: string) => `${firstName} was successfully clocked out!`
      },
      error: {
        title: (clockIn: boolean) => `${clockIn ? 'Clock in' : 'Clock out'} error`,
        message: 'Something went wrong. Please try again.'
      },
      selectLocation: 'Select location'
    },
    editTeamMember: {
      fistNameRequired: 'You must provide a first name',
      lastNameRequired: 'You must provide a last name',
      phoneNumberOrEmailRequired: 'You must provide a phone number or email',
      editLabel: 'Edit personal information',
      editEmployeeDetails: `Edit employee details`,
      breadcrumbEditEmployeeDetails: `Edit Details`,
      unassignedFromClockedLocation: 'You may not remove a user from a location where they are currently clocked in.'
    },
    memberPayCard: {
      cardTitle: 'Pay Information',
      editLabel: 'Edit pay information',
      hourlyRate: 'Hourly pay rate',
      hourlyRateValidation: 'You must provide a valid pay rate',
      noPaySet: 'No pay rate set'
    },
    dangerCard: {
      cardTitle: 'Danger zone',
      deactivateAccount: 'Deactivate account',
      deactivateDescription:
        'This will prevent login & use but still will keep them in your records. You will still be charged for this user.',
      deactivate: 'Deactivate',
      deactivated: 'Deactivated',
      deactivateModalConfirmation: 'Are you sure you want to deactivate this account?',
      reactivateModalConfirmation: 'Are you sure you want to reactivate this account?',
      reactivateAccount: 'Reactivate account',
      reactivateDescription:
        'This will reenable login & use of this account. You are still being charged for this user.',
      reactivate: 'Reactivate',
      deleteAccount: 'Delete account',
      deleteModalConfirmation: 'Are you sure you want to delete this account?',
      deleteDescription:
        'This will delete this user and all their data, this cannot be restored. You will stop being charged for this user.',
      thisWill: 'This will:',
      willDescriptionPartOne: 'Stop {name} from logging in & clocking in and out',
      willDescriptionPartTwo: 'Clock {name} out if they are currently clocked in',
      willDescriptionPartThree: 'Delete any personal information about {name}',
      thisWont: "This won't",
      wontDescriptionPartOne: 'Delete any personal information about {name}',
      wontDescriptionPartTwo: 'Delete any clock-in-out records for {name}',
      stillCharged: 'You will still be charged for this account',
      stillReactivate: 'You can reactivate this account at any time on this page',
      stopCharged: 'You will stop being charged for this account',
      cantRecover: 'You will not be able to recover this account if you delete it'
    },
    teamMembersModal: {
      addYourTeam: 'Add your team',
      add: 'Add',
      submitButton: 'Submit',
      invite: 'Invite',
      telephoneValidation: 'Please enter a telephone number',
      emailValidation: 'Please enter an email address',
      contactValidation: 'Please pick a contact type'
    },
    scheduleCard: {
      cardTitle: 'Availability Schedule',
      notAvailable: 'Not available',
      editLabel: 'Edit availability schedule'
    },
    editSchedule: {
      breadcrumb: 'Set Schedule',
      editTitle: 'Set availability schedule',
      availableFrom: 'Available From',
      availableUntil: 'Available Until',
      confirm: 'Confirm',
      modalText: (firstName: string) => `Are you sure you want to update ${firstName}’s schedule?

      Shifts on existing rosters will not be impacted, but going forward, shifts will only be able to be scheduled within these times.`
    },
    verticalTabMenu: {
      overviewLabel: 'Overview',
      shiftsLabel: 'Shifts',
      skillsLabel: 'Skills',
      manageAccountLabel: 'Manage Account'
    },
    selectLoc: 'Select Locations'
  }
});

export default withCommon<typeof teamStrings>(teamStrings);
