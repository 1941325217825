  /*
   * These seems like a bit of an arbitrary choice, but there is method to the madness.
   *
   * Think of hue as being how far around the color wheel you are. It's measure in degrees, so adding 360 to the hue gets
   * the same color again.
   * 
   * 166 is the start hue, because it gives us a nice teal green which fits the timeshift color scheme.
   * 
   * To generate a set of colors, we need to increment the hue to move around the color wheel.
   * 
   * This increment is a fixed value, because we want the color sequence to stay the same if a station is added/removed.
   * If we use a variable increment based on the number of stations, then we'll change all the colors if we 
   * change the station list at all.
   * 
   * We choose 49 as an increment for several reasons:
   * - It's vaguely close to being a 1/6 of the way around the color wheel, so we'll get a basic progression of
   * green/blue/purple/red/orange/yellow for the first six colors.
   * - It's far enough away from being 1/6 of the color wheel, so the next set of six colors will be a
   * suitably different green/blue/etc.
   * - It's actually coprime to 360, so will generate 360 different colors. Although if anyone has 360 stations
   * here, they're 100% using the system wrong.
   */
const STARTING_HUE = 166;
const HUE_INCREMENT = 49;

export const generateColors = (count: number) => {
  const colors: string[] = [];
  
  let currentHue = STARTING_HUE;

  const createColor = (hue: number) => hsl(currentHue, 72, 64);

  for(let i = 0; i < count; i++) {
    let newColor = createColor(currentHue);

    colors.push(newColor);

    currentHue += HUE_INCREMENT;
  }

  return colors;
}

export const sliderColor = (
  stationIdx: number = 0,
  stationCount: number = 1,
  positionIdx: number = 0,
  positionCount: number = 1,
  lightnessTweak: number = 0
) => {
  const hue = 166 + 60 * stationIdx;
  const saturation = 72 * (1 - positionIdx / (1.5 * positionCount));
  const color = hsl(hue, saturation, 64 + lightnessTweak);
  return color;
};

export const hsl = (hue: number, saturation: number, lightness: number) => {
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};