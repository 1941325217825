import { FC, HTMLAttributes } from 'react';

export const DeleteRosterCalendarIcon: FC<HTMLAttributes<SVGSVGElement>> = props => (
  <svg {...props} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 15.6L15 5.60001M5 5.60001L15 15.6"
      stroke="#828282"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
