import LocalizedStrings from 'react-localization';
import { constructNameEN } from '../../employee/employee-name-helper';
import { withCommon } from '../../services/translation/commonTranslations';

const distance = (distanceInKm: number) =>
  distanceInKm < 1 ? distanceInKm * 1000 + ' metres' : distanceInKm.toFixed(1) + ' km';

const shiftStrings = new LocalizedStrings({
  en: {
    allShifts: 'All Shifts',
    timeAndCost: 'Time and cost report',
    myShifts: 'My Shifts',
    columns: {
      name: 'Name',
      date: 'Date',
      started: 'Started',
      ended: 'Ended',
      duration: 'Duration',
      estimatedCost: 'Est. cost',
      clockIn: 'Clock in method',
      clockOut: 'Clock out method',
      risk: 'Risk',
      when: 'When'
    },
    detailView: {
      clockIn: 'Clock in',
      clockOut: 'Clock out',
      editShift: 'Edit shift',
      startLocation: 'Start location',
      endLocation: 'End location',
      startDevice: 'Start device',
      endDevice: 'End device',
      unknownLocation: 'Unknown location',
      noLocationData: 'There are no locations associated with this shift',
      estimatedCost: 'Estimated Cost',
      estimatedCostExplanation:
        'At the moment, TimeShift doesn’t support full payroll functionality so additional context like overtime, contracted hours, tax or NI aren’t used in this estimation.',
      duration: 'Duration',
      distanceWithin: (distanceInKm: number, locationName: string) =>
        `Within ${distance(distanceInKm)} of ${locationName}`,
      distanceOutside: (distanceInKm: number, locationName: string) =>
        `${distance(distanceInKm)} away from ${locationName}`
    },
    editShiftForm: {
      clockIn: 'Clock in time',
      clockInRequired: 'Clock in date & time required',
      clockInValidation: 'You must provide a clock in date & time that is in the past.',
      clockOut: 'Clock out time',
      clockOutValidation: 'Clock out date & time must be in the past.',
      endBeforeStart: 'Clock out date & time must be after clock in date & time',
      risk: 'Risk',
      riskRequired: 'You must set a risk level',
      riskOptions: {
        low: 'Low',
        medium: 'Medium',
        high: 'High'
      },
      clockedOut: 'Clocked out',
      update: 'Update',
      undoClockOut: 'Clock back in'
    },
    addGeolocation: {
      modalTitle: 'Add a new geolocation',
      modalDescription:
        'If this is a location you recognise and is an approved job site, confirm it below and TimeShift will automatically approve clock-in-outs from it.',
      geolocationLabel: 'Geolocation',
      siteNameLabel: 'Site name',
      geolocationRequired: 'You must provide a geolocation name'
    },
    employeeName: constructNameEN,
    viewReport: 'View report',
    selectLocations: 'Select Locations',
    selectedLocationModalTitle: 'Update selected locations',
    showingDataForLocations: 'Showing data for location(s):',
    noLocationsSelected: 'No locations selected'
  }
});

export default withCommon<typeof shiftStrings>(shiftStrings);
