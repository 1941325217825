import React, { FC } from 'react';
import { Title } from '../../../components/shared/text/Title';
import { FiX } from 'react-icons/fi';
import shiftStrings from '../../shifts/shifts.translations';
import { MapPoint } from '../../../components/shared/maps/Map';
import { WorkLocation } from '../../../services/location/location-helper';
import { SubmissionErrorBoundary } from '../../../components/errors/SubmissionErrorBoundary';
import { MapField } from '../../../components/shared/form/MapField';
import { SubmitCancelButtons } from '../../../components/shared/form/SubmitCancelButtons';
import { useForm } from 'react-hook-form';
import { useCreateLocationCoordinatesMutation } from '../../../services/gql/graphql';
import { useLocationService } from '../../../services/location/LocationService';
import { useErrorHandler } from 'react-error-boundary';
import { Modal } from '../../../components/shared/modal/Modal';
import { TsForm } from '../../../components/shared/form/Form';
import { TextFieldWithOptionalLabel } from '../../../components/shared/form/TextInput';

interface AddGeolocationModalProps {
  open: boolean;
  onClose: () => void;
  workLocation: WorkLocation;
  mapPoints: MapPoint[];
}

interface AddGeolocationFormFields {
  locationName: string;
  geolocation: {
    longitude: number;
    latitude: number;
  };
}

export const AddGeolocationModal: FC<AddGeolocationModalProps> = ({ open, onClose, workLocation, mapPoints }) => {
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);
  const methods = useForm<AddGeolocationFormFields>();
  const [{ fetching }, CreateLocationCoordinates] = useCreateLocationCoordinatesMutation();

  const handleError = useErrorHandler();

  const addLocation = async (values: AddGeolocationFormFields) => {
    if (!locationId) return;
    const { geolocation, locationName } = values;
    const { longitude, latitude } = geolocation;
    try {
      await CreateLocationCoordinates({
        locationCoordinates: {
          locationName,
          locationId,
          longitude,
          latitude
        }
      });
    } catch (e) {
      handleError(e as Error);
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col relative m-8 sm:w-96">
        <Title className="mb-4">{shiftStrings.addGeolocation.modalTitle}</Title>
        <p className="mb-2">{shiftStrings.addGeolocation.modalDescription}</p>
        <SubmissionErrorBoundary>
          <TsForm methods={methods} onSubmit={addLocation}>
            <div className="flex flex-col py-2">
              <div className="mb-14">
                <MapField
                  name="geolocation"
                  required
                  validationMessage="Please select a location"
                  title={shiftStrings.addGeolocation.geolocationLabel}
                  origin={workLocation}
                  className="h-48 sm:h-80 rounded"
                  center={[workLocation.position.longitude, workLocation.position.latitude - 0.001]}
                  points={mapPoints}
                />
              </div>
              <TextFieldWithOptionalLabel
                name={'locationName'}
                title={shiftStrings.addGeolocation.siteNameLabel}
                validationMessage={shiftStrings.addGeolocation.geolocationRequired}
                required
              />
            </div>
            <SubmitCancelButtons onComplete={onClose} loading={fetching} />
          </TsForm>
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
