import React, { FC, useEffect, useState } from 'react';
import { PageContainer } from '../../../components/page/PageContainer';
import { PageHeader } from '../../../components/page/PageHeader';
import strings from '../roster.translations';
import { GiAtom } from 'react-icons/gi';
import { AccordionWizard } from '../../../components/shared/Accordion/AccordionWizard';
import { PageSizer, SizerMode } from '../../../components/page/PageSizer';
import { RosterCreateDateRange } from './RosterCreateDateRange';
import RosterCreateBusyness, { RosterBusyness } from './RosterCreateBusyness';
import { eachDayOfInterval, isEqual } from 'date-fns';
import { BusynessLevel } from '../rosterTypes';
import { InfoChit } from '../../../components/shared/InfoChit';
import { Button } from '../../../components/shared/button/Button';
import { getBaseURL, useAuthenticationService } from '../../../services/authentication/authentication-service';
import { busynessToTarget } from '../rosterHelper';
import { useLocationService } from '../../../services/location/LocationService';
import { dateToApiFormat } from '../../../helpers/dateHelper';
import { useNavigate } from 'react-router-dom';
import { LoggerLevel, useLogger } from '../../../services/logging/Logger';
import { TSCard } from '../../../components/shared/card/Card';

interface RosterCreateProps {}

export const RosterCreate: FC<RosterCreateProps> = () => {
  const { log } = useLogger();
  const breadcrumbs = [
    { name: strings.rosterBreadcrumbTitle, to: '/roster' },
    {
      name: strings.createRosters,
      to: '/roster/create'
    }
  ];

  const [[startDate, endDate], setRosterDates] = useState<[Date | undefined, Date | undefined]>(() => [
    undefined,
    undefined
  ]);

  const [busyPattern, setBusyPattern] = useState<RosterBusyness[]>([]);
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    //If start/end date changes, create a new array of null data
    if (startDate && endDate) {
      const dateRange = eachDayOfInterval({ start: new Date(startDate!), end: new Date(endDate!) });
      const tempBusyPattern = dateRange.map((e: Date) => {
        const existingEle = busyPattern.find(localEle => isEqual(localEle.date, e));
        return { date: e, level: existingEle ? existingEle.level : BusynessLevel.NORMAL } as RosterBusyness;
      });
      setBusyPattern(tempBusyPattern);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const sendOptimization = async () => {
    setSubmitLoading(true);
    setErrorMessage(undefined);
    const APIUrl = `${await getBaseURL()}/Optimiser/OptimiserRequestWithTargets`;
    const { user } = useAuthenticationService.getState();

    const dateTargets: { Date: string; Target?: number }[] = busyPattern.map(patternDay => {
      return {
        Date: dateToApiFormat(patternDay.date),
        Target: busynessToTarget(patternDay.level || BusynessLevel.NORMAL)
      };
    });

    const request = {
      LocationDateTargets: {
        LocationId: locationId,
        DateTargets: dateTargets
      },
      BuildAvailability: true
    };

    try {
      const response = await fetch(APIUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.access_token}`,
          'tenant-id': user?.profile?.organisation || ''
        },
        body: JSON.stringify(request)
      });

      const data = await response.json();

      if (data.batchDetails && data.batchDetails.length === 1) {
        navigate('/roster');
      } else {
        log(data, LoggerLevel.Error);
        setErrorMessage(strings.rosterCreate.error.issueWithRequest);
      }
    } catch {
      setErrorMessage(strings.rosterCreate.error.unableToSend);
    }

    setSubmitLoading(false);
  };

  return (
    <PageContainer
      customHeader={<PageHeader pageTitle={strings.cardTitle} breadcrumbs={breadcrumbs} icon={<GiAtom />} />}
    >
      <PageSizer className="flex flex-col w-full p-5" mode={SizerMode.PAGE}>
        <TSCard className="p-5">
          <AccordionWizard
            choices={[
              {
                heading: strings.rosterCreate.selectRosterDates,
                component: (
                  <RosterCreateDateRange startDate={startDate} endDate={endDate} changeDatesCallback={setRosterDates} />
                )
              },
              {
                heading: strings.rosterCreate.selectBusy,
                component: busyPattern.length ? (
                  <RosterCreateBusyness existingPattern={busyPattern} setPatternCallback={setBusyPattern} />
                ) : (
                  <InfoChit>{strings.rosterCreate.selectStartEndDate}</InfoChit>
                )
              },
              {
                heading: strings.rosterCreate.createTabHeader,
                component: busyPattern.length ? (
                  <div className="flex flex-col gap-2">
                    <p>{strings.rosterCreate.submit.createPeriod(startDate!, endDate!)}</p>
                    <p>{strings.rosterCreate.submit.autofillTime}</p>
                    <p>{strings.rosterCreate.submit.start}</p>
                    <p className="text-red-500">{errorMessage}</p>
                  </div>
                ) : (
                  <InfoChit>{strings.rosterCreate.selectStartEndDate}</InfoChit>
                )
              }
            ]}
            customSubmit={
              <Button primary onClick={sendOptimization} loading={submitLoading}>
                {strings.rosterCreate.submitText}
              </Button>
            }
          />
        </TSCard>
      </PageSizer>
    </PageContainer>
  );
};
