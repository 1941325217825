import { addDays, addSeconds, compareAsc, format } from 'date-fns';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatUTCDate } from '../../../helpers/dateHelper';
import { ReadRoster, useGetMyShiftsForDateRangeQuery } from '../../../services/gql/graphql';
import { useLocationService } from '../../../services/location/LocationService';
import { useRosterSelectService } from '../../roster/calendar/common/rosterSelectService';
import { NextShiftCard } from './NextShiftCard';
import { UpcomingShiftsCard } from './UpcomingShiftsCard';

export interface ShiftData {
  date: string;
  startTime: string;
  endTime: string;
  startTimeRaw: Date;
  endTimeRaw: Date;
}

interface UpcomingShiftProps {}

export const UpcomingShiftSection: FC<UpcomingShiftProps> = () => {
  const [getRosterByLocationAndDate] = useRosterSelectService(state => [state.getRosterByLocationAndDate]);
  const navigate = useNavigate();
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);

  const [{ data, fetching }] = useGetMyShiftsForDateRangeQuery({
    variables: {
      range: {
        start: formatUTCDate(new Date()),
        //We want to include the end date, so set it to midnight on the day after.
        end: formatUTCDate(addDays(new Date(), 7))
      }
    },
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    ),
    requestPolicy: 'network-only'
  });

  const onViewRoster = async (startDate: Date, endDate: Date) => {
    const rosterData = (await getRosterByLocationAndDate(startDate, endDate, locationId!)) as ReadRoster[];
    const formatDate = format(new Date(rosterData[0].rosterDate), 'yyyy-MM-dd');
    navigate(`/calendar/${rosterData[0].id}/${formatDate}/StaffRosterView`);
  };

  const shifts = data?.myShiftsForDateRange?.shifts
    ?.map(shift => {
      const date = new Date(shift.date);
      const startTime = addSeconds(date, shift.start);
      const endTime = addSeconds(date, shift.end);
      return {
        date: format(date, 'EEEE do LLLL'),
        startTime: format(startTime, 'HH:mm'),
        endTime: format(endTime, 'HH:mm'),
        startTimeRaw: startTime,
        endTimeRaw: endTime
      };
    })
    .sort((a, b) => compareAsc(a.startTimeRaw, b.startTimeRaw)) as ShiftData[];

  return (
    <>
      {shifts && shifts?.length > 0 ? (
        <NextShiftCard shift={shifts[0]} loading={fetching} callback={onViewRoster} />
      ) : (
        ''
      )}
      <UpcomingShiftsCard shifts={shifts} loading={fetching} callback={onViewRoster} />
    </>
  );
};
