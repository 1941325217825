import { FC, HTMLAttributes } from 'react';
import { format as formatDate } from 'date-fns';

interface DateTimeProps extends HTMLAttributes<HTMLTimeElement> {
  format?: string;
  date: Date;
}

export const DateTime: FC<DateTimeProps> = ({ date, format = 'EEEE do MMMM yyyy', ...props }) => {
  return (
    <time {...props} dateTime={date.toDateString()}>
      {formatDate(date, format)}
    </time>
  );
};
