import { FC } from 'react';
import { useTimeSlots } from './ganttHelper';

interface GridProps {
  minTime: Date;
  maxTime: Date;
}

export const MINUTES_PER_SLOT = 15;

export const Grid: FC<GridProps> = ({ minTime, maxTime }) => {
  const hourlySlots = useTimeSlots(minTime, maxTime, 60);

  return <div className="flex w-full h-full border-dashed border-r">
    {hourlySlots.map(slot => {
      return <div className="flex-1 w-full h-full border-dashed border-l" />
    })}
  </div>
}