import React, { FC } from 'react';
import { EmployeeAvatar, ExpectedEmployee } from './EmployeeAvatar';

export enum EmployeeStatus {
  ON_SHIFT,
  NOT_ON_SHIFT
}

interface EmployeePresenceCardProps {
  employees: ExpectedEmployee[];
}

export const EmployeePresenceCard: FC<EmployeePresenceCardProps> = ({ employees }) => {
  return (
    <div className="flex overflow-auto gap-5 py-2">
      {employees.map(employee => (
        <EmployeeAvatar employee={employee} key={employee.id} />
      ))}
    </div>
  );
};
