import React, { FC } from 'react';
import { BsCurrencyPound } from 'react-icons/bs';
import { BiStopwatch } from 'react-icons/bi';
import { HiArrowNarrowDown, HiArrowNarrowUp } from 'react-icons/hi';
import { MdPerson } from 'react-icons/md';
import strings from '../home.translations';
import { SummaryData } from '../summary/buildSummaryData';
import { ExplainerModal } from '../../../components/shared/modal/ExplainerModal';
import { displayPay } from '../../../helpers/pay';
import { SkeletonLoader } from '../../../components/shared/SkeletonLoader';
import { Icon } from '../../../components/shared/icons/Icon';

interface TimeCostSummaryCardProps {
  type: TimeCostType;
  title: string;
  summaryData: SummaryData | undefined;
  fetching: boolean;
  dayDiff: number;
}

export enum TimeCostType {
  TIME,
  COST,
  STAFF
}

export const TimeCostSummaryCard: FC<TimeCostSummaryCardProps> = ({ type, title, summaryData, fetching, dayDiff }) => {
  const computeIcon = () => {
    switch (type) {
      case TimeCostType.TIME:
        return <Icon Icon={BiStopwatch} theme="primary" big />;
      case TimeCostType.COST:
        return <Icon Icon={BsCurrencyPound} theme="primary" big />;
      case TimeCostType.STAFF:
        return <Icon Icon={MdPerson} theme="primary" big />;
    }
  };

  const formatCurrentVal = () => {
    const displayPayValue = displayPay(summaryData!.costInPounds);
    switch (type) {
      case TimeCostType.TIME:
        return (
          Math.floor(summaryData!.timeInMinutes / 60) +
          strings.common.hourShortHand +
          ' ' +
          (summaryData!.timeInMinutes % 60) +
          strings.common.minuteShortHand
        );
      case TimeCostType.COST:
        return summaryData!.costInPounds && summaryData!.costInPounds === 0 ? '-' : displayPayValue;
      case TimeCostType.STAFF:
        return summaryData!.totalStaff + ' ' + strings.timeCostSummary.people;
    }
  };

  const formatDayDifference = () => {
    const formattedString =
      dayDiff === 1 ? strings.timeCostSummary.previousDay : strings.timeCostSummary.previousDays(dayDiff);
    return <p className="font-medium">{formattedString}</p>;
  };

  const calcDifference = () => {
    const moreOrLessTime = summaryData!.timeDifferenceInMinutes >= 0 ? strings.common.more : strings.common.less;
    const moreOrLessCost = summaryData!.costDifferenceInPounds >= 0 ? strings.common.more : strings.common.less;
    const moreOrLessStaff = summaryData!.totalStaffDifference >= 0 ? strings.common.more : strings.common.less;

    const primaryIconStyle = 'text-primary-500';
    const primaryTextStyle = 'font-bold text-primary-500';

    switch (type) {
      case TimeCostType.TIME:
        return <>
          <div className="flex flex-row items-center">
            {summaryData!.timeDifferenceInMinutes >= 0 ? (
              <HiArrowNarrowUp className={primaryIconStyle} />
            ) : (
              <HiArrowNarrowDown className={primaryIconStyle} />
            )}
            <p className={primaryTextStyle}>{`${Math.floor(
              Math.abs(summaryData!.timeDifferenceInMinutes) / 60
            )} hours ${moreOrLessTime}`}</p>
          </div>
          {formatDayDifference()}
        </>;
      case TimeCostType.COST:
        const displayDifferencePayValue = displayPay(Math.abs(summaryData!.costDifferenceInPounds));

        const getArrowIcon = () => {
          if(summaryData!.costDifferenceInPounds === 0){
            return '';
          } else if (summaryData!.costDifferenceInPounds > 0) {
            return <HiArrowNarrowUp className={primaryIconStyle} />;
          } else {
            return <HiArrowNarrowDown className={primaryIconStyle} />;
          }
        };
        const returnDayDifference = () => {
          if(summaryData!.costDifferenceInPounds && summaryData!.costDifferenceInPounds !== 0){
            return formatDayDifference();
          } else {
            return '';
          }
        };

        return <>
          <div className="flex flex-row items-center">
            {getArrowIcon()}
            <p className={primaryTextStyle}>
              {`${summaryData!.costDifferenceInPounds && summaryData!.costDifferenceInPounds === 0 ? '' : displayDifferencePayValue + ' ' + moreOrLessCost}`}
            </p>
          </div>
          {returnDayDifference()}
        </>;
      case TimeCostType.STAFF:
        return <>
          <div className="flex flex-row items-center">
            {summaryData!.totalStaffDifference >= 0 ? (
              <HiArrowNarrowUp className={primaryIconStyle} />
            ) : (
              <HiArrowNarrowDown className={primaryIconStyle} />
            )}
            <p className="font-bold text-primary-500">{`${Math.abs(summaryData!.totalStaffDifference)} ${
              summaryData!.totalStaffDifference === 1 ? strings.timeCostSummary.person : strings.timeCostSummary.people
            } ${moreOrLessStaff}`}</p>
          </div>
          {formatDayDifference()}
        </>;
    }
  };

  return (
    <div className="flex flex-col lg:flex-row border-b lg:border-r lg:border-b-0 last:border-0 p-2 w-full h-full">
      <div className="relative m-2 w-full h-full">
        <div className="flex flex-col h-full w-full">
          <div className="flex flex-row">
            <p className="font-medium font-semibold">{title}</p>
            {type === TimeCostType.COST && (
              <ExplainerModal
                className={'p-px ml-2'}
                label={strings.common.detailViewCommon.estimatedCost}
                title={strings.common.detailViewCommon.estimatedCost}
                content={strings.common.detailViewCommon.estimatedCostExplanation}
              />
            )}
          </div>
          <div className="absolute top-0 right-0">{computeIcon()}</div>
          {!fetching ? (
            <div>
              <p className="font-bold text-2xl">{formatCurrentVal()}</p>
              {calcDifference()}
            </div>
          ) : (
            <div className="flex flex-col gap-2 h-[70px] w-full">
              <SkeletonLoader className="w-20 h-full" />
              <SkeletonLoader className="w-32 h-full" />
              <SkeletonLoader className="w-full h-full" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
