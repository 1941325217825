import { FC } from 'react';

interface InitialsAvatarProps {
  name?: string;
  size?: number;
}

export const InitialsAvatar: FC<InitialsAvatarProps> = ({ name, size = 40 }) => {
  let initials = name
    ?.match(/\b([A-Z0-9])/g)
    ?.join('')
    .toUpperCase();

  if (initials && initials.length > 1) {
    initials = initials.slice(0, 1) + initials.slice(-1);
  }

  return (
    <div>
      <div
        style={{ height: `${size}px`, width: `${size}px` }}
        className={`bg-primary-500 p-2 rounded-[50%] text-white dark:text-black flex items-center justify-center`}
      >
        <span>{initials || ''}</span>
      </div>
    </div>
  );
};
