import LocalizedStrings from 'react-localization';
import { withCommon } from '../../services/translation/commonTranslations';

const devicesStrings = new LocalizedStrings({
  en: {
    manageOrgSection: {
      title: 'Manage Organisation',
      orgDetailsCard: {
        title: 'Organisation Details',
        name: 'Organisation Name',
        logo: 'Organisation Logo'
      },
      locationCard: {
        title: 'Locations',
        desc: 'Add new locations and manage existing ones'
      },
      stationsCard: {
        title: 'Stations',
        desc: 'Add new locations and manage existing ones'
      }
    },
    locationsSection: {
      title: 'Locations',
      noData: 'No locations to display'
    },
    addLocationModal: {
      title: 'Add New Location',
      nameField: 'Location name',
      namePlaceholder: 'Name',
      nameRequired: 'Location name required',
      uniqueName: 'Location name must be unique.'
    }
  }
});

export default withCommon<typeof devicesStrings>(devicesStrings);
