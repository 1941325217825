import { FC } from 'react';
import { ReadRosterPositions, RosterStation } from '../../../services/gql/graphql';
import { Position } from './Position';
import { LEFT_COLUMN_WIDTH } from './Roster';
import { PickerDirection } from './RosterPersonPicker';
import { useRosterService } from './rosterService';
import { StationHeader } from './StationHeader';

interface StationProps {
  station: RosterStation;
  color: string;
  lastStation?: boolean;
}

export const Station: FC<StationProps> = ({ station, color, lastStation }) => {
  const [completed] = useRosterService(state => [state.completed]);

  return (
    <>
      <div className="flex h-12 bg-primary-std">
        <div
          style={{ width: LEFT_COLUMN_WIDTH }}
          className={`border-r-2 items-center h-full flex items-center text-lg`}
        >
          <StationHeader station={station} color={color} readOnly={completed} />
        </div>
        <div style={{ background: color }} className="bg-secondary-std flex-1 opacity-25" />
      </div>
      {station.positions?.map(position => {
        return (
          <Position
            position={position as ReadRosterPositions}
            color={color}
            key={position.id}
            stationId={station.stationId}
            stationTasks={station.tasks ?? []}
            dropdownDirection={lastStation ? PickerDirection.UP : PickerDirection.DOWN}
          />
        );
      })}
    </>
  );
};
