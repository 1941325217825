import { FC } from 'react';
import { useLocationService } from '../../services/location/LocationService';
import strings from './shifts.translations';

interface ShowingDataForLocationsTextProps {}

export const ShowingDataForLocationsText: FC<ShowingDataForLocationsTextProps> = () => {
  const [selectedShiftLocations] = useLocationService(state => [state.selectedShiftLocations]);

  const checkedShifts = selectedShiftLocations.filter(location => location.checked);

  return checkedShifts.length > 0 ? (
    <p>
      {strings.showingDataForLocations}{' '}
      <span className="font-bold">{checkedShifts.map(location => location.name).join(', ')}</span>
    </p>
  ) : (
    <p className='font-bold'>{strings.noLocationsSelected}</p>
  );
};
