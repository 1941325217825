import React, { FC, useState } from 'react';
import strings from '../roster.translations';
import { useRosterService } from './rosterService';
import { format } from 'date-fns';
import { FiCalendar } from 'react-icons/fi';
import { Button } from '../../../components/shared/button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { createSuccessToast } from '../../../services/notification/ToastService';
import { RosterHeaderCommon } from '../common/RosterHeaderCommon';
import { ConfirmDialog } from '../../../components/shared/ConfirmDialog';

interface RosterHeaderProps {
  readOnly?: boolean;
  calendar?: boolean;
}
export const RosterHeader: FC<RosterHeaderProps> = ({ readOnly, calendar }) => {
  const { rosterId } = useParams<'rosterId'>();
  const { date: rosterDate } = useParams<'date'>();

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [date, completed, publishRoster] = useRosterService(state => [
    state.roster.rosterDate,
    state.completed,
    state.publishRoster
  ]);
  const dateToDisplay = format(new Date(date), 'EEEE do MMMM y');

  const displayCalendar = { name: strings.calendarBreadcrumbTitle, to: '/calendar' };
  const displayRoster = { name: strings.rosterBreadcrumbTitle, to: '/roster' };

  const breadcrumbs = [
    calendar ? displayCalendar : displayRoster,
    {
      name: (
        <div className="flex gap-2 items-center">
          <FiCalendar />
          {dateToDisplay}
        </div>
      ),
      to: '/'
    }
  ];

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onConfirm = async () => {
    setLoading(true);

    await publishRoster(!completed);
    setModalOpen(false);
    if (!completed) {
      //Changing from non-complete to complete eg/ publishing the roster
      createSuccessToast(`Roster successfully published on ${dateToDisplay}`);
      navigate('/roster');
    }
    setLoading(false);
  };

  return (
    <RosterHeaderCommon
      headerTitle={completed ? strings.publishedRoster : strings.editRoster}
      breadcrumbs={breadcrumbs}
      headerButtons={
        readOnly ? (
          <></>
        ) : (
          <>
            <Button
              onClick={() => {
                navigate(`/roster/${rosterId}/${rosterDate!.substring(0, 10)}/requirements`);
              }}
            >
              {strings.viewRequirements}
            </Button>
            {completed && <Button onClick={() => setModalOpen(true)}>{strings.publishButton.edit}</Button>}
            <Button onClick={!completed ? () => setModalOpen(true) : undefined} primary>
              {completed ? strings.publishButton.complete : strings.publishButton.draft}
            </Button>
          </>
        )
      }
    >
      <ConfirmDialog
        title={completed ? strings.publishButton.edit : strings.publishButton.draft}
        confirmContent={completed ? strings.confirmModal.confirmEdit : strings.confirmModal.confirmPublish}
        confirmLabel={completed ? strings.confirmModal.confirm : strings.confirmModal.publish}
        cancelLabel={strings.common.cancel}
        open={modalOpen}
        onSuccess={onConfirm}
        loading={loading}
        onClose={() => setModalOpen(false)}
      />
    </RosterHeaderCommon>
  );
};
