import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { SubmissionErrorBoundary } from '../../components/errors/SubmissionErrorBoundary';
import { Modal } from '../../components/shared/modal/Modal';
import { Title } from '../../components/shared/text/Title';
import strings from '../devices/devices.translations';
import { AddDeviceForm } from './AddDeviceForm';

interface AddDeviceModalProps {
  locationId: number;
  open: boolean;
  onClose: () => void;
}

export const AddDeviceModal: FC<AddDeviceModalProps> = ({ open, onClose, locationId }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="relative m-8">
        <Title>{strings.addDeviceModal.title}</Title>
        <SubmissionErrorBoundary>
          <AddDeviceForm onComplete={onClose} locationId={locationId} />
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
