import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button } from '../../../components/shared/button/Button';
import { SelectInputOption, SelectInput } from '../../../components/shared/form/SelectForm';
import { Watch } from '../../../components/shared/icons/Watch';
import { Modal } from '../../../components/shared/modal/Modal';
import { ClockInStatus } from '../../../services/clockIn/ClockService';
import { Location } from '../../../services/gql/graphql';
import { useLocationService } from '../../../services/location/LocationService';
import strings from '../team.translations';

interface TeamMemberClockModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (locationId: number) => Promise<string | undefined>;
  onSuccess: () => void;
  loading: boolean;
  clockState: ClockInStatus;
  locationList: Location[];
  alreadyClockedLocation?: number;
}

export const TeamMemberClockModal: FC<TeamMemberClockModalProps> = ({
  open,
  onClose,
  onSubmit,
  onSuccess,
  loading,
  clockState,
  locationList,
  alreadyClockedLocation
}) => {
  const [selectedLocationId, setSelectedLocation] = useLocationService(state => [
    state.selectedLocation?.id,
    state.setSelectedLocation
  ]);

  const locationId = alreadyClockedLocation || selectedLocationId;

  const locationOptions = useMemo(() => locationList?.map(loc => ({
    id: loc.id.toString(),
    name: loc.name
  })), [locationList]);

  const [selectedOption, setSelectedOption] = useState<SelectInputOption | undefined>();

  useEffect(() => {
    const defaultId = alreadyClockedLocation || selectedLocationId;
    setSelectedOption(locationOptions.find(opt => opt.id === defaultId?.toString()));
  }, [setSelectedOption, locationOptions, alreadyClockedLocation, selectedLocationId]);

  const onLocationChange = (newLocOption?: SelectInputOption) => {
    setSelectedOption(newLocOption);
    if (newLocOption) {
      const newLoc = locationList.find(loc => loc.id.toString() === newLocOption.id);

      if (newLoc) {
        setSelectedLocation(newLoc);
      }
    }
  };

  const clockString = clockState === ClockInStatus.In ? strings.common.clockOut : strings.common.clockIn;
  const [errorMessage, setErrorMessage] = useState<string>();

  const onClock = async () => {
    setErrorMessage("");
    const error = await onSubmit(locationId!);

    if (!error) {
      onSuccess();
      onClose();
    } else {
      setErrorMessage(error);
    }
  }

  return (
    <Modal open={open} onClose={onClose} widthOverride="w-100">
      <div className="p-10 flex flex-col gap-5 items-center">
        <div className="font-extrabold text-xl border-b border-gray-200 pb-3 w-full text-center">
          {clockString}
        </div>
        {locationOptions.length > 1 && (
          <SelectInput
            name="selected-location"
            placeholder={strings.clock.selectLocation}
            value={selectedOption}
            onChange={onLocationChange}
            options={locationOptions}
            className="w-72"
            disabled={!!alreadyClockedLocation}
          />
        )}

        <Button
          primary
          loading={loading}
          onClick={onClock}
          className="flex items-center justify-center w-full gap-2"
        >
          <Watch className="w-4 h-6" />
          {clockString}
        </Button>
        {errorMessage && <p className="text-sm text-red-500 leading-5">{errorMessage}</p>}
      </div>
    </Modal>
  );
};
