import LocalizedStrings from 'react-localization';
import { ClockInStatus } from '../../services/clockIn/ClockService';
import { withCommon } from '../../services/translation/commonTranslations';

const clockStrings = new LocalizedStrings({
  en: {
    mainTitle: (type: ClockInStatus) => `Clock ${type === ClockInStatus.In ? 'In' : 'Out'}`,
    manualClockInWarning:
      'If you don’t want to use your location, you can clock in and out without it but your manager may need to approve each shift.',
    manualClockInPrompt: (type: ClockInStatus) => `Clock ${type === ClockInStatus.In ? 'in' : 'out'}`,
    locationClockInDescription:
      'Clocking in with your location is the most secure and easiest way to clock in. We only take a snapshot of your location and can not track you in the background.',
    noLocationsClockInDescription:
      'No geolocations have been registered for your organisation. Please contact your manager or account support if this is wrong. Please use the button below to clock in without location verification.',
    giveLocationAccess: 'Give access to your location',
    manualClocking: 'Clock in and out manually',
    confirmManualClocking: 'I want to clock in and out manually',
    clockStatusConfirmation: 'Successful!',
    clockStatusDescription: (type: ClockInStatus) =>
      `${type === ClockInStatus.In ? 'You are now clocked in.' : 'You are now clocked out.'}`,
    clockInSuccess: "You're all good to go!",
    clock: (type: ClockInStatus) => `Clock ${type === ClockInStatus.In ? 'in' : 'out'}`,
    clockAnyway: (type: ClockInStatus) => `Clock ${type === ClockInStatus.In ? 'in' : 'out'} anyway`,
    forgotClockingTimeWarning: (type: ClockInStatus) =>
      `I forgot to clock ${type === ClockInStatus.In ? 'in' : 'out'} at the right time`,
    forgotTitle: (type: ClockInStatus) => `I forgot to clock ${type === ClockInStatus.In ? 'in' : 'out'}`,
    forgotDescription: (type: ClockInStatus) =>
      `No worries, sometimes it happens! Please enter the time below that you did clock ${
        type === ClockInStatus.In ? 'in' : 'out'
      } and your manager will have to approve it.`,
    forgotTimePrompt: 'Please select a time',
    clockLocationText: {
      valid: 'Great, your location is close to one of your work’s registered locations.',
      tooFar: (type: ClockInStatus, distance?: string) =>
        `Your location is ${distance}km away from any your work’s registered locations. You can still clock ${
          type === ClockInStatus.In ? 'in' : 'out'
        }  but your manager might contact you to approve this shift.`
    },
    allowLocationText:
      'To clock in with location, click "Allow" on the pop up or change your permission in your browser settings.',
    errorFutureDate: 'Date cannot be in the future',
    nfcInvalid: 'This tap has expired',
    closeTab: 'You can close this tab now.',
    clockCode: 'Clock Code',
    device: 'Select Device',
    clockIn: 'Clock In',
    cancelled: 'Cancelled',
    cancelledDescription: 'You will not be clocked in to TimeShift.',
    noDevices: 'Clock services not enabled because there are no devices configured for your location.',
    selectLocation: 'Select location',
    locationNotAssociatedErrorMessage: 'You are not associated with this location.'
  }
});

export default withCommon<typeof clockStrings>(clockStrings);
