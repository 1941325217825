import React, { FC } from 'react';
import strings from '../roster.translations';
import { DateInput } from '../../../components/shared/date/DateInput';
import { createErrorToast } from '../../../services/notification/ToastService';

interface RosterCreateDateRangeProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  changeDatesCallback: (dates: [Date | undefined, Date | undefined]) => void;
}

export const RosterCreateDateRange: FC<RosterCreateDateRangeProps> = ({ startDate, endDate, changeDatesCallback }) => {
  return (
    <div className="flex flex-row gap-8 items-center justify-center">
      <div className="flex flex-col items-start">
        <p className="text-sm font-medium">{strings.rosterCreate.startDate}</p>
        <DateInput
          name={'start'}
          onChange={(newDate: Date | undefined) => {
            if (newDate! < endDate! || !endDate) {
              changeDatesCallback([newDate, endDate]);
            } else {
              createErrorToast(strings.rosterCreate.selectStartEndDateInvalid);
            }
          }}
          value={startDate}
        />
      </div>
      <div className="flex flex-col items-start">
        <p className="text-sm font-medium">{strings.rosterCreate.endDate}</p>
        <DateInput
          name={'end'}
          onChange={(newDate: Date | undefined) => {
            if (startDate! < newDate! || !startDate) {
              changeDatesCallback([startDate, newDate]);
            } else {
              createErrorToast(strings.rosterCreate.selectStartEndDateInvalid);
            }
          }}
          value={endDate}
        />
      </div>
    </div>
  );
};
