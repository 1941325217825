import { FC } from 'react';
import { PageContainer } from '../../../../components/page/PageContainer';
import { RosterSelectServiceProvider } from '../common/rosterSelectService';
import { RosterCalendar } from './RosterCalendar';
import strings from '../../roster.translations';
import { FiCalendar } from 'react-icons/fi';
import { PageHeader } from '../../../../components/page/PageHeader';
import { GiAtom } from 'react-icons/gi';
import { Button } from '../../../../components/shared/button/Button';
import { useNavigate } from 'react-router';

export const RosterSelectPage: FC = () => {
  const breadcrumbs = [{ name: strings.rosterBreadcrumbTitle, to: '/roster' }];
  const navigate = useNavigate();

  return (
    <PageContainer
      customHeader={
        <PageHeader pageTitle={strings.cardTitle} breadcrumbs={breadcrumbs} icon={<FiCalendar />}>
          <Button onClick={() => navigate('/roster/create')} primary className="flex items-center space-x-2">
            <GiAtom className="h-6 w-6" />
            <span>{strings.createRosters}</span>
          </Button>
        </PageHeader>
      }
    >
      <RosterSelectServiceProvider>
        <RosterCalendar />
      </RosterSelectServiceProvider>
    </PageContainer>
  );
};
