import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { UserInfoCopyBoxes } from '../../helpers/TextCopy';

interface ScribblesProps {}

export const Scribbles: FC<ScribblesProps> = () => {
  return (
    <PageContainer>
      <PageSizer className="relative flex-col flex gap-5" mode={SizerMode.PAGE}>
        <UserInfoCopyBoxes />
        <Link to="calendar">{'Calendar'}</Link>
        <Link to="map">{'Map'}</Link>
      </PageSizer>
    </PageContainer>
  );
};
