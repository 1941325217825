import LocalizedStrings from 'react-localization';
import { withCommon } from '../../../services/translation/commonTranslations';

const formStrings = new LocalizedStrings({
  en: {
    optional: 'Optional',
    pleaseSelect: 'Please select'
  }
});

export default withCommon<typeof formStrings>(formStrings);
