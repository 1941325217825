import { useVariable } from '../../services/backstage/BackstageProvider';
import { suspend } from 'suspend-react';

const fetchPolicyData = async (url: string) => {
  const response = await fetch(url);
  const data = await response.text();
  const lastModified = response.headers.get('Last-Modified');
  return { data, lastModified: new Date(lastModified as string).toLocaleDateString() };
};

export const useGetPolicies = () => {
  const termsAndConditionsUrl = useVariable('termsAndConditionsUrl');
  const privacyPolicyUrl = useVariable('privacyPolicyUrl');

  const { termsAndConditions, privacyPolicy } = suspend(async () => {
    const termsAndConditions = await fetchPolicyData(termsAndConditionsUrl!);
    const privacyPolicy = await fetchPolicyData(privacyPolicyUrl!);
    return {
      termsAndConditions,
      privacyPolicy
    };
  }, [termsAndConditionsUrl, privacyPolicyUrl]);

  return {
    privacyPolicy,
    termsAndConditions
  };
};
