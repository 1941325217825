import { format } from 'date-fns';
import { FC } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useClient } from 'urql';
import create, { State } from 'zustand';
import createContext from 'zustand/context';
import { patternToEmployeeSchedule } from '../../../helpers/BuildWorkingPattern';
import {
  EmployeeIdWorkingPatternsDocument,
  EmployeeIdWorkingPatternsQuery,
  EmployeeIdWorkingPatternsQueryVariables,
  GetCategorisedTrainingItemsForStationDocument,
  GetCategorisedTrainingItemsForStationQuery,
  GetCategorisedTrainingItemsForStationQueryVariables,
  GetEmployeeTrainingItemsDocument,
  GetEmployeeTrainingItemsQuery,
  GetEmployeeTrainingItemsQueryVariables,
  TrainingCategory,
  TrainingItem,
  WorkingPatternPeriods
} from '../../../services/gql/graphql';
import { LoggerLevel, useLogger } from '../../../services/logging/Logger';

export enum TeamMemberUpdateType {
  SHIFTS,
  HEADER,
  ALL
}

export interface TeamMemberService extends State {
  updateNeeded?: TeamMemberUpdateType;
  forceUpdate: (type: TeamMemberUpdateType) => void;
  updateComplete: (type: TeamMemberUpdateType) => void;
  getSkillsData: (employeeId: string) => any;
  getAvailabilitySchedule: (userId: string) => any;
}

const { Provider, useStore } = createContext<TeamMemberService>();
export const useTeamMemberService = useStore;

export const TeamMemberProvider: FC = ({ children }) => {
  const { log } = useLogger();
  const client = useClient();
  const handleError = useErrorHandler();

  const createStore = () =>
    create<TeamMemberService>((set, get) => {
      return {
        forceUpdate: (type: TeamMemberUpdateType) => {
          set({ updateNeeded: type });
        },
        updateComplete: (type: TeamMemberUpdateType) => {
          if (get().updateNeeded === type) {
            set({ updateNeeded: undefined });
          }
        },
        getSkillsData: async (employeeId: string) => {
          // We don't have a proper endpoint for 'get all the training items', so we need to iterate over a hard coded
          // list of stations.
          const stationIds = [1, 2, 3];
          const trainingCategories: TrainingCategory[] = [];

          stationIds.forEach(async id => {
            const response = await client
              .query<GetCategorisedTrainingItemsForStationQuery, GetCategorisedTrainingItemsForStationQueryVariables>(
                GetCategorisedTrainingItemsForStationDocument,
                {
                  id
                }
              )
              .toPromise();

            if (response.error) {
              handleError(response.error);
            } else {
              const newList = response.data?.categorisedTrainingItemsForStation?.trainingCategories;
              if (newList && newList.length > 0) {
                trainingCategories.push(newList[0] as TrainingCategory);
              }
            }
          });

          const response = await client
            .query<GetEmployeeTrainingItemsQuery, GetEmployeeTrainingItemsQueryVariables>(
              GetEmployeeTrainingItemsDocument,
              {
                id: employeeId
              },
              {
                requestPolicy: 'network-only'
              }
            )
            .toPromise();

          let employeeTraining: TrainingItem[] = [];
          if (response.error) {
            handleError(response.error);
          } else {
            employeeTraining = response.data?.allTrainingForEmployee?.trainingItems as TrainingItem[];
          }

          return { trainingCategories, employeeTraining };
        },
        getAvailabilitySchedule: async (userId: string) => {
          if (!userId) {
            log('Expecting user id', LoggerLevel.Error);
            return;
          }

          const response = await client
            .query<EmployeeIdWorkingPatternsQuery, EmployeeIdWorkingPatternsQueryVariables>(
              EmployeeIdWorkingPatternsDocument,
              {
                dateAndPeriods: {
                  date: format(new Date(), 'yyyy-MM-dd'),
                  periods: 7
                },
                id: userId
              }
            )
            .toPromise();

          if (response.error) {
            handleError(response.error);
          }

          const data = response.data?.employeeIdWorkingPatterns;

          if (!data) {
            log('Expecting availability schedule', LoggerLevel.Error);
            return;
          }

          return patternToEmployeeSchedule(data as WorkingPatternPeriods);
        }
      };
    });

  return <Provider createStore={createStore}>{children}</Provider>;
};
