import React, { FC, useState } from 'react';
import { Divider } from '../shared/Divider';
import strings from './clock.translations';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import { WorkTimeDataSource } from '../../services/gql/graphql';
import { useErrorHandler } from 'react-error-boundary';
import { useLocationService } from '../../services/location/LocationService';
import { Feature } from '../../services/backstage/Feature';
import { Button } from '../shared/button/Button';
import { Watch } from '../shared/icons/Watch';
import { SelectInput, SelectInputOption } from '../shared/form/SelectForm';

interface ClockManuallyProps {
  type: ClockInStatus;
  onSuccess: () => void;
  onForgotten: () => void;
}

export const ClockManually: FC<ClockManuallyProps> = ({ type, onSuccess, onForgotten }) => {
  const [clockState, clockLocationId, setClockState, loading, error] = useClockService(service => [
    service.clockState,
    service.clockLocationId,
    service.setClockState,
    service.loadingState,
    service.errorMessage
  ]);

  const handleError = useErrorHandler();
  const [selectedLocationId, setSelectedLocation, locationList] = useLocationService(state => [
    state.selectedLocation?.id,
    state.setSelectedLocation,
    state.locationList
  ]);

  const locationId = clockLocationId || selectedLocationId;

  const locationOptions = locationList?.map(loc => ({
    id: loc.id.toString(),
    name: loc.name
  }));

  const getDefaultOption = () => {
    return locationOptions.find(opt => opt.id === locationId?.toString());
  }

  const [selectedOption, setSelectedOption] = useState<SelectInputOption | undefined>(getDefaultOption);

  const onLocationChange = (newLocOption?: SelectInputOption) => {
    setSelectedOption(newLocOption);
    if (newLocOption) {
      const newLoc = locationList.find(loc => loc.id.toString() === newLocOption.id);

      if (newLoc) {
        setSelectedLocation(newLoc);
      }
    }
  };

  const onSubmit = async () => {
    try {
      if (locationId === undefined) return;
      await setClockState(type, locationId, WorkTimeDataSource.Manual);
      onSuccess();
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <>
      <Feature name="locationClockIn">
        <p className="text-sm font-content leading-5">{strings.manualClockInWarning}</p>
      </Feature>
      {locationOptions.length > 1 && (
        <SelectInput
          name="selected-location"
          placeholder={strings.selectLocation}
          value={selectedOption}
          onChange={onLocationChange}
          options={locationOptions}
          className="w-72"
          disabled={!!clockLocationId}
        />
      )}
      {error && <p className="text-sm text-red-500 leading-5">{error}</p>}
      <Button
        primary
        loading={loading}
        onClick={() => {
          onSubmit();
        }}
        className="flex items-center justify-center w-full gap-2"
      >
        <Watch className="w-4 h-6" />
        {clockState === ClockInStatus.In ? strings.common.clockOut : strings.common.clockIn}
      </Button>
      <Feature name="retroClockIn">
        <>
          <Divider />
          <Button
            className="mt-4"
            onClick={() => {
              onForgotten();
            }}
          >
            {strings.forgotClockingTimeWarning(type)}
          </Button>
        </>
      </Feature>
    </>
  );
};
