import * as React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FiCalendar, FiChevronRight } from 'react-icons/fi';
import { Day, useLilius } from 'use-lilius';
import { compareAsc, differenceInDays, eachDayOfInterval, format, isBefore } from 'date-fns';
import { InputProps, withField } from '../form/Field';
import { DatePicker, DayLabels } from './DatePicker';
import { Modal } from '../modal/Modal';
import strings from '../shared.translations';
import { Button } from '../button/Button';
import { merge } from '../../../helpers/Utility';
import { Icon } from '../icons/Icon';

export const defaultLabels = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat'
};

interface DateRangeInputProps extends InputProps<[Date, Date] | undefined> {
  labels?: DayLabels;
}

export const DateRangeInput: React.FC<DateRangeInputProps> = ({
  value,
  onChange,
  className,
  labels = defaultLabels
}) => {
  const {
    calendar,
    inRange,
    isSelected,
    select,
    deselect,
    selectRange,
    selected,
    viewing,
    viewNextMonth,
    viewPreviousMonth
  } = useLilius({ weekStartsOn: Day.MONDAY });

  useDeepCompareEffect(() => {
    onChange?.([selected[0], selected[selected.length - 1]]);
  }, [selected]);

  useDeepCompareEffect(() => {
    if (!value) return;
    const [start, end] = value;
    if (start === selected[0] && end === selected[selected.length - 1]) return;
    selectRange(start, end, true);
  }, [value, selectRange]);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <div className={merge(className, 'relative flex w-full')}>
      <Button onClick={() => setIsOpen(true)} className={'flex w-full items-center justify-center'}>
        <div className="flex space-x-3 py-0.5">
          <Icon small Icon={FiCalendar} />
          {selected[0] ? (
            <span className="flex space-x-1 font-medium">
              <span>{format(selected[0], 'E do')}</span>
              <Icon small Icon={FiChevronRight} />
              <span>{format(selected[selected.length - 1], 'E do')}</span>
            </span>
          ) : (
            <span className="text-secondary-std">{strings.dateRangeInput.noSelected}</span>
          )}
        </div>
      </Button>

      <Modal widthOverride={'w-full sm:max-w-max'} open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="flex w-full flex-col items-stretch pb-4">
          <DatePicker
            dismiss={() => setIsOpen(false)}
            calendar={calendar[0]}
            selected={selected}
            viewing={viewing}
            inRange={inRange}
            isSelected={isSelected}
            viewPreviousMonth={viewPreviousMonth}
            viewNextMonth={viewNextMonth}
            onDayClick={day => {
              const sorted = selected.sort((a, b) => compareAsc(a, b));

              // If no date is currently selected, simply select the day
              if (sorted.length === 0) {
                select(day);
                return;
              }

              // If there is only one date selected & clicking on that date, deselected it
              if (sorted.length === 1) {
                if (isSelected(day)) {
                  deselect(day);
                  return;
                }
              }

              if (isSelected(day)) {
                const daysBefore = differenceInDays(day, sorted[0]);
                const daysAfter = differenceInDays(sorted[sorted.length - 1], day);

                if (daysBefore === 0) {
                  //If clicking the start day, unselect it.
                  deselect(sorted);
                  select(sorted[sorted.length - 1]);
                  return;
                } else if (daysAfter === 0) {
                  //If clicking the end day, unselect it.
                  deselect(sorted);
                  select(sorted[0]);
                  return;
                }

                // If the date is in the middle of a range, trim the range to that date.
                // If the date is closer to the start, move the start date. Otherwise, move the end.
                let range: Date[];
                if (daysBefore < daysAfter) {
                  range = eachDayOfInterval({
                    start: day,
                    end: sorted[sorted.length - 1]
                  });
                } else {
                  range = eachDayOfInterval({ start: sorted[0], end: day });
                }

                const diff = sorted.filter(d => range.map(a => a.getTime()).includes(d.getTime()));
                selectRange(diff[0], diff[diff.length - 1], true);

                return;
              }

              // If the day selected is before the start of the current range, expand the range to start at that date
              if (isBefore(day, sorted[0])) {
                selectRange(day, sorted[sorted.length - 1], true);
                return;
              }

              // If the day selected is after the end of the current range, expand the range to end at that date
              selectRange(sorted[0], day, true);
            }}
            labels={labels}
          />
          <Button
            className="mx-5"
            onClick={() => {
              deselect(selected);
            }}
          >
            {strings.dateRangeInput.clear}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export const DateRangeField = withField(DateRangeInput, 'Optional');
