import React, { FC, HTMLAttributes } from 'react';
import { Title } from '../text/Title';
import { IconType } from 'react-icons';
import { Icon, Theme } from '../icons/Icon';
import { RiEdit2Line } from 'react-icons/ri';
import { IconButton } from '../button/IconButton';

export interface CardEditDetails {
  onClick: () => void;
  label: string;
  anchorId?: string;
}

interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  descriptor?: string;
  icon?: IconType;
  smallContent?: JSX.Element;
  largeContent?: JSX.Element;
  editDetails?: CardEditDetails;
  theme?: Theme;
}

export const CardHeader: FC<CardHeaderProps> = ({
  title,
  descriptor,
  icon,
  smallContent,
  largeContent,
  editDetails,
  theme = 'primary',
  ...props
}) => {
  return (
    <div className="flex flex-row p-4 pb-0 gap-5 items-center" {...props}>
      <Title className="flex items-center space-x-2">
        {icon && <Icon theme={theme} Icon={icon} />}
        <div className="space-x-2 flex items-center justify-center">
          <span>{title}</span>
          {descriptor && (
            <>
              <span className="text-tertiary-std">{'•'}</span>
              <span className="text-sm font-medium text-secondary-std">{descriptor}</span>
            </>
          )}
        </div>
      </Title>
      <div className="flex flex-1 gap-5 justify-end">
        <div className="hidden lg:block">{largeContent}</div>
        {smallContent}
        {editDetails && (
          <IconButton
            className="rounded-full bg-gray-100 dark:bg-gray-500 p-1 h-8 w-8"
            label={editDetails?.label}
            Icon={RiEdit2Line}
            onClick={editDetails?.onClick}
            id={editDetails?.anchorId}
          />
        )}
      </div>
    </div>
  );
};
