import LocalizedStrings from 'react-localization';

export const commonStrings = new LocalizedStrings({
  en: {
    common: {
      clockIn: 'Clock-In',
      clockOut: 'Clock-Out',
      time: 'Time',
      date: 'Date',
      submit: 'Submit',
      cancel: 'Cancel',
      create: 'Create',
      save: 'Save',
      back: 'Back',
      delete: 'Delete',
      reset: 'Reset',
      close: 'Close',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phoneNumber: 'Phone number',
      home: 'Home',
      currencySymbol: '£',
      more: 'more',
      less: 'less',
      hourShortHand: 'h',
      minuteShortHand: 'm',
      detailViewCommon: {
        estimatedCostExplanation:
          'At the moment, TimeShift doesn’t support full payroll functionality so additional context like overtime, contracted hours, tax or NI aren’t used in this estimation.',
        estimatedCost: 'Estimated Cost'
      },
      openMenu: 'Open menu',
      download: 'Download',
      closeModal: 'Close modal',
      roles: {
        staff: 'Staff',
        manager: 'Manager',
        admin: 'Admin'
      },
      pagination: {
        pageXOfY: (page: number, total: number) => `Page ${page} of ${total}`
      },
      search: 'Search',
      mandatory: (field: string) => `${field} is required`,
      ok: "OK"
    },
  }
});

export function withCommon<Type>(strings: Type): Type & typeof commonStrings {
  return {
    ...strings,
    ...commonStrings
  };
}
