import React, { FC, ReactNode } from 'react';
import create, { State } from 'zustand';
import createContext from 'zustand/context';
import { handleError } from '../gql/config/URQLProvider';
import { Location, useGetMeQuery } from '../gql/graphql';

export interface LocationService extends State {
  selectedLocation?: Location;
  locationList: Location[];
  setSelectedLocation: (newVal?: Location) => void;
  mappedLocations: CheckboxLocation[];
  setMappedLocations: (newVal: any) => void;
  selectedShiftLocations: BaseLocation[];
  setSelectedShiftLocations: (newVal: any) => void;
}

export interface CheckboxLocation extends BaseLocation {
  descriptionMarkup: ReactNode;
}

export interface BaseLocation {
  key: string;
  name: string;
  checked: boolean
}

const STORAGE_KEY_PREFIX = 'selectedLocation';

const SELECTED_SHIFT_LOCATIONS_KEY_PREFIX = 'selectedShiftLocations';

const { Provider, useStore } = createContext<LocationService>();
export const useLocationService = useStore;

export const LocationProvider: FC = ({ children }) => {
  const [{ data: meData, error: meError }] = useGetMeQuery();
  if (meError) handleError(meError);

  const storageKey = `${STORAGE_KEY_PREFIX}-${meData?.myEmployee?.identityId}`;

  const selectedShiftLocationStorageKey = `${SELECTED_SHIFT_LOCATIONS_KEY_PREFIX}-${meData?.myEmployee?.identityId}`;

  const locationList = (meData?.myEmployee?.restaurants as Location[]) || [];

  const getDefaultLocation = () => {
    let defaultLocation: Location | undefined;
    const valueInStorage = localStorage.getItem(storageKey);

    if (valueInStorage) {
      const objectInStorage = JSON.parse(valueInStorage) as Location;

      // Need to check if the stored location is still in the user's list.
      if (locationList.find(loc => loc.id === objectInStorage.id)) {
        defaultLocation = objectInStorage;
      }
    }

    if (!defaultLocation && meData?.myEmployee?.restaurants && meData.myEmployee.restaurants.length > 0) {
      defaultLocation = meData.myEmployee.restaurants[0] as Location;
      localStorage.setItem(storageKey, JSON.stringify(defaultLocation));
    }

    return defaultLocation;
  };

  const getDefaultShiftLocations = () => {
    let locations: BaseLocation[] | undefined;
    const valueInStorage = localStorage.getItem(selectedShiftLocationStorageKey);

    if (valueInStorage) {
      const objectInStorage = JSON.parse(valueInStorage);

      if (objectInStorage) {
        locations = objectInStorage;
      }
    }

    if (!locations) {
      locations = locationList.map(location => {
        return {
          key: String(location.id),
          name: location.name,
          checked: true
        };
      });

      saveShiftLocations(locations);
    }

    return locations;
  }

  const saveShiftLocations = (value: BaseLocation[]) => {
    localStorage.setItem(selectedShiftLocationStorageKey, JSON.stringify(value));
  }

  const createStore = () =>
    create<LocationService>(set => ({
      selectedLocation: getDefaultLocation(),
      locationList: locationList,
      setSelectedLocation: (newVal?: Location) => {
        localStorage.setItem(storageKey, JSON.stringify(newVal));
        set({ selectedLocation: newVal });
      },
      mappedLocations: [],
      setMappedLocations: (newVal: any) => {
        localStorage.setItem(storageKey, JSON.stringify(newVal));
        set({ mappedLocations: newVal });
      },
      selectedShiftLocations: getDefaultShiftLocations(),
      setSelectedShiftLocations: (newVal: any) => {
        saveShiftLocations(newVal);
        set({ selectedShiftLocations: newVal });
      }
    }));

  return <Provider createStore={createStore}>{children}</Provider>;
};
