import { FC, useMemo } from 'react';
import strings from '../team.translations';
import {
  ReadEmployee,
  useGetEmployeesForUserQuery,
  useGetMeQuery,
  useGetStaffByIdQuery
} from '../../../services/gql/graphql';
import { NavPage } from '../../../components/page/Breadcrumbs';
import { useErrorHandler } from 'react-error-boundary';
import { AiOutlineUser } from 'react-icons/ai';
import {
  isManagement,
  Permissions,
  useAuthenticationService
} from '../../../services/authentication/authentication-service';
import { TeamMemberDisplay } from './TeamMemberDisplay';
import { useParams } from 'react-router';
import { TeamMemberProvider } from './TeamMemberService';

export const TeamMember: FC = () => {
  const { userId } = useParams<'userId'>();
  const handleError = useErrorHandler();

  const [role, permission] = useAuthenticationService(state => [state.role, state.user?.profile.permission]);
  const canEdit = permission?.includes(Permissions.EmployeeUpdate) ?? false;

  const isManager = isManagement(role);

  const [{ data: employeeLocationData, error: employeeLocationError }] = useGetEmployeesForUserQuery({
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    ),
    pause: !isManager
  });

  if (employeeLocationError) {
    handleError(employeeLocationError);
  }

  const [{ data: userData, error: userError }, getStaffByIdQuery] = useGetStaffByIdQuery({
    variables: {
      id: userId
    },
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    ),
    pause: !isManager,
    requestPolicy: 'network-only'
  });

  if (userError) {
    handleError(userError);
  }

  const breadcrumbName = (employee?: ReadEmployee) => {
    return (
      <>
        <AiOutlineUser className="mr-2" />
        {employee && strings.employeeName(employee.firstName, employee.lastName)}
      </>
    );
  };

  let staffList =
    employeeLocationData?.employeesForUser?.employees?.filter(x => x.identityId !== userData?.employee?.identityId) ??
    [];

  const managerBreadcrumbs: NavPage[] = [
    {
      name: strings.teamPageTitle,
      to: '/team'
    },
    {
      name: breadcrumbName(userData?.employee as ReadEmployee),
      to: '/team-member',
      pages: staffList!
        .filter(staff => !staff.isDeleted && !staff.isDeactivated)
        .map(staffMember => {
          return {
            name: breadcrumbName(staffMember as ReadEmployee),
            to: `/team-member/${staffMember.identityId}`
          };
        })
    }
  ];

  const [{ data: myData }] = useGetMeQuery();
  const myUserId = myData!.myEmployee!.identityId;
  const isOwnPage = userId === myUserId;

  const staffBreadcrumbs: NavPage[] = [
    {
      name: strings.myProfileTitle,
      to: `/team-member/${myData?.myEmployee?.identityId}`
    }
  ];

  return (
    <TeamMemberProvider>
      <TeamMemberDisplay
        userData={isManager ? userData?.employee as ReadEmployee : myData?.myEmployee as ReadEmployee}
        breadcrumbs={isManager ? managerBreadcrumbs : staffBreadcrumbs}
        editMemberCard={canEdit}
        editMemberPayCard={canEdit}
        showShiftTable={isManager || isOwnPage}
        refetchUserData={getStaffByIdQuery}
        isMe={isOwnPage}
      />
    </TeamMemberProvider>
  );
};
