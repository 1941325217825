import React, { FC } from 'react';
import { Title } from '../../../components/shared/text/Title';
import strings from '../team.translations';
import { SubmissionErrorBoundary } from '../../../components/errors/SubmissionErrorBoundary';
import { FiX } from 'react-icons/fi';
import { OnboardingContents, OnboardingUserData } from './OnboardingContents';
import { Modal } from '../../../components/shared/modal/Modal';

interface OnboardingModalProps {
  user: OnboardingUserData;
  open: boolean;
  onClose: () => void;
}

export const OnboardingModal: FC<OnboardingModalProps> = ({ user, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} widthOverride="sm:w-max">
      <div className="relative m-8 w-auto sm:max-w-lg">
        <Title>{strings.onboarding.button}</Title>
        <SubmissionErrorBoundary>
          <OnboardingContents user={user} onClose={onClose} />
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
