import React, { FC, useState } from 'react';
import { Divider } from '../shared/Divider';
import strings from './clock.translations';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import { useGetMeQuery, WorkTimeDataSource } from '../../services/gql/graphql';
import { useErrorHandler } from 'react-error-boundary';
import { Watch } from '../shared/icons/Watch';
import { useLocationService } from '../../services/location/LocationService';
import { Feature } from '../../services/backstage/Feature';
import { Button } from '../shared/button/Button';
import { LoggerLevel, useLogger } from '../../services/logging/Logger';
import { SelectInput, SelectInputOption } from '../shared/form/SelectForm';

interface ClockNFCProps {
  type: ClockInStatus;
  onSuccess: () => void;
  onForgotten: () => void;
}

export const ClockNFC: FC<ClockNFCProps> = ({ type, onSuccess, onForgotten }) => {
  const [{ data: userData }] = useGetMeQuery();
  const [setClockState, clockLocationId, loading, error] = useClockService(service => [
    service.setClockState,
    service.clockLocationId,
    service.loadingState,
    service.errorMessage
  ]);
  const handleError = useErrorHandler();

  const [selectedLocationId, setSelectedLocation, locationList] = useLocationService(state => [
    state.selectedLocation?.id,
    state.setSelectedLocation,
    state.locationList
  ]);

  const locationId = clockLocationId || selectedLocationId;

  const locationOptions = locationList?.map(loc => ({
    id: loc.id.toString(),
    name: loc.name
  }));

  const getDefaultOption = () => {
    return locationOptions.find(opt => opt.id === locationId?.toString());
  }

  const [selectedOption, setSelectedOption] = useState<SelectInputOption | undefined>(getDefaultOption);

  const onLocationChange = (newLocOption?: SelectInputOption) => {
    setSelectedOption(newLocOption);
    if (newLocOption) {
      const newLoc = locationList.find(loc => loc.id.toString() === newLocOption.id);

      if (newLoc) {
        setSelectedLocation(newLoc);
      }
    }
  };

  const { log } = useLogger();

  const onSubmit = async () => {
    try {
      if (locationId === undefined) return;
      await setClockState(type, locationId, WorkTimeDataSource.Nfc);
      onSuccess();
    } catch (e) {
      log(`Employee ${userData?.myEmployee?.identityId} failed to clock in with NFC`, LoggerLevel.Error, true);
      handleError(e);
    }
  };

  return (
    <>
      {error && <p className="text-sm text-red-500 leading-5">{error}</p>}
      {locationOptions.length > 1 && (
        <SelectInput
          name="selected-location"
          placeholder={strings.selectLocation}
          value={selectedOption}
          onChange={onLocationChange}
          options={locationOptions}
          className="w-full"
          disabled={!!clockLocationId}
        />
      )}
      <Button
        className="flex items-center justify-center w-full gap-2"
        primary
        loading={loading}
        onClick={() => {
          onSubmit();
        }}
      >
        <Watch className="w-4 h-6" /> {strings.manualClockInPrompt(type)}
      </Button>
      <Feature name="retroClockIn">
        <>
          <Divider />
          <Button
            className="mt-4"
            onClick={() => {
              onForgotten();
            }}
          >
            {strings.forgotClockingTimeWarning(type)}
          </Button>
        </>
      </Feature>
    </>
  );
};
