import { FC, useState } from 'react';
import strings from '../../roster.translations';
import { RadioGroup } from '../../../../components/shared/RadioGroup';
import { useRosterSelectService } from '../common/rosterSelectService';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/shared/button/Button';
import { BusynessLevel } from '../../rosterTypes';
import { busynessToInputStyle } from '../../rosterHelper';
import { dateToGQLFormat } from '../../../../helpers/dateHelper';
import { useLocationService } from '../../../../services/location/LocationService';

interface RosterCreateModalContentProps {
  onClose: () => void;
  date: Date;
}

export const RosterCreateModalContent: FC<RosterCreateModalContentProps> = ({ onClose, date }) => {
  const navigate = useNavigate();
  const createRoster = useRosterSelectService(state => state.createRoster);
  const [loading, setLoading] = useState<boolean>(false);
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);

  const items = [
    {
      key: 'busy',
      value: BusynessLevel.BUSY,
      descriptionMarkup: <p>{strings.createRosterModal.radioOptions.busy}</p>,
      className: busynessToInputStyle(BusynessLevel.BUSY)
    },
    {
      key: 'normal',
      value: BusynessLevel.NORMAL,
      descriptionMarkup: <p>{strings.createRosterModal.radioOptions.normal}</p>,
      className: busynessToInputStyle(BusynessLevel.NORMAL)
    },
    {
      key: 'quiet',
      value: BusynessLevel.QUIET,
      descriptionMarkup: <p>{strings.createRosterModal.radioOptions.quiet}</p>,
      className: busynessToInputStyle(BusynessLevel.QUIET)
    }
  ];

  const [selectedTemplate, setSelectedTemplate] = useState<string>(BusynessLevel.BUSY);

  const onCreate = async () => {
    setLoading(true);
    const rosterId = await createRoster(selectedTemplate, date, locationId!);
    if (rosterId) {
      navigate(`${rosterId}/${dateToGQLFormat(date).substring(0, 10)}`);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={'flex flex-col'}>
      <p className="pt-2 px-2">{strings.createRosterModal.howBusy}</p>
      <RadioGroup
        className="flex flex-row w-full"
        fieldSetClasses={`flex flex-col md:flex-row md:flex-wrap space-y-4 md:space-y-0 focus:bg-red-500 whitespace-nowrap`}
        name="role"
        items={items}
        onChange={newVal => setSelectedTemplate(newVal)}
        value={selectedTemplate}
      />
      <div className={'flex justify-end w-full gap-2'}>
        <Button onClick={onClose}>{strings.common.cancel}</Button>
        <Button onClick={onCreate} primary loading={loading}>
          {strings.create}
        </Button>
      </div>
    </div>
  );
};
