import React, { FC } from 'react';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { addDays, subDays } from 'date-fns';
import { DateRangeInput } from './DateRangeInput';
import { Button } from '../button/Button';
import { merge } from '../../../helpers/Utility';
import strings from '../shared.translations';

interface LabelSettings {
  hide?: boolean;
}

interface CustomIntervalTimePickerLabels {
  previous?: LabelSettings;
  next?: LabelSettings;
}

interface CustomIntervalTimePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (dates: [Date, Date]) => void;
  dayDiff: number;
  inputStyle?: string;
  labels?: CustomIntervalTimePickerLabels;
}

export const CustomIntervalTimePicker: FC<CustomIntervalTimePickerProps> = ({
  startDate,
  endDate,
  onChange,
  dayDiff,
  inputStyle,
  labels
}) => {
  const nextTimeInterval = () => {
    onChange([addDays(endDate, 1), addDays(endDate, dayDiff + 1)]);
  };

  const prevTimeInterval = () => {
    onChange([subDays(startDate, dayDiff + 1), subDays(startDate, 1)]);
  };

  return (
    <div className="flex items-stretch gap-2 sm:justify-start w-full sm:w-full whitespace-nowrap">
      <Button className="flex gap-1 items-center" onClick={prevTimeInterval}>
        <FiChevronsLeft className="h-5 w-5" />
        {!labels?.previous?.hide && <span className="hidden sm:block">{strings.date.previousWeek}</span>}
      </Button>
      <DateRangeInput
        className={merge('flex-1', inputStyle)}
        name="Date filter"
        value={[startDate, endDate]}
        onChange={dates => {
          if (!dates) return;
          const [start, end] = dates;
          if (!start || !end) return;
          onChange(dates);
        }}
      />
      <Button className="flex gap-1 items-center" onClick={nextTimeInterval}>
        {!labels?.next?.hide && <span className="hidden sm:block">{strings.date.nextWeek}</span>}
        <FiChevronsRight className="h-5 w-5" />
      </Button>
    </div>
  );
};
