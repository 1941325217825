import React, { FC, useCallback, useMemo, useState } from 'react';
import TableContents from '../../../components/shared/table/TableContents';
import { TableChit } from '../../../components/shared/table/TableChit';
import { TimeshiftAvatar } from '../../../components/shared/TimeshiftAvatar';
import { FiMail, FiPhone } from 'react-icons/fi';
import strings from '../team.translations';
import { TableFilter } from '../../../components/shared/table/TableFilter';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../../components/shared/checkboxes/Checkbox';
import { Role } from '../../../services/authentication/authentication-service';
import { RoleOption } from './addTeamMembers/AddMultipleTeamMembersForm';
import { Row } from 'react-table';
import { InfoChitImportance } from '../../../components/shared/InfoChit';
import { TableLoader } from '../../../components/shared/table/TableLoader';
import { useLocationService } from '../../../services/location/LocationService';
import { globalFilter } from './teamTableFilter';

export interface StaffMember {
  identityId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isDeleted?: Boolean;
  isDeactivated?: Boolean;
  restaurants: { id: number; name: string }[];
}

enum TableLocalStorageKeys {
  Deleted = 'team-table-deleted-table-checkbox',
  Deactivated = 'team-table-deactivated-table-checkbox'
}

interface TeamTableProps {
  staff: StaffMember[];
  fetching?: boolean;
  locations?: string[];
}

interface NameDetails {
  name: string;
  id: string;
  locationNames: string[];
}

interface ContactDetails {
  phonenumber: string;
  email: string;
}

interface EmployeeTableStatus {
  isDeleted: Boolean | undefined;
  isDeactivated: Boolean | undefined;
}

export interface TeamTableRow extends Record<string, unknown> {
  nameAndId: NameDetails;
  employeeStatus: EmployeeTableStatus;
  contactDetails: ContactDetails;
  role: string;
}

export const TeamTable: FC<TeamTableProps> = ({ staff, fetching, locations }) => {
  const [locationList] = useLocationService(state => [state.locationList]);
  const [showDeletedUsersChecked, setShowDeletedUsersChecked] = useState<boolean>(
    window.localStorage.getItem(TableLocalStorageKeys.Deleted) === 'true'
  );
  const [showDeactivatedUsersChecked, setShowDeactivatedUsersChecked] = useState<boolean>(
    window.localStorage.getItem(TableLocalStorageKeys.Deactivated) === 'true'
  );

  const filteredStaff = useMemo(() => {
    let returnStaff: StaffMember[] = staff.filter(e => !e.isDeleted && !e.isDeactivated);

    if (showDeletedUsersChecked && showDeactivatedUsersChecked) {
      returnStaff = staff;
    } else if (showDeletedUsersChecked) {
      returnStaff = staff.filter(e => !e.isDeactivated);
    } else if (showDeactivatedUsersChecked) {
      returnStaff = staff.filter(e => !e.isDeleted);
    }

    return returnStaff;
  }, [showDeletedUsersChecked, showDeactivatedUsersChecked, staff]);

  const tableCell = (value: string | undefined, customComp: JSX.Element, isDeactivated?: boolean) => {
    return (
      <div className={`flex flex-row items-center gap-2 ${isDeactivated && 'opacity-60'}`}>
        {customComp}
        {value}
      </div>
    );
  };

  const buildName = useCallback(
    ({
      value,
      row: {
        original: {
          employeeStatus: { isDeactivated }
        }
      }
    }: {
      value: NameDetails;
      row: Row<TeamTableRow>;
    }) => {
      const buildLocationText = (names: string[]) => {
        if (names.length === 1) {
          return names[0];
        } else {
          return strings.locationText(names);
        }
      };

      return (
        <Link className="flex flex-row items-center gap-2" to={`/team-member/${value?.id}`}>
          <TimeshiftAvatar size={35} name={value?.name} greyscale={!!isDeactivated} />
          <div>
            <p className={isDeactivated && 'opacity-60'}>{value?.name}</p>
            {locationList.length > 1 && <p className="text-gray-500">{buildLocationText(value.locationNames)}</p>}
          </div>
        </Link>
      );
    },
    [locationList]
  );

  const buildContact = useCallback(
    ({
      value,
      row: {
        original: {
          employeeStatus: { isDeactivated }
        }
      }
    }: {
      value: ContactDetails;
      row: Row<TeamTableRow>;
    }) => {
      const content = (
        <div>
          {value.phonenumber && (
            <div className="flex gap-2 items-center">
              <FiPhone />
              {value.phonenumber}
            </div>
          )}
          {value.email && (
            <div className="flex gap-2 items-center">
              <FiMail />
              {value.email}
            </div>
          )}
        </div>
      );
      return tableCell(undefined, content, !!isDeactivated);
    },
    []
  );

  const buildRoleChit = useCallback(
    ({
      value,
      row: {
        original: {
          employeeStatus: { isDeactivated }
        }
      }
    }: {
      value?: string;
      row: Row<TeamTableRow>;
    }) => {
      return (
        <div className="w-min ml-auto mr-0 flex gap-2 items-center">
          {isDeactivated && (
            <TableChit importance={InfoChitImportance.MEDIUM}>{strings.dangerCard.deactivated}</TableChit>
          )}
          <TableChit>{value}</TableChit>
        </div>
      );
    },
    []
  );

  const rows: TeamTableRow[] | undefined = useMemo(
    () =>
      filteredStaff
        .filter(data => data.restaurants.some(res => locations?.includes(String(res.id))))
        .map(e => {
          const roleOptions: RoleOption[] = [
            {
              id: Role.Staff,
              name: 'Staff'
            },
            {
              id: Role.Manager,
              name: 'Manager'
            },
            {
              id: Role.Admin,
              name: 'Admin'
            }
          ];

          return {
            nameAndId: {
              name: strings.employeeName(e?.firstName ?? '', e?.lastName ?? ''),
              id: e.identityId,
              locationNames: e.restaurants.map(rest => rest.name)
            },
            employeeStatus: {
              isDeleted: e.isDeleted,
              isDeactivated: e.isDeactivated
            },
            contactDetails: {
              email: e.email,
              phonenumber: e.phoneNumber
            },
            role: roleOptions.find(i => i.id === e.role)?.name ?? e.role
          };
        }),
    [filteredStaff, locations]
  );

  const sortByName = (rowA: any, rowB: any) => {
    const startA = rowA.values.nameAndId.name.toLowerCase();
    const startB = rowB.values.nameAndId.name.toLowerCase();

    if (startA > startB) return 1;
    if (startB > startA) return -1;
    return 0;
  };

  const desktopColumns = useMemo(
    () => [
      {
        accessor: 'nameAndId',
        Cell: buildName,
        sortType: sortByName
      },
      {
        accessor: 'contactDetails',
        Cell: buildContact
      },
      {
        accessor: 'role',
        Cell: buildRoleChit
      }
    ],
    [buildName, buildContact, buildRoleChit]
  );

  const mobileColumns = useMemo(
    () => [
      {
        accessor: 'nameAndId',
        Cell: buildName,
        sortType: sortByName
      },
      {
        accessor: 'role',
        Cell: buildRoleChit
      }
    ],
    [buildName, buildRoleChit]
  );

  const [filterTerm, setFilterTerm] = useState<string>('');

  const toggleDeletedUsers = () => {
    window.localStorage.setItem(TableLocalStorageKeys.Deleted, (!showDeletedUsersChecked).toString());
    setShowDeletedUsersChecked(!showDeletedUsersChecked);
  };

  const toggleDeactivatedUsers = () => {
    window.localStorage.setItem(TableLocalStorageKeys.Deactivated, (!showDeactivatedUsersChecked).toString());
    setShowDeactivatedUsersChecked(!showDeactivatedUsersChecked);
  };

  return (
    <>
      <div className="border-b flex justify-end sm:items-center space-x-8 flex-col items-end sm:flex-row">
        <div className="flex sm:flex-row flex-col sm:space-x-8">
          <Checkbox
            className="mt-2 mr-2 sm:m-0 justify-between"
            label={strings.showDeletedUsers}
            onChange={toggleDeletedUsers}
            checked={showDeletedUsersChecked}
            rightAlignCheckbox
          />
          <Checkbox
            className="mt-2 mr-2 sm:m-0 justify-between"
            label={strings.showDeactivatedUsers}
            onChange={toggleDeactivatedUsers}
            checked={showDeactivatedUsersChecked}
            rightAlignCheckbox
          />
        </div>
        <TableFilter value={filterTerm} onChange={setFilterTerm} />
      </div>
      {fetching ? (
        <TableLoader></TableLoader>
      ) : (
        <>
          <div className="hidden lg:block">
            <TableContents
              includeRecord={globalFilter}
              filterValue={filterTerm}
              columns={desktopColumns}
              data={rows}
              pageSize={50}
            />
          </div>
          <div className="lg:hidden">
            <TableContents
              includeRecord={globalFilter}
              filterValue={filterTerm}
              columns={mobileColumns}
              data={rows}
              pageSize={50}
            />
          </div>
        </>
      )}
    </>
  );
};
