import { getDistance, getRhumbLineBearing } from 'geolib';

export interface Position {
  latitude: number;
  longitude: number;
}

export interface Distance {
  start: Position;
  end: Position;
  km: number;
  distanceOutOfRadius?: number;
}

export interface WorkLocation {
  position: Position;
  radius: number;
  name: string;
}

export const geolocationToGqlCoords = (coords?: Position) => {
  if (!(coords?.latitude && coords?.longitude)) return undefined;
  return { longitude: coords.longitude, latitude: coords.latitude };
};

const simplifyPosition = (possiblyComplicatedPosition: any) => {
  /*
   * I know this function seems insane, but it's actually really important.
   *
   * In the backend, co-ordinates are stored only as longitude/latitude pairs. When we
   * get the device's location, it includes a whole bunch of stuff like altitudes and
   * so on.
   *
   * If we try to compare these two positions, it returns NaN, because the store
   * location doesn't have an altitude. This function ensures we're comparing like
   * for like.
   */
  return {
    longitude: possiblyComplicatedPosition.longitude,
    latitude: possiblyComplicatedPosition.latitude
  };
};

interface PositionWithExtraStuff {
  longitude: number;
  latitude: number;
  [key: string]: any;
}

export const getDistanceInKm = (a: PositionWithExtraStuff, b: PositionWithExtraStuff) => {
  return getDistance(simplifyPosition(a), simplifyPosition(b)) / 1000;
};

export const getClosestLocationToPosition = (locations: WorkLocation[], location: Position) => {
  let closestLocation: WorkLocation | undefined;
  let closestLocationKm: number | undefined;
  locations.forEach(workLocation => {
    const km = getDistanceInKm(location, workLocation.position);

    if (closestLocationKm === undefined || closestLocationKm > km) {
      closestLocationKm = km;
      closestLocation = workLocation;
    }
  });

  return closestLocation;
};

export const getDistanceBetweenPositions = (locations: WorkLocation[], location: Position) => {
  const distancesToLocation = locations.map<Distance>(workLocation => {
    const km = getDistance(simplifyPosition(location), simplifyPosition(workLocation.position)) / 1000;
    return {
      start: location,
      end: workLocation.position,
      km,
      distanceOutOfRadius: workLocation.radius <= km ? km - workLocation.radius : undefined
    };
  });
  const [closest] = distancesToLocation.sort((a, b) => (a.km > b.km ? 1 : a.km < b.km ? -1 : 0));
  return closest;
};

export const calculateOffsetFromOrigin = (point: Position, origin: Position): [number, number, number] => {
  const hypotenuse = getDistance(point, origin) / 1000;
  const angle = getRhumbLineBearing(point, origin);

  const verticalDirection = angle <= 180;
  const verticalOffset = Math.sin(angle) * hypotenuse * (verticalDirection ? 1 : -1);
  const horizontalDirection = origin.longitude - point.longitude;
  const horizontalOffset = Math.cos(angle) * hypotenuse * (horizontalDirection < 0 ? -1 : 1);

  return [verticalOffset, horizontalOffset, hypotenuse];
};
