import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Loading } from '../../../components/shared/Loading';
import { useAuthenticationService } from '../authentication-service';

interface PostLogoutProps {}

export const PostLogout: FC<PostLogoutProps> = () => {
  const clearUser = useAuthenticationService(state => state.clearUser);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await clearUser();
      navigate('/');
    })();
  }, [clearUser, navigate]);

  return <Loading />;
};
