import { FC,
     useState } from 'react';
import { BaseLocation, useLocationService } from '../../services/location/LocationService';
import { LocationsCheckboxes } from '../../components/shared/checkboxes/LocationsCheckboxes';
import { Button } from '../../components/shared/button/Button';
import { commonStrings } from '../../services/translation/commonTranslations';
import { Modal } from '../../components/shared/modal/Modal';
import { FiX } from 'react-icons/fi';
import { Title } from '../../components/shared/text/Title';
import strings from './shifts.translations';

interface AllShiftsPageProps {
    onCompleteCallback: () => void;
    onClose: () => void;
    open: boolean;
}

export const UpdateSelectedLocationsModal: FC<AllShiftsPageProps> = ({
    onCompleteCallback,
    onClose,
    open
}) => {
  const [setSelectedShiftLocations] = useLocationService(state => [state.setSelectedShiftLocations]);
  const [selectedLocations, setSelectedLocations] = useState<BaseLocation[]>([]);

  const onComplete = () => {
    setSelectedShiftLocations(selectedLocations);
    onCompleteCallback();
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className='relative m-8 space-y-4'>
      <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
      </button>
      <Title>{strings.selectedLocationModalTitle}</Title>
      <LocationsCheckboxes onUpdate={locations => setSelectedLocations(locations)} />
      <Button className="sm:mr-3 w-full sm:w-auto" onClick={onClose}>
          {commonStrings.common.cancel}
        </Button>
        <Button primary className="w-full sm:w-auto" onClick={onComplete} loading={false}>
            {commonStrings.common.ok}
        </Button>
    </div>
    </Modal>
  );
};
