import React, { useEffect, FC, useState, HTMLAttributes } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import strings from './translations';
import { merge } from '../../../helpers/Utility';

interface PageControlsProps extends HTMLAttributes<HTMLDivElement> {
  currentPage: number;
  numberOfPages?: number;

  forward: () => void;
  canGoForward: boolean;

  backward: () => void;
  canGoBackward: boolean;
}

export const PageControls: FC<PageControlsProps> = ({
  className,
  currentPage,
  numberOfPages,
  backward,
  canGoBackward,
  forward,
  canGoForward
}) => {
  return (
    <div className={merge(className, 'flex pt-3 pb-5 justify-center items-center')}>
      <button disabled={!canGoBackward} onClick={backward} className={!canGoBackward ? 'pointer-events-none' : ''}>
        <FiChevronLeft className={`${!canGoBackward ? 'text-tertiary-std' : 'text-primary-std'} h-7 w-7`} />
      </button>
      <p className="mx-2">{strings.paginationComponent(currentPage, numberOfPages)}</p>
      <button disabled={!canGoForward} onClick={forward} className={!canGoForward ? 'pointer-events-none' : ''}>
        <FiChevronRight className={`${!canGoForward ? 'text-tertiary-std' : 'text-primary-std'} h-7 w-7`} />
      </button>
    </div>
  );
};

export const usePagination = (countPerPage: number, total?: number): [number, number, PageControlsProps] => {
  const [numberOfPages, setNumberOfPages] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    // When supplied with props, if there is a known total, calculate how many pages there will be
    if (!total) return;
    setNumberOfPages(Math.ceil(total / countPerPage));
  }, [countPerPage, total]);

  const backward = () => setCurrentPage(currentPage - 1);
  const forward = () => setCurrentPage(currentPage + 1);

  // TODO: Write logic to ensure that you can't go past where there is data for
  const canGoBackward = currentPage >= 1;
  const canGoForward = !numberOfPages || currentPage < numberOfPages - 1;

  const offset = currentPage * countPerPage;
  const limit = countPerPage;

  return [offset, limit, { currentPage, numberOfPages, forward, backward, canGoForward, canGoBackward }];
};

export const InfiniteItemsExample = () => {
  const [offset, limit, controls] = usePagination(30);

  return (
    <div>
      {offset} {limit}
      <footer>
        <PageControls {...controls} />
      </footer>
    </div>
  );
};

export const HasTotalExample = () => {
  const totalNumberOfItems = 100;
  const [offset, limit, controls] = usePagination(30, totalNumberOfItems);

  return (
    <div>
      {offset} {limit}
      <footer>
        <PageControls {...controls} />
      </footer>
    </div>
  );
};

// This is not an ideal situation, we should know the total before pagination but in
// circumstances where we can't, this will work 100% of the time.
export const StopProgressOnNoMoreDataExample = () => {
  const [offset, limit, controls] = usePagination(2);

  // A stand in for getting data from the server
  const data = offset < 2 ? ['Item 1', 'Item 2'] : ['Item 2'];

  // If the data on current page isn't fully saturating the offset,
  // it would indicate that there is no more data to come
  const hasNoMoreData = data.length < offset;

  return (
    <div>
      {offset} {limit}
      <footer>
        <PageControls {...controls} canGoForward={!hasNoMoreData} />
      </footer>
    </div>
  );
};
