import { FC, Suspense, useState } from 'react';
import { differenceInDays, parseJSON, subWeeks } from 'date-fns';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { PageContainer } from '../../components/page/PageContainer';
import strings from './shifts.translations';
import { TableContainer } from '../../components/shared/table/TableContainer';
import { PageHeader } from '../../components/page/PageHeader';
import { FiClock, FiDatabase, FiMapPin } from 'react-icons/fi';
import { TableLoader } from '../../components/shared/table/TableLoader';
import { useNavigate, useParams } from 'react-router';
import { Feature } from '../../services/backstage/Feature';
import { EmployeeShiftsTable } from './EmployeeShiftsTable';
import { Button } from '../../components/shared/button/Button';
import { CustomIntervalTimePicker } from '../../components/shared/date/CustomIntervalTimePicker';
import { dayDiff } from '../../helpers/dateHelper';
import { useLocationService } from '../../services/location/LocationService';
import { UpdateSelectedLocationsModal } from './UpdateSelectedLocationsModal';
import { ShowingDataForLocationsText } from './ShowingDataForLocationsText';

export const AllShiftsPage: FC = () => {
  const { recordId } = useParams<'recordId'>();
  const { shiftStart } = useParams<'shiftStart'>();

  const [locations, selectedShiftLocations] = useLocationService(state => [
    state.locationList,
    state.selectedShiftLocations
  ]);

  const navigate = useNavigate();

  const getSensibleStartDate = () => {
    const dif = Math.abs(differenceInDays(parseJSON(shiftStart!).setHours(0), parseJSON(new Date())));
    if (dif > 7) {
      //We only show 1 week of shift records, if it's older than a week, we need to change the date range.
      return parseJSON(shiftStart!);
    }

    let today = new Date();
    const tomorrow = today.setDate(today.getDate() + 1);

    return subWeeks(tomorrow, 1);
  };

  const [[startDate, endDate], setDatePair] = useState<[Date, Date]>(() => [getSensibleStartDate(), new Date()]);

  const breadcrumbs = [{ name: strings.allShifts, to: '/shifts' }];

  const setDatePairCallback = (dates: [Date, Date]) => {
    setDatePair(dates);
  };

  const [showUpdateLocationModal, setShowUpdateLocationModal] = useState<boolean>(false);

  return (
    <PageContainer
      customHeader={
        <PageHeader pageTitle={strings.allShifts} breadcrumbs={breadcrumbs} icon={<FiClock />}>
          <div className="flex flex-col md:flex-row gap-2 md:gap-5">
            {locations?.length > 1 && (
              <Button
                onClick={() => setShowUpdateLocationModal(true)}
                primary
                className="flex items-center justify-center space-x-2"
              >
                <FiMapPin />
                <span>{strings.selectLocations}</span>
              </Button>
            )}
            <Feature name="reports">
              <Button
                onClick={() => navigate('/shifts/report')}
                primary
                className="flex items-center justify-center space-x-2"
              >
                <FiDatabase />
                <span>{strings.viewReport}</span>
              </Button>
            </Feature>
          </div>
        </PageHeader>
      }
    >
      <PageSizer mode={SizerMode.PAGE}>
        <TableContainer>
          <div className="md:p-6 p-4 space-y-3">
            <ShowingDataForLocationsText />
            <div className="flex items-stretch justify-center ">
              <CustomIntervalTimePicker
                startDate={startDate}
                endDate={endDate}
                onChange={setDatePairCallback}
                dayDiff={dayDiff(startDate, endDate)}
                labels={{
                  previous: { hide: true },
                  next: { hide: true }
                }}
              />
            </div>
          </div>
          <Suspense fallback={<TableLoader />}>
            <EmployeeShiftsTable
              startDate={startDate}
              endDate={endDate}
              recordId={recordId}
              locations={selectedShiftLocations
                .filter(location => location.checked && location.key)
                .map(location => location.key)}
            />
          </Suspense>
        </TableContainer>
      </PageSizer>
      <UpdateSelectedLocationsModal
        onCompleteCallback={() => {
          setShowUpdateLocationModal(false);
        }}
        open={showUpdateLocationModal}
        onClose={() => setShowUpdateLocationModal(false)}
      />
    </PageContainer>
  );
};
