import create from 'zustand';
import { User as OIDCUser, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { getVariable, service } from '../backstage/BackstageProvider';

let host = window.location.protocol.concat('//');
host = host.concat(window.location.hostname);
if (window.location.port) {
  host = host.concat(':');
  host = host.concat(window.location.port);
}

const getConfig = async () => {
  const IDENTITY_CLIENT_ID = await getVariable('authIdentityClientId');
  const AUTH_SERVER_URL = await getVariable('authUrl');
  const REDIRECT_URL = await getVariable('authRedirectUrl');
  const LOGOFF_REDIRECT_URL = await getVariable('authLogoffRedirectUrl');

  const IDENTITY_CONFIG = {
    authority: AUTH_SERVER_URL,
    client_id: IDENTITY_CLIENT_ID,
    redirect_uri: host.concat(REDIRECT_URL),
    post_logout_redirect_uri: host.concat(LOGOFF_REDIRECT_URL),
    response_type: 'code',
    scope: 'openid profile email api.read offline_access IdentityServerApi',
    filterProtocolClaims: true,
    loadUserInfo: true,
    extraQueryParams: {
      setPasswordRedirect: host
    }
  };

  const METADATA_OIDC = {
    issuer: AUTH_SERVER_URL,
    jwks_uri: AUTH_SERVER_URL.concat('/.well-known/openid-configuration/jwks'),
    authorization_endpoint: AUTH_SERVER_URL.concat('/connect/authorize'),
    token_endpoint: AUTH_SERVER_URL.concat('/connect/token'),
    userinfo_endpoint: AUTH_SERVER_URL.concat('/connect/userinfo'),
    end_session_endpoint: AUTH_SERVER_URL.concat('/connect/endsession'),

    /* TODO There is a huge reload loop issue with using this functionality on cookie
         protected browsers (safari, firefox) and modes (chrome incognito)
         check_session_iframe: AUTH_SERVER_URL.concat('/connect/checksession'),
         */
    revocation_endpoint: AUTH_SERVER_URL.concat('/connect/revocation'),
    introspection_endpoint: AUTH_SERVER_URL.concat('/connect/introspect')
  };

  return { IDENTITY_CONFIG, METADATA_OIDC, AUTH_SERVER_URL, IDENTITY_CLIENT_ID };
};

export enum AuthenticationState {
  Authenticated = 'Authenticated',
  Unauthenticated = 'Unauthenticated'
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Admin = 'TimeshiftAdmin',
  Manager = 'TimeshiftManager',
  Staff = 'TimeshiftStaff'
}

export const isStaff = (role: Role | undefined) => role && role.includes(Role.Staff);

export const isManagement = (role: Role | undefined) =>
  role && (role.includes(Role.Admin) || role.includes(Role.Manager));

export const isAdmin = (role: Role | undefined) => role === Role.Admin;

export enum Permissions {
  EmployeeCreate = 'Employee.Create',
  EmployeeRead = 'Employee.Read',
  EmployeeReadAll = 'Employee.ReadAll',
  EmployeeUpdate = 'Employee.Update',
  EmployeeDelete = 'Employee.Delete',
  EmployeeDeactivate = 'Employee.Deactivate',
  SettingsCreate = 'Settings.Create',
  TemplateCreate = 'Template.Create',
  TemplateRead = 'Template.Read',
  TemplateUpdate = 'Template.Update',
  TemplateDelete = 'Template.Delete',
  WorkingPatternRead = 'WorkingPattern.Read',
  WorkingPatternUpdate = 'WorkingPattern.Update',
  AreaCreate = 'Area.Create',
  WorkingPatternCreate = 'WorkingPattern.Create',
  AreaRead = 'Area.Read',
  AreaUpdate = 'Area.Update',
  AreaDelete = 'Area.Delete',
  AvailabilityCreate = 'Availability.Create',
  AvailabilityRead = 'Availability.Read',
  AvailabilityUpdate = 'Availability.Update',
  OperatingHoursCreate = 'OperatingHours.Create',
  OperatingHoursRead = 'OperatingHours.Read',
  OperatingHoursUpdate = 'OperatingHours.Update',
  OperatingHoursDelete = 'OperatingHours.Delete',
  OptimiserExecute = 'Optimiser.Execute',
  RestaurantCreate = 'Restaurant.Create',
  RestaurantRead = 'Restaurant.Read',
  RestaurantUpdate = 'Restaurant.Update',
  RosterCreate = 'Roster.Create',
  RosterRead = 'Roster.Read',
  RosterUpdate = 'Roster.Update',
  RosterDelete = 'Roster.Delete',
  SettingsRead = 'Settings.Read',
  SettingsUpdate = 'Settings.Update',
  SettingsDelete = 'Settings.Delete',
  SkillsCreate = 'Skills.Create',
  SkillsRead = 'Skills.Read',
  SkillsUpdate = 'Skills.Update',
  SkillsDelete = 'Skills.Delete',
  StationCreate = 'Station.Create',
  StationRead = 'Station.Read',
  StationUpdate = 'Station.Update',
  StationDelete = 'Station.Delete',
  TargetCreate = 'Target.Create',
  TargetUpdate = 'Target.Update',
  TargetDelete = 'Target.Delete',
  TargetRead = 'Target.Read',
  TaskCreate = 'Task.Create',
  TaskRead = 'Task.Read',
  TaskUpdate = 'Task.Update',
  TaskDelete = 'Task.Delete',
  WorkingPatternDelete = 'WorkingPattern.Delete',
  AvailabilityDelete = 'Availability.Delete',
  RestaurantDelete = 'Restaurant.Delete',
  WorkingTimeDataCreate = 'WorkingTimeData.Create',
  WorkingTimeDataRead = 'WorkingTimeData.Read',
  WorkingTimeDataReadAll = 'WorkingTimeData.ReadAll',
  WorkingTimeDataUpdate = 'WorkingTimeData.Update',
  WorkingTimeDataCreateSelf = 'WorkingTimeData.CreateSelf',
  WorkingTimeDataReadSelf = 'WorkingTimeData.ReadSelf',
  WorkingTimeDataUpdateSelf = 'WorkingTimeData.UpdateSelf'
}

export const getTenantConfiguration = async (id: string) => {
  try {
    const url = `${window.BACKSTAGE_URL}${window.BACKSTAGE_ENVIRONMENT}/${id}.json`;
    const response = await fetch(url);
    const config = await response.json();
    await service.updateConfiguration({ name: 'HTTPTenantProvider', priority: 2, config });
  } catch (e) {
    return {};
  }
};

export type User = OIDCUser & { profile?: { organisation?: string; permission: Permissions[] } };

export interface AuthService {
  state: AuthenticationState;
  user?: User;
  role?: Role;
  name?: string;
  userManager: Promise<UserManager>;

  setUser: (user: User) => Promise<void>;
  clearUser: () => Promise<void>;
  restore: () => Promise<void>;
}

const ROLE_KEY = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const useAuthenticationService = create<AuthService>((set, get) => ({
  state: AuthenticationState.Unauthenticated,
  restore: async () => {
    const { AUTH_SERVER_URL, IDENTITY_CLIENT_ID } = await getConfig();
    const token = sessionStorage.getItem(`oidc.user:${AUTH_SERVER_URL}:${IDENTITY_CLIENT_ID}`);

    if (!token) {
      set(state => {
        return { ...state, state: AuthenticationState.Unauthenticated };
      });
    } else {
      const user = JSON.parse(token) as User;
      if (user.profile.organisation) await getTenantConfiguration(user.profile.organisation);

      set(state => {
        const name = (() => {
          const names = user.profile.name as unknown;
          if (typeof names === 'string') return names;
          return (names as string[]).toString().split(',')[1];
        })();
        return { ...state, user, name, state: AuthenticationState.Authenticated, role: user.profile[ROLE_KEY] as Role };
      });
    }
  },
  setUser: async (user: User) => {
    if (user.profile.organisation) await getTenantConfiguration(user.profile.organisation);

    set(state => {
      const role = user.profile[ROLE_KEY] as Role;
      const name = (() => {
        const names = user.profile.name as unknown;
        if (typeof names === 'string') return names;
        return (names as string[]).toString().split(',')[1];
      })();
      return { ...state, user, state: AuthenticationState.Authenticated, role, name };
    });
  },
  clearUser: async () =>
    await set(state => {
      state.userManager.then(userManager => {
        userManager.clearStaleState();
      });
      return { ...state, user: undefined, state: AuthenticationState.Unauthenticated };
    }),
  userManager: (async () => {
    const { IDENTITY_CONFIG, METADATA_OIDC } = await getConfig();
    return new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC
      }
    });
  })()
}));

const authService = useAuthenticationService.getState();
authService.restore();

authService.userManager.then(userManager => {
  const renewAccessToken = async () => {
    try {
      const user = (await userManager.signinSilent()) as User;
      await authService.setUser(user);
    } catch {
      userManager.signinRedirect();
    }
  };

  userManager.events.addAccessTokenExpired(renewAccessToken);

  // In the scenario where the application is in the background (different active tab, device is sleeping, etc) the access token can become expired causing gql errors.
  // Previously we were only relying on "addAccessTokenExpired" to trigger in these scenarios, in order to renew the token, however, unfortunately it doesnt seem to always work.
  // It seems like "addAccessTokenExpiring" does, however, appear to get called in the background.
  // So a workaround to the issue is to renew here. (Note: this should result in the "addAccessTokenExpired" listener never getting fired.)
  userManager.events.addAccessTokenExpiring(renewAccessToken);
});

export const getBaseURL = async () => {
  const url = await getVariable('apiUrl');
  if (!url) {
    throw new Error('GraphQL url is not defined.');
  }
  return url.replace('/graphql', '/api');
};

export const getDefaultFetchOptions = async () => {
  const { user } = useAuthenticationService.getState();
  const options: { headers: { [key: string]: string } } = {
    headers: { 'content-type': 'application/json', accept: 'application/json' }
  };
  if (user) options.headers['authorization'] = `Bearer ${user.access_token}`;
  const baseUrl = await getBaseURL();
  return { options, baseUrl };
};
