import * as React from 'react';
import {
  EmployeeWorkTimeRecord,
  UpdateWorkTimeOrigin,
  useUpdateMyShiftMutation,
  useUpdateShiftMutation,
  WorkTimeChangeReason,
  WorkTimeDataRisk,
  WorkTimeDataSource
} from '../../../services/gql/graphql';
import strings from '../shifts.translations';
import { useForm } from 'react-hook-form';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitCancelButtons } from '../../../components/shared/form/SubmitCancelButtons';
import { setSeconds } from 'date-fns';
import { Permissions, useAuthenticationService } from '../../../services/authentication/authentication-service';
import { Button } from '../../../components/shared/button/Button';
import { TsForm } from '../../../components/shared/form/Form';
import { DateTimeField } from '../../../components/shared/date/DateTimeInput';
import { SelectField } from '../../../components/shared/form/SelectForm';

interface EditShiftFormProps {
  shift: EmployeeWorkTimeRecord;
  onComplete: () => void;
  suggestedOutTime?: Date;
  isMe?: boolean;
}

interface RiskOption {
  id: WorkTimeDataRisk;
  name: string;
}

interface EditShiftFields {
  start: Date;
  end?: Date;
  risk: RiskOption;
}

export const EditShiftForm: React.FC<EditShiftFormProps> = ({ shift, onComplete, suggestedOutTime, isMe }) => {
  const [canUpdateOthers] = useAuthenticationService(state => [
    state.user?.profile.permission.includes(Permissions.WorkingTimeDataUpdate)
  ]);
  const riskOptions: RiskOption[] = [
    {
      id: WorkTimeDataRisk.Low,
      name: strings.editShiftForm.riskOptions.low
    },
    {
      id: WorkTimeDataRisk.Medium,
      name: strings.editShiftForm.riskOptions.medium
    },
    {
      id: WorkTimeDataRisk.High,
      name: strings.editShiftForm.riskOptions.high
    }
  ];

  const selectedOption = riskOptions.find(option => option.id === shift.risk) || riskOptions[0];

  const methods = useForm<EditShiftFields>({
    defaultValues: {
      start: new Date(shift.start?.dateTime),
      end: shift.end ? new Date(shift.end?.dateTime) : suggestedOutTime,
      risk: selectedOption
    }
  });

  const mutation = isMe ? useUpdateMyShiftMutation : useUpdateShiftMutation;
  const [updateShiftStatus, updateShift] = mutation();
  const handleError = useErrorHandler();

  const onSubmit = async (data: EditShiftFields) => {
    const start = setSeconds(data.start, 0);
    const end = data.end ? setSeconds(data.end, 0) : undefined;

    if (end && start > end) {
      methods.setError('end', {
        type: 'custom',
        message: strings.editShiftForm.endBeforeStart
      });
      return;
    }

    const endObject: UpdateWorkTimeOrigin | undefined = data.end
      ? {
          dateTime: end,
          origin: shift.end?.origin ?? WorkTimeDataSource.Override
        }
      : undefined;

    const result = await updateShift({
      change: {
        recordId: shift.id,
        changeReason: WorkTimeChangeReason.ManagerCorrection,
        start: {
          dateTime: start,
          origin: shift.start?.origin
        },
        end: endObject,
        risk: canUpdateOthers ? data.risk.id : WorkTimeDataRisk.High
      }
    });

    if (result.error) {
      handleError(result.error);
    } else {
      onComplete();
    }
  };
  const onClockBackIn = () => {
    const newData = methods.getValues();
    newData.end = undefined;
    onSubmit(newData);
  };

  const inPastCondition = (date: Date) => setSeconds(date, 0) < new Date();
  const validateStartDate = (date: Date) => {
    return inPastCondition(date);
  };
  const validateEndDate = (date: Date) => {
    return date && inPastCondition(date);
  };

  return (
    <TsForm methods={methods} onSubmit={onSubmit}>
      <DateTimeField
        name="start"
        title={strings.editShiftForm.clockIn}
        required
        validator={validateStartDate}
        validationMessage={strings.editShiftForm.clockInRequired}
        customValidatorMessage={strings.editShiftForm.clockInValidation}
      />
      <DateTimeField
        name="end"
        title={strings.editShiftForm.clockOut}
        validator={validateEndDate}
        customValidatorMessage={methods.formState.errors.end?.message || strings.editShiftForm.clockOutValidation}
      />
      {canUpdateOthers && (
        <SelectField
          name="risk"
          title={strings.editShiftForm.risk}
          required
          validationMessage={strings.editShiftForm.riskRequired}
          options={riskOptions}
        />
      )}
      <div className="flex items-center mt-5">
        {methods.getValues().end && (
          <Button onClick={onClockBackIn} className="whitespace-nowrap w-full">
            {strings.editShiftForm.undoClockOut}
          </Button>
        )}
        <SubmitCancelButtons
          onComplete={onComplete}
          loading={updateShiftStatus.fetching}
          submitText={strings.editShiftForm.update}
        />
      </div>
    </TsForm>
  );
};
