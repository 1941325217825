import React, { FC } from 'react';
import { RosterDateSwitch } from '../ganttView/RosterDateSwitch';
import { GanttTimerBar } from '../../../components/shared/gantt/GanttTimerBar';
import { useRosterService } from '../ganttView/rosterService';
import { LEFT_COLUMN_WIDTH } from '../ganttView/Roster';

interface RosterTimerBarProps {
  readOnly?: boolean;
}

export const RosterTimerBar: FC<RosterTimerBarProps> = ({ readOnly }) => {
  const [rosterDate, minTime, maxTime] = useRosterService(state => [
    state.roster.rosterDate,
    state.minTime,
    state.maxTime
  ]);

  return (
    <div className="grid grid-cols-2 border-b-2 bg-primary-std" style={{ gridTemplateColumns: `${LEFT_COLUMN_WIDTH}px auto` }}>
      <RosterDateSwitch rosterDate={new Date(rosterDate)} readOnly={readOnly} />
      <GanttTimerBar minTime={minTime} maxTime={maxTime} />
    </div>
  );
};
