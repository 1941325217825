import React, { FC, HTMLAttributes } from 'react';
import { Breadcrumbs, NavPage } from '../../../components/page/Breadcrumbs';
import { PageSizer, SizerMode } from '../../../components/page/PageSizer';
import { ReadEmployee } from '../../../services/gql/graphql';
import { TeamMemberHeaderContent } from './TeamMemberHeaderContent';
import { TeamMemberHeaderContentSkeleton } from './TeamMemberHeaderContentSkeleton';

interface TeamMemberHeaderProps extends HTMLAttributes<HTMLDivElement> {
  breadcrumbs?: NavPage[];
  employee?: ReadEmployee;
  sendInfoCallback?: () => void;
  isMe?: boolean;
}

export const TeamMemberHeader: FC<TeamMemberHeaderProps> = ({ breadcrumbs, employee, sendInfoCallback, isMe }) => {
  let className = 'w-full bg-secondary-std border-primary-std border-b';

  return (
    <div className={className}>
      <PageSizer mode={SizerMode.HEADER}>
        {breadcrumbs && <Breadcrumbs pages={breadcrumbs} />}
        {employee ? (
          <TeamMemberHeaderContent employee={employee!} sendInfoCallback={sendInfoCallback} isMe={isMe} />
        ) : (
          <TeamMemberHeaderContentSkeleton />
        )}
      </PageSizer>
    </div>
  );
};
