import React, { FC } from 'react';
import { useRosterService } from '../../../ganttView/rosterService';
import { RosterDateSwitch } from '../../../ganttView/RosterDateSwitch';
import { generateColors } from '../../../ganttView/rosterHelper';
import { StationHeader } from '../../../ganttView/StationHeader';
import { RosterPickerIcon } from '../../../ganttView/RosterPickerIcon';
import { secondsSinceMidnightToTimeString } from '../../../../../helpers/dateHelper';
import strings from '../../../roster.translations';

export const ESSMobileRosterView: FC = () => {
  const [roster] = useRosterService(state => [state.roster]);
  const colors = generateColors(roster.stations?.length || 0);

  return (
    <>
      <RosterDateSwitch rosterDate={new Date(roster.rosterDate)} readOnly />
      <div className="flex flex-col gap-5 mt-2">
        {roster.stations?.map((station, idx) => {
          const staffMemberToShow = station.positions?.find(pos => !!pos.employee);

          return (
            <div>
              <StationHeader station={station} color={colors[idx]} readOnly={true} />
              {!staffMemberToShow ? (
                <div className="px-5 py-3 flex justify-center">{strings.essMobile.noStaff}</div>
              ) : (
                <div className="flex flex-col gap-2 px-5 py-2">
                  {station.positions?.map(position => {
                    if (!position.employee) {
                      return null;
                    }

                    const employeeName = `${position.employee.firstName} ${position.employee.lastName}`;

                    return (
                      <div className="flex justify-between">
                        <div className="flex gap-2 items-center">
                          <RosterPickerIcon name={employeeName} />
                          <div>{employeeName}</div>
                        </div>
                        <div>
                          {`${secondsSinceMidnightToTimeString(position.start)} - ${secondsSinceMidnightToTimeString(
                            position.end
                          )}`}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
