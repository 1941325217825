import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarEvent } from '../common/Calendar';
import { ReadRoster, Shift, WorkingPatternDay } from '../../../../services/gql/graphql';
import { useRosterSelectService } from '../common/rosterSelectService';
import { getDaysInMonth, getStartAndEndDateOfMonth } from '../../../../helpers/dateHelper';
import { canWorkTargetDay, dateToRosterDateFormat } from '../../rosterHelper';
import { RosterCalendarContextObject, RosterStatus } from '../rosterCalendar/RosterCalendar';
import { StaffCalendarContextObject } from '../../rosterTypes';
import { useLocationService } from '../../../../services/location/LocationService';

export const useBuildESSCalendarEvents = (workPatterns: WorkingPatternDay[]) => {
  const navigate = useNavigate();

  const [getRosterByLocationAndDate] = useRosterSelectService(state => [state.getRosterByLocationAndDate]);

  const [events, setEvents] = useState<CalendarEvent<RosterCalendarContextObject>[]>([]);

  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);

  const [calendarLoading, setCalendarLoading] = useState<boolean>(false);

  const buildEvents = useCallback(
    async (monthToShow: Date, shifts?: Shift[]) => {
      setCalendarLoading(true);

      const { startDate, endDate } = getStartAndEndDateOfMonth(monthToShow);

      const rosterData = (await getRosterByLocationAndDate(startDate, endDate, locationId!)) as ReadRoster[];

      const daysInMonth = getDaysInMonth(monthToShow.getMonth(), monthToShow.getFullYear());

      const newEvents = daysInMonth.map(day => {
        const event: CalendarEvent<StaffCalendarContextObject> = { date: day, contextObject: {} };

        const dateToCompare = dateToRosterDateFormat(day);
        const roster = rosterData.find(r => r.rosterDate.substring(0, 19) === dateToCompare);

        if (roster && roster.status === RosterStatus.published) {
          event.contextObject!.status = roster.status as RosterStatus;
          event.contextObject!.id = roster.id;

          event.contextObject!.onClick = () => {
            navigate(`/calendar/${roster.id}/${roster.rosterDate.substring(0, 10)}/StaffRosterView`);
          };
        }

        event.contextObject!.shift = shifts?.find(shift => shift.date.substring(0, 19) === dateToCompare);

        event.contextObject!.canWork = canWorkTargetDay(workPatterns!, day);

        return event;
      });

      setCalendarLoading(false);

      setEvents(newEvents);
    },
    [getRosterByLocationAndDate, navigate, setEvents, workPatterns, locationId]
  );

  return { buildEvents, events, calendarLoading };
};
