import React, { FC } from 'react';
import strings from '../../../pages/team/team.translations';
import { FiMail, FiPhone } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { IconButton } from '../button/IconButton';


export enum ContactInfo {
  none,
  email,
  phone
}

export type ContactInfoType = typeof ContactInfo[keyof typeof ContactInfo];

interface OptionalContactInfoProps {
  selectedContactType: ContactInfo;
  setContactTypeCallback: (contactType: ContactInfo) => void;
  index?: number;
}

export const OptionalContactInfo: FC<OptionalContactInfoProps> = ({ selectedContactType, setContactTypeCallback, index = 0 }) => {
  const contactInfoButtonStyle = (contactType: ContactInfo) => {
    return `border rounded-none w-full flex place-content-center ${selectedContactType === contactType && 'underline'}`;
  };

  return (
    <div className="flex flex-row space-x-1 w-full sm:w-auto sm:space-x-0">
      <div className="flex flex-col w-full">
        <IconButton
          className={contactInfoButtonStyle(ContactInfo.email)}
          label={strings.addTeamMemberForm.email}
          Icon={FiMail}
          onClick={() => {
            setContactTypeCallback(ContactInfo.email);
          }}
        />
        {selectedContactType === ContactInfo.email && (
          <motion.div
            className="bg-primary-500 flex bottom-0 w-full rounded-b-lg h-[5px]"
            layoutId={`contact-tab-underline-${index}`}
            transition={{ type: 'spring', stiffness: 500, damping: 25 }}
          />
        )}
      </div>

      <div className="flex flex-col w-full">
        <IconButton
          className={contactInfoButtonStyle(ContactInfo.phone)}
          label={strings.addTeamMemberForm.phoneNumber}
          Icon={FiPhone}
          onClick={() => {
            setContactTypeCallback(ContactInfo.phone);
          }}
        />
        {selectedContactType === ContactInfo.phone && (
          <motion.div
            className="bg-primary-500 flex bottom-0 w-full rounded-b-lg h-[5px]"
            layoutId={`contact-tab-underline-${index}`}
            transition={{ type: 'spring', stiffness: 500, damping: 25 }}
          />
        )}
      </div>
    </div>
  );
};
