import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRosterByIdQuery, ReadRoster } from '../../../../services/gql/graphql';
import { useLocationService } from '../../../../services/location/LocationService';
import { RosterServiceProvider } from '../../ganttView/rosterService';
import { StaffRosterView } from './StaffRosterView';

export const StaffRosterPage: FC = () => {
  const { rosterId } = useParams<'rosterId'>();
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);
  const navigate = useNavigate();

  const minTime = new Date(0);
  minTime.setHours(9);

  const maxTime = new Date(0);
  maxTime.setHours(22);

  const [{ data: rosterData }] = useGetRosterByIdQuery({
    variables: {
      id: rosterId
    },
    requestPolicy: 'network-only'
  });

  useEffect(() => {
    if (rosterData?.roster?.location?.id !== locationId) {
      navigate("/calendar");
    }
  }, [locationId, navigate, rosterData]);

  return (
    <RosterServiceProvider roster={rosterData!.roster as ReadRoster} minTime={minTime} maxTime={maxTime} key={rosterId}>
      <StaffRosterView />
    </RosterServiceProvider>
  );
};
