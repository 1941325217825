import LocalizedStrings from 'react-localization';
import { withCommon } from '../../../services/translation/commonTranslations';

const calendarStrings = new LocalizedStrings({
  en: {
    calendarBreadcrumbTitle: 'Calendar',
    cardTitle: 'Calendar',
    shorthandDays: {
      Sunday: 'Sun',
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat'
    }
  }
});

export default withCommon<typeof calendarStrings>(calendarStrings);
