import React, { FC, Suspense, useState } from 'react';
import { SubComponentProps } from '../../../components/shared/table/TableContents';
import { ShiftsTableRow } from '../ShiftsTable';
import { TimeSummary } from './TimeSummary';
import { TableLoader } from '../../../components/shared/table/TableLoader';
import { MapSummary } from './MapSummary';
import { EditShiftModal } from '../editShift/EditShiftModal';
import { FiEdit } from 'react-icons/fi';
import strings from '../shifts.translations';
import { ExplainerModal } from '../../../components/shared/modal/ExplainerModal';
import { Feature } from '../../../services/backstage/Feature';
import { calculateMinutesDuration, displayMinutesAsHoursMinutes } from '../../../helpers/dateHelper';
import { EmployeeWorkTimeRecord } from '../../../services/gql/graphql';
import { getPayToDisplay } from '../../../helpers/pay';
import { Button } from '../../../components/shared/button/Button';
import { IconButton } from '../../../components/shared/button/IconButton';
import { Watch } from '../../../components/shared/icons/Watch';

export enum ShiftDetailsSummaryType {
  IN,
  OUT
}

export const ShiftDetailsView: FC<SubComponentProps<ShiftsTableRow>> = ({ row }) => {
  const start = row.record.start;
  const end = row.record.end;

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [suggestedClockOutTime, setSuggestedClockOutTime] = useState<Date | undefined>();

  const onClose = () => {
    setEditModalOpen(false);
    setSuggestedClockOutTime(undefined);
    row.onShiftEdit && row.onShiftEdit();
  };

  const handleClockOut = () => {
    setSuggestedClockOutTime(new Date());
    setEditModalOpen(true);
  };

  const shiftLengthInMinutes = calculateMinutesDuration(start?.dateTime, end?.dateTime || new Date());
  const rateOfPay = row.record.employee?.hourlyRate || 0;

  return (
    <Suspense fallback={<TableLoader />}>
      <div className="flex flex-col sm:flex-row gap-5 p-5">
        <div className={'flex-1 flex flex-col gap-5 border-b sm:border-b-0 sm:border-r pb-5 sm:pb-0'}>
          <div className={'flex flex-col relative gap-5'}>
            <div className="flex items-stretch gap-5">
              <div className="flex-1">
                {start && <TimeSummary type={ShiftDetailsSummaryType.IN} dateTime={new Date(start.dateTime)} />}
              </div>
              <div className="flex-1">
                {end ? (
                  <TimeSummary type={ShiftDetailsSummaryType.OUT} dateTime={new Date(end.dateTime)} />
                ) : (
                  <div className={'flex h-full items-center'}>
                    <Button
                      onClick={handleClockOut}
                      primary
                      className="flex items-center justify-center w-full gap-2 md:w-max self-center mt-2 md:mt-0"
                    >
                      <Watch className="w-4 h-6" />
                      {strings.common.clockOut}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <Button className="flex justify-center gap-2 items-center sm:hidden" onClick={() => setEditModalOpen(true)}>
              <FiEdit />
              {strings.detailView.editShift}
            </Button>
            <IconButton
              Icon={FiEdit}
              label="Edit shift"
              className="top-0 right-0 absolute hidden sm:block"
              onClick={() => setEditModalOpen(true)}
            />
          </div>
          <div className="flex flex-col gap-5 sm:flex-row w-full">
            <div className={'flex-1'}>
              <div className="flex-1 flex items-center">{strings.detailView.duration}</div>
              <div>{displayMinutesAsHoursMinutes(shiftLengthInMinutes)}</div>
            </div>
            <Feature name={'pay'}>
              <div className={'flex-1'}>
                <div className="flex-1 flex items-center">
                  {strings.detailView.estimatedCost}
                  <div className={'relative flex items-center'}>
                    <ExplainerModal
                      className={'absolute -left-1'}
                      label={strings.common.detailViewCommon.estimatedCost}
                      title={strings.common.detailViewCommon.estimatedCost}
                      content={strings.common.detailViewCommon.estimatedCostExplanation}
                    />
                  </div>
                </div>
                <div>{getPayToDisplay(shiftLengthInMinutes, rateOfPay)}</div>
              </div>
            </Feature>
          </div>
        </div>
        <div className="flex-1">
          <MapSummary start={start} end={end} />
        </div>
      </div>
      <EditShiftModal
        shift={row.record as EmployeeWorkTimeRecord}
        open={editModalOpen}
        suggestedOutTime={suggestedClockOutTime}
        onClose={onClose}
        isMe={row.isMe}
      />
    </Suspense>
  );
};
