import React, { FC } from 'react';
import { PageContainer } from '../../../../components/page/PageContainer';
import { PageHeader } from '../../../../components/page/PageHeader';
import strings from '../../team.translations';
import { PageSizer, SizerMode } from '../../../../components/page/PageSizer';
import { ReadEmployee, useGetOpenWorkRecordsQuery, useGetStaffByIdQuery } from '../../../../services/gql/graphql';
import { breadcrumbName } from '../../../../helpers/Utility';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { TeamMemberDetailsEditForm } from './TeamMemberDetailsEditForm';
import { FiEdit } from 'react-icons/fi';

interface TeamMemberDetailsEditProps {}

export const TeamMemberDetailsEdit: FC<TeamMemberDetailsEditProps> = () => {
  const { userId } = useParams<'userId'>();
  const handleError = useErrorHandler();

  const [{ data: userData, error: userError }] = useGetStaffByIdQuery({
    variables: {
      id: userId
    },
    requestPolicy: 'network-only'
  });

  if (userError) {
    handleError(userError);
  }

  const [{ data: shiftData, error: shiftError}] = useGetOpenWorkRecordsQuery({
    variables: {
      args: {
        employeeId: userId
      }
    },
    requestPolicy: 'network-only'
  });

  if (shiftError) {
    handleError(shiftError);
  }

  const breadcrumbs = [
    {
      name: strings.teamPageTitle,
      to: '/team'
    },
    {
      name: breadcrumbName(userData!.employee! as ReadEmployee, strings.employeeName),
      to: `/team-member/${userId}`
    },
    {
      name: strings.editTeamMember.breadcrumbEditEmployeeDetails,
      to: `/team-member/${userId}/edit-details`
    }
  ];

  let shiftLocationId: number | undefined = undefined; 
  if (shiftData?.getOpenWorkTimeRecord && shiftData.getOpenWorkTimeRecord.length > 0) {
    shiftLocationId = shiftData?.getOpenWorkTimeRecord[0].locationId;
  }

  return (
    <PageContainer>
      <PageHeader
        icon={<FiEdit />}
        pageTitle={
          strings.editTeamMember.editEmployeeDetails +
          ': ' +
          `${userData!.employee!.firstName + ' ' + userData!.employee!.lastName}`
        }
        breadcrumbs={breadcrumbs}
      />
      <PageSizer mode={SizerMode.PAGE}>
        <div className="py-5">
          <TeamMemberDetailsEditForm
            employee={userData?.employee as ReadEmployee}
            currentLocationId={shiftLocationId}
          />
        </div>
      </PageSizer>
    </PageContainer>
  );
};
