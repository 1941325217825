import React, { FC } from 'react';
import { TSTitleCard } from '../../../components/shared/card/Card';
import strings from './upcomingShift.translations';
import { BsCalendar2 } from 'react-icons/bs';
import { ShiftData } from './UpcomingShiftSection';
import { RiCalendar2Fill } from 'react-icons/ri';
import { IconButton } from '../../../components/shared/button/IconButton';
import { Loading } from '../../../components/shared/Loading';

interface UpcomingShiftsCardProps {
  shifts: ShiftData[];
  loading?: boolean;
  callback: (startDate: Date, endDate: Date) => void;
}

export const UpcomingShiftsCard: FC<UpcomingShiftsCardProps> = ({ shifts, loading, callback }) => {
  return loading ? (
    <Loading />
  ) : (
    <TSTitleCard title={strings.upcomingShiftTitle} icon={BsCalendar2}>
      <div>
        {shifts.length > 0 ? (
          shifts.map((shift, index) => (
            <div className={`px-4 py-4 flex items-center w-full border-primary-std ${index !== shifts.length - 1 ? 'border-b' : 'pb-0'} ${index === 0 ? 'pt-0' : ''}`}>
              <div>
                <p className="text-xl font-extrabold">{shift.date}</p>
                <p className="text-lg">{strings.startEndTime(shift.startTime, shift.endTime)}</p>
              </div>
              <IconButton className="ml-auto" big Icon={RiCalendar2Fill} label={strings.viewButton} onClick={() => callback(shift.startTimeRaw, shift.endTimeRaw)}/>
            </div>
          ))
        ) : (
          <p className="text-xl text-center font-extrabold">{strings.noData}</p>
        )}
      </div>
    </TSTitleCard>
  );
};
