import { TextZoom } from '@capacitor/text-zoom';
import { Capacitor } from '@capacitor/core';
import { FC, useEffect } from 'react';

export const NativeFontManager: FC = () => {
  useEffect(() => {
    (async () => {
      if (!Capacitor.isNativePlatform()) return;
      const root = document.documentElement;
      const factor = await TextZoom.getPreferred();
      const fontSize = (factor.value * 14).toFixed(0);
      root.setAttribute('style', `font-size: ${fontSize}px`);
      root.style.setProperty('--primary-font', 'system-ui');
      root.style.setProperty('--content-font', 'system-ui');
    })();
  }, []);

  return null;
};
