import { FC, HTMLAttributes } from 'react';

export const ViewRosterCalendarIcon: FC<HTMLAttributes<SVGSVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="61" height="42" viewBox="0 0 61 42" fill="none">
    <path
      d="M25.1295 17.7941H21.692V21.0294H25.1295V17.7941ZM32.0045 17.7941H28.567V21.0294H32.0045V17.7941ZM38.8795 17.7941H35.442V21.0294H38.8795V17.7941ZM42.317 6.47058H40.5983V3.23529H37.1608V6.47058H23.4108V3.23529H19.9733V6.47058H18.2545C16.3467 6.47058 14.8342 7.92647 14.8342 9.70588L14.817 32.3529C14.817 34.1324 16.3467 35.5882 18.2545 35.5882H42.317C44.2076 35.5882 45.7545 34.1324 45.7545 32.3529V9.70588C45.7545 7.92647 44.2076 6.47058 42.317 6.47058ZM42.317 32.3529H18.2545V14.5588H42.317V32.3529Z"
      fill="#666666"
    />
  </svg>
);
