import { FC, HTMLAttributes } from 'react';
import strings from '../shifts.translations';
import { Position } from '../../../services/location/location-helper';
import { useNearestLocation } from '../../../services/location/useNearestLocation';

interface LocationSummaryProps extends HTMLAttributes<HTMLDivElement> {
  position?: Position;
}

export const LocationSummary: FC<LocationSummaryProps> = ({ className, position }) => {
  const { closestLocation, distanceFromLocation } = useNearestLocation(position);

  return (
    <div className={className}>
      {distanceFromLocation !== undefined && closestLocation?.name ? (
        distanceFromLocation > closestLocation.radius ? (
          <div className="text-red-500">
            {strings.detailView.distanceOutside(distanceFromLocation, closestLocation.name)}
          </div>
        ) : (
          <div className="text-green-500">
            {strings.detailView.distanceWithin(distanceFromLocation, closestLocation.name)}
          </div>
        )
      ) : (
        <div>{strings.detailView.unknownLocation}</div>
      )}
    </div>
  );
};
