import { FC, useEffect, useState } from 'react';
import { ClockMethod } from './ClockMethod';
import { ClockManually } from './ClockManually';
import { ClockRequest } from './ClockRequest';
import { ClockTitle } from './ClockTitle';
import strings from './clock.translations';
import { LocationPermissionType, useDeviceLocation } from '../../services/location/useDeviceLocation';
import { ClockLocation } from './ClockLocation';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import { ClockForgot } from './ClockForgot';
import { SubmissionErrorBoundary } from '../errors/SubmissionErrorBoundary';
import { useApprovedLocations } from '../../services/location/useApprovedLocations';
import { ClockMethodNoLocation } from './ClockMethodNoLocation';
import { ClockNFC } from './ClockNFC';
import { useFlag } from '../../services/backstage/BackstageProvider';

export enum ClockType {
  Overview = 'Overview',
  Manual = 'Manual',
  ClockRequested = 'ClockRequested',
  Location = 'Location',
  Forgot = 'Forgot',
  NFC = 'NFC'
}

interface ClockHandlerProps {
  closeHandler: () => void;
  type?: ClockType;
  isModal?: boolean;
}

const locPrefToClockType = (pref?: LocationPermissionType) => {
  switch (pref) {
    case LocationPermissionType.Granted:
      return ClockType.Location;
    case LocationPermissionType.Denied:
      return ClockType.Manual;
    default:
      return ClockType.Overview;
  }
};

export const ClockHandler: FC<ClockHandlerProps> = ({ closeHandler, type, isModal }) => {
  const locationsEnabled = useFlag("locationClockIn");
  const [locationPermission] = useDeviceLocation(service => [service.location.permission]);

  const defaultStart = locationsEnabled ? locPrefToClockType(locationPermission) : ClockType.Manual;

  const [viewState, setViewState] = useState<ClockType>(type ? type : defaultStart);
  const { workLocations } = useApprovedLocations();

  useEffect(() => {
    if (!locationPermission || !locationsEnabled) return;
    setViewState(type ? type : locPrefToClockType(locationPermission));
  }, [locationPermission, type, locationsEnabled]);

  const [clockState] = useClockService(service => [service.clockState]);
  const newStatus = clockState === ClockInStatus.In ? ClockInStatus.Out : ClockInStatus.In;
  const clockingTitle = (
    <ClockTitle className="border-b border-gray-200 pb-3">{strings.mainTitle(newStatus)}</ClockTitle>
  );

  return (
    <div className="flex flex-col w-full sm:max-w-md text-center p-5 space-y-4">
      <SubmissionErrorBoundary>
        {(() => {
          switch (viewState) {
            case ClockType.Overview:
              return (
                <>
                  {clockingTitle}
                  {workLocations.length > 0 ? (
                    <ClockMethod setViewState={setViewState} />
                  ) : (
                    <ClockMethodNoLocation setViewState={setViewState} />
                  )}
                </>
              );
            case ClockType.Manual:
              return (
                <>
                  {clockingTitle}
                  <ClockManually
                    type={newStatus}
                    onSuccess={() => setViewState(ClockType.ClockRequested)}
                    onForgotten={() => setViewState(ClockType.Forgot)}
                  />
                </>
              );
            case ClockType.NFC:
              return (
                <>
                  {clockingTitle}
                  <ClockNFC
                    type={newStatus}
                    onSuccess={() => setViewState(ClockType.ClockRequested)}
                    onForgotten={() => setViewState(ClockType.Forgot)}
                  />
                </>
              );
            case ClockType.ClockRequested:
              return <ClockRequest isModal={isModal} close={closeHandler} clockState={clockState} />;
            case ClockType.Location:
              return workLocations.length > 0 ? (
                <ClockLocation
                  type={newStatus}
                  onSuccess={() => setViewState(ClockType.ClockRequested)}
                  onForgotten={() => setViewState(ClockType.Forgot)}
                />
              ) : (
                <>
                  {clockingTitle}
                  <ClockMethodNoLocation setViewState={setViewState} />
                </>
              );
            case ClockType.Forgot:
              return <ClockForgot type={newStatus} onSuccess={() => setViewState(ClockType.ClockRequested)} />;
          }
        })()}
      </SubmissionErrorBoundary>
    </div>
  );
};
