import React, { FC, useState } from 'react';
import strings from '../team.translations';
import { useSendLoginInfoNotifcationToEmployee } from '../../../services/notification/NotificationService';
import { Button } from '../../../components/shared/button/Button';

export interface OnboardingUserData {
  identityId: string;
  firstName: string;
}

interface OnboardingContentsProps {
  user: OnboardingUserData;
  onClose: () => void;
}

export const OnboardingContents: FC<OnboardingContentsProps> = ({ user, onClose }) => {
  const [sendTextLoading, setSendTextLoading] = useState<boolean>(false);

  const text = strings.onboarding.individualSpiel(user.firstName);

  const send = useSendLoginInfoNotifcationToEmployee();

  const sendNotification = async () => {
    setSendTextLoading(true);
    const request = await send(user.identityId);
    setSendTextLoading(false);
    if (!request!.error) {
      onClose();      
    }
  };

  return (
    <div className={'flex flex-col gap-2'}>
      <p>{text}</p>
      <div className={'flex justify-end w-full gap-2'}>
        <Button onClick={onClose}>{strings.common.cancel}</Button>
        <Button onClick={sendNotification} primary loading={sendTextLoading}>
          {strings.onboarding.send}
        </Button>
      </div>
    </div>
  );
};
