import * as React from 'react';
import { IconType } from 'react-icons';
import { merge } from '../../../helpers/Utility';
import { Loader } from '../Loader';

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  Icon: IconType;
  small?: boolean;
  big?: boolean;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  loading?: boolean;
}

const getStyleForSize = (big?: boolean, small?: boolean) => {
  if (small) return ['p-0.5 bg-opacity-0 dark:bg-opacity-0 rounded-lg', 'w-5 h-5'];
  if (big) return ['p-3 h-[fit-content] w-max rounded-full', 'w-8 h-8'];
  return ['p-2 w-max h-[fit-content] rounded-full', 'w-6 h-6'];
};

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  small,
  big,
  Icon,
  label,
  primary,
  secondary,
  loading,
  danger,
  disabled,
  className,
  type = 'button',
  ...props
}) => {
  const [container, icon] = getStyleForSize(big, small);

  const style = (() => {
    if (primary)
      return 'bg-primary-50 hover:bg-primary-100 dark:text-primary-400 text-primary-700 font-normal focus:ring-primary-500';
    if (secondary)
      return 'bg-secondary-50 hover:bg-secondary-100 dark:text-secondary-400 text-secondary-700 font-normal focus:ring-secondary-500';
    if (danger)
      return 'bg-red-50 hover:bg-red-100 dark:hover:bg-red-600 dark:hover:bg-opacity-30 dark:bg-red-600 dark:bg-opacity-20 text-red-600 dark:text-red-300 font-medium focus:ring-red-500';

    return 'hover:bg-black dark:hover:bg-white hover:bg-opacity-5 dark:hover:bg-opacity-5 text-primary-std font-normal focus:ring-gray-600 dark:focus:ring-gray-400';
  })();

  return (
    <button type={type} title={label} disabled={disabled} className={merge(className, container, style)} {...props}>
      {loading ? (
        <span>
          <Loader className={icon} />
        </span>
      ) : null}
      <span className="sr-only">{label}</span>
      <Icon className={icon} />
    </button>
  );
};
