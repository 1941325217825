import { WorkLocation } from './location-helper';
import { useErrorHandler } from 'react-error-boundary';
import { useGetLocationsQuery } from '../gql/graphql';

export const useApprovedLocations = () => {
  const handleError = useErrorHandler();

  const [{ data, error }] = useGetLocationsQuery();
  if (error) handleError(error);

  const filteredData =
    data?.locations?.filter(loc => {
      return loc.locationCoordinates!.length !== 0;
    }) ?? [];

  const workLocations: WorkLocation[] = filteredData.flatMap(loc => {
    return loc.locationCoordinates?.map(geolocation => {
      return {
        name: geolocation.locationName ? geolocation.locationName : loc.name,
        position: {
          longitude: geolocation.longitude,
          latitude: geolocation.latitude
        },
        radius: 0.6
      };
    }) as WorkLocation[];
  });

  return { workLocations };
};
