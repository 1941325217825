import React, { FC } from 'react';
import strings from '../roster.translations';
import { RosterBusyHeaderHeight, RosterBusyPicker, RosterBusyPickerHeight } from './RosterBusyPicker';
import { merge } from '../../../helpers/Utility';
import { BusynessLevel } from '../rosterTypes';

interface RosterCreateBusynessProps {
  existingPattern: RosterBusyness[];
  setPatternCallback: (newPattern: RosterBusyness[]) => void;
}

export interface RosterBusyness {
  date: Date;
  level: BusynessLevel | null;
}

const RosterCreateBusyness: FC<RosterCreateBusynessProps> = ({ existingPattern, setPatternCallback }) => {
  const updateLocalBusynessState = (newData: RosterBusyness) => {
    let stateCopy = existingPattern;

    let currRecordIdx = existingPattern.findIndex((e: RosterBusyness) => {
      return e.date === newData.date;
    });

    if (currRecordIdx !== -1) {
      stateCopy[currRecordIdx] = newData;
      setPatternCallback([...stateCopy]);
    }
  };

  return (
    <div className="border">
      <div className={`flex w-full`}>
        <div className="flex flex-col text-center w-[400px] border-r">
          <div className={merge('border-b', RosterBusyHeaderHeight)} />
          <p className={merge('border-b items-center justify-center flex', RosterBusyPickerHeight)}>
            {strings.rosterCreate.levels.busy}
          </p>
          <p className={merge('border-b items-center justify-center flex', RosterBusyPickerHeight)}>
            {strings.rosterCreate.levels.normal}
          </p>
          <p className={merge('border-b items-center justify-center flex', RosterBusyPickerHeight)}>
            {strings.rosterCreate.levels.quiet}
          </p>
        </div>
        <div
          className={`overflow-x-auto flex 1 grid grid-cols-${existingPattern.length} w-min border-r`}
          style={{ gridTemplateColumns: `repeat(${existingPattern.length}, 100px)` }}
        >
          {existingPattern.map((e: RosterBusyness, index: number) => {
            return (
              <RosterBusyPicker
                first={index === 0}
                busyData={e}
                changeCallback={(e: RosterBusyness) => {
                  updateLocalBusynessState(e);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(RosterCreateBusyness);
