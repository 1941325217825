import { FC } from 'react';
import { EmployeeSchedule } from '../../../../helpers/BuildWorkingPattern';
import { Checkbox } from '../../../../components/shared/checkboxes/Checkbox';
import { TimeInput } from '../../../../components/shared/form/TimeInput';

interface TeamMemberScheduleEditFormRowProps {
  schedule: EmployeeSchedule;
  onCheck: () => void;
  onStartChange: (newVal?: string) => void;
  onEndChange: (newVal?: string) => void;
}

export const TeamMemberScheduleEditFormRow: FC<TeamMemberScheduleEditFormRowProps> = ({
  schedule,
  onCheck,
  onStartChange,
  onEndChange
}) => {
  return (
    <>
      <div className="flex schedules-center gap-2 items-center">
        <Checkbox checked={schedule.available} onChange={onCheck} />
        <p>{schedule.day}</p>
      </div>
      <TimeInput
        name={`${schedule.day}-from`}
        onChange={onStartChange}
        value={schedule.available ? schedule.start : ''}
        disabled={!schedule.available}
      />
      <TimeInput
        name={`${schedule.day}-to`}
        onChange={onEndChange}
        value={schedule.available ? schedule.end : ''}
        disabled={!schedule.available}
      />
    </>
  );
};
