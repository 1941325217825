export enum SuspenseRecordStatus {
  Pending,
  Resolved,
  Rejected
}

export interface SuspenseRecord {
  status: SuspenseRecordStatus;
  value: Promise<any> | any;
}

export const evaluateSuspenseRecord = (record: SuspenseRecord) => {
  if (record.status === SuspenseRecordStatus.Resolved) {
    return record.value as any;
  } else {
    throw record.value;
  }
};

export const handleSuspenseAction = (promise: Promise<any>, invalidator?: (newConfig: any) => void) => {
  const record: SuspenseRecord = {
    status: SuspenseRecordStatus.Pending,
    value: promise
  };

  promise.then(
    value => {
      if (record.status === SuspenseRecordStatus.Pending) {
        record.status = SuspenseRecordStatus.Resolved;
        record.value = value;
      }
      invalidator?.(value);
    },
    err => {
      if (record.status === SuspenseRecordStatus.Pending) {
        record.status = SuspenseRecordStatus.Rejected;
        record.value = err;
      }
    }
  );

  return record;
};
