import { FC, useEffect, useMemo, useState } from 'react';
import { PageSizer, SizerMode } from '../../../../../components/page/PageSizer';
import { MonthPicker } from '../../common/MonthPicker';
import { Loader } from '../../../../../components/shared/Loader';
import { Calendar } from '../../common/Calendar';
import { StaffCalendarContent } from './StaffCalendarContent';
import { useGetMyShiftsForDateRangeQuery } from '../../../../../services/gql/graphql';
import { ESSCalendarProps } from '../../../rosterTypes';
import { StaffCalendarHeader } from './StaffCalendarHeader';
import { useBuildESSCalendarEvents } from '../../hooks/useBuildESSCalendarEvents';
import { formatUTCDate, getStartAndEndDateOfMonth } from '../../../../../helpers/dateHelper';
import { useLocationService } from '../../../../../services/location/LocationService';

export const ESSDesktopCalendar: FC<ESSCalendarProps> = ({ workPatterns }) => {
  const [monthToShow, setMonthToShow] = useState<Date>(new Date());
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);

  const [{ data: shiftData, fetching: shiftsLoading }] = useGetMyShiftsForDateRangeQuery({
    variables: {
      range: {
        start: formatUTCDate(getStartAndEndDateOfMonth(monthToShow).startDate),
        end: formatUTCDate(getStartAndEndDateOfMonth(monthToShow).endDate)
      }
    },
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    )
  });

  const shifts = shiftData?.myShiftsForDateRange?.shifts;

  const { events, buildEvents, calendarLoading } = useBuildESSCalendarEvents(workPatterns);

  const onMonthChange = (newDate: Date) => {
    setMonthToShow(newDate);
  };

  useEffect(() => {
    const filteredShifts = shifts?.filter(shift => shift.location?.id === locationId);
    buildEvents(monthToShow, filteredShifts);
  }, [buildEvents, monthToShow, shifts, locationId]);

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="w-full border border-b-2 bg-primary-std">
          <PageSizer mode={SizerMode.CONTENT}>
            <MonthPicker value={monthToShow} onChange={onMonthChange} />
          </PageSizer>
        </div>
        <PageSizer className="flex flex-col flex-1 p-5" mode={SizerMode.CONTENT}>
          {calendarLoading || shiftsLoading ? (
            <Loader className="mt-10 h-10" />
          ) : (
            <Calendar
              monthToShow={monthToShow}
              HeaderComponent={StaffCalendarHeader}
              ContentComponent={StaffCalendarContent}
              events={events}
              className="rounded-lg flex-1"
              options={{ minimumCellHeight: 100 }}
            />
          )}
        </PageSizer>
      </div>
    </>
  );
};
