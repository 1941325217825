import React, { FC, HTMLAttributes } from 'react';
import { AccordionDataOption, AccordionSteps } from './AccordionWizard';
import strings from '../shared.translations';
import { Button } from '../button/Button';
import { merge } from '../../../helpers/Utility';
import { motion } from 'framer-motion';

interface AccordionOptionProps extends HTMLAttributes<HTMLDivElement> {
  option: AccordionDataOption;
  steps: AccordionSteps;
  active: boolean;
  stepCallback: (newStep: number) => void;
  customSubmit: JSX.Element;
}

export const AccordionOption: FC<AccordionOptionProps> = ({
  option,
  steps,
  active,
  stepCallback,
  customSubmit,
  className
}) => {
  return (
    <div className={merge('flex flex-col gap-4 py-4', className)}>
      <p
        className="font-semibold text-lg cursor-pointer"
        onClick={() => {
          stepCallback(steps.currentStep);
        }}
      >
        {option.heading}
      </p>
      {active && (
        <motion.div
          className="flex flex-col gap-2"
          layoutId={'accordionOption'}
          transition={{ ease: 'easeOut', duration: 0.2 }}
        >
          {option.component}
          <div className="flex flex-row gap-4">
            {steps.currentStep !== 0 && (
              <Button
                onClick={() => {
                  stepCallback(steps.currentStep - 1);
                }}
              >
                {strings.previous}
              </Button>
            )}
            {steps.currentStep === steps.totalSteps && <div>{customSubmit}</div>}
            {steps.currentStep !== steps.totalSteps && (
              <Button
                primary
                onClick={() => {
                  stepCallback(steps.currentStep + 1);
                }}
              >
                {strings.next}
              </Button>
            )}
          </div>
        </motion.div>
      )}
    </div>
  );
};
