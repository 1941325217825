import React, { FC, useState } from 'react';
import { EmployeeWorkTimeRecord, useUpdateShiftMutation, WorkTimeDataRisk } from '../../../services/gql/graphql';
import strings from '../home.translations';
import { FiCheck, FiFlag } from 'react-icons/fi';
import { TimeshiftAvatar } from '../../../components/shared/TimeshiftAvatar';
import { RiskIcon } from '../../../components/shared/icons/RiskIcon';
import { useErrorHandler } from 'react-error-boundary';
import { EditShiftModal } from '../../shifts/editShift/EditShiftModal';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components/shared/Loader';
import { createErrorToast, createSuccessToast } from '../../../services/notification/ToastService';
import { UnverifiedClocksTimes } from './UnverifiedClocksTimes';
import EllipsisTooltip from 'ellipsis-tooltip-react-chan';
import { useLocationService } from '../../../services/location/LocationService';

interface UnverifiedClocksCardProps {
  shift: EmployeeWorkTimeRecord;
}

export const UnverifiedClocksCard: FC<UnverifiedClocksCardProps> = ({ shift }) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hideCard, setHideCard] = useState<boolean>(false);

  const employeeName = `${shift.employee!.firstName} ${shift.employee!.lastName}`;

  const [locationList] = useLocationService(state => [state.locationList]);

  const getLocationName = (id: number) => {
    const location = locationList.find(loc => loc.id === id);

    return location?.name;
  }

  const [, updateShift] = useUpdateShiftMutation();
  const handleError = useErrorHandler();
  let navigate = useNavigate();

  const approve = async () => {
    setLoading(true);

    const result = await updateShift({
      change: {
        recordId: shift.id,
        start: {
          dateTime: shift.start?.dateTime,
          geoLocation: shift.start?.geoLocation
            ? {
                latitude: shift.start?.geoLocation?.latitude,
                longitude: shift.start?.geoLocation?.longitude
              }
            : undefined,
          origin: shift.start?.origin
        },
        end: shift.end
          ? {
              dateTime: shift.end?.dateTime,
              geoLocation: shift.end?.geoLocation
                ? {
                    latitude: shift.end?.geoLocation?.latitude,
                    longitude: shift.end?.geoLocation?.longitude
                  }
                : undefined,
              origin: shift.end?.origin
            }
          : undefined,
        risk: WorkTimeDataRisk.Low
      }
    });

    if (result.error) {
      createErrorToast(strings.unverifiedClocks.approveFail);
      handleError(result.error);
    } else {
      setHideCard(true);
      createSuccessToast(strings.unverifiedClocks.approveSuccess);
    }
  };

  return (
    <div
      className={`flex flex-col sm:flex-row border border-primary-std rounded bg-primary-std ${
        hideCard ? 'hidden' : ''
      }`}
    >
      <div
        className="flex-1 py-2 px-5 flex items-center gap-2 cursor-pointer"
        onClick={() => {
          navigate(`/shifts/${shift.id}/${shift.start?.dateTime}`);
        }}
      >
        <TimeshiftAvatar name={employeeName} size={40} />
        <div className="flex-1 xl:w-[133px]">
          <EllipsisTooltip
            options={{
              place: 'bottom',
              delayShow: 500
            }}
          >
            <span className="font-bold">{employeeName}</span>
          </EllipsisTooltip>
          <UnverifiedClocksTimes shift={shift} />
          {locationList.length > 1 && getLocationName(shift.locationId)}
        </div>
        <RiskIcon risk={shift.risk} />
      </div>
      <div className="flex sm:flex-col border-t sm:border-t-0">
        <button
          onClick={approve}
          disabled={loading}
          className="sm:border-b px-3 py-2 hover:bg-secondary-std border-l flex-1 text-sm border-primary-std flex items-center justify-center gap-2"
        >
          {loading ? (
            <div>
              <Loader className="w-20 h-6" />
            </div>
          ) : (
            <>
              <FiCheck />
              {strings.unverifiedClocks.approve}
            </>
          )}
        </button>
        <button
          onClick={() => setEditModalOpen(true)}
          disabled={loading}
          className="border-l px-3 py-2 hover:bg-secondary-std border-primary-std flex flex-1 text-sm items-center justify-center gap-2"
        >
          <FiFlag />
          {strings.unverifiedClocks.edit}
        </button>
      </div>
      <EditShiftModal shift={shift} open={editModalOpen} onClose={() => setEditModalOpen(false)} />
    </div>
  );
};
