import { FC, HTMLAttributes } from 'react';

export const CreateCalendarIcon: FC<HTMLAttributes<SVGSVGElement>> = props => (
  <svg width="40" height="28" viewBox="0 0 60 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.6 6.20454H41.025V3.25H37.875V6.20454H22.125V3.25H18.975V6.20454H17.4C15.6675 6.20454 14.25 7.53409 14.25 9.15909V32.7955C14.25 34.4205 15.6675 35.75 17.4 35.75H42.6C44.3325 35.75 45.75 34.4205 45.75 32.7955V9.15909C45.75 7.53409 44.3325 6.20454 42.6 6.20454ZM42.6 32.7955H17.4V13.5909H42.6V32.7955Z"
      fill="#666666"
    />
    <path
      d="M37.6562 24.7812H31.0937V30.875H28.9062V24.7812H22.3438V22.75H28.9062V16.6562H31.0937V22.75H37.6562V24.7812Z"
      fill="#666666"
    />
  </svg>
);