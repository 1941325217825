import React, { FC } from 'react';
import { ClockInOutCard } from './ClockInOutCard';
import { ClockProvider } from '../../services/clockIn/ClockService';

export const ClockInOutPage: FC = () => {
  return (
    <ClockProvider>
      <ClockInOutCard />
    </ClockProvider>
  );
};
