import React, { FC, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { TSCardChevronRight } from '../../components/shared/card/Card';
import { ConfirmDialog } from '../../components/shared/ConfirmDialog';
import { Icon } from '../../components/shared/icons/Icon';
import { useDeleteLocationMutation } from '../../services/gql/graphql';
import strings from './manageLocation.translations';

interface DeleteLocationCardProps {
  locationId: number;
}

export const DeleteLocationCard: FC<DeleteLocationCardProps> = ({ locationId }) => {
  const [{ fetching, error }, deleteLocation] = useDeleteLocationMutation();

  const handleError = useErrorHandler();
  if (error) handleError(error);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const onDelete = async () => {
    await deleteLocation({ id: locationId });
    navigate('/manage-organisation/locations');
  };

  return (
    <>
      <TSCardChevronRight
        onClick={() => setModalOpen(true)}
        icon={<Icon Icon={FiAlertTriangle} theme="red" />}
        title={strings.deleteLocationCard.title}
        description={strings.deleteLocationCard.description}
        largeHeader
      />
      <ConfirmDialog
        title={strings.deleteLocationCard.title}
        confirmContent={<p>{strings.deleteLocationCard.confirmation}</p>}
        confirmLabel={strings.common.delete}
        cancelLabel={strings.common.cancel}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSuccess={onDelete}
        loading={fetching}
      ></ConfirmDialog>
    </>
  );
};
