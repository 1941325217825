import React, { FC, Suspense, useState } from 'react';
import { TableContainer } from '../../../../../components/shared/table/TableContainer';
import { EmployeeShiftsTable } from '../../../../shifts/EmployeeShiftsTable';
import { TableLoader } from '../../../../../components/shared/table/TableLoader';
import { formatISO, subWeeks } from 'date-fns';
import { TeamMemberUpdateType, useTeamMemberService } from '../../TeamMemberService';
import { ReadEmployee } from '../../../../../services/gql/graphql';
import strings from '../../../team.translations';
import { FiClock } from 'react-icons/fi';
import { TSTitleCard } from '../../../../../components/shared/card/Card';
import { CustomIntervalTimePicker } from '../../../../../components/shared/date/CustomIntervalTimePicker';
import { dayDiff } from '../../../../../helpers/dateHelper';
import { useLocationService } from '../../../../../services/location/LocationService';
import { useParams } from 'react-router';
import { ShowingDataForLocationsText } from '../../../../shifts/ShowingDataForLocationsText';

interface ShiftsSectionProps {
  showShiftTable?: boolean;
  isMe?: boolean | undefined;
  userData: ReadEmployee;
}

export const shiftsSectionId = 'shifts-section';

export const ShiftsSection: FC<ShiftsSectionProps> = ({ userData, showShiftTable, isMe }) => {
  const [shiftsTableUpdateNeeded, updateComplete, forceUpdate] = useTeamMemberService(state => [
    state.updateNeeded === TeamMemberUpdateType.SHIFTS,
    state.updateComplete,
    state.forceUpdate
  ]);

  let today = new Date();
  const tomorrow = today.setDate(today.getDate() + 1);

  const [[startDate, endDate], setDatePair] = useState<[Date, Date]>(() => [subWeeks(tomorrow, 1), new Date()]);
  const smartSetDatePair = (dates: [Date, Date]) => {
    if (formatISO(dates[0]) === formatISO(startDate) && formatISO(dates[1]) === formatISO(endDate)) return;
    setDatePair(dates);
  };

  const [selectedShiftLocations] = useLocationService(state => [state.selectedShiftLocations]);
  const { recordId } = useParams<'recordId'>();

  return (
    <section id={shiftsSectionId}>
      <TSTitleCard title={strings.verticalTabMenu.shiftsLabel} icon={FiClock}>
        {showShiftTable ? (
          <TableContainer>
            <div className="md:p-6 p-4 space-y-3">
              <ShowingDataForLocationsText />
            </div>
            <div className="flex items-stretch justify-center md:p-6 p-4">
              <CustomIntervalTimePicker
                startDate={startDate}
                endDate={endDate}
                onChange={smartSetDatePair}
                dayDiff={dayDiff(startDate, endDate)}
                labels={{
                  previous: { hide: true },
                  next: { hide: true }
                }}
              />
            </div>
            <Suspense fallback={<TableLoader />}>
              <EmployeeShiftsTable
                startDate={startDate}
                endDate={endDate}
                userId={userData.identityId}
                refetchComplete={() => updateComplete(TeamMemberUpdateType.SHIFTS)}
                needsRefetch={shiftsTableUpdateNeeded}
                onShiftEdit={() => forceUpdate(TeamMemberUpdateType.HEADER)}
                isMe={isMe}
                recordId={recordId}
                locations={selectedShiftLocations.filter(location => location.checked).map(location => location.key)}
              />
            </Suspense>
          </TableContainer>
        ) : null}
      </TSTitleCard>
    </section>
  );
};
