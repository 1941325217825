import LocalizedStrings from 'react-localization';

const translations = new LocalizedStrings({
  en: {
    unknownLocation: 'Unknown location',
    recogniseThisLocation: 'Click to approve this location'
  }
});

export default translations;
