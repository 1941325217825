import { EmployeeWorkTimeRecord } from '../../../services/gql/graphql';
import { differenceInCalendarDays, differenceInMinutes } from 'date-fns';

export interface SummaryData {
  timeInMinutes: number;
  timeDifferenceInMinutes: number;
  totalStaff: number;
  totalStaffDifference: number;
  costInPounds: number;
  costDifferenceInPounds: number;
}

const getSummaryFromShiftArray = (shifts: EmployeeWorkTimeRecord[]) => {
  let time = 0;
  let cost = 0;

  const staffIds: string[] = [];

  shifts.forEach(shift => {
    const employeeId = shift.employee!.identityId;
    if (!staffIds.includes(employeeId)) {
      staffIds.push(employeeId);
    }

    if (shift.end && shift.start) {
      const durationInMinutes = differenceInMinutes(new Date(shift.end.dateTime), new Date(shift.start.dateTime));

      time += durationInMinutes;

      const payRate = shift.employee?.hourlyRate || 0;
      cost += (payRate * durationInMinutes) / 60;
    }
  });

  return { time, staff: staffIds.length, cost };
};

export const buildSummaryData = (
  currentPeriod: EmployeeWorkTimeRecord[],
  periodToCompare: EmployeeWorkTimeRecord[]
) => {
  const currentPeriodData = getSummaryFromShiftArray(currentPeriod);
  const comparisonData = getSummaryFromShiftArray(periodToCompare);

  const data: SummaryData = {
    timeInMinutes: currentPeriodData.time,
    timeDifferenceInMinutes: currentPeriodData.time - comparisonData.time,
    totalStaff: currentPeriodData.staff,
    totalStaffDifference: currentPeriodData.staff - comparisonData.staff,
    costInPounds: currentPeriodData.cost,
    costDifferenceInPounds: currentPeriodData.cost - comparisonData.cost
  };

  return data;
};

export const getComparisonPeriodDates = (start: Date, end: Date) => {
  const periodLength = differenceInCalendarDays(end, start);

  const compareStart = new Date(start);
  const compareEnd = new Date(end);

  compareStart.setDate(compareStart.getDate() - periodLength);
  compareEnd.setDate(compareEnd.getDate() - periodLength);

  return { start: compareStart, end: compareEnd };
};
