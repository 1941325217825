import { FC } from 'react';
import { CalendarCellProps } from '../../common/Calendar';
import { StaffCalendarContextObject } from '../../../rosterTypes';
import { CalendarHeader } from '../../common/CalendarHeader';

export const StaffCalendarHeader: FC<CalendarCellProps<StaffCalendarContextObject>> = ({
  date,
  contextObject,
  today
}) => {
  const styles = getComputedStyle(document.body);
  const successColor = styles.getPropertyValue('--calendar-published').trim().toLowerCase();

  const className = contextObject?.canWork === false ? "bg-[url('../public/stripe-icon-26x24.png')]" : '';

  return (
    <CalendarHeader
      date={date}
      today={!!today}
      className={className}
      backgroundColor={contextObject?.shift && successColor}
    />
  );
};
