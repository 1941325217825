import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { AiFillCaretDown } from 'react-icons/ai';
import { StaffOption } from '../rosterTypes';
import { FiAlertOctagon, FiAlertTriangle, FiCheck } from 'react-icons/fi';
import strings from '../roster.translations';
import { merge } from '../../../helpers/Utility';
import { RosterPickerIcon } from './RosterPickerIcon';

export enum PickerDirection {
  UP,
  DOWN
}

interface RosterPersonPickerProps {
  options: StaffOption[];
  onChange: (newVal: StaffOption) => void;
  value: StaffOption;
  selectClassName?: string;
  direction?: PickerDirection;
}

export const RosterPersonPicker: FC<RosterPersonPickerProps> = ({
  options,
  onChange,
  value,
  selectClassName,
  direction = PickerDirection.DOWN
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedStaffOption, setSelectedStaffOption] = useState<StaffOption>(value);

  useEffect(() => {
    setSelectedStaffOption(value);
  }, [setSelectedStaffOption, value]);

  const filteredOptions =
    searchTerm === '' ? options : options.filter(staff => staff.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const onStaffChange = useCallback(
    (newStaff: StaffOption) => {
      onChange(newStaff);
      setSelectedStaffOption(newStaff);
    },
    [onChange, setSelectedStaffOption]
  );

  const nobodyAvailable = options.length === 1;

  if (nobodyAvailable) {
    return (
      <div className="w-full pl-3 pr-2 text-sm border rounded-lg py-[5px] flex gap-3 items-center">
        <FiAlertOctagon className="h-8 w-8 text-red-500" />
        {strings.noStaff}
      </div>
    );
  }

  let comboboxClass = merge(
    'flex items-center py-1 pl-3 relative cursor-default overflow-hidden rounded-lg border',
    selectClassName
  );

  comboboxClass = merge(
    comboboxClass,
    'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
  );

  let optionsClass = "absolute mt-1 max-h-60 w-full z-10 overflow-auto rounded-md bg-white"
  optionsClass = merge(optionsClass, "py-1 text-base shadow-lg focus:outline-none sm:text-sm");

  if (direction === PickerDirection.UP) {
    optionsClass = merge(optionsClass, "bottom-12");
  }

  return (
    <div className="w-full">
      <Combobox value={selectedStaffOption} onChange={onStaffChange}>
        <div className="relative mt-1 ">
          <div className={comboboxClass}>
            {selectedStaffOption.id ? (
              <RosterPickerIcon name={selectedStaffOption.name} />
            ) : (
              <FiAlertTriangle className="h-9 w-9 text-yellow-500" />
            )}
            <Combobox.Input
              ref={inputRef}
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0 text-ellipsis"
              displayValue={(staff: StaffOption) => staff.name}
              onFocus={(event: { target: { value: string } }) => {
                if (event.target.value === strings.notAssigned) {
                  inputRef.current!.value = '';
                }
              }}
              onChange={event => setSearchTerm(event.target.value)}
            />
            <Combobox.Button className="absolute w-full inset-y-0 right-0 flex justify-end items-center pr-2">
              <AiFillCaretDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setSearchTerm('')}
          >
            <Combobox.Options className={optionsClass}>
              {filteredOptions.length === 0 && searchTerm !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">{strings.noResults}</div>
              ) : (
                filteredOptions.map((staff: StaffOption) => (
                  <Combobox.Option
                    key={staff.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 rounded-lg ${
                        active ? 'bg-blue-400 text-white' : 'text-black'
                      }`
                    }
                    value={staff}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-bold' : 'font-normal'}`}>{staff.name}</span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-blue-400'
                            }`}
                          >
                            <FiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
