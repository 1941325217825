import React, { FC, Fragment, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import strings from './page.translations';
import { TSCard } from '../shared/card/Card';
import { merge } from '../../helpers/Utility';
import { AiFillCaretDown, AiFillHome } from 'react-icons/ai';
import { FiChevronRight } from 'react-icons/fi';

export interface NavPage {
  name: ReactNode;
  to: string;
  pages?: NavPage[];
}

interface BreadcrumbProps {
  page: NavPage;
}

interface BreadcrumbsProps {
  pages: NavPage[];
}

const Breadcrumb: FC<BreadcrumbProps> = ({ page }) => {
  return (
    <li key={page.to}>
      <div className="flex items-center">
        <FiChevronRight className="flex-shrink-0 h-5 w-5 text-tertiary-std" aria-hidden="true" />
        {!page.pages ? (
          <NavLink
            to={page.to}
            className={
              'flex border items-center border-transparent py-2 px-3 pr-3 text-sm font-medium text-secondary-std hover:text-primary-std'
            }
          >
            {page.name}
          </NavLink>
        ) : (
          <Menu as="div" className="relative inline-block">
            <Menu.Button
              as="button"
              className={merge(
                'flex border items-center border-transparent py-2 px-3 pr-3 text-sm font-medium text-secondary-std hover:text-primary-std',
                'crumb group rounded hover:border-primary-std hover:pr-7 transition-spacing duration-300 relative'
              )}
            >
              {page.name}
              <AiFillCaretDown className="arrow w-3 h-3 transition-opacity opacity-0 group-hover:opacity-100 duration-300 absolute right-2" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items as="div" className="absolute left-0 mt-1 origin-top-left shadow-lg z-20">
                <TSCard className="flex flex-col w-max max-h-96 overflow-y-scroll bg-primary-std border-primary-std rounded-lg border">
                  {page.pages.map(singlePage => (
                    <Menu.Item key={singlePage.to}>
                      {({ active }) => (
                        <Link
                          className={merge(
                            'flex items-center px-3 rounded py-2 m-1 text-sm font-medium hover:text-primary-std text-secondary-std',
                            active ? 'bg-secondary-std' : null
                          )}
                          to={singlePage.to}
                        >
                          {singlePage.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </TSCard>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </li>
  );
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ pages }) => {
  return (
    <nav className="flex mb-px print:hidden" aria-label="Breadcrumb">
      <ol className="flex items-center">
        <li>
          <div className="mr-3">
            <Link to="/" className="text-secondary-std hover:text-primary-std">
              <AiFillHome className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{strings.common.home}</span>
            </Link>
          </div>
        </li>
        {pages.map(page => (
          <Breadcrumb page={page} key={page.to} />
        ))}
      </ol>
    </nav>
  );
};
