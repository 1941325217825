import React, { FC } from 'react';
import successAnimation from '../shared/animations/successAnimation.json';
import Lottie from 'lottie-react';
import { ClockTitle } from './ClockTitle';
import strings from './clock.translations';
import { Button } from '../shared/button/Button';
import { ClockInStatus } from '../../services/clockIn/ClockService';

interface ClockRequestProps {
  close?: () => void;
  isModal?: boolean;
  clockState: ClockInStatus
}

export const ClockRequest: FC<ClockRequestProps> = ({ close, isModal, clockState }) => {
  return (
    <div className="h-max p-8 flex flex-col justify-center items-center">
      <ClockTitle className="-mb-2 relative">{strings.clockStatusConfirmation}</ClockTitle>
      <Lottie className="h-32" animationData={successAnimation} loop={false} />
      <p>{strings.clockStatusDescription(clockState)}</p>
      {isModal && close && (
        <Button
          className="w-full mt-4"
          onClick={() => {
            close();
          }}
        >
          {strings.common.close}
        </Button>
      )}
    </div>
  );
};
