import React, { FC, useEffect, useState } from 'react';
import { TextField } from '../../components/shared/form/TextInput';
import strings from './team.translations';
import { RadioGroupField } from '../../components/shared/RadioGroup';
import { roleSelectOptions } from './teamMember/overview/TeamFormCommon';
import { Feature } from '../../services/backstage/Feature';
import { validateEmail, validatePhone, formatPhoneNumber } from '../../helpers/Utility';
import { CheckboxGroupField } from '../../components/shared/checkboxes/CheckboxGroup';
import { ReadEmployee, ReadRole, useGetMeQuery, useGetRolesQuery, useLocationsQuery } from '../../services/gql/graphql';
import { isAdmin, Permissions, useAuthenticationService } from '../../services/authentication/authentication-service';
import { useLocationService } from '../../services/location/LocationService';
import { Checkbox } from '../../components/shared/checkboxes/Checkbox';
import { TeamMemberAddFields } from './teamMember/AddSingleTeamMemberForm';
import { UseFormReturn } from 'react-hook-form';
import { EditTeamMemberFields } from './teamMember/overview/TeamMemberDetailsEditForm';

interface TeamMemberAddOrEditFormContentProps {
  addMode: boolean;
  employee?: ReadEmployee;
  methods: UseFormReturn<TeamMemberAddFields> | UseFormReturn<EditTeamMemberFields>;
}

export const TeamMemberAddOrEditFormContent: FC<TeamMemberAddOrEditFormContentProps> = ({
  addMode,
  employee,
  methods
}) => {
  const [permission] = useAuthenticationService(state => [state.user?.profile.permission]);
  const [checked, setChecked] = useState<boolean>(true);

  const [phoneOrEmailRequiredError, setPhoneOrEmailRequiredError] = useState<boolean>(false);

  const [{ data }] = useGetMeQuery();
  const isMe = employee?.identityId === data!.myEmployee!.identityId; //Shouldn't be able to modify your own role
  const canEditRole = permission?.includes(Permissions.EmployeeUpdate) && !isMe;

  const [{ data: roleData }] = useGetRolesQuery({
    pause: !addMode && !canEditRole
  });
  const roles: ReadRole[] = roleData?.roles ?? [];

  const [locationList, mappedLocations, setMappedLocations] = useLocationService(state => [
    state.locationList,
    state.mappedLocations,
    state.setMappedLocations
  ]);

  const [role] = useAuthenticationService(state => [state.role]);
  const [{ data: allLocationsData }] = useLocationsQuery();
  const locations = isAdmin(role) ? allLocationsData!.locations! : locationList;

  useEffect(() => {
    setMappedLocations(
      locations.map(location => {
        return {
          key: String(location.id),
          name: location.name,
          checked: Boolean(employee?.restaurants?.find(restaurant => restaurant.id === location.id)),
          descriptionMarkup: <p>{location.name}</p>
        };
      })
    );
  }, [locations, employee, setMappedLocations]);

  const firstAndLastNameFields = (
    <div className="flex flex-row gap-2">
      <TextField
        name="firstName"
        title={strings.common.firstName}
        placeholder={!addMode ? undefined : strings.addTeamMemberForm.firstNamePlaceholder}
        validationMessage={
          !addMode
            ? strings.editTeamMember.fistNameRequired
            : strings.common.mandatory(strings.addTeamMemberForm.firstName)
        }
        required
      />
      <TextField
        name="lastName"
        title={strings.common.lastName}
        placeholder={!addMode ? undefined : strings.addTeamMemberForm.lastNamePlaceholder}
        validationMessage={
          !addMode
            ? strings.editTeamMember.lastNameRequired
            : strings.common.mandatory(strings.addTeamMemberForm.lastName)
        }
        required
      />
    </div>
  );

  const roleFields = (
    <>
      {(addMode || canEditRole) && (
        <RadioGroupField
          className="w-full"
          fieldSetClasses={`flex flex-col md:flex-row md:flex-wrap space-y-4 md:space-y-0 focus:bg-red-500 whitespace-nowrap`}
          labelClasses={'border rounded-md'}
          name="role"
          title={strings.role}
          required
          validationMessage={strings.common.mandatory(strings.role)}
          noOptionsString={strings.addTeamMemberForm.noOptionsString}
          items={roleSelectOptions(roles)}
        />
      )}
    </>
  );

  const emailAndPhoneNumberFields = (
    <Feature name="detailsEdit">
      <TextField
        name="email"
        title={strings.common.email}
        validator={(email: string) => {
          return validateEmail(email);
        }}
        customValidatorMessage={strings.addTeamMemberForm.invalidEmail}
      />
      <TextField
        name="phoneNumber"
        title={strings.common.phoneNumber}
        validator={(phone: string) => {
          const validPhone = validatePhone(phone);

          const phoneOrEmailRequired = !(phone || methods.getValues().email);
          setPhoneOrEmailRequiredError(phoneOrEmailRequired);

          return validPhone;
        }}
        onChange={value => {        
          //@ts-ignore
          methods.setValue('phoneNumber', formatPhoneNumber(value));
        }}
        customValidatorMessage={strings.addTeamMemberForm.invalidPhone}
      />
      {phoneOrEmailRequiredError && (
        <div className="mb-4 text-red-500">{strings.editTeamMember.phoneNumberOrEmailRequired}</div>
      )}
    </Feature>
  );

  const locationFields = (
    <Feature name="devices">
      {locations.length > 1 && (
        <CheckboxGroupField
          fieldSetClasses="grid grid-flow-row grid-cols-1 md:grid-cols-3"
          name="locationIds"
          title={strings.locations}
          onChange={(ids: string[]) => {
            mappedLocations.forEach(location => {
              if (ids.includes(location.key)) {
                location.checked = true;
              } else {
                location.checked = false;
              }
            });

            setMappedLocations([...mappedLocations]);
          }}
          items={mappedLocations}
          required
          validationMessage={strings.common.mandatory(strings.location)}
        />
      )}
    </Feature>
  );

  const sendAccountInformation = (
    <div className="flex flex-row p-2 pl-4 rounded-lg bg-secondary-std gap-2 mb-4">
      <Checkbox name="sendRegistration" onChange={() => setChecked(!checked)} checked={checked} />
      <div className="flex flex-col align-start p-2 rounded-lg bg-secondary-std gap-2">
        <p className="">{strings.addTeamMemberForm.toggleSendAccountInformation}</p>
        <p className="italic">{strings.addTeamMemberForm.sendAccountInformation}</p>
      </div>
    </div>
  );

  return (
    <>
      {firstAndLastNameFields}
      {roleFields}
      {emailAndPhoneNumberFields}
      {locationFields}
      {addMode && sendAccountInformation}
    </>
  );
};
