import { FC, useEffect, useState } from 'react';
import { PageSizer, SizerMode } from '../../../components/page/PageSizer';
import { PageContainer } from '../../../components/page/PageContainer';
import { TeamMemberHeader } from './TeamMemberHeader';
import { ReadEmployee, useGetMeQuery } from '../../../services/gql/graphql';
import { NavPage } from '../../../components/page/Breadcrumbs';
import { OnboardingModal } from '../onboarding/OnboardingModal';
import {
  isAdmin,
  isManagement,
  useAuthenticationService
} from '../../../services/authentication/authentication-service';
import { OverviewSection, overviewSectionId } from './overview/OverviewSection';
import { ShiftsSection, shiftsSectionId } from './overview/shifts/ShiftsSection';
import { SideMenuOption, VerticalMenu } from '../../../components/shared/menu/VerticalMenu';
import { TeamMemberUpdateType, useTeamMemberService } from './TeamMemberService';
import { FiAlertOctagon, FiClock, FiStar, FiUser } from 'react-icons/fi';
import { DangerCardSection, dangerCardSectionId } from './danger/DangerCardSection';
import strings from '../../team/team.translations';
import { useParams } from 'react-router-dom';
import { SkillsSection, skillsSectionId } from './skills/SkillsSection';
import { useFlag } from '../../../services/backstage/BackstageProvider';

interface TeamMemberDisplayProps {
  userData?: ReadEmployee;
  breadcrumbs?: NavPage[];
  sendInfoCallback?: () => void;
  editMemberCard?: boolean;
  editMemberPayCard?: boolean;
  showShiftTable?: boolean;
  isMe?: boolean;
  refetchUserData?: () => void;
}

export const TeamMemberDisplay: FC<TeamMemberDisplayProps> = ({
  userData,
  breadcrumbs,
  editMemberCard,
  editMemberPayCard,
  showShiftTable,
  isMe,
  refetchUserData
}) => {
  const { userId } = useParams<'userId'>();
  const [{ data }] = useGetMeQuery();
  const myUserId = data!.myEmployee!.identityId;
  const isUserOwnPage = userId === myUserId;
  const [updateComplete, updateAllNeeded] = useTeamMemberService(state => [
    state.updateComplete,
    state.updateNeeded === TeamMemberUpdateType.ALL
  ]);

  useEffect(() => {
    if (updateAllNeeded && refetchUserData) {
      refetchUserData();
      updateComplete(TeamMemberUpdateType.ALL);
    }
  }, [updateAllNeeded, updateComplete, refetchUserData]);

  const [role] = useAuthenticationService(state => [state.role]);

  const isAdminRole = isAdmin(role);
  const isManagerRole = isManagement(role);
  const rosterFlag = useFlag('roster');

  const [sendInfoOpen, setSendInfoOpen] = useState<boolean>(false);

  const iconStyle = 'w-4 h-4 mt-0.5';

  const overviewElement = userData && (
    <OverviewSection userData={userData} editMemberCard={editMemberCard} editMemberPayCard={editMemberPayCard} />
  );
  const shiftsElement = userData ? (
    <ShiftsSection userData={userData} showShiftTable={showShiftTable} isMe={isMe} />
  ) : (
    <></>
  );
  const skillsElement = !isMe && isAdminRole && rosterFlag && userData ? <SkillsSection userData={userData} /> : <></>;
  const dangerCardElement =
    isAdminRole && !isMe && !isUserOwnPage && userData ? <DangerCardSection userData={userData} /> : <></>;

  const allElements = (
    <>
      {overviewElement}
      {shiftsElement}
      {skillsElement}
      {dangerCardElement}
    </>
  );

  let tabOptions: SideMenuOption[] = [
    {
      anchorId: overviewSectionId,
      icon: <FiUser className={iconStyle} />,
      label: strings.verticalTabMenu.overviewLabel,
      element: overviewElement
    },
    {
      anchorId: shiftsSectionId,
      icon: <FiClock className={iconStyle} />,
      label: strings.verticalTabMenu.shiftsLabel,
      element: shiftsElement
    }
  ];

  if (!isMe && isAdminRole && rosterFlag) {
    tabOptions.push({
      anchorId: skillsSectionId,
      icon: <FiStar className={iconStyle} />,
      label: strings.verticalTabMenu.skillsLabel,
      element: skillsElement
    });
  }

  if (isAdminRole && !isMe && !isUserOwnPage) {
    tabOptions.push({
      anchorId: dangerCardSectionId,
      icon: <FiAlertOctagon className={iconStyle} />,
      label: strings.verticalTabMenu.manageAccountLabel,
      element: dangerCardElement
    });
  }

  return (
    <PageContainer
      customHeader={
        <TeamMemberHeader
          employee={userData}
          breadcrumbs={breadcrumbs}
          sendInfoCallback={isManagerRole ? () => setSendInfoOpen(true) : undefined}
          isMe={isMe}
        />
      }
    >
      <PageSizer className="flex gap-5 flex-col lg:flex-row" mode={SizerMode.PAGE}>
        {userData?.isDeleted ? (
          <></>
        ) : (
          <>
            <div className="hidden lg:flex gap-5 w-full">
              <VerticalMenu sideMenuOptions={tabOptions} isScroll={false} />
            </div>
            <div className="lg:hidden ">
              <VerticalMenu sideMenuOptions={tabOptions} allElements={allElements} isScroll={true} />
            </div>
          </>
        )}
      </PageSizer>
      {userData && (
        <OnboardingModal
          user={{
            firstName: userData.firstName,
            identityId: userData.identityId!
          }}
          open={Boolean(sendInfoOpen)}
          onClose={() => {
            setSendInfoOpen && setSendInfoOpen(false);
          }}
        />
      )}
    </PageContainer>
  );
};
