import React, { FC } from 'react';
import { TSCard } from '../../components/shared/card/Card';
import strings from '../../components/clock/clock.translations';
import { Button } from '../../components/shared/button/Button';
import { useNavigate } from 'react-router';

export const ClockInOutCancel: FC = () => {
  const navigate = useNavigate();
  return (
    <TSCard className="py-7 px-5">
      <div className="flex flex-col items-center space-y-4 w-full">
        <h2 className="text-center text-xl font-extrabold">{strings.cancelled}</h2>
        <p>{strings.cancelledDescription}</p>
      </div>
      <br />
      <div className="flex flex-col items-center">
        <Button onClick={() => navigate('/clock-in-out')} className="flex justify-center w-[40%]">
          {strings.common.back}
        </Button>
      </div>
    </TSCard>
  );
};
