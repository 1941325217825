import * as React from 'react';
import { IconBaseProps, IconType } from 'react-icons';

export type Theme = 'red' | 'amber' | 'emerald' | 'blue' | 'indigo' | 'violet' | 'pink' | 'primary' | 'secondary';

export interface IconProps extends IconBaseProps {
  Icon: IconType;
  theme?: Theme;
  big?: boolean;
  small?: boolean;
}

/* 
 bg-red-50 text-red-600 dark:bg-red-600
 bg-amber-50 text-amber-600 dark:bg-amber-600
 bg-emerald-50 text-emerald-600 dark:bg-emerald-600
 bg-blue-50 text-blue-600 dark:bg-blue-600
 bg-indigo-50 text-indigo-600 dark:bg-indigo-600
 bg-violet-50 text-violet-600 dark:bg-violet-600
 bg-pink-50 text-pink-600 dark:bg-pink-600
 bg-primary-50 text-primary-600 dark:bg-primary-600
 bg-secondary-50 text-secondary-600 dark:bg-secondary-600
 */

const getStyleForTheme = (theme?: Theme) => {
  switch (theme) {
    case 'red':
    case 'amber':
    case 'emerald':
    case 'blue':
    case 'indigo':
    case 'violet':
    case 'pink':
    case 'primary':
    case 'secondary':
      return `bg-${theme}-50 text-${theme}-600 dark:bg-${theme}-600 dark:text-white`;

    default:
      return 'bg-gray-100 dark:bg-gray-700 text-primary-std';
  }
};

const getStyleForSize = (big?: boolean, small?: boolean) => {
  if (small) return ['bg-opacity-0 dark:bg-opacity-0', 'w-5 h-5'];
  if (big) return ['p-3 h-[fit-content] w-max rounded-full', 'w-8 h-8'];
  return ['p-2 w-max h-[fit-content] rounded-full', 'w-6 h-6'];
};

export const Icon: React.FC<IconProps> = ({ children, Icon, theme, big, small, className, ...props }) => {
  const [container, icon] = getStyleForSize(big, small);

  return (
    <div aria-hidden className={`${container} ${className} ${getStyleForTheme(theme)}`}>
      <Icon {...props} className={icon} />
    </div>
  );
};
