import React, { FC, useMemo, useState } from 'react';
import { getDistanceInKm, Position, WorkLocation } from '../../../services/location/location-helper';
import { Map, MapPoint } from './Map';
import { Status, StatusIcon } from '../icons/StatusIcon';
import { AddGeolocationModal } from '../../../pages/shifts/addGeolocation/AddGeolocationModal';
import translations from './maps.translations';
import { Button } from '../button/Button';

interface WorkLocationMapProps {
  personPosition: Position;
  workLocation: WorkLocation;
  interactive?: boolean;
}

export const WorkLocationMap: FC<WorkLocationMapProps> = ({ personPosition, workLocation, interactive = false }) => {
  const mapPoints: [number, number][] = [];
  if (workLocation?.position.latitude !== undefined && workLocation.position.longitude !== undefined) {
    mapPoints.push([workLocation.position.longitude, workLocation.position.latitude]);
  }
  const [open, setOpen] = useState<boolean>(false);

  const map = useMemo(() => {
    const mapPoints: MapPoint[] = [];
    if (workLocation?.position.latitude !== undefined && workLocation.position.longitude !== undefined) {
      mapPoints.push({
        longitude: workLocation.position.longitude,
        latitude: workLocation.position.latitude,
        primary: true
      });
    }

    if (personPosition.latitude !== undefined && personPosition.longitude !== undefined) {
      mapPoints.push({
        longitude: personPosition.longitude,
        latitude: personPosition.latitude
      });
    }

    const distance = getDistanceInKm(workLocation.position, personPosition);
    const withinRadius = distance < workLocation.radius;

    return (
      <div className="relative">
        <Map
          origin={workLocation}
          className="h-48 rounded"
          center={[personPosition.longitude, personPosition.latitude - 0.001]}
          points={mapPoints}
        />
        <div className="absolute bottom-5 w-full flex justify-center px-5">
          {interactive ? (
            <>
              <Button
                className="flex flex-col items-center gap-1"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <div className="flex text-center gap-2">
                  <span>{withinRadius ? workLocation.name : translations.unknownLocation}</span>
                  {<StatusIcon status={withinRadius ? Status.OK : Status.BAD} />}
                </div>
                {!withinRadius && (
                  <span className="text-sm text-secondary-std">{translations.recogniseThisLocation}</span>
                )}
              </Button>
              <AddGeolocationModal
                mapPoints={mapPoints.filter(p => p.primary !== true)}
                workLocation={{ position: personPosition, radius: 0.5, name: translations.unknownLocation }}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              />
            </>
          ) : (
            <div className="flex text-center gap-2 bg-primary-std p-3 rounded-lg border border-primary-std">
              <span>{withinRadius ? workLocation.name : translations.unknownLocation}</span>
              {<StatusIcon status={withinRadius ? Status.OK : Status.BAD} />}
            </div>
          )}
        </div>
      </div>
    );
  }, [workLocation, personPosition, open, interactive]);

  return map;
};
