import React, { FC } from 'react';
import { PageContainer } from '../../../components/page/PageContainer';
import { ReadRoster } from '../../../services/gql/graphql';
import { RosterHeader } from './RosterHeader';
import { Roster } from './Roster';
import { useRosterService } from './rosterService';

interface RosterPageProps {}

export const RosterPage: FC<RosterPageProps> = () => {
  const [roster] = useRosterService(state => [state.roster]);

  return (
    <PageContainer customHeader={<RosterHeader />}>
      <div className="mx-[5%] border-l border-r border-primary-std">
        <Roster roster={roster as ReadRoster} />
      </div>

    </PageContainer>
  );
};
