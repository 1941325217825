import { FC, HTMLAttributes, useState } from 'react';
import { TSCard } from '../../../components/shared/card/Card';
import { useLocationService } from '../../location/LocationService';

interface RequireAssignedLocationProps extends HTMLAttributes<HTMLDivElement> {
  locationIds: string[];
  errorMessage: string;
}

export const RequireAssignedLocation: FC<RequireAssignedLocationProps> = ({ locationIds, errorMessage, children }) => {
  const [locationList] = useLocationService(state => [state.locationList]);

  const checkLocationList = () => {
    let missingLocationFound = false;

    locationIds.forEach(id => {
      const locationIsInList = locationList.findIndex(loc => loc.id.toString() === id) >= 0;

      if (!locationIsInList) {
        missingLocationFound = true;
      }
    });

    return !missingLocationFound;
  };

  const [canView] = useState<boolean>(checkLocationList());

  return <>{canView ? children : <TSCard className="text-center p-5">{errorMessage}</TSCard>}</>;
};
