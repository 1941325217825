import React, { FC, useState, Suspense } from 'react';
import { Watch } from '../shared/icons/Watch';
import { ClockHandler } from './ClockHandler';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import strings from './clock.translations';
import { Loader } from '../shared/Loader';
import { Button } from '../shared/button/Button';
import { merge } from '../../helpers/Utility';
import { Modal } from '../shared/modal/Modal';

interface ClockPopoverProps {}

export const ClockPopover: FC<ClockPopoverProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [clockState] = useClockService(service => [service.clockState]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        widthOverride="w-100"
      >
        <div className="relative m-4">
          <Suspense fallback={<Loader className={'h-10'} />}>
            <ClockHandler
              isModal={true}
              closeHandler={() => {
                setOpen(false);
              }}
            />
          </Suspense>
        </div>
      </Modal>
      <div className={merge('relative sm:inline-block flex-col w-full')}>
        <Button
          primary
          onClick={() => {
            setOpen(!open);
          }}
          className="flex items-center justify-center w-full gap-2"
        >
          <Watch className="w-4 h-6" />
          {clockState === ClockInStatus.In ? strings.common.clockOut : strings.common.clockIn}
        </Button>
      </div>
    </>
  );
};
