import strings from './errors.translations';

enum ErrorCodes {
  UNKNOWN = "UNKNOWN",
  VERIFICATION_CODE_INVALID = "VERIFICATION_CODE_INVALID",
  DEVICE_NOT_ACTIVE = "DEVICE_NOT_ACTIVE"
}

export const errorCodeToMessage = (code?: string) => {
  let message: string;

  switch (code) {
    case ErrorCodes.DEVICE_NOT_ACTIVE:
      message = strings.messages.unactivatedDevice;
      break;
    case ErrorCodes.VERIFICATION_CODE_INVALID:
      message = strings.messages.wrongOrExpiredCode;
      break;
    case ErrorCodes.UNKNOWN:
    default:
      message = strings.apology;
      break;
  }

  return message;
}