import { FC } from 'react';
import { UnverifiedClocksSection } from './unverifiedClocks/UnverifiedClocksSection';
import { EmployeePresenceSection } from './present/EmployeePresenceSection';
import { TimeCostSummarySection } from './timeCostSummary/TimeCostSummarySection';
import { Feature } from '../../services/backstage/Feature';
import { UpcomingShiftSection } from './upcomingShift/UpcomingShiftSection';
import { RosterSelectServiceProvider } from '../roster/calendar/common/rosterSelectService';

interface HomeContentsManagersProps {}

export const HomeContentsManagers: FC<HomeContentsManagersProps> = () => {
  return (
    <div className="flex flex-col gap-5">
      <Feature name="roster">
        <RosterSelectServiceProvider>
          <UpcomingShiftSection />
        </RosterSelectServiceProvider>
      </Feature>
      <EmployeePresenceSection />
      <Feature name="pay">
        <TimeCostSummarySection />
      </Feature>
      <UnverifiedClocksSection />
    </div>
  );
};
