import {
  EmployeeWorkTimeRecord,
  GetShiftsListDocument,
  GetShiftsListQuery,
  GetShiftsListQueryVariables
} from '../../../services/gql/graphql';
import { calculateMinutesDuration, dateToGQLFormat } from '../../../helpers/dateHelper';
import { CombinedError } from '@urql/core';
import { useState } from 'react';
import { useClient } from 'urql';
import groupBy from 'lodash.groupby';
import { eachDayOfInterval, getDayOfYear, parseISO } from 'date-fns';
import deepmerge from 'deepmerge';
import useDeepCompareEffect from 'use-deep-compare-effect';
import translations from './allShiftsReport.translations';
import { LoggerLevel, useLogger } from '../../../services/logging/Logger';

interface TeamMemberSummary extends Record<string, any> {
  employeeId: string;
  name: string;
  locationId: number;
  total: any;
}

export const getTotal = (data: TeamMemberSummary | TeamMemberSummary[]) => {
  const total = Object.entries(data).reduce<number>((acc, [key, value]) => {
    const sum = acc + value.total;
    return sum;
  }, 0);
  return total;
};

export const useShiftReport = (start: Date, end: Date, locationIdsToFilterOn: string[] = []) => {
  const client = useClient();
  const { log } = useLogger();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<CombinedError>();
  const [data, setData] = useState<{ team: TeamMemberSummary[]; total: number }>();

  const fetchData = async () => {
    setLoading(true);

    const adaptedEnd = new Date(end);
    adaptedEnd.setDate(adaptedEnd.getDate() + 1);

    try {
      setData({ team: [], total: 0 });
      const response = await client
        .query<GetShiftsListQuery, GetShiftsListQueryVariables>(
          GetShiftsListDocument,
          {
            queryWorkItemArgs: {
              from: dateToGQLFormat(start),
              to: dateToGQLFormat(adaptedEnd)
            }
          },
          { requestPolicy: 'network-only' }
        )
        .toPromise();

      let records: any = response?.data?.getWorkTimeRecord;
      if (!records) return;

      const team = groupBy(records, 'employee.identityId');
      const teamMembers: { [key: string]: { [key: string]: any[] } } = {};
      Object.keys(team).forEach(key => {
        const shifts = team[key];
        const employeeId = shifts[0]!.employee?.identityId;

        const shiftsByDay = groupBy(shifts, shift => {
          const date = shift?.start?.dateTime;
          if (!date) return;
          return getDayOfYear(parseISO(date));
        });

        if (!teamMembers[employeeId]) {
          teamMembers[employeeId] = {
            ...shiftsByDay
          };
          return;
        }

        teamMembers[employeeId] = deepmerge(shiftsByDay, teamMembers[employeeId]);
      });

      const teamMembersSummary: Record<string, TeamMemberSummary> = {};

      Object.keys(teamMembers).forEach(key => {
        const days = teamMembers[key];
        const dayArray = Object.values<EmployeeWorkTimeRecord[]>(days);
        const firstName = dayArray[0][0]?.employee?.firstName ?? '';
        const lastName = dayArray[0][0]?.employee?.lastName ?? '';
        const name = translations.employeeName(firstName, lastName);
        teamMembersSummary[key] = {
          employeeId: key,
          name,
          locationId: dayArray[0][0].locationId,
          total: 0
        };

        eachDayOfInterval({ start, end })
          .map(date => getDayOfYear(date))
          .forEach(dayId => {
            const shifts = days[dayId];
            if (!shifts) {
              teamMembersSummary[key][dayId] = 0;
              return;
            }
            const hours = shifts.reduce<number>((acc, shift) => {
              const start = shift?.start?.dateTime;
              const end = shift?.end?.dateTime;
              if (!start || !end) return acc;
              const duration = calculateMinutesDuration(start, end || new Date());
              const hours = duration / 60;
              return acc + hours;
            }, 0);
            teamMembersSummary[key][dayId] = hours;
          });
      });

      Object.keys(teamMembersSummary).forEach(key => {
        teamMembersSummary[key]['total'] = Object.entries(teamMembersSummary[key]).reduce((acc, [key, value]) => {
          if (key !== 'locationId' && typeof value === 'number') {
            return acc + value;
          }
          return acc;
        }, 0);
      });

      setData({
        team: Object.values(teamMembersSummary),
        total: getTotal(teamMembersSummary as TeamMemberSummary)
      });
    } catch (e) {
      log(e, LoggerLevel.Error);
      setLoading(false);
      setError(e as CombinedError);
    }
    setLoading(false);
  };

  useDeepCompareEffect(() => {
    fetchData();
  }, [start, end, client, locationIdsToFilterOn]);

  return { data, fetching: loading, error: error, fetchData };
};
