import { FC, useState } from "react";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import strings from '../shared/shared.translations'

interface SearchProps {
    onChange: (newValue: string) => void
}

export const Search: FC<SearchProps> = ({ onChange }) => {
  const [value, setValue] = useState<string>('');

  const clear = () => {
    setValue('');
    onChange('');
  }

  return <div className="relative flex items-center">
    <AiOutlineSearch className="absolute left-0 ml-[10px] text-[24px]"/>
    <input
    type="text"
    value={value}
    onChange={(e) => {
      const newValue = e.target.value;
      
      setValue(newValue);
      onChange(newValue);
    }}
    className='flex pl-[40px] pr-[20px] rounded-md'
    placeholder={strings.common.search}
  />
  {value !== '' && <AiOutlineClose className='absolute right-0 mr-[10px] text-[24px]' onClick={clear}/>}
  </div>;
};
