import create, { State } from 'zustand';
import { Storage } from '@capacitor/storage';
import { requestLocationFromBrowser } from './requestLocationFromBrowser';

export enum LocationPermissionType {
  NotGranted,
  Denied,
  Granted
}

export enum LocationState {
  Unknown = 'Unknown',
  Loaded = 'Loaded',
  Error = 'Error'
}

interface UseLocationState extends State {
  location: {
    state: LocationState;
    permission?: LocationPermissionType;
    position?: GeolocationPosition;
    dateGranted?: string;
  };
  restoreLocationPermissions: () => void;
  validateAndSetLocationPermission: (newPermission: LocationPermissionType) => void;
  resetLocationPermission: () => void;
  getUserLocation: () => void;
}

const disableSetLocationPermission = (storedValue: string | null) => {
  //Returns true if *invalid*
  //Returns false if *valid*
  if (storedValue !== null && storedValue !== LocationPermissionType.NotGranted.toString()) return true;
  return false;
};

export const useDeviceLocation = create<UseLocationState>((set, get) => ({
  location: {
    permission: undefined,
    state: LocationState.Unknown,
    dateGranted: undefined
  },
  restoreLocationPermissions: async () => {
    const { value: storedValue } = await Storage.get({ key: 'locationPermission' });
    const { value: storedDate } = await Storage.get({ key: 'dateLocationPermissionGranted' });
    set(state => ({
      location: {
        ...state.location,
        permission: parseInt(storedValue || LocationPermissionType.NotGranted.toString()),
        dateGranted: storedDate === null ? undefined : storedDate
      }
    }));
    if (get().location.permission === LocationPermissionType.Granted && !get().location.position) {
      get().getUserLocation();
    }
  },
  resetLocationPermission: async () => {
    await Storage.remove({ key: 'locationPermission' });
    set(state => ({
      location: {
        ...state.location,
        permission: LocationPermissionType.NotGranted,
        dateGranted: undefined
      }
    }));
  },
  validateAndSetLocationPermission: async (newPermission: LocationPermissionType) => {
    const { value: storedValue } = await Storage.get({ key: 'locationPermission' });
    if (disableSetLocationPermission(storedValue)) return;
    await Storage.set({
      key: 'locationPermission',
      value: newPermission.toString()
    });
    set(state => ({
      location: {
        ...state.location,
        permission: newPermission
      }
    }));
  },
  getUserLocation: async () => {
    try {
      const date = new Date().toISOString();
      await requestLocationFromBrowser().then(position =>
        set(state => ({
          location: {
            ...state.location,
            state: LocationState.Loaded,
            position,
            permission: LocationPermissionType.Granted,
            dateGranted: date
          }
        }))
      );
      await Storage.set({ key: 'dateLocationPermissionGranted', value: date });
    } catch (error) {
      await Storage.remove({ key: 'dateLocationPermissionGranted' });
      return set(state => ({
        location: {
          ...state.location,
          state: LocationState.Error,
          error,
          permission: LocationPermissionType.Denied,
          dateGranted: undefined
        }
      }));
    }
  }
}));

const locationState = useDeviceLocation.getState();
locationState.restoreLocationPermissions();
