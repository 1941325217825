import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuthenticationService, User } from '../authentication-service';
import { User as OIDCUser } from 'oidc-client-ts';
import jwt_decode from 'jwt-decode';
import { Loading } from '../../../components/shared/Loading';
import { LoggerLevel, useLogger } from '../../../services/logging/Logger';

interface RedirectProps {}

export const constructUser = (accessToken: string, refreshToken: string, scope: string, tokenType: string) => {
  const profile: any = jwt_decode(accessToken);
  const userSettings = {
    access_token: accessToken,
    refresh_token: refreshToken,
    scope: scope,
    token_type: tokenType,
    profile: profile,
    expires_at: profile['exp'],
    id_token: '',
    session_state: '',
    state: undefined
  };
  return new OIDCUser(userSettings) as User;
};

export const Redirect: FC<RedirectProps> = () => {
  const [getUserManager, setUser] = useAuthenticationService(state => [state.userManager, state.setUser]);
  const navigate = useNavigate();
  const { log } = useLogger();

  useEffect(() => {
    (async () => {
      const restore = window.localStorage.getItem('ts-restore');
      window.localStorage.removeItem('ts-restore');

      const params = new URLSearchParams(window.location.search);
      const manager = await getUserManager;
      if (!params.get('access_token')) {
        try {
          const user = (await manager.signinRedirectCallback()) as User;
          await setUser(user);
          navigate(restore ? restore : '/');
        } catch {
          // Weird hack
          // If a user bookmarks the URL for the login screen, then they can log into the IDP
          // with old details, and this causes an error on the sign in redirect.
          // Weirdly, the solution is to just navigate to the home page. Going to the IDP again
          // from the start sorts everything out, so we just let the normal process fix it.
          log(`Sign in redirect callback failed- attempting to redirect`, LoggerLevel.Error, true);
          navigate(restore ? restore : '/');
        }

        return;
      }

      const accessToken = params.get('access_token');
      const refreshToken = params.get('refresh_token');
      const scope = params.get('scope');
      const tokenType = params.get('token_type');

      if (!accessToken || !refreshToken || !scope || !tokenType) {
        throw new Error('Authentication is misconfigured: unsuccessful 1FA flow.');
      }

      const user = constructUser(accessToken, refreshToken, scope, tokenType);
      await manager.clearStaleState();
      await manager.storeUser(user);
      await setUser(user);

      navigate(restore ? restore : '/');
    })();
  }, [getUserManager, navigate, setUser, log]);

  return <Loading />;
};
