import LocalizedStrings from 'react-localization';
import { constructNameEN } from '../../employee/employee-name-helper';
import { withCommon } from '../../services/translation/commonTranslations';

const pageStrings = new LocalizedStrings({
  en: {
    nav: {
      logout: 'Sign Out',
      myProfile: 'My profile',
      myData: 'My data',
      uiTest: 'UI Testing',
      manageOrg: 'Manage Organisation',
      manageLocation: 'Manage Location',
      mobileOpenMenu: 'Open main menu',
      lockedFeature: 'This feature of TimeShift is not unlocked.',
      pageNames: {
        me: 'Me',
        team: 'Team',
        sandbox: 'Sandbox',
        shifts: 'Shifts',
        roster: 'Rosters',
        myShifts: 'My Shifts',
        healthAndSafety: 'Health & Safety',
        calendar: 'Calendar'
      }
    },
    employeeName: constructNameEN,
    locationSelect: {
      pleaseSelect: 'Please select',
      selectLocation: 'Select Location'
    }
  }
});

export default withCommon<typeof pageStrings>(pageStrings);
