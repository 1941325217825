import { FC, HTMLAttributes } from 'react';
import { Handle } from './Handle';
import { merge } from '../../../helpers/Utility';

interface BarProps extends HTMLAttributes<HTMLDivElement> {
  color: string;
  slotCount: number;
  startSlot: number;
  endSlot: number;
  onStartHandleDrag: (event: MouseEvent) => void,
  onEndHandleDrag: (event: MouseEvent) => void,
  onStartHandleDragEnd: (event: MouseEvent) => void,
  onEndHandleDragEnd: (event: MouseEvent) => void,
  readOnly?: boolean;
}

const BAR_HEIGHT_PERCENT = 64;

export const Bar: FC<BarProps> = ({
  color,
  className,
  slotCount,
  startSlot,
  endSlot,
  onStartHandleDragEnd,
  onEndHandleDragEnd,
  onStartHandleDrag,
  onEndHandleDrag,
  readOnly
}) => {
  // How far along the container the bar should start.
  const startPercent = (startSlot / slotCount) * 100;

  // How much of the container the bar should take up.
  // NOTE: We need to add a 1 here, because both the start and end slot are included in the width.
  // eg. If the bar starts in slot 0, and ends in slot 2, it spans slot 0, 1 and 2, which is three slots.
  const lengthInSlots = endSlot - startSlot + 1;
  const widthPercent = (lengthInSlots / slotCount) * 100;

  const style = {
    left: `${startPercent}%`,
    width: `${widthPercent}%`,
    height: `${BAR_HEIGHT_PERCENT}%`,
    top: `${(100 - BAR_HEIGHT_PERCENT) / 2}%`
  };

  return (
    <div style={style} className={merge(className, 'absolute px-1')}>
      <div style={{ backgroundColor: color }} className="relative w-full h-full rounded-lg">
        {!readOnly && (
          <>
            <Handle start onDragHandle={onStartHandleDrag} onDragHandleEnd={onStartHandleDragEnd} />
            <Handle onDragHandle={onEndHandleDrag} onDragHandleEnd={onEndHandleDragEnd} />
          </>
        )}
      </div>
    </div>
  );
};
