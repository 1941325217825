import React, { FC } from 'react';
import strings from './table.translations';
import { FiX } from 'react-icons/fi';
import { TextInput } from '../form/TextInput';
import { IconButton } from '../button/IconButton';

interface TableFilterProps {
  value: string;
  onChange: (newFilter: string) => void;
}

export const TableFilter: FC<TableFilterProps> = ({ value, onChange }) => {
  return (
    <div className="flex space-x-2 justify-end p-2 items-center">
      <TextInput
        className="w-72"
        name="filter"
        value={value}
        placeholder={strings.filter}
        onChange={newValue => onChange(newValue || '')}
      ></TextInput>
      <IconButton Icon={FiX} label={strings.clearFilter} onClick={() => onChange('')} />
    </div>
  );
};
