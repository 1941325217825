import { RosterStatus } from './calendar/rosterCalendar/RosterCalendar';
import { Shift, WorkingPatternDay } from '../../services/gql/graphql';

export enum BusynessLevel {
  BUSY = 'Busy',
  NORMAL = 'Normal',
  QUIET = 'Quiet'
}

export interface StaffOption {
  id: string;
  name: string;
}


export interface StaffCalendarContextObject {
  id?: string;
  hasRoster?: boolean;
  status?: RosterStatus;
  canWork?: boolean;
  shift?: Shift;
  onClick?: () => void;
}

export interface ESSCalendarProps {
  workPatterns: WorkingPatternDay[];
  readOnly?: boolean;
}
