import { FC } from 'react';
import { InfoChit, InfoChitProps } from '../InfoChit';

interface TableChitProps extends InfoChitProps {}

export const TableChit: FC<TableChitProps> = props => {
  return (
    <div className="flex">
      <InfoChit {...props} />
    </div>
  );
};
