import React, { FC } from 'react';
import strings from './form.translations';
import { Button } from '../button/Button';

interface SubmitCancelButtonsProps {
  onComplete: () => void;
  loading: boolean;
  cancelText?: string;
  submitText?: string;
  disabled?: boolean;
}

export const SubmitCancelButtons: FC<SubmitCancelButtonsProps> = ({
  onComplete,
  loading,
  cancelText = strings.common.cancel,
  submitText = strings.common.submit,
  disabled = false
}) => {
  return (
    <div className="flex w-full items-center justify-end gap-5">
      <Button onClick={onComplete}>{cancelText}</Button>
      <Button disabled={disabled} primary type="submit" loading={loading}>
        {submitText}
      </Button>
    </div>
  );
};
