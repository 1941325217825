import React, { FC } from 'react';
import { CalendarHeader } from '../common/CalendarHeader';
import { CalendarCellProps } from '../common/Calendar';
import { isPublished, RosterCalendarContextObject } from './RosterCalendar';

export const RosterCalendarHeader: FC<CalendarCellProps<RosterCalendarContextObject>> = ({ date, contextObject, today }) => {
  const styles = getComputedStyle(document.body);
  let backgroundColor = '';
  let color = styles.getPropertyValue('--calendar-text-color--light');
  if (contextObject && contextObject.rosterStatus) {
    backgroundColor = isPublished(contextObject)
      ? styles.getPropertyValue('--calendar-published')
      : styles.getPropertyValue('--calendar-draft');
    color = styles.getPropertyValue('--calendar-text-color');
  }
  return <CalendarHeader today={!!today} date={date} backgroundColor={backgroundColor} color={color} />;
};