import { FC, HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { merge } from '../../../helpers/Utility';

interface HandleProps extends HTMLAttributes<HTMLDivElement> {
  onDragHandle: (event: MouseEvent) => void,
  onDragHandleEnd: (event: MouseEvent) => void,
  start?: boolean;
}

export const Handle: FC<HandleProps> = ({ onDragHandle, onDragHandleEnd, className, start }) => {
  const ref = useRef<HTMLDivElement>(null);

  let intClassName = 'absolute z-10 rounded-lg bg-black w-[8px] h-[75%] top-[15%] opacity-25 cursor-pointer';

  if (start) {
    intClassName = merge(intClassName, 'left-[6px]');
  } else {
    intClassName = merge(intClassName, 'right-[6px]');
  }

  const onMouseUp = useCallback((event: MouseEvent) => {
    window.removeEventListener('mousemove', onDragHandle);
    onDragHandleEnd(event);
  }, [onDragHandleEnd, onDragHandle]);

  const onMouseDown = useCallback(() => {
    if (window.getSelection) {window.getSelection()?.removeAllRanges();}
    window.addEventListener('mousemove', onDragHandle);
    window.addEventListener('mouseup', onMouseUp, {once: true});
  }, [onDragHandle, onMouseUp]);

  useEffect(() => {
    const element = ref.current;

    element?.addEventListener('mousedown', onMouseDown);

    return () => {
      element?.removeEventListener('mousedown', onMouseDown);
      element?.removeEventListener('mousemove', onDragHandle);
      element?.removeEventListener('mouseup', onMouseUp);
    }
  }, [onMouseDown, onMouseUp, onDragHandle, ref]);

  return <div ref={ref} className={merge(className, intClassName)} />;
};
