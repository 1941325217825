import { FC } from 'react';
import { HeaderGroup } from 'react-table';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

interface TableHeaderProps {
  headerGroups: HeaderGroup<any>[];
}

export const TableHeader: FC<TableHeaderProps> = ({ headerGroups }) => {
  return (
    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, i) => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`px-6 pb-2 pt-4 text-left text-xs font-medium text-secondary-std tracking-wider ${
                i > 2 && column.id !== 'expander' ? 'hidden sm:table-cell' : ''
              }`}
            >
              <span className="flex gap-2 items-center">
                {column.render('Header')}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <TiArrowSortedDown className="w-4 h-4" />
                  ) : (
                    <TiArrowSortedUp className="w-4 h-4" />
                  )
                ) : (
                  ''
                )}
              </span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
