import React, { FC, Suspense, useEffect, useRef } from 'react';
import { TsForm } from '../../../../components/shared/form/Form';
import { useForm } from 'react-hook-form';
import strings from '../../team.translations';
import {
  Exact,
  ReadEmployee,
  UpdateEmployee,
  UpdateStaffMutation,
  useUpdateStaffMutation
} from '../../../../services/gql/graphql';
import { OperationResult } from 'urql';
import { Feature } from '../../../../services/backstage/Feature';
import { handleBEFormError } from './TeamFormCommon';
import { useErrorHandler } from 'react-error-boundary';
import { CurrencyField } from '../../../../components/shared/form/CurrencyField';
import { TSTitleCard } from '../../../../components/shared/card/Card';
import { SubmissionErrorBoundary } from '../../../../components/errors/SubmissionErrorBoundary';
import { TableLoader } from '../../../../components/shared/table/TableLoader';
import { Button } from '../../../../components/shared/button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocationService } from '../../../../services/location/LocationService';
import { TSTitleCardStyle } from '../AddSingleTeamMemberForm';
import { TeamMemberAddOrEditFormContent } from '../../TeamMemberAddOrEditFormContent';

interface TeamMemberEditFormProps {
  employee: ReadEmployee;
  currentLocationId?: number;
}

export interface EditTeamMemberFields {
  firstName: string;
  lastName: string;
  role: string;
  email?: string;
  phoneNumber?: string;
  payRate: number;
  locationIds: string[];
}

export const TeamMemberDetailsEditForm: FC<TeamMemberEditFormProps> = ({ employee, currentLocationId }) => {
  const { scrollPayIntoView } = useParams<'scrollPayIntoView'>();

  const paySectionRef = useRef<HTMLElement>(null);

  const [mappedLocations] = useLocationService(state => [state.mappedLocations]);

  const [{ fetching: fetchingStaff }, UpdateEmployee] = useUpdateStaffMutation();
  const fetching = fetchingStaff;

  const isPositive = (num: number) => num >= 0;

  const handleError = useErrorHandler();

  const methods = useForm<EditTeamMemberFields>({
    defaultValues: {
      firstName: employee.firstName,
      lastName: employee.lastName,
      role: employee.role,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      payRate: employee.hourlyRate,
      locationIds: employee.restaurants?.map(loc => loc.id.toString())
    }
  });

  const onSubmit = async (data: EditTeamMemberFields) => {
    if (!isPositive(data.payRate)) {
      //Can't have a negative pay
      // @ts-ignore
      methods.setError('payRate', {
        message: strings.memberPayCard.hourlyRateValidation
      });
      return;
    }

    if (!(data.phoneNumber || data.email)) {
      return;
    }

    if (currentLocationId && data.locationIds.indexOf(currentLocationId.toString()) === -1) {
      methods.setError('locationIds', {
        message: strings.editTeamMember.unassignedFromClockedLocation,
        type: 'custom'
      });
      return;
    }

    try {
      const updateData = {
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        email: data.email ? data.email : undefined, // Don't send an empty
        identityId: employee.identityId,
        phoneNumber: data.phoneNumber ? data.phoneNumber : undefined, // Don't send empty
        ignoreHourContribution: false,
        isRosterable: true,
        hourlyRate: data.payRate,
        locationIds: mappedLocations.filter(location => location.checked).map(location => Number(location.key))
      };

      let result: OperationResult<UpdateStaffMutation, Exact<{ employee: UpdateEmployee }>> | undefined;

      result = await UpdateEmployee({
        employee: updateData
      });

      if (result.error) {
        throw result.error;
      } else {
        goToEmployeePage(result.data?.updateEmployee?.identityId);
      }
    } catch (ex: any) {
      handleBEFormError(ex, methods, handleError);
    }
  };

  const { userId } = useParams<'userId'>();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(`/team-member/${userId}`);
  };

  const goToEmployeePage = (id: string) => {
    navigate(`/team-member/${id}`);
  };

  useEffect(() => {
    if (scrollPayIntoView) {
      paySectionRef.current?.scrollIntoView();
    }
  });

  return (
    <TsForm methods={methods} onSubmit={onSubmit}>
      <TSTitleCard title={strings.cardTitle} className={TSTitleCardStyle}> 
        <SubmissionErrorBoundary>
          <Suspense fallback={<TableLoader />}>
            <TeamMemberAddOrEditFormContent addMode={false} employee={employee} methods={methods}/>
          </Suspense>
        </SubmissionErrorBoundary>
      </TSTitleCard>
      <Feature name="pay">
        <section ref={paySectionRef}>
          <TSTitleCard title={strings.memberPayCard.cardTitle} className={TSTitleCardStyle}>
            <SubmissionErrorBoundary>
              <Suspense fallback={<TableLoader />}>
                <div className="flex flex-row gap-2 w-[50%]">
                  <CurrencyField
                    name={'payRate'} //TODO - Pass icon when Kofo UI supports it
                    title={strings.memberPayCard.hourlyRate}
                    validationMessage={strings.memberPayCard.hourlyRateValidation}
                  />
                </div>
              </Suspense>
            </SubmissionErrorBoundary>
          </TSTitleCard>
        </section>
      </Feature>
      <div className="space-y-5 max-w-3xl mx-auto mb-4pt-5 pl-5">
        <div className="flex w-full items-center justify-end gap-5">
          <Button onClick={goBack}>{strings.common.cancel}</Button>
          <Button primary type="submit" loading={fetching}>
            {strings.common.save}
          </Button>
        </div>
      </div>
    </TsForm>
  );
};
