import React, { FC, useState } from 'react';
import { AccordionOption } from './AccordionOption';

export interface AccordionDataOption {
  heading: string;
  component: any;
}

export interface AccordionSteps {
  totalSteps: number;
  currentStep: number;
}

interface AccordionWizardProps {
  choices: AccordionDataOption[];
  customSubmit: JSX.Element;
}

export const AccordionWizard: FC<AccordionWizardProps> = ({ choices, customSubmit }) => {
  const [accordionStep, setAccordionStep] = useState<number>(0);

  return (
    <div className="divide-y">
      {choices.map((option: AccordionDataOption, idx: number) => {
        return (
          <AccordionOption
            option={option}
            steps={{ totalSteps: choices.length - 1, currentStep: idx }}
            active={idx === accordionStep}
            stepCallback={setAccordionStep}
            customSubmit={customSubmit}
          />
        );
      })}
    </div>
  );
};
