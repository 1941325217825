import React, { FC } from 'react';
import { BsCurrencyPound } from 'react-icons/bs';
import strings from '../../team.translations';
import { ReadEmployee } from '../../../../services/gql/graphql';
import { TSTitleCard } from '../../../../components/shared/card/Card';
import { useNavigate } from 'react-router-dom';

interface TeamMemberPayCardProps {
  enableEdit?: boolean;
  employee: ReadEmployee;
}

export const TeamMemberPayCard: FC<TeamMemberPayCardProps> = ({ employee, enableEdit }) => {
  const navigate = useNavigate();
  const onEditClick = () => {
    navigate('edit-details/true');
  };

  return (
    <TSTitleCard
      title={strings.memberPayCard.cardTitle}
      className="h-full"
      icon={BsCurrencyPound}
      editDetails={
        enableEdit
          ? {
              label: strings.memberPayCard.editLabel,
              onClick: onEditClick
            }
          : undefined
      }
    >
      <div className="pt-4">
        <div className="font-semibold">{strings.memberPayCard.hourlyRate}</div>
        {employee.hourlyRate ? (
          <p>{strings.common.currencySymbol + employee.hourlyRate.toFixed(2)}</p>
        ) : (
          <p>{strings.memberPayCard.noPaySet}</p>
        )}
      </div>
    </TSTitleCard>
  );
};
