import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { TimeshiftAvatar } from '../../../components/shared/TimeshiftAvatar';
import { EmployeeStatus } from './EmployeePresenceCard';
import strings from './present.translations';
import { differenceInMinutes } from 'date-fns';
import { Loader } from '../../../components/shared/Loader';
import { SkeletonLoader } from '../../../components/shared/SkeletonLoader';
import EllipsisTooltip from 'ellipsis-tooltip-react-chan';
import { useLocationService } from '../../../services/location/LocationService';

export interface ExpectedEmployee {
  firstName: string;
  lastName: string;
  id: string;
  status: EmployeeStatus;
  locationId?: number;
  clockOutTime?: Date;
}

interface StaffAvatarProps {
  employee: ExpectedEmployee;
  fetching?: boolean;
}

export const EmployeeAvatar: FC<StaffAvatarProps> = ({ employee, fetching }) => {
  const employeeName = `${employee.firstName} ${employee.lastName}`;
  let displayStatus: string;
  let background: string;
  let greyscale: boolean = false;
  switch (employee.status) {
    case EmployeeStatus.ON_SHIFT:
      displayStatus = strings.status.present;
      background = 'bg-primary-400';
      break;
    case EmployeeStatus.NOT_ON_SHIFT:
      let diffMinutes: number | undefined;
      if (employee.clockOutTime) {
        const now = new Date();
        diffMinutes = differenceInMinutes(now, employee.clockOutTime);
      }

      if (diffMinutes === undefined) {
        displayStatus = strings.status.notOnShift;
      } else {
        if (diffMinutes === 0) {
          displayStatus = strings.status.justNow;
        } else if (diffMinutes < 60) {
          displayStatus = strings.status.clockedOutMinutes(diffMinutes);
        } else {
          const diffHours = Math.floor(diffMinutes / 60);
          displayStatus = strings.status.clockedOutHours(diffHours);
        }
      }

      background = 'bg-gray-400';
      greyscale = true;
      break;
  }

  const [locationList] = useLocationService(state => [state.locationList]);

  const getLocationName = (id: number) => {
    const location = locationList.find(loc => loc.id === id);

    return location?.name;
  };

  return (
    <Link to={`/team-member/${employee.id}`} className={employee.id ? '' : 'pointer-events-none'}>
      <div className={'w-[90px] max-w-max-content h-full flex flex-col justify-center items-center'}>
        {fetching ? (
          <SkeletonLoader className="w-[70px] h-[70px]" />
        ) : (
          <TimeshiftAvatar greyscale={greyscale} name={employeeName} />
        )}
        <p
          className={`w-full whitespace-nowrap h-6 p-0.5 px-2 flex justify-center items-center rounded-md uppercase text-xs leading-2 font-semibold text-white -mt-3 z-[1] ${background}`}
        >
          {fetching ? <Loader className="h-[16px]" /> : displayStatus}
        </p>
        <EllipsisTooltip
          options={{
            place: 'bottom',
            delayShow: 500
          }}
          style={{ textAlign: 'center' }}
        >
          <span className="font-semibold mt-1 text-sm sm:text-base">{employeeName}</span>
        </EllipsisTooltip>
        {locationList.length > 1 && typeof employee.locationId === 'number' && (
          <EllipsisTooltip
            options={{
              place: 'bottom',
              delayShow: 500
            }}
            style={{ textAlign: 'center' }}
          >
            <span className="mt-1 text-sm sm:text-base">{getLocationName(employee.locationId)}</span>
          </EllipsisTooltip>
        )}
      </div>
    </Link>
  );
};
