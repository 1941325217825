import React, { FC } from 'react';
import strings from '../home.translations';
import { FiAlertTriangle } from 'react-icons/fi';
import { UnverifiedClocksList } from './UnverifiedClocksList';
import { InfoChit } from '../../../components/shared/InfoChit';
import { TSTitleCard } from '../../../components/shared/card/Card';

export const UnverifiedClocksSection: FC = () => {
  return (
    <TSTitleCard
      title={strings.unverifiedClocks.title}
      icon={FiAlertTriangle}
      theme="amber"
      smallContent={<InfoChit>{strings.unverifiedClocks.lastTwoWeeks}</InfoChit>}
    >
      <UnverifiedClocksList />
    </TSTitleCard>
  );
};
