import LocalizedStrings from 'react-localization';

const reportStrings = new LocalizedStrings({
  en: {
    viewEmployeesDetails: "View Employee's Details",
    printReport: 'Print report',
    downloadData: 'Download data'
  }
});

export default reportStrings;
