import { FC } from 'react';
import create, { State } from 'zustand';
import createContext from 'zustand/context';
import {
  useEndMyWorkTimeRecordMutation,
  useGetMyCurrentWorkStatusQuery,
  useStartMyWorkTimeRecordMutation,
  WorkTimeDataSource
} from '../gql/graphql';
import { geolocationToGqlCoords, Position } from '../location/location-helper';
import { dateToGQLFormat, stripSeconds } from '../../helpers/dateHelper';

export enum ClockInStatus {
  In,
  Out,
  Unknown
}

export interface ClockOptions {
  coords?: Position;
  manualDate?: any;
  deviceId?: string;
  secureCode?: string;
}

export interface ClockService extends State {
  clockState: ClockInStatus;
  clockLocationId?: number;
  loadingState: boolean;
  errorMessage?: string;
  setClockState: (
    newState: ClockInStatus,
    locationId: number,
    source: WorkTimeDataSource,
    options?: ClockOptions
  ) => Promise<void>;
  refreshClockState: () => void;
}

const { Provider, useStore } = createContext<ClockService>();
export const useClockService = useStore;

export const ClockProvider: FC = ({ children }) => {
  // Because we want to use the same suspense/loading/error handling
  // we want to create the service in a react-y way so we can use that data on our initial creation.
  // This provider is especially replacing the suspense "loading" state for each component using it

  const [{ data: workStatusData }] = useGetMyCurrentWorkStatusQuery({
    requestPolicy: 'network-only'
  });

  const startMyWorkTimeRecord = useStartMyWorkTimeRecordMutation()[1];
  const endMyWorkTimeRecord = useEndMyWorkTimeRecordMutation()[1];

  const setClockState = (
    newClockState: ClockInStatus,
    locationId: number,
    source: WorkTimeDataSource,
    options?: ClockOptions
  ) => {
    const gqlCoords = geolocationToGqlCoords(options?.coords);
    const date = new Date();
    stripSeconds(date);

    if (newClockState === ClockInStatus.In) {
      return startMyWorkTimeRecord({
        workTimeRecordStart: {
          locationId: locationId,
          startSource: source,
          start: options?.manualDate ?? dateToGQLFormat(date),
          startGeoLocation: gqlCoords,
          deviceId: options?.deviceId,
          verificationCode: options?.secureCode
        }
      });
    } else if (newClockState === ClockInStatus.Out) {
      return endMyWorkTimeRecord({
        workTimeRecordEnd: {
          locationId: locationId,
          endSource: source,
          end: options?.manualDate ?? dateToGQLFormat(date),
          endGeoLocation: gqlCoords,
          deviceId: options?.deviceId,
          verificationCode: options?.secureCode
        }
      });
    }
  };

  const getCurrentClockState = () => {
    let initialClockState = ClockInStatus.Unknown;
    let clockLocation: number | undefined = undefined;
    if (workStatusData?.getMyOpenWorkTimeRecord) {
      const record = workStatusData?.getMyOpenWorkTimeRecord[0];
      if (record) {
        initialClockState = ClockInStatus.In;
        clockLocation = record.locationId;
      } else {
        initialClockState = ClockInStatus.Out;
      }
    }

    return { initialClockState, clockLocation };
  };

  const createStore = () =>
    create<ClockService>(set => ({
      clockState: getCurrentClockState().initialClockState,
      clockLocationId: getCurrentClockState().clockLocation,
      loadingState: false,
      setClockState: async (newClockState, locationId, source, options) => {
        set(state => ({ loadingState: true, errorMessage: undefined }));

        const result = await setClockState(newClockState, locationId, source, options);
        const error = result?.error;
        if (error) {
          set(state => ({ loadingState: false }));
          throw error;
        }

        set(state => ({
          clockState: newClockState,
          clockLocationId: newClockState === ClockInStatus.In ? locationId : undefined,
          loadingState: false
        }));
      },
      refreshClockState: () => {
        set(state => ({
          clockState: getCurrentClockState().initialClockState,
          clockLocationId: getCurrentClockState().clockLocation
        }));
      }
    }));

  return <Provider createStore={createStore}>{children}</Provider>;
};
