import React, { FC, HTMLAttributes } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { PageSizer, SizerMode } from '../../../components/page/PageSizer';
import { Breadcrumbs } from '../../../components/page/Breadcrumbs';

interface RosterHeaderCommonProps extends HTMLAttributes<HTMLDivElement> {
  headerTitle: string;
  breadcrumbs: any;
  headerButtons: any;
}

export const RosterHeaderCommon: FC<RosterHeaderCommonProps> = ({
  headerTitle,
  breadcrumbs,
  headerButtons,
  children
}) => {
  return (
    <div className="w-full border-primary-std bg-secondary-std border-b">
      <PageSizer mode={SizerMode.HEADER}>
        {breadcrumbs && <Breadcrumbs pages={breadcrumbs} />}
        <div className={'w-full flex flex-col md:flex-row justify-between'}>
          <div className="flex gap-5 items-center text-2xl font-bold">
            <FiCalendar />
            <div>{headerTitle}</div>
          </div>
          <div className="flex gap-5">{headerButtons}</div>
        </div>
      </PageSizer>
      {children}
    </div>
  );
};
