import React, { FC } from 'react';
import { EmployeeWorkTimeRecord } from '../../../services/gql/graphql';
import { EditShiftForm } from './EditShiftForm';
import { FiX } from 'react-icons/fi';
import { SubmissionErrorBoundary } from '../../../components/errors/SubmissionErrorBoundary';
import { Title } from '../../../components/shared/text/Title';
import strings from '../shifts.translations';
import { Modal } from '../../../components/shared/modal/Modal';

interface EditShiftModalProps {
  shift: EmployeeWorkTimeRecord;
  open: boolean;
  onClose: () => void;
  suggestedOutTime?: Date;
  isMe?: boolean;
}

export const EditShiftModal: FC<EditShiftModalProps> = ({ shift, open, suggestedOutTime, onClose, isMe }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="relative m-8 sm:max-w-md">
        <Title>{strings.detailView.editShift}</Title>
        <SubmissionErrorBoundary>
          <EditShiftForm onComplete={onClose} shift={shift} suggestedOutTime={suggestedOutTime} isMe={isMe} />
        </SubmissionErrorBoundary>
        <button className="absolute top-0 right-0" onClick={onClose}>
          <FiX className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
};
