import * as React from 'react';
import { Menu as HeadlessMenu } from '@headlessui/react';

export interface ActiveProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

export const Action: React.FC<ActiveProps> = ({ children, active, ...props }) => {
  return (
    <button
      {...props}
      className={`group flex space-x-2 rounded-md items-center hover:bg-black dark:hover:bg-white hover:bg-opacity-5 dark:hover:bg-opacity-5 hover:text-primary-std w-full px-4 py-2 ${
        active ? 'bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-5 text-primary-std' : 'text-secondary-std'
      }`}
    >
      {children}
    </button>
  );
};

export const DropdownAction: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
  return (
    <HeadlessMenu.Item>
      {({ active }) => (
        <Action {...props} active={active}>
          {children}
        </Action>
      )}
    </HeadlessMenu.Item>
  );
};
