import { buildSummaryData, getComparisonPeriodDates, SummaryData } from './buildSummaryData';
import { EmployeeWorkTimeRecord, useGetShiftsListQuery } from '../../../services/gql/graphql';
import { dateToGQLFormat } from '../../../helpers/dateHelper';
import { useMemo } from 'react';

export const useSummaryData = (start: Date, end: Date) => {

  const adaptedEnd = new Date(end);
  adaptedEnd.setDate(adaptedEnd.getDate() + 1);

  const comparisonPeriod = getComparisonPeriodDates(start, adaptedEnd);

  const [{ data: currentData, fetching: currentFetching, error: currentError }] = useGetShiftsListQuery({
    variables: {
      queryWorkItemArgs: {
        from: dateToGQLFormat(start),
        to: dateToGQLFormat(adaptedEnd)
      }
    },
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    )
  });

  const [{ data: comparisonData, fetching: comparisonFetching, error: comparisonError }] = useGetShiftsListQuery({
    variables: {
      queryWorkItemArgs: {
        from: dateToGQLFormat(comparisonPeriod.start),
        to: dateToGQLFormat(comparisonPeriod.end)
      }
    },
    context: useMemo(
      () => ({
        suspense: false
      }),
      []
    )
  });

  let summaryData: SummaryData | undefined = undefined;
  if (currentData?.getWorkTimeRecord && comparisonData?.getWorkTimeRecord) {
    summaryData = buildSummaryData(
      currentData.getWorkTimeRecord as EmployeeWorkTimeRecord[],
      comparisonData.getWorkTimeRecord as EmployeeWorkTimeRecord[]
    );
  }

  return { summaryData, fetching: comparisonFetching || currentFetching, error: currentError || comparisonError };
};
