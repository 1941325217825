import { FC, HTMLAttributes } from 'react';

export const Watch: FC<HTMLAttributes<SVGSVGElement>> = props => (
  <svg {...props} height="1em" width="1em" viewBox="0 0 23 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.8562 5.20142V3.57527C20.8562 1.96733 19.5516 0.662781 17.9437 0.662781H5.3229C3.71496 0.662781 2.41041 1.96733 2.41041 3.57527V5.20142C1.24784 5.61281 0.469962 6.71107 0.46875 7.94401V24.4481C0.469963 25.6811 1.24784 26.7793 2.41041 27.1907V28.8169C2.41041 30.4248 3.71496 31.7294 5.3229 31.7294H17.9437C19.5516 31.7294 20.8562 30.4248 20.8562 28.8169V27.1907C22.0188 26.7793 22.7966 25.6811 22.7979 24.4481V7.94401C22.7966 6.71104 22.0188 5.61281 20.8562 5.20142V5.20142ZM18.9145 28.817C18.9145 29.0743 18.8126 29.3218 18.6306 29.5038C18.4485 29.6859 18.201 29.7878 17.9437 29.7878H5.32291C4.78652 29.7878 4.35208 29.3534 4.35208 28.817V27.3607H18.9145L18.9145 28.817ZM16.6428 17.0943L15.9438 18.9049L11.2838 17.104C10.9088 16.9596 10.6613 16.598 10.6625 16.1963V9.58004H12.6041V15.5312L16.6428 17.0943ZM18.9145 5.0318H4.35207V3.57555C4.35207 3.03916 4.78651 2.60472 5.3229 2.60472H17.9437C18.201 2.60472 18.4485 2.70666 18.6306 2.88869C18.8126 3.07072 18.9145 3.31828 18.9145 3.57554L18.9145 5.0318Z"
      fill="white"
    />
  </svg>
);
