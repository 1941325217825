import * as React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { merge } from '../../../helpers/Utility';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  widthOverride?: string;
}

export const Modal: React.FC<ModalProps> = ({ children, open, onClose, widthOverride }) => {
  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as="div" static open={open} className="fixed inset-0 z-30 overflow-y-auto" onClose={onClose}>
        <div className="overflow-hidden">
          <div className="pb-safe flex items-end justify-center text-center sm:block sm:p-0 w-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={merge(
                  widthOverride ? widthOverride : 'w-full xl:w-max sm:w-5/6',
                  'bg-primary-std fixed bottom-0 inline-block transform p-1 text-left shadow-xl transition-all sm:relative sm:bottom-auto sm:rounded-lg sm:pb-1 sm:align-middle'
                )}
              >
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
