import React, { FC } from 'react';
import { ReadRoster, RosterStation } from '../../../services/gql/graphql';
import { Station } from './Station';
import { generateColors } from './rosterHelper';
import { RosterTimerBar } from '../common/RosterTimerBar';

interface RosterProps {
  roster: ReadRoster;
}

export const LEFT_COLUMN_WIDTH = 350;

export const Roster: FC<RosterProps> = ({ roster }) => {
  const colors = generateColors(roster.stations?.length || 0);
  return (
    <div className="relative w-full">
      <RosterTimerBar />
      {roster.stations?.map((station, idx) => (
        <Station
          station={station as RosterStation}
          color={colors[idx]}
          lastStation={idx === roster.stations!.length - 1}
        />
      ))}
    </div>
  );
};
