import { FC } from 'react';
import { DraftCalendarIcon } from '../../../../../components/shared/icons/DraftCalendarIcon';
import { PublishedCalendarIcon } from '../../../../../components/shared/icons/PublishedCalendarIcon';
import { isDateInPast } from '../../../../../helpers/dateHelper';
import { isPublished, RosterCalendarContextObject } from '../RosterCalendar';
import strings from '../../../roster.translations';
import { SingleRosterJobStatus } from '../../../../../services/gql/graphql';
import { AutofillIcon } from '../../../../../components/shared/icons/AutofillingIcon';
import { AutoFillLoader } from '../../../../../components/shared/icons/AutoFillLoader';
import { FiAlertTriangle } from 'react-icons/fi';

interface IconToDisplayProps {
  contextObject: RosterCalendarContextObject;
  date: Date;
}

export const IconToDisplay: FC<IconToDisplayProps> = ({ contextObject, date }) => {
  let text: string = '';
  let icon: JSX.Element = <div></div>;

  if (isPublished(contextObject)) {
    icon = <PublishedCalendarIcon />;
    text = isDateInPast(date, new Date()) ? strings.completed : strings.published;
  } else if (
    contextObject.optimiserStatus === SingleRosterJobStatus.OptimiserError ||
    contextObject.optimiserStatus === SingleRosterJobStatus.SkippedError ||
    contextObject.optimiserStatus === SingleRosterJobStatus.SkippedErrorCreating
  ) {
    text = strings.error;
    icon = <FiAlertTriangle className="h-8 w-8" />;
  } else if (
    contextObject.optimiserStatus === SingleRosterJobStatus.RosterQueueing ||
    contextObject.optimiserStatus === SingleRosterJobStatus.RosterCreating ||
    contextObject.optimiserStatus === SingleRosterJobStatus.OptimiserQueued
  ) {
    text = strings.queued;
    icon = <AutofillIcon />;
  } else if (contextObject.optimiserStatus === SingleRosterJobStatus.OptimiserStarted) {
    text = strings.inProgress;
    icon = <AutoFillLoader />;
  } else if (contextObject.id) {
    icon = <DraftCalendarIcon />;
    if (contextObject.optimiserStatus === SingleRosterJobStatus.OptimiserCompleted) {
      text = strings.ready;
    } else {
      text = strings.draft;
    }
  }

  return (
    <div className="flex flex-col items-center text-gray-500">
      {icon}
      <p className="text-xs font-bold">{text.toUpperCase()}</p>
    </div>
  );
};
