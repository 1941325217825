import { eachDayOfInterval, getDate, getDayOfYear, getMonth, hoursToMinutes } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Controls,
  Description,
  Report,
  ReportContainer,
  ReportProvider,
  Table
} from '../../../components/reports/Report';
import { CustomIntervalTimePicker } from '../../../components/shared/date/CustomIntervalTimePicker';
import { getTotal, useShiftReport } from './useShiftReport';
import translations from './allShiftsReport.translations';
import { FiClock, FiMapPin, FiRefreshCcw } from 'react-icons/fi';
import { Button } from '../../../components/shared/button/Button';
import { PageHeader } from '../../../components/page/PageHeader';
import strings from '../shifts.translations';
import { PageContainer } from '../../../components/page/PageContainer';
import { useLocationService } from '../../../services/location/LocationService';
import { dayDiff } from '../../../helpers/dateHelper';
import { UpdateSelectedLocationsModal } from '../UpdateSelectedLocationsModal';
import { ShowingDataForLocationsText } from '../ShowingDataForLocationsText';

export const AllShiftsReport: FC = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() - 1);
  const start = new Date(today);
  start.setDate(start.getDate() - 6);

  const [locations, selectedShiftLocations] = useLocationService(state => [
    state.locationList,
    state.selectedShiftLocations
  ]);

  const [[startDate, endDate], setDatePair] = useState<[Date, Date]>(() => [start, today]);

  const { data, fetching, fetchData } = useShiftReport(startDate, endDate);

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    const getFilteredRecords = () => {
      const records = data?.team?.filter((record: any) => {
        const locationArr = selectedShiftLocations
          .filter(location => location.checked && location.key! === String(record.locationId))
          .map(location => location.key);

        return locationArr.length > 0;
      });

      const total = getTotal(records!);

      return {
        team: records!,
        total
      };
    };

    if (data) {
      setFilteredData(getFilteredRecords());
    }
  }, [selectedShiftLocations, data]);

  const handleNavigate = (row?: Record<string, string>) => {
    if (row?.employeeId === undefined) {
      window.open(`/team`, '_blank', 'noopener');
      return;
    }
    window.open(`/team-member/${row.employeeId}`, '_blank', 'noopener');
  };

  const formatReportTime = (totalTime: number) => {
    return `${Math.floor(totalTime)}h ${hoursToMinutes(totalTime) % 60}m`;
  };

  const breadcrumbs = [
    { name: strings.allShifts, to: '/shifts' },
    { name: strings.timeAndCost, to: '/shifts/report' }
  ];

  const reportValue: Report = {
    name: translations.title,
    description: translations.description(startDate, endDate),
    data: filteredData?.team,
    loading: fetching
  };

  const [showUpdateLocationModal, setShowUpdateLocationModal] = useState<boolean>(false);

  return (
    <PageContainer>
      <PageHeader pageTitle={strings.timeAndCost} breadcrumbs={breadcrumbs} icon={<FiClock />}>
        <div className="flex flex-col md:flex-row gap-2 md:gap-5">
          {locations?.length > 1 && (
            <Button
              onClick={() => setShowUpdateLocationModal(true)}
              primary
              className="flex items-center justify-center space-x-2"
            >
              <FiMapPin />
              <span>{strings.selectLocations}</span>
            </Button>
          )}
          <ReportProvider value={reportValue}>
            <Controls />
          </ReportProvider>
        </div>
      </PageHeader>
      <ReportContainer report={reportValue}>
        <div className="flex items-stretch justify-center md:p-6 p-4 print:hidden">
          {useMemo(
            () => (
              <CustomIntervalTimePicker
                startDate={startDate}
                endDate={endDate}
                onChange={setDatePair}
                dayDiff={dayDiff(startDate, endDate)}
              />
            ),
            [startDate, endDate, setDatePair]
          )}
        </div>

        <div className="flex flex-col w-full md:p-6 p-4 print:p-0">
          <div>
            <Description />
            <ShowingDataForLocationsText/>
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold tracking-tight mt-1 mb-3">{translations.timeOverview.title}</h2>
              <Button className="h-1/2 print:hidden" onClick={fetchData}>
                <FiRefreshCcw />
              </Button>
            </div>
          </div>
        </div>
        <Table
          onRowClick={handleNavigate}
          headers={[
            { title: translations.timeOverview.headers.employeeName, accessor: 'name' },
            ...eachDayOfInterval({ start: startDate, end: endDate }).map(date => ({
              title: `${getDate(date)}/${getMonth(date) + 1}`,
              accessor: `${getDayOfYear(date)}`
            })),
            { title: translations.timeOverview.headers.total, accessor: 'total' }
          ]}
          Cell={({ value }) => {
            const isNumber = typeof value === 'number';
            return (
              <span className={isNumber && value === 0 ? 'text-gray-400 print:text-black' : ''}>
                {!isNumber ? value : formatReportTime(value)}
              </span>
            );
          }}
        >
          <Total>
            <p className="font-semibold">{data && formatReportTime(filteredData?.total || 0)}</p>
          </Total>
        </Table>
      </ReportContainer>
      <UpdateSelectedLocationsModal
            onCompleteCallback={() => {
              setShowUpdateLocationModal(false);
            }}
            open={showUpdateLocationModal}
            onClose={() => setShowUpdateLocationModal(false)}
          />
    </PageContainer>
  );
};

export const Total: FC = ({ children }) => {
  return (
    <div className="flex justify-between text-base">
      <p>{translations.timeOverview.total}</p>
      {children}
    </div>
  );
};
