import { AiOutlineUser } from 'react-icons/ai';
import { ReadEmployee } from '../services/gql/graphql';
import {phone} from 'phone';

export const merge = (...classes: (string | undefined | null)[]) => {
  return classes.filter(Boolean).join(' ');
};

export const isEmployeeDeactivatedOrDeleted = (employee: ReadEmployee) => {
  return employee.isDeactivated || employee.isDeleted;
};

export const emailRegex = /\S+@\S+\.\S+/;
export const generalPhoneRegex = /^[+0][0-9]{10,}$/;

export const validateEmail = (email: string) => {
  if (!email) return true; //If the email is undefined then it is valid - kind of a quirk with the add user forms
  return emailRegex.test(email);
};

export const validatePhone = (phoneString: string) => {
  if (!phoneString) return true;
  return phone(phoneString, {country: 'GB'}).isValid;
};

export const countryCodeUK = '+44';

export const formatPhoneNumber = (number?: string) => {
  let numberToSend = number?.replaceAll(' ', '');

  if (numberToSend && numberToSend[0] === '0') {
    const arr = numberToSend.split('');
    arr.shift();
    arr.unshift(countryCodeUK);
    numberToSend = arr.join('');
  }
  return numberToSend?.substring(0, 16);
};

export const breadcrumbName = (
  employee: ReadEmployee,
  employeeString: (firstName: string, lastName: string) => string
) => {
  return (
    <>
      <AiOutlineUser className="mr-2" />
      {employeeString(employee.firstName, employee.lastName)}
    </>
  );
};
