import { AnchorHTMLAttributes } from 'react';

export interface LinkActionProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  active?: boolean;
}

export const LinkAction: React.FC<LinkActionProps> = ({ children, active, ...props }) => {
  return (
    <a
      {...props}
      className={`bg-white dark:bg-black border border-primary-std group flex cursor-pointer space-x-2 items-center hover:bg-gray-200 dark:hover:bg-gray-700 w-full min-w-max px-4 py-2 ${
        active ? 'rounded-t-lg' : 'rounded-lg'
      }`}
    >
      {children}
    </a>
  );
};
