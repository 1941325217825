import React, { FC } from 'react';
import { ClockInStatus, useClockService } from '../../services/clockIn/ClockService';
import { TSCard } from '../../components/shared/card/Card';
import { ClockTitle } from '../../components/clock/ClockTitle';
import strings from '../../components/clock/clock.translations';
import { SubmissionErrorBoundary } from '../../components/errors/SubmissionErrorBoundary';
import { ClockInOutForm } from './ClockInOutForm';

export const ClockInOutCard: FC = () => {
  const [clockState] = useClockService(service => [service.clockState]);
  const newStatus = clockState === ClockInStatus.In ? ClockInStatus.Out : ClockInStatus.In;
  const clockingTitle = <ClockTitle>{strings.mainTitle(newStatus)}</ClockTitle>;

  return (
    <TSCard className="py-7 px-5">
      <div className="flex flex-col items-center space-y-4 w-full text-center p-5">
        {clockingTitle}
        <SubmissionErrorBoundary>
          <ClockInOutForm newStatus={newStatus} />
        </SubmissionErrorBoundary>
      </div>
    </TSCard>
  );
};
