import React, { FC } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { IconButton } from '../../../components/shared/button/IconButton';
import { format } from 'date-fns';
import { useGetAdjacentRosters } from '../rosterHelper';
import { useNavigate } from 'react-router-dom';
import { ReadRoster } from '../../../services/gql/graphql';
import strings from '../roster.translations';

interface RosterDateSwitchProps {
  rosterDate: Date;
  readOnly?: boolean;
}

export const RosterDateSwitch: FC<RosterDateSwitchProps> = ({ rosterDate, readOnly }) => {
  const navigate = useNavigate();
  const { prevRoster, nextRoster } = useGetAdjacentRosters(rosterDate);

  const onClickNavigate = (roster: ReadRoster) => {
    const rosterEditLink = `/roster/${roster.id}/${roster.rosterDate.substring(0, 10)}`;
    const essLink = `/calendar/${roster.id}/${roster.rosterDate.substring(0, 10)}/StaffRosterView`
    navigate(readOnly ? essLink : rosterEditLink);
  };

  return (
    <div className="flex flex-row gap-2 items-center justify-center">
      {prevRoster && (
        <IconButton
          label={strings.rosterDateSwitch.prevLabel}
          Icon={FiChevronLeft}
          onClick={() => {
            onClickNavigate(prevRoster!);
          }}
        />
      )}
      <p className="text-lg">{`${format(rosterDate, 'EEEE do MMMM')}`}</p>
      {nextRoster && (
        <IconButton
          label={strings.rosterDateSwitch.nextLabel}
          Icon={FiChevronRight}
          onClick={() => {
            onClickNavigate(nextRoster!);
          }}
        />
      )}
    </div>
  );
};
