import { FC } from 'react';
import { Checkbox } from '../../../../components/shared/checkboxes/Checkbox';
import { CategorisedTraining } from '../../../../services/gql/graphql';

interface SkillsTrainingItemProps {
  trainingItem: CategorisedTraining;
  checked: boolean;
  onCheck: () => void;
  color: string;
}

export const SkillsTrainingItem: FC<SkillsTrainingItemProps> = ({ trainingItem, checked, onCheck, color }) => {
  return (
    <div className="flex gap-5">
      <Checkbox onChange={onCheck} checked={checked} color={color} />
      <div>{trainingItem.name}</div>
    </div>
  );
};
