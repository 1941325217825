import React, { FC } from 'react';

interface RosterPickerIconProps {
  name: string;
}

export const RosterPickerIcon: FC<RosterPickerIconProps> = ({ name }) => {
  const firstInitial = name.split(' ')[0].charAt(0).toUpperCase();
  const secondInitial = name.split(' ')[1].charAt(0).toUpperCase();

  return (
    <div className="rounded-full flex items-center justify-center bg-gray-400 w-4 h-4 p-4 text-sm">
      <p className="text-white">{firstInitial + secondInitial}</p>
    </div>
  );
};
