import React, { FC, HTMLAttributes } from 'react';
import { merge } from '../../helpers/Utility';

interface SkeletonLoaderProps extends HTMLAttributes<HTMLDivElement> {}

export const SkeletonLoader: FC<SkeletonLoaderProps> = ({ children, className, ...props }) => {
  return (
    <div {...props} className={merge('bg-gray-200 rounded-full animate-pulse', className)}>
      {children}
    </div>
  );
};
