import { useErrorHandler } from 'react-error-boundary';
import { useSendWelcomeInfoMutation } from '../gql/graphql';

export const useSendLoginInfoNotifcationToEmployee = () => {
  const handleError = useErrorHandler();

  const [sendRequest, sendNotificationToEmployee] = useSendWelcomeInfoMutation();

  const send = async (id: string) => {
    let result;

    if (!sendRequest.fetching) {
      result = await sendNotificationToEmployee({
        id: id
      });

      if (result.error) {
        handleError(result.error);
      }
    }

    return result;
  };

  return send;
}