import React, { FC } from 'react';
import { RosterTimerBar } from '../common/RosterTimerBar';
import { ReadRoster } from '../../../services/gql/graphql';

interface RosterRequirementProps {
  roster: ReadRoster;
}

export const RosterRequirement: FC<RosterRequirementProps> = ({ roster }) => {
  return (
    <div>
      <RosterTimerBar />
      {JSON.stringify(roster)}
    </div>
  );
};
