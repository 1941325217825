import React, { FC } from 'react';
import { RiUserLocationLine } from 'react-icons/ri';
import strings from './present.translations';
import { LiveLabel } from '../../../components/shared/text/LiveLabel';
import { EmployeeWorkTimeRecord, useGetShiftsListQuery } from '../../../services/gql/graphql';
import { EmployeePresenceCard, EmployeeStatus } from './EmployeePresenceCard';
import { ExpectedEmployee } from './EmployeeAvatar';
import { dateToGQLFormat } from '../../../helpers/dateHelper';
import { isEmployeeDeactivatedOrDeleted } from '../../../helpers/Utility';
import { TSTitleCard } from '../../../components/shared/card/Card';

const hoursToShow = 2;

export const EmployeePresenceSection: FC = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const [{ data }] = useGetShiftsListQuery({
    variables: {
      queryWorkItemArgs: {
        from: dateToGQLFormat(yesterday),
        to: dateToGQLFormat(tomorrow)
      }
    }
  });

  const relevantRecords: EmployeeWorkTimeRecord[] = [];
  data!.getWorkTimeRecord!.forEach(record => {
    let includeRecord = true;
    if (record.end) {
      const limit = new Date();
      limit.setHours(limit.getHours() - hoursToShow);
      const clockOutTime = new Date(record.end.dateTime);
      if (clockOutTime < limit) {
        includeRecord = false;
      }
    }

    const existingRecordIndex = relevantRecords.findIndex(existingRecord => {
      return existingRecord.employee?.identityId === record.employee?.identityId;
    });

    if (existingRecordIndex >= 0) {
      const existingRecord = relevantRecords[existingRecordIndex];

      if (existingRecord.end) {
        if (record.end && existingRecord.end.dateTime > record.end.dateTime) {
          includeRecord = false;
        } else {
          relevantRecords.splice(existingRecordIndex, 1);
        }
      }
    }

    if (includeRecord) {
      relevantRecords.push(record as EmployeeWorkTimeRecord);
    }
  });

  const employees: ExpectedEmployee[] = relevantRecords
    .filter(record => !isEmployeeDeactivatedOrDeleted(record.employee!))
    .map(record => {
      let clockOutTime: Date | undefined;
      if (record.end) {
        clockOutTime = new Date(record.end.dateTime);
      }

      return {
        id: record.employee!.identityId,
        firstName: record.employee!.firstName || '',
        lastName: record.employee!.lastName || '',
        status: clockOutTime ? EmployeeStatus.NOT_ON_SHIFT : EmployeeStatus.ON_SHIFT,
        clockOutTime: clockOutTime,
        locationId: record.locationId
      };
    });
  return (
    <TSTitleCard title={strings.WhosOn} icon={RiUserLocationLine} smallContent={<LiveLabel />}>
      {employees.length > 0 ? (
        <EmployeePresenceCard employees={employees} />
      ) : (
        <div className="py-2">{strings.nobody}</div>
      )}
    </TSTitleCard>
  );
};
