import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ClockHandler, ClockType } from './ClockHandler';
import { Loading } from '../shared/Loading';
import { ClockProvider } from '../../services/clockIn/ClockService';
import strings from './clock.translations';
import { LoggerLevel, useLogger } from '../../services/logging/Logger';

export const NFCClock = () => {
  const [correctRoute, setCorrectRoute] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const [done, setDone] = useState<boolean>(false);
  const location = useLocation();
  const state = location.state as { id: string };
  const { log } = useLogger();

  useEffect(() => {
    const etag = window.localStorage.getItem('nfc-etag');
    if (etag && state?.id && etag === state.id) {
      setCorrectRoute(true);
    } else {
      log('Attempt to use NFC clock with expired id', LoggerLevel.Warn, true);
      setCorrectRoute(false);
    }
    setLoading(false);
    window.localStorage.removeItem('nfc-etag');
  }, [state, log]);

  return (
    <div className="flex h-screen w-screen justify-center items-center">
      {loading ? (
        <Loading />
      ) : (
        <ClockProvider>
          {correctRoute ? (
            !done ? (
              <ClockHandler type={ClockType.NFC} closeHandler={() => setDone(true)} />
            ) : (
              <p>{strings.closeTab}</p>
            )
          ) : (
            <div className="p-8">{strings.nfcInvalid}</div>
          )}
        </ClockProvider>
      )}
    </div>
  );
};
