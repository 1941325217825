import { FC, HTMLAttributes } from 'react';
import { merge } from '../../../../helpers/Utility';


interface CalendarHeaderProps extends HTMLAttributes<HTMLDivElement> {
  date: any;
  today: any;
  backgroundColor?: string;
  color?: string;
}
/*
 This CalendarHeader is used for Roster Calendar and Staff Calendar
 */
export const CalendarHeader: FC<CalendarHeaderProps> = ({ date, today, backgroundColor, color, className }) => {

  return (
    <div
      style={{ backgroundColor, color }}
      className={merge(
        `w-full pl-1 font-bold ${today ? 'border border-gray-700 rounded-lg' : 'border-b-2'}`,
        className
      )}
    >
      <div className={`rounded w-min px-1`}>{date.getDate()}</div>
    </div>
  );
};