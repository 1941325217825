import React, { FC } from 'react';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { Calendar, CalendarCellProps, CalendarEvent } from '../roster/calendar/common/Calendar';

interface CalendarScribbleProps {}

interface FakeCalendarInfo {
  headerColor?: string;
  info?: string;
}

const Header: FC<CalendarCellProps<FakeCalendarInfo>> = ({ date, contextObject, today }) => {
  return (
    <div style={{ backgroundColor: contextObject?.headerColor }} className="w-full py-2 pl-1 border-b">
      <div className={`border-2 rounded w-min px-1 border-black ${!today && 'border-opacity-0'}`}>{date.getDate()}</div>
    </div>
  );
};

const Content: FC<CalendarCellProps<FakeCalendarInfo>> = ({ contextObject }) => {
  return <div>{contextObject?.info}</div>;
};

export const CalendarScribble: FC<CalendarScribbleProps> = () => {
  const monthToShow = new Date();

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const events: CalendarEvent<FakeCalendarInfo>[] = [
    {
      date: today,
      contextObject: {
        info: 'I am today'
      }
    },
    {
      date: tomorrow,
      contextObject: {
        headerColor: '#FF0000',
        info: 'Header should be red'
      }
    }
  ];

  return (
    <PageContainer lockHeader>
      <PageSizer className="h-full relative flex-col flex gap-5" mode={SizerMode.CONTENT}>
        <Calendar monthToShow={monthToShow} HeaderComponent={Header} ContentComponent={Content} events={events} />
      </PageSizer>
    </PageContainer>
  );
};
