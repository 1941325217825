import { FC, HTMLAttributes } from 'react';
import { InfoChit } from '../../../../components/shared/InfoChit';
import { ReadEmployee } from '../../../../services/gql/graphql';
import strings from '../../team.translations';

interface TeamMemberLocationsProps extends HTMLAttributes<HTMLDivElement> {
  employee: ReadEmployee;
}

export const TeamMemberLocations: FC<TeamMemberLocationsProps> = ({ employee, className }) => {
  return (
    <div className={className}>
      <div className="font-semibold">{strings.locations}</div>
      <div className="flex flex-wrap gap-2">
        {employee.restaurants?.map(loc => <InfoChit>{loc.name}</InfoChit>)}
      </div>
    </div>
  );
};
