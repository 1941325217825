import React, { FC, HTMLAttributes, useState } from 'react';
import { BsQuestion } from 'react-icons/bs';
import { FiX } from 'react-icons/fi';
import { Title } from '../text/Title';
import { Modal } from './Modal';
import { IconButton } from '../button/IconButton';

interface ExplainerModalProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  content: string;
  label: string;
}

export const ExplainerModal: FC<ExplainerModalProps> = ({ className, title, content, label }) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setTooltipOpen(true);
  };
  const handleClose = () => {
    setTooltipOpen(false);
  };

  return (
    <>
      <IconButton className={className} onClick={handleOpen} Icon={BsQuestion} label={label} />
      <Modal open={tooltipOpen} onClose={handleClose}>
        <div className="max-w-xl p-6">
          <div className="flex flex-row w-full justify-between mb-3">
            <Title>{title}</Title>
            <button onClick={handleClose}>
              <FiX className="h-6 w-6" />
            </button>
          </div>
          <div>
            <p>{content}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};
