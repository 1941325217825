import React, { FC } from 'react';
import { MdPerson } from 'react-icons/md';
import strings from '../../team.translations';
import { ReadEmployee } from '../../../../services/gql/graphql';
import { TSTitleCard } from '../../../../components/shared/card/Card';
import { useNavigate } from 'react-router-dom';
import { TeamMemberLocations } from './TeamMemberLocations';
import { Feature } from '../../../../services/backstage/Feature';

interface TeamMemberCardProps {
  enableEdit?: boolean;
  employee: ReadEmployee;
}

export const TeamMemberCard: FC<TeamMemberCardProps> = ({ employee, enableEdit }) => {
  const navigate = useNavigate();
  const onEditClick = () => {
    navigate('edit-details');
  };

  return (
    <TSTitleCard
      title={strings.cardTitle}
      icon={MdPerson}
      editDetails={
        enableEdit
          ? {
              label: strings.editTeamMember.editLabel,
              onClick: onEditClick
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-4 pt-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <div className="font-semibold">{strings.common.firstName}</div>
            <div>{employee.firstName}</div>
          </div>
          <div>
            <div className="font-semibold">{strings.common.lastName}</div>
            <div>{employee.lastName}</div>
          </div>
        </div>
        <div className="grid break-all grid-cols-1 gap-4">
          <div>
            <div className="font-semibold">{strings.common.email}</div>
            <div className="text-blue-400 hover:text-purple-600">
              <a href={'mailto:' + employee.email}>{employee.email}</a>
            </div>
          </div>
          <div>
            <div className="font-semibold">{strings.common.phoneNumber}</div>
            <div className="text-blue-400 hover:text-purple-600">
              <a href={'tel:' + employee.phoneNumber}>{employee.phoneNumber}</a>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <div className="font-semibold">{strings.role}</div>
            <div>{employee.role}</div>
          </div>
        </div>
        <Feature name="devices">
          <TeamMemberLocations employee={employee} />
        </Feature>
      </div>
    </TSTitleCard>
  );
};
