import { FC } from 'react';
import { CheckboxProps } from './Checkbox';

export const BigCheckbox: FC<CheckboxProps> = ({ register, label }) => {
  return (
    <div className="sm:space-x-2 flex flex-col items-center text-center mb-2">
      <label className="text-sm mb-1">{label}</label>
      <input
        {...register}
        type="checkbox"
        className="h-10 w-10 border-primary-std bg-primary-std focus:ring-primary-500 focus:border-primary-500 rounded-md focus:outline-none"
      />
    </div>
  );
};
