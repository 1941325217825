import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

import { Exchange } from 'urql';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { Router } from 'react-router';
import { getVariable } from '../services/backstage/BackstageProvider';
import { evaluateSuspenseRecord, handleSuspenseAction, SuspenseRecord } from './SuspenseHelper';
import { BrowserRouterProps } from 'react-router-dom';
import { BrowserHistory, createBrowserHistory } from 'history';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let appInsights: Promise<ApplicationInsights>;
let suspenseRecord: SuspenseRecord;

const globalHistory = createBrowserHistory({ window });
export const reactPlugin = new ReactPlugin();

export const startAppInsights = async () => {
  const appInsights = new ApplicationInsights({
    config: {
      distributedTracingMode: DistributedTracingModes.AI,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      disableFetchTracking: false,
      connectionString: await getVariable('appInsightsConnectionString'),
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: globalHistory }
      }
    }
  });
  appInsights.loadAppInsights();
  return appInsights;
};

export const getAppInsights = () => {
  if (appInsights) return appInsights;
  appInsights = startAppInsights();
  return appInsights;
};

export const useAppInsights = () => {
  if (suspenseRecord) return evaluateSuspenseRecord(suspenseRecord);
  const record = handleSuspenseAction(getAppInsights());
  suspenseRecord = record;
  return evaluateSuspenseRecord(record);
};

export const AppInsightsRouter: FC<BrowserRouterProps> = ({ children }) => {
  let historyRef = useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = globalHistory;
  }

  let history = historyRef.current;
  let [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router children={children} action={state.action} location={state.location} navigator={history} />;
};

export const dependencyReportingExchange: Exchange = ({ client, forward }) => {
  return operations => {
    // <-- The ExchangeIO function
    const operationResult = forward(operations);
    return operationResult;
  };
};
