import { FC, useCallback, useEffect, useMemo } from 'react';
import { EmployeeWorkTimeRecord, WorkTimeDataRisk, WorkTimeDataSource } from '../../services/gql/graphql';
import { format as formatDate } from 'date-fns';
import strings from './shifts.translations';
import TableContents from '../../components/shared/table/TableContents';
import { calculateMinutesDuration, displayMinutesAsHoursMinutes } from '../../helpers/dateHelper';
import { InfoChitImportance } from '../../components/shared/InfoChit';
import { TableChit } from '../../components/shared/table/TableChit';
import { ShiftDetailsView } from './detailsView/ShiftDetailsView';
import { TableExpander } from '../../components/shared/table/TableExpander';
import { ClockMethodToString } from '../../components/shared/table/Utils';
import { Column } from 'react-table';
import { displayPay, getPayFromDuration } from '../../helpers/pay';
import { useFlag } from '../../services/backstage/BackstageProvider';
import { Icon } from '../../components/shared/icons/Icon';
import { FiAlertTriangle } from 'react-icons/fi';
import { useLocationService } from '../../services/location/LocationService';

export interface ShiftsTableProps {
  startDate: Date;
  endDate: Date;
  userId?: string;
  recordId?: string;
  needsRefetch?: boolean;
  refetchComplete?: () => void;
  onShiftEdit?: () => void;
  data?: EmployeeWorkTimeRecord[];
  refetch?: () => void;
  isMe?: boolean;
}

export interface ShiftsTableRow extends Record<string, unknown> {
  name: string;
  dateInfo: {
    date: Date;
    start: Date;
    end?: Date;
    duration?: number;
    pay?: number;
  };
  record: EmployeeWorkTimeRecord;
  risk?: WorkTimeDataRisk;
  clockInMethod?: WorkTimeDataSource;
  clockOutMethod?: WorkTimeDataSource;
  locationId: number;
  onShiftEdit?: () => void;
  isMe?: boolean;
}

export const ShiftsTable: FC<ShiftsTableProps> = ({
  startDate,
  endDate,
  userId,
  recordId,
  needsRefetch,
  refetchComplete,
  onShiftEdit,
  data,
  refetch,
  isMe
}) => {
  const pay = useFlag('pay');

  useEffect(() => {
    if (recordId) {
      document.querySelector(`#${'row-' + recordId}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [recordId]);

  const startDateForQuery = new Date(startDate);
  startDateForQuery.setHours(0, 0, 0, 0);

  const endDateForQuery = new Date(endDate);
  endDateForQuery.setHours(0, 0, 0, 0);
  //We want to include the end date, so set it to midnight on the day after.
  endDateForQuery.setDate(endDateForQuery.getDate() + 1);

  const [locationList] = useLocationService(state => [state.locationList]);

  useEffect(() => {
    if (needsRefetch && refetchComplete) {
      refetch && refetch();
      refetchComplete();
    }
  }, [needsRefetch, refetchComplete, refetch]);

  const riskToImportance = (risk: WorkTimeDataRisk) => {
    switch (risk) {
      case WorkTimeDataRisk.High:
        return InfoChitImportance.HIGH;
      case WorkTimeDataRisk.Medium:
        return InfoChitImportance.MEDIUM;
      case WorkTimeDataRisk.Low:
        return InfoChitImportance.LOW;
    }
  };

  const formatMobileDateTime = (date: Date, start: Date, end?: Date) => {
    let mobileDate = formatDate(date, 'do MMM');
    mobileDate += ` ${formatDate(start, 'HH:mm')}`;
    if (end) {
      mobileDate += ` - `;
      mobileDate += formatDate(end, 'HH:mm');
    }
    return mobileDate;
  };

  const rows: ShiftsTableRow[] | undefined = useMemo(() => {
    const sortedRecords = data?.sort((a, b) => {
      if (a.start?.dateTime > b.start?.dateTime) {
        return -1;
      } else {
        return 1;
      }
    });

    return sortedRecords?.map(record => {
      const duration = calculateMinutesDuration(record.start?.dateTime, record.end?.dateTime || new Date());

      return {
        name: strings.employeeName(record?.employee?.firstName ?? '', record?.employee?.lastName ?? ''),
        dateInfo: {
          date: new Date(record.start?.dateTime),
          start: new Date(record.start?.dateTime),
          end: record.end ? new Date(record.end?.dateTime) : undefined,
          duration: duration,
          pay: getPayFromDuration(duration, record?.employee?.hourlyRate || 0)
        },
        record: record as EmployeeWorkTimeRecord,
        risk: record.risk,
        clockInMethod: record.start?.origin,
        clockOutMethod: record.end?.origin,
        onShiftEdit: onShiftEdit,
        isMe: isMe,
        locationId: record.locationId
      };
    });
  }, [data, onShiftEdit, isMe]);

  const sortRisk = useCallback((rowA: any, rowB: any) => {
    const importanceA = riskToImportance(rowA.original.risk);
    const importanceB = riskToImportance(rowB.original.risk);
    if (importanceA > importanceB) return 1;
    if (importanceB > importanceA) return -1;
    return 0;
  }, []);

  const buildImportanceChit = useCallback(({ value }: { value?: WorkTimeDataRisk }) => {
    switch (value) {
      case WorkTimeDataRisk.Low:
        return <div>{'-'}</div>;
      case WorkTimeDataRisk.Medium:
        return <Icon Icon={FiAlertTriangle} theme="amber" />;
      case WorkTimeDataRisk.High:
        return <Icon Icon={FiAlertTriangle} theme="red" />;
    }
  }, []);

  const buildClockTime = useCallback(({ value }: { value?: string }) => {
    return value ? formatDate(new Date(value), 'HH:mm') : <TableChit>{value}</TableChit>;
  }, []);

  const buildClockMethod = useCallback(({ value }: { value?: WorkTimeDataSource }) => {
    return <TableChit>{ClockMethodToString(value)}</TableChit>;
  }, []);

  const buildLocation = useCallback(({ value }: { value: number }) => {
    const location = locationList.find(loc => loc.id === value);
    return <TableChit>{location?.name}</TableChit>;
  }, [locationList]);

  const desktopColumns = useMemo(() => {
    const optionalColumns: (Column<ShiftsTableRow> | null)[] = [
      !userId
        ? {
            Header: strings.columns.name,
            accessor: 'name'
          }
        : null,
      {
        Header: strings.columns.date,
        accessor: 'dateInfo.date',
        Cell: ({ value }: { value: Date }) => {
          return formatDate(new Date(value), 'EEEE do MMMM');
        }
      },
      {
        Header: strings.columns.started,
        accessor: 'dateInfo.start',
        Cell: buildClockTime,
        sortType: (rowA: any, rowB: any) => {
          const startA = formatDate(rowA.original['dateInfo']['start'], 'HH:mm');
          const startB = formatDate(rowB.original['dateInfo']['start'], 'HH:mm');
          if (startA > startB) return 1;
          if (startB > startA) return -1;
          return 0;
        }
      },
      {
        Header: strings.columns.ended,
        accessor: 'dateInfo.end',
        Cell: buildClockTime,
        sortType: (rowA: any, rowB: any) => {
          const endA = formatDate(rowA.original['dateInfo']['end'], 'HH:mm');
          const endB = formatDate(rowB.original['dateInfo']['end'], 'HH:mm');
          if (endA > endB) return 1;
          if (endB > endA) return -1;
          return 0;
        }
      },
      {
        Header: strings.columns.duration,
        accessor: 'dateInfo.duration',
        Cell: ({ value }: { value: number }) => {
          return <div className="whitespace-nowrap">{displayMinutesAsHoursMinutes(value)}</div>;
        }
      },
      pay
        ? {
            Header: strings.columns.estimatedCost,
            accessor: 'dateInfo.pay',
            Cell: ({ value }: { value: number }) => {
              return <div className="whitespace-nowrap">{displayPay(value)}</div>;
            }
          }
        : null,
      locationList.length > 1
        ? {
            Header: 'Location',
            accessor: 'locationId',
            Cell: buildLocation
          }
        : null,
      {
        Header: strings.columns.clockIn,
        accessor: 'clockInMethod',
        Cell: buildClockMethod
      },
      {
        Header: strings.columns.clockOut,
        accessor: 'clockOutMethod',
        Cell: buildClockMethod
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: TableExpander
      }
    ];

    const columns = optionalColumns.filter(c => c !== null) as Column<ShiftsTableRow>[];

    return columns;
  }, [buildClockTime, buildLocation, buildClockMethod, userId, pay, locationList]);

  const mobileColumns = useMemo(() => {
    const columns: Column<ShiftsTableRow>[] = [
      {
        Header: strings.columns.when,
        accessor: 'dateInfo',
        Cell: ({ value }: { value: { date: Date; start: Date; end: Date } }) => {
          return formatMobileDateTime(value.date, value.start, value.end);
        },
        sortType: (rowA: any, rowB: any) => {
          const startA = new Date(rowA.original['dateInfo']['start']);
          const startB = new Date(rowB.original['dateInfo']['start']);
          if (startA > startB) return 1;
          if (startB > startA) return -1;
          return 0;
        }
      },
      {
        Header: strings.columns.risk,
        accessor: 'risk',
        Cell: buildImportanceChit,
        sortType: sortRisk
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: TableExpander
      }
    ];

    if (!userId) {
      columns.unshift({
        Header: strings.columns.name,
        accessor: 'name'
      });
    }

    return columns;
  }, [buildImportanceChit, sortRisk, userId]);

  return (
    <>
      <div className="hidden lg:block">
        <TableContents
          columns={desktopColumns}
          data={rows}
          DetailsView={ShiftDetailsView}
          pageSize={50}
          rowIdCallback={row => {
            return `${'row-' + row.original.record.id}`;
          }}
          autoExpandIdx={rows?.findIndex(e => e.record.id === recordId)}
        />
      </div>
      <div className="lg:hidden">
        <TableContents
          columns={mobileColumns}
          data={rows}
          DetailsView={ShiftDetailsView}
          pageSize={50}
          rowIdCallback={row => {
            return `${'row-' + row.original.record.id}`;
          }}
          autoExpandIdx={rows?.findIndex(e => e.record.id === recordId)}
        />
      </div>
    </>
  );
};
