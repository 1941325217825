import React, { FC } from 'react';
import strings from '../shifts.translations';
import { useNearestLocation } from '../../../services/location/useNearestLocation';
import { WorkLocationMap } from '../../../components/shared/maps/WorkLocationMap';
import { WorkTimeOrigin } from '../../../services/gql/graphql';
import { LocationSummary } from './LocationSummary';

interface MapSummaryProps {
  start?: WorkTimeOrigin;
  end?: WorkTimeOrigin;
}

export const MapSummary: FC<MapSummaryProps> = ({ start, end }) => {
  const clockInPosition = start?.geoLocation;
  const clockOutPosition = end?.geoLocation;
  const clockInDevice = start?.device;
  const clockOutDevice = end?.device;

  const noLocations = !clockInPosition && !clockOutPosition && !clockInDevice && !clockOutDevice;

  const { closestLocation: closestLocationClockIn } = useNearestLocation(clockInPosition);

  const { closestLocation: closestLocationClockOut } = useNearestLocation(clockOutPosition);

  return (
    <div className="flex flex-col md:flex-row gap-5 h-full">
      {noLocations ? (
        <div className="text-center self-center w-full">{strings.detailView.noLocationData}</div>
      ) : (
        <>
          <div className="flex flex-col md:flex-1">
            {clockInPosition ? (
              <>
                <div>{strings.detailView.startLocation}</div>
                {clockInPosition && closestLocationClockIn && (
                  <WorkLocationMap personPosition={clockInPosition} workLocation={closestLocationClockIn} interactive />
                )}
                <LocationSummary position={clockInPosition} />
              </>
            ) : (
              clockInDevice && (
                <>
                  <div>{strings.detailView.startDevice}</div>
                  <div>{clockInDevice.name}</div>
                </>
              )
            )}
          </div>
          <div className="flex flex-col md:flex-1">
            {clockOutPosition ? (
              <>
                <div>{strings.detailView.endLocation}</div>
                {clockOutPosition && closestLocationClockOut && (
                  <WorkLocationMap
                    personPosition={clockOutPosition}
                    workLocation={closestLocationClockOut}
                    interactive
                  />
                )}
                <LocationSummary position={clockOutPosition} />
              </>
            ) : (
              clockOutDevice && (
                <>
                  <div>{strings.detailView.endDevice}</div>
                  <div>{clockOutDevice.name}</div>
                </>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};
