import { FC, useState } from 'react';
import { LinkAction } from './LinkAction';
import { motion } from 'framer-motion';

export interface SideMenuOption {
  icon?: JSX.Element;
  label: string;
  element?: JSX.Element;
  anchorId?: string;
}

interface VerticalMenuProps {
  sideMenuOptions: SideMenuOption[];
  allElements?: JSX.Element;
  isScroll?: boolean;
}

export const VerticalMenu: FC<VerticalMenuProps> = ({ sideMenuOptions, allElements, isScroll }) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const menuItems = sideMenuOptions.map(({ icon, label, anchorId }, idx: number) => {
    const isActive = tabIndex === idx;

    const tabMenuItems = (
      <div className="flex flex-col min-w-[165px]">
        <LinkAction
          key={label}
          active={isActive}
          onClick={() => {
            setTabIndex(idx);
          }}
        >
          <span>{icon}</span>
          <span>{label}</span>
        </LinkAction>
        {isActive && (
          <motion.div
            className="bg-primary-500 w-full h-[5px] rounded-b-lg"
            layoutId={`vertical-tab-active`}
            transition={{ type: 'spring', stiffness: 500, damping: 25 }}
          />
        )}
      </div>
    );

    const scrollMenuItems = (
      <div className="flex flex-col min-w-[165px]">
        <LinkAction href={`#${anchorId}`} key={anchorId}>
          <span>{icon}</span>
          <span>{label}</span>
        </LinkAction>
      </div>

    );

    return isScroll === true ? scrollMenuItems : tabMenuItems;
  });

  return (
    <>
      <div className="w-full sm:w-max flex flex-row lg:flex-col gap-2 mb-4 overflow-auto sm:overflow-visible">{menuItems}</div>
      <div className="space-y-3 flex-1">{isScroll === true ? allElements : sideMenuOptions[tabIndex].element}</div>
    </>
  );
};
