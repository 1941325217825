import React, { FC } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { TsForm } from '../../../components/shared/form/Form';
import { SubmitCancelButtons } from '../../../components/shared/form/SubmitCancelButtons';
import { TextFieldWithOptionalLabel } from '../../../components/shared/form/TextInput';
import { useCreateLocationMutation, useGetLocationsQuery, useGetMeQuery } from '../../../services/gql/graphql';
import strings from '../manageOrg.translations'

interface AddLocationFormProps {
  onComplete: (newId?: number) => void;
}
interface AddLocationFields {
  name: string;
}

export const AddLocationForm: FC<AddLocationFormProps> = ({ onComplete }) => {
  const handleError = useErrorHandler();
  const methods = useForm<AddLocationFields>();

  const [{ data, error }] = useGetMeQuery();
  if (error) handleError(error);

  const [{ fetching }, CreateLocation] = useCreateLocationMutation();

  const [{ data: locationsData, error: locationsError }] = useGetLocationsQuery();
  if (locationsError) handleError(locationsError);


  const onChange = (values: string) => {
    const getLocationName = locationsData?.locations?.find(location => location.name === values)?.name;

    if(getLocationName) {
      methods.setError('name', {
        type: 'custom',
        message: strings.addLocationModal.uniqueName
      });
      return ;
    }
  };

  const onSubmit = async (values: AddLocationFields) => {
    try {
      const result = await CreateLocation({
        location: {
          name: values.name,
          areaId: data!.myEmployee!.restaurants![0].area!.id
        }
      });
      if (result.error) {
        handleError(result.error);
      } else {
        const id = result.data?.createLocation?.id;
        onComplete(id);
      };
    } catch (e) {
      handleError(e as Error);
    }
  };

  return (
    <TsForm methods={methods} onSubmit={onSubmit}>
      <div className="flex flex-col space-y-5 xl:w-96 mt-3">
        <TextFieldWithOptionalLabel
          name="name"
          title={strings.addLocationModal.nameField}
          validationMessage={strings.addLocationModal.nameRequired}
          customValidatorMessage={methods.formState.errors.name?.message}
          required
          placeholder={strings.addLocationModal.namePlaceholder}
          onChange={values => onChange(values)} />
        <div className="flex items-center justify-end gap-3">
          <SubmitCancelButtons disabled={methods.formState.errors.name?.message ? true : false} onComplete={onComplete} loading={fetching} submitText={strings.common.create}></SubmitCancelButtons>
        </div>
      </div>
    </TsForm>
  );
};
