import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { AddTeamMemberRow } from './AddTeamMemberRow';
import strings from '../../team.translations';
import { Role } from '../../../../services/authentication/authentication-service';
import { useLocationService } from '../../../../services/location/LocationService';
import { CreateEmployee, useCreateMultipleUsersAndEmployeesMutation } from '../../../../services/gql/graphql';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { createErrorToast } from '../../../../services/notification/ToastService';
import { formatPhoneNumber } from '../../../../helpers/Utility';
import { Button } from '../../../../components/shared/button/Button';
import { TSCard } from '../../../../components/shared/card/Card';
import { TsForm } from '../../../../components/shared/form/Form';

export interface RoleOption {
  id: Role;
  name: string;
}

interface Fields {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  role: RoleOption;
  sendRegistration: boolean;
  ignoreHourContribution: boolean;
  userName: string;
  locationIds?: number[];
}

export const AddMultipleTeamMembersForm: FC = () => {
  const [locationId] = useLocationService(state => [state.selectedLocation?.id]);
  const buildNewField = () => {
    return {
      firstName: '',
      lastName: '',
      role: {
        id: Role.Staff,
        name: 'Staff'
      },
      sendRegistration: true,
      ignoreHourContribution: false,
      userName: ''
    };
  };
  const methods = useForm<{ members: Fields[] }>({ defaultValues: { members: [buildNewField()] } });

  const memberList = methods.watch('members');

  const handleAdd = (i: number) => () => {
    memberList.splice(i + 1, 0, buildNewField());
    methods.setValue('members', memberList);
  };

  const handleDelete = (i: number) => () => {
    if (memberList.length > 1) {
      memberList.splice(i, 1);
      methods.setValue('members', memberList);
    }
  };

  const navigate = useNavigate();

  const onCancel = () => {
    navigate('/team');
  };

  const [{ fetching }, createMultipleUsersAndEmployees] = useCreateMultipleUsersAndEmployeesMutation();

  const handleSubmit = async (data: { members: Fields[] }) => {
    const payload: CreateEmployee[] = data.members.map(member => {
      const newPhoneNumber = formatPhoneNumber(member.phoneNumber);
      return {
        ...member,
        role: member.role?.id ?? '',
        userName: uuidv4(),
        ...(locationId && { locationIds: [locationId] }),
        ...(member.email && { email: member.email }),
        ...(member.phoneNumber && { phoneNumber: newPhoneNumber })
      };
    });
    const result = await createMultipleUsersAndEmployees({
      employees: payload
    });

    if (result.error) {
      createErrorToast(result.error.graphQLErrors[0].message);
      return;
    } else {
      navigate('/team');
    }
  };

  return (
    <TsForm methods={methods} onSubmit={handleSubmit} className="flex flex-col gap-5">
      <TSCard>
        <div className="w-full overflow-x-hidden">
          <div className="overflow-auto p-5">
            <div
              style={{ gridTemplateColumns: '3fr 3fr 5fr 1fr 1fr 80px' }}
              className="grid grid-cols-6 gap-x-5 gap-y-2  min-w-[800px]"
            >
              <label className="text-sm">{strings.addTeamMemberForm.firstName}</label>
              <label className="text-sm">{strings.addTeamMemberForm.lastName}</label>
              <label className="text-sm">{strings.addTeamMemberForm.contactInformation}</label>
              <label className="text-sm">{strings.addTeamMemberForm.role}</label>
              <label className="text-sm text-center">{strings.teamMembersModal.invite}</label>
              <div></div>
              {memberList.map((_, idx) => {
                return (
                  <AddTeamMemberRow
                    methods={methods}
                    idx={idx}
                    handleDelete={handleDelete(idx)}
                    handleAdd={handleAdd(idx)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TSCard>
      <div className="justify-end gap-5 flex">
        <Button type="button" onClick={onCancel}>
          {strings.common.cancel}
        </Button>
        <Button primary type="submit" loading={fetching}>
          {strings.teamMembersModal.submitButton}
        </Button>
      </div>
    </TsForm>
  );
};
