import { FC, useState } from 'react';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSizer, SizerMode } from '../../components/page/PageSizer';
import { LocationSearchBox } from '../../components/shared/maps/LocationSearchBox';
import { MapPoint } from '../../components/shared/maps/Map';
import { SinglePointMap } from '../../components/shared/maps/SingleLocationMap';

export const MapScribble: FC = () => {
  const [mapPoint, setMapPoint] = useState<MapPoint>();

  const onSelect = (address: string, longitude: number, latitude: number) => {
    setMapPoint({longitude, latitude});
  }

  return (
    <PageContainer>
      <PageSizer mode={SizerMode.PAGE}>
        <LocationSearchBox onSelect={onSelect} initialSearchValue={"3 Mickle Way"} />
        {mapPoint && (
          <div className="w-72">
            <SinglePointMap point={mapPoint} />
          </div>
        )}
      </PageSizer>
    </PageContainer>
  );
};
