import { FC, useEffect, useState } from 'react';
import { TSTitleCard } from '../../../../components/shared/card/Card';
import { Loader } from '../../../../components/shared/Loader';
import {
  CategorisedTraining,
  ReadEmployee,
  TrainingCategory,
  useAddEmployeeTrainingMutation,
  useRemoveEmployeeTrainingMutation
} from '../../../../services/gql/graphql';
import { useTeamMemberService } from '../TeamMemberService';
import strings from '../../team.translations';
import { FiStar } from 'react-icons/fi';
import { SkillsStation } from './SkillsStation';
import { generateColors } from '../../../roster/ganttView/rosterHelper';

interface SkillsSectionProps {
  userData: ReadEmployee;
}

export const skillsSectionId = 'skills-section-id';

export const SkillsSection: FC<SkillsSectionProps> = ({ userData }) => {
  const [getSkillsData] = useTeamMemberService(state => [state.getSkillsData]);

  const [skillsLoading, setSkillsLoading] = useState<boolean>(false);
  const [trainingCategories, setTrainingCategories] = useState<TrainingCategory[]>();
  const [employeeTraining, setEmployeeTraining] = useState<CategorisedTraining[]>();

  const [addTrainingRes, addTraining] = useAddEmployeeTrainingMutation();
  const [removeTrainingRes, removeTraining] = useRemoveEmployeeTrainingMutation();

  useEffect(() => {
    const process = async () => {
      setSkillsLoading(true);
      const skillsData = await getSkillsData(userData.identityId!);
      setSkillsLoading(false);
      setTrainingCategories(skillsData.trainingCategories);
      setEmployeeTraining(skillsData.employeeTraining);
    };
    
    process();
  }, [setTrainingCategories, setEmployeeTraining, getSkillsData, userData]);

  const onCheck = (itemId: number) => {
    if (addTrainingRes.fetching || removeTrainingRes.fetching) {
      return;
    }

    const itemInCheckedListIndex = employeeTraining!.findIndex(item => item.id === itemId);
    const newList = [...employeeTraining!];
    if (itemInCheckedListIndex > -1) {
      newList.splice(itemInCheckedListIndex, 1);
      removeTraining({ args: { employeeId: userData.identityId, trainingItemId: itemId } });
    } else {
      trainingCategories?.forEach(cat => {
        cat.trainingItems!.forEach(item => {
          if (item.id === itemId) {
            newList.push(item);
            addTraining({ args: { employeeId: userData.identityId, trainingItemId: itemId } });
          }
        });
      });
    }

    setEmployeeTraining(newList);
  };

  const colors = generateColors(trainingCategories?.length || 0);

  return (
    <section id={skillsSectionId}>
      <div className="flex flex-col w-full">
        {skillsLoading ? (
          <Loader className="h-10 mt-10" />
        ) : (
          <TSTitleCard title={strings.verticalTabMenu.skillsLabel} icon={FiStar}>
            <div className="flex flex-col gap-5">
              {trainingCategories?.map((cat, index) => (
                <SkillsStation
                  key={cat.name}
                  trainingCategory={cat}
                  onCheck={onCheck}
                  checkedTrainingItems={employeeTraining || []}
                  color={colors[index]}
                ></SkillsStation>
              ))}
            </div>
          </TSTitleCard>
        )}
      </div>
    </section>
  );
};
