import { format } from 'date-fns';
import { FC } from 'react';
import { TimeSlot, useTimeSlots } from './ganttHelper';
import { END_BUFFER_CELL_SIZE, TIME_PICKER_CELL_SIZE } from './GanttTimePicker';
import { merge } from '../../../helpers/Utility';

interface GanttTimerBarProps {
  minTime: Date;
  maxTime: Date;
  className?: string;
}

const GanttTimerCell: FC<{ slot: TimeSlot; finalCell?: boolean }> = ({ slot, finalCell }) => {
  return (
    <div className="flex-1 relative" style={{ height: 40 }}>
      <div className="absolute w-full h-full flex items-center justify-center ml-[-50%]">
        {format(slot.start, 'HH:mm')}
      </div>
      {finalCell && (
        <div className="absolute w-full h-full flex items-center justify-center ml-[50%]">
          {format(slot.end, 'HH:mm')}
        </div>
      )}
    </div>
  );
};

export const GanttTimerBar: FC<GanttTimerBarProps> = ({ minTime, maxTime, className }) => {
  const hourlySlots = useTimeSlots(minTime, maxTime, 60);

  return (
    <div className={merge("flex w-full", className)}>
      <div style={{ width: TIME_PICKER_CELL_SIZE }} className=""></div>
      <div className="flex-1 flex">
        {hourlySlots.map((slot, index) => (
          <GanttTimerCell slot={slot} finalCell={index === hourlySlots.length - 1} />
        ))}
      </div>
      <div style={{ width: END_BUFFER_CELL_SIZE }}></div>
    </div>
  );
};
