import { FC, HTMLAttributes } from 'react';
import { merge } from '../../helpers/Utility';

export enum InfoChitImportance {
  NONE,
  LOW,
  MEDIUM,
  HIGH
}

export interface InfoChitProps extends HTMLAttributes<HTMLDivElement> {
  importance?: InfoChitImportance;
}

export const InfoChit: FC<InfoChitProps> = ({ importance, className, children }) => {
  let classToUse = merge('px-2 py-1 rounded text-center font-semibold flex items-center', className);

  switch (importance) {
    case InfoChitImportance.HIGH:
      classToUse = merge(classToUse, 'dark:bg-red-600 dark:text-white bg-red-50 text-red-600');
      break;
    case InfoChitImportance.MEDIUM:
      classToUse = merge(classToUse, 'dark:bg-yellow-600 dark:text-white bg-yellow-50 text-yellow-600');
      break;
    case InfoChitImportance.LOW:
      classToUse = merge(classToUse, 'dark:bg-primary-600 dark:text-white bg-primary-50 text-primary-600');
      break;
    default:
      classToUse = merge(classToUse, 'dark:bg-grey bg-black bg-opacity-10 text-primary-std');
      break;
  }

  return <div className={classToUse}>{children}</div>;
};
