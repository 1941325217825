import LocalizedStrings from 'react-localization';
import { withCommon } from '../../services/translation/commonTranslations';

const myDataStrings = new LocalizedStrings({
  en: {
    myDataPageTitle: 'My data',
    personalInfo: 'Personal information',
    privacyPolicy: 'Privacy policy',
    termsAndConditions: 'Terms and conditions ',
    sideNav: {
      privacyInfo: 'Privacy Information',
      downloadData: 'Download your data',
      infoUpToDate: 'Keep your information up to date',
      deleteYourData: 'Delete your data',
      contactUs: 'Contact us'
    },
    privacyInformationCard: {
      title: 'Privacy Information',
      gdpr: 'GDPR: Right to be informed',
      viewTermsAndConditions: 'View terms and conditions',
      lastUpdated: 'Last updated:',
      agreed: 'Agreed:',
      viewPolicy: 'View privacy policy',
      never: 'Never'
    },
    downloadDataCard: {
      title: 'Download your data',
      gdpr: 'GDPR: Right of access, data portability',
      description:
        'As per your right, you can download all of the data TimeShift has that relates to you. ' +
        "The full download can be a large file that can take a while to process, please use the controls below to choose what you'd like to download now.",
      shiftAndBreakRecords: 'Shift & break records'
    },
    infoUpToDateCard: {
      title: 'Keep your information up to date',
      gdpr: 'GDPR: Right to rectification',
      description:
        'You have a right to update any information about yourself and can follow the links below to the relevant areas of TimeShift to complete those steps. ' +
        'You may need to contact your employer to change information such as historic shift & break records.',
      linkAdditionalInfo: 'Email, phone number, name, profile picture, etc'
    },
    deleteDataCard: {
      title: 'Delete your data',
      gdpr: 'GDPR: Right to erasure',
      description:
        'As your account is supplied through your job and is required as a working capacity as an employee, your right to erasure does not apply. In the circumstances you leave this job, ' +
        'your right to erasure will apply and your personal information will be deleted when it is no longer required for processing, e.g. reporting, export, pay, etc.'
    },
    contactUsCard: {
      title: 'Contact us',
      gdpr: 'GDPR: Right to restrict processing, object and related to automated decision making',
      description:
        'In relation to your right to restrict processing, object and anything in related to automated decision making please contact us at'
    }
  }
});

export default withCommon<typeof myDataStrings>(myDataStrings);
