import React, { FC } from 'react';
import strings from './myData.translations';
import { LinkButton } from '../../components/shared/LinkButton';
import { TSTitleCard } from '../../components/shared/card/Card';
import { RiFileUserFill } from 'react-icons/ri';

interface InformationUpToDateCardProps {}

export const informationUpToDateCardId = 'information-up-to-date-card';

export const InformationUpToDateCard: FC<InformationUpToDateCardProps> = () => {
  return (
    <TSTitleCard
      id={informationUpToDateCardId}
      title={strings.infoUpToDateCard.title}
      descriptor={strings.infoUpToDateCard.gdpr}
      icon={RiFileUserFill}
    >
      <div>
        <p className="leading-5">{strings.infoUpToDateCard.description}</p>
        <div className="mt-4">
          <LinkButton to={`/my-profile`} className="flex flex-col w-max max-w-full">
            <p className="font-medium">{strings.personalInfo}</p>
            <p className="text-xs text-secondary-std">{strings.infoUpToDateCard.linkAdditionalInfo}</p>
          </LinkButton>
        </div>
      </div>
    </TSTitleCard>
  );
};
