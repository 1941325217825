import { FC, Fragment, HTMLAttributes, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { merge } from '../../../helpers/Utility';

interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  button: ReactNode;
}

export const Dropdown: FC<DropdownProps> = ({ button, children, className }) => {
  return (
    <Menu as="div" className={merge(className, 'ml-4 relative flex-shrink-0')}>
      <Menu.Button as="div" className="w-full">
        {button}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-bottom-right absolute right-0 w-max-content rounded-md shadow-lg py-1 bg-primary-std ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
