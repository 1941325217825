import React, { FC } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TsForm } from '../../components/shared/form/Form';
import { SubmitCancelButtons } from '../../components/shared/form/SubmitCancelButtons';
import { TextFieldWithOptionalLabel } from '../../components/shared/form/TextInput';
import { Device, useCreateDeviceMutation, useGetDevicesForLocationQuery } from '../../services/gql/graphql';
import strings from './devices.translations';

interface AddDeviceFormProps {
  locationId: number;
  onComplete: () => void;
}

interface AddDeviceFields {
  name: string;
}

export const AddDeviceForm: FC<AddDeviceFormProps> = ({ locationId, onComplete }) => {
  const methods = useForm<AddDeviceFields>();

  const [{ fetching }, createDevice] = useCreateDeviceMutation();
  const navigate = useNavigate();

  const handleError = useErrorHandler();

  const [{ data }] = useGetDevicesForLocationQuery({ variables: { id: Number(locationId) } });

  const onChange = (values: string) => {
    methods.clearErrors('name');
    const devices = data?.devicesForLocation as Device[];

    const getDeviceNameFromList = devices.find(device => device?.name === values)?.name;

    if (getDeviceNameFromList) {
      methods.setError('name', {
        type: 'custom',
        message: strings.addDeviceModal.uniqueName
      });
      return;
    }
  };
  const onSubmit = async (values: AddDeviceFields) => {
    try {
      const result = await createDevice({
        args: {
          name: values.name,
          locationId: locationId!
        }
      });

      if (result.data?.createDevice?.id) {
        navigate(result.data?.createDevice?.id);
      }

      if (result.error) {
        handleError(result.error);
      } else {
        onComplete();
      }
    } catch (e) {
      handleError(e as Error);
    }
  };

  return (
    <TsForm methods={methods} onSubmit={onSubmit}>
      <div className="flex flex-col space-y-5 xl:w-96 mt-3">
        <TextFieldWithOptionalLabel
          name="name"
          title={strings.addDeviceModal.nameField}
          validationMessage={strings.addDeviceModal.nameRequired}
          required
          placeholder={strings.addDeviceModal.namePlaceholder}
          customValidatorMessage={methods.formState.errors.name?.message}
          onChange={values => onChange(values)}
        />
        <div className="flex items-center justify-end gap-3">
          <SubmitCancelButtons
            disabled={methods.formState.errors.name?.message ? true : false}
            onComplete={onComplete}
            loading={fetching}
            submitText={strings.common.create}
          ></SubmitCancelButtons>
        </div>
      </div>
    </TsForm>
  );
};
